import React from "react";
import { observer, inject } from "mobx-react";
import { HashRouter, Switch, Route, Link, withRouter } from 'react-router-dom';
import { withStore } from '../utility/Core'

import Comment from './Comment';
import PoeParsedForUI from './PoeParsedForUI';


class CommentInput extends React.Component {

	constructor() {
		super();
		this.state = {
			readyToPost: false,
		}
	}

	createReply() {
		const { relEdge, core }  = this.props;

		const uid  = core.user.uid
		const t0   = Math.round((new Date()).getTime() / 1000)
		const nid  = core.uuid('n')

		core.op({
			realm: 'node',
			op: 'nodeCreate',
			uid: core.user.uid,
			nid: nid,
			pid: core.uuid('p'),
			title: '',
			type: 'comment',
			info: {
				rel: relEdge.src.nid
			},
			lid: core.uuid('l'),
			locked_uid: uid,
			locked_t: t0,
		})

		core.op({
			realm: 'edge',
			op: 'edgeCreate',
			uid: core.user.uid,
			eid: core.uuid('e'),
			type: 'comment',
			src: {
				nid: relEdge.dst.nid, pid: null, beg: null, end: null
			},
			dst: {
				nid: nid, pid: null, beg: null, end: null
			},
			info: {
				rel: relEdge.src.nid
			},
			lid: core.uuid('l'),
		})

	}

	componentDidMount() {
		const { nid, rel, core } = this.props;
		this.newPid = core.uuid('p')
		core.setCaret({nid: nid, pid: this.newPid, pos:0})
	}

	render() {
		const { relEdge, core }  = this.props;
		const { readyToPost }    = this.state;
		// <Comment config={this.props.config} nid={this.props.nid} />

		if (!core.user.permission('create', 'edge_comment', core.nodes[relEdge.src.nid])) return <button disabled={true}>
			You cannot reply
		</button>

		return <button onClick={this.createReply.bind(this)}>
		Add a reply
		</button>

	}
}

export default withRouter(withStore(observer(CommentInput)))
