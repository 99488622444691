import React from "react";
import { observer, inject } from "mobx-react";
import { withStore } from '../utility/Core'
import { Link } from 'react-router-dom';

import Storage from "../utility/Storage";

class UserHomeButton extends React.Component {

	render() {
		const { core } = this.props;

		function logout() {

			const storage = new Storage;

			storage.query({
				'action': 'logout',
			})
			.then(function(response) {
				console.log(response);
				core.logout();
			})
			.catch(function(thrown) {
				alert('Could not reach server to properly log you out :/');
				core.logout();
			});
		}

		//return <span onClick={logout}>{core.user.short}</span>
		return <Link to="/settings" title={core.user.name}>{core.user.short}</Link>
	}

}

export default withStore(observer(UserHomeButton))
