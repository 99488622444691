import React from "react";
import ReactDOM from "react-dom";
import { action, observer, inject } from "mobx-react";
import { withStore } from '../utility/Core'

const dasherize = require('sugar/string/dasherize');
const defaults  = require('sugar/object/defaults');
const clone     = require('sugar/object/clone');

// cf. https://www.youtube.com/watch?v=WGjv-p9jYf0

class HoverTool extends React.Component {

	render() {
		const { core } = this.props;

		if (this.props.isOpen) {
			return <HoverToolCore config={this.props.config} className={this.props.className}>{this.props.children}</HoverToolCore>
		} else {
			return null;
		}
	}

}

class HoverToolCore extends React.Component {

	config = {
		css: {
				'display': 'inline-block',
				'backgroundColor': '#fff',
				'position': 'absolute',
				'border': ' 1px solid #000',
				'outline': 'none !important',
				'zIndex': '1200'
		}
	}

	componentWillMount() {
		this.hoverToolTarget = document.createElement('div');
		this.hoverToolTarget.tabIndex = '0';
		document.body.appendChild(this.hoverToolTarget);
	}

	componentWillUnmount() {
		document.body.removeChild(this.hoverToolTarget);
	}

	render() {
		let css = clone(this.config.css);
		defaults(css, this.props.config.css)

		const elm = <div style={css} class={this.props.className ? 'hover-tool-target ' + this.props.className : 'hover-tool-target'} tabIndex="0">{this.props.children}</div>

		return ReactDOM.createPortal(elm, this.hoverToolTarget)
	}
}

export default withStore(observer(HoverTool))
