import React from "react";
import { observer, inject, Provider } from "mobx-react";
import { withStore } from '../utility/Core'

import UserGroups from './Settings/UserGroups'
import UserInvites from './Settings/UserInvites'

import Storage from "../utility/Storage";

class UserPage extends React.Component {

	render() {
		const {core, uid} = this.props
		return <div className="main big-screen">coming soon</div>
	}

}

export default withStore(observer(UserPage))
