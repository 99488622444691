import axios from "axios";

export default class Storage {

	constructor(urlpath='/sync/') {

		this.cancelSource         = axios.CancelToken.source();

		this.serverUrl                                                = 'http://www.social-hypertext.de:3000'
		if (document.location.hostname == 'localhost') this.serverUrl = 'http://localhost:5000'								// /sync/
		if (document.location.protocol == 'https:')    this.serverUrl = 'https://www.shorx.de/shorx-server'									// /shorx-server/


		this.axiosParms     = {
			baseURL: this.serverUrl + urlpath,
			timeout: 15000,
			cancelToken: this.cancelSource.token,
			withCredentials: true,
			cache:  false,
		}

	}

	query = function(param) {
		this.ajax = axios.create(this.axiosParms)

		this.ajax.cancelHandler = this.cancelSource
		return this.ajax.post('/', param);
	}


}
