import React from "react";
import { observer, inject, action, runInAction } from "mobx-react";
import { withStore } from '../../utility/Core'

import UserShort from "../UserShort";
import DirectNode from "../DirectNode";

const truncate = require('sugar/string/truncate');

class NodeContextAnnotation extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			editContent: false,
		}
	}

	componentDidMount() {
		const { core, eid, nodeComponentId } = this.props
		const edge = core.edgeById(eid)

		if (!edge) return null

		if ((edge.predicate_nid) && (typeof core.nodes[edge.predicate_nid] === 'undefined')) {
			core.loadNode(edge.predicate_nid, 'full')
		}
	}

	componentWillUnmount() {
		this.endEdit()
	}

	beginEdit() {
		const { core, eid, nodeComponentId } = this.props
		const { editContent } = this.state
		const edge      = core.edgeById(eid)

		if (!edge) return null

		const lid = core.uuid('l')
		const newNid = core.uuid('n')
		const newPid = core.uuid('p')

		if (!edge.predicate_nid) {
			core.op({
				realm: 'node',
				op: 'nodeCreate',
				uid: core.user.uid,
				lid: lid,
				nid: newNid,
				pid: newPid,
				title: '',
				type: 'predicate',
				locked_uid: core.user.uid,
				locked_t: parseInt(Date.now()/1000),
				info: {
					rel: edge.eid,
				},
			})
			core.op({
				op: 'edgeUpdate',
				realm: 'edge',
				eid: edge.eid,
				uid: core.user.uid,
				lid: core.uuid('l'),
				prev_mod_uuid: edge.last_mod_uuid,
				mods: { predicate_nid: newNid },
				locked_uid: core.user.uid,
			})
		}
		else {
			this.acquireLock(edge.predicate_nid)
		}

		this.setState({editContent: true})
	}

	endEdit() {
		const { core, eid, nodeComponentId } = this.props
		const { editContent } = this.state
		const edge      = core.edgeById(eid)

		if (!edge) return null
		this.setState({editContent: false})

		// cleanup: remove empty predicates
		if (edge.predicate_nid) {
			const node = core.nodes[edge.predicate_nid]

			if ((node) && (node.loaded == 'full')) {
				if (node.txt().trim() == '') {

					core.op({
							realm: 'node',
							op:    'nodeDelete',
							uid:    core.user.uid,
							nid:    node.nid,
							prev_mod_uuid: node.last_mod_uuid,
							uid: core.user.uid,
							lid: core.uuid('l'),
					})

					core.op({
						op: 'edgeUpdate',
						realm: 'edge',
						eid: eid,
						uid: core.user.uid,
						lid: core.uuid('l'),
						prev_mod_uuid: edge.last_mod_uuid,
						mods: { predicate_nid: null },
						prev: { predicate_nid: node.nid}
					})

				}
			}

			this.releaseLock(edge.predicate_nid)
		}
	}

	updateEdge() {
		const { core, eid, nodeComponentId } = this.props
		const { editContent } = this.state

		const edge      = core.edgeById(eid)
		if (!edge) return null
		if (edge.uid != core.user.uid) return <div className='contextual-edge edge-annotation'>forbidden</div>

		if (editContent) {
			this.endEdit()
			return
		}
		else {
			this.beginEdit()
		}
	}

	acquireLock(nid) {
		const { core } = this.props
		const node = core.nodes[nid]

		if (!core.user.permission('update', 'node', node)) {
			alert('You lack the necessary permissions to edit this card.')
			return false
		}

		core.requestLock(nid)
		    .then(() => {
					//onSuccess()
					})
		    .catch((e) => {
				  if (e.status == 401) {
						alert('Could not lock the edge predicate for editing. It is already locked by another user.')
					}
					else {
						alert('Could not lock the edge predicate. You may go offline to edit it locally.')
						console.error(e)
					}
					this.setState({editContent: false})
				})
	}

	releaseLock(nid) {
		const { core } = this.props;

		core.releaseLock(nid)
		    .then(() => { })
		    .catch((e) => { /* console.error(e) */ })
	}

	deleteEdge() {
		const { core, eid, nodeComponentId } = this.props

		const edge      = core.edgeById(eid)
		if (!edge) return null

		core.op({
			op: 'edgeDelete',
			nid: edge.src.nid,
			realm: 'edge',
			eid: edge.eid,
			type: edge.type,
			src: edge.src,
			dst: edge.dst,
			prev_mod_uuid: edge.last_mod_uuid,
			uid: core.user.uid,
			lid: core.uuid('l'),
		})
	}

	render() {
		const { core, eid, nodeComponentId } = this.props
		const { editContent } = this.state

		const edge      = core.edgeById(eid)
		if (!edge) return null
		if (edge.uid != core.user.uid) return <div className='contextual-edge edge-annotation'>forbidden</div>

		const node      = edge.predicate_nid ? core.nodes[edge.predicate_nid] : false

		var content = null

		if (node) {
			if (editContent) {
				content = <DirectNode nid={node.nid} mode="edit" />
			}
			else {
				content = node.txt()
			}
		}

		const buttons = []

		// the user can always modify own annotations
		if (editContent) {
			buttons.push(<span key="button-edit" className="micro-button" onClick={this.updateEdge.bind(this)}>OK</span>)
		}
		else {
			buttons.push(<span key="button-edit" className="micro-button" onClick={this.updateEdge.bind(this)}>Modify</span>)
		}


		// the user can always delete own annotations
		buttons.push(<span key="button-del" className="micro-button" onClick={this.deleteEdge.bind(this)}>Delete</span>)

		return <div className='contextual-edge edge-annotation' data-eid={eid} title="Private Annotation. Only you can see this.">
			<span className="fa fa-exclamation icon" />
			<div className="contextual-edge-details">
				<div className={'contextual-edge-predicate' + (editContent ? ' active':'')}>{content}</div>
				<div className='contextual-edge-operations'>{buttons}</div>
			</div>
		</div>
	}

}

export default withStore(observer(NodeContextAnnotation))
