import React from "react";
import { observer, inject, Provider } from "mobx-react";
import { withStore } from '../utility/Core'

import UserGroups from './Settings/UserGroups'
import PoeParsedForUI from './PoeParsedForUI'

import Storage from "../utility/Storage";

class PermissionButton extends React.Component {

	render() {
		const { permId, value, name, modifyPermission } = this.props;

		const category = 'perm-category-' + permId.substr(0, permId.lastIndexOf('_'))

		if (typeof value === 'undefined') return <td className={category}><span className="permission-buttonfa fa-circle-o-notch fa-spin" /></td>

		if (value)  return <td key={permId} className={category} title={name} onClick={() => {modifyPermission(false)}}><span className="permission-button fa fa-check-circle" /></td>
		if (!value) return <td key={permId} className={category} title={name} onClick={() => {modifyPermission(true)}}><span className="permission-button fa fa-circle-o" /></td>
	}

}

class GroupPage extends React.Component {

	constructor() {
		super();

		this.storage = new Storage();

		this.state = {
			gid: false,
			userlist: undefined
		}
	}

	componentWillMount() {
		this.updateUserlist()
	}

	componentDidUpdate() {
		const { gid }  = this.props

		if (gid != this.state.gid) {
			this.setState({userlist: undefined})
			this.updateUserlist()
		}
	}

	componentWillUnmount() {
		if (typeof this.storage.ajax !== 'undefined') this.storage.ajax.cancelHandler.cancel('component unmounted');
	}

	updateUserlist() {
		const { core, gid } = this.props


		this.setState({gid: gid})

		this.storage.query({
			'action': 'groupUsers',
			'gid': gid,
			})
			.then((response) => {
				if (response.data.status == 200) {
					this.setState({
						userlist: response.data.data
					})
				}
				else {
					console.log(response)
				}
			})
			.catch((thrown) => {
				console.log(thrown);
			})
	}

	modifyPermission(uid, permId, value) {
		const { core, gid } = this.props

		let   oldValue = undefined
		this.state.userlist.map((row) => {
			if (row.uid == uid) {
				oldValue = row[permId]
			}
		})

		const set = (v) => {
			const userlist = this.state.userlist
			userlist.map((row) => {
				if (row.uid == uid) {
					row[permId] = v
					if (uid == core.user.uid) core.userGroup(gid)[permId] = v
				}
			})
			this.setState({userlist: userlist})
		}

		set(undefined)

		this.storage.query({
			'action': 'groupChangePermission',
			'gid': gid,
			'uid': uid,
			'permId': permId,
			'value': value,
			})
			.then((response) => {
				if (response.data.status == 200) {
					set(response.data.data.value)
				}
				else {
					set(oldValue)
				}
			})
			.catch((thrown) => {
				set(oldValue)
			})

	}

	userlist() {
		const { core } = this.props

		if (typeof this.state.userlist === 'undefined') return 'loading...'
		if (this.state.userlist.length == 0)            return 'No users in group (visible to you)'

		const perms = {

			admin:       'Admin',
			GA_update:   'Update group associations (share/unshare)',

			node_read:   'Read cards',
			node_create: 'Create cards',
			node_update: 'Update other users\' cards',
			node_delete: 'Delete other users\' cards',

			edge_like_read:   'See Likes',
			edge_like_create: 'Like cards and passages',
			edge_like_update: 'Change other users\' likes',
			edge_like_delete: 'Delete other users\' likes',

			edge_comment_read:   'Read comments',
			edge_comment_create: 'Add comments',
			edge_comment_update: 'Update other users\' comments',
			edge_comment_delete: 'Delete other users\' comments',

			edge_tag_read:   'See tags',
			edge_tag_create: 'Tag content',
			edge_tag_update: 'Update other user\'s tags',
			edge_tag_delete: 'Delete other user\'s tags',

			edge_edge_read:   'See links',
			edge_edge_create: 'Create links',
			edge_edge_update: 'Update other users\' links',
			edge_edge_delete: 'Delete other users\' links',
		}

		return <table className="permission-table">
		<thead>
			<tr className="perm-category">
				<th className="perm-global"></th>
				<th className="perm-global"></th>
				<th className="perm-global"></th>
				<th colSpan="4" className="category perm-category-node">Cards</th>
				<th colSpan="4" className="category perm-category-edge_like">Likes</th>
				<th colSpan="4" className="category perm-category-edge_comment">Comments</th>
				<th colSpan="4" className="category perm-category-edge_tag">Tags</th>
				<th colSpan="4" className="category perm-category-edge_edge">Links</th>
			</tr>
			<tr className="perm-details">
				<th>User</th>
				<th>Admin</th>
				<th>Share</th>

				<th className="perm-category-node"><span className="fa fa-eye" /></th>
				<th className="perm-category-node"><span className="fa fa-plus" /></th>
				<th className="perm-category-node"><span className="fa fa-edit" /></th>
				<th className="perm-category-node"><span className="fa fa-trash" /></th>

				<th className="perm-category-edge_like"><span className="fa fa-eye" /></th>
				<th className="perm-category-edge_like"><span className="fa fa-plus" /></th>
				<th className="perm-category-edge_like"><span className="fa fa-edit" /></th>
				<th className="perm-category-edge_like"><span className="fa fa-trash" /></th>

				<th className="perm-category-edge_comment"><span className="fa fa-eye" /></th>
				<th className="perm-category-edge_comment"><span className="fa fa-plus" /></th>
				<th className="perm-category-edge_comment"><span className="fa fa-edit" /></th>
				<th className="perm-category-edge_comment"><span className="fa fa-trash" /></th>

				<th className="perm-category-edge_tag"><span className="fa fa-eye" /></th>
				<th className="perm-category-edge_tag"><span className="fa fa-plus" /></th>
				<th className="perm-category-edge_tag"><span className="fa fa-edit" /></th>
				<th className="perm-category-edge_tag"><span className="fa fa-trash" /></th>

				<th className="perm-category-edge_edge"><span className="fa fa-eye" /></th>
				<th className="perm-category-edge_edge"><span className="fa fa-plus" /></th>
				<th className="perm-category-edge_edge"><span className="fa fa-edit" /></th>
				<th className="perm-category-edge_edge"><span className="fa fa-trash" /></th>
			</tr>
			</thead>
			<tbody>

				{
					this.state.userlist.map((row) => {
						return <tr key={row.uid}>
							<PoeParsedForUI tag="td" txt={'%'+row.uid} />{Object.keys(perms).map((key) => {



								return <PermissionButton permId={key} value={row[key]} name={perms[key]} key={key} modifyPermission={(v) => { this.modifyPermission(row.uid, key, v) }} /> })

						}</tr>
					})
				}
			</tbody>
		</table>
	}

	render() {
		const { core, gid } = this.props
		const group    = core.userGroup(gid)

		if (!group) return <div className="group-page big-screen"><h1>Invalid Group</h1></div>



		return <div className="group-page big-screen"><h1>{group.name}</h1>{this.userlist()}</div>
	}

}

export default withStore(observer(GroupPage))
