import React from "react";
import { observer, inject } from "mobx-react";
import { withStore } from '../../utility/Core'


class DevNode extends React.Component {

	/*constructor(props) {
		super(props)
		this.performUndo = this.performUndo.bind(this)
		this.performRedo = this.performRedo.bind(this)
		this.keypress    = this.keypress.bind(this)
	}

	componentWillMount() {
	}

	componentDidMount() {
		document.addEventListener('keydown', this.keypress);
	}

	componentWillUnmount() {
		document.removeEventListener('keydown', this.keypress);
	}

	keypress(e) {
		if ((e.ctrlKey) && (!e.shiftKey) && (e.keyCode == 90)) {
			this.performUndo()
			e.preventDefault()
			}
		if ((e.ctrlKey) && (e.shiftKey) && (e.keyCode == 90)) {
			this.performRedo()
			e.preventDefault()
		}
	}

	undoPos() {
		const { core } = this.props

		var undoPos = core.operations.length - 1

		while((undoPos>=0) && (core.operations[undoPos].undoes)) {
			undoPos = core.operations.findIndex(op => op.lid == core.operations[undoPos].undoes)-1
		}

		return undoPos
	}

	redoPos() {
		const { core } = this.props
		const undoPos  = this.undoPos()
		const tmp      = {}

		var redoPos    = false

		for(var i=core.operations.length - 1; i>undoPos; i--) {
			if (core.operations[i].opType == 'undo') {
				if (!tmp[core.operations[i].lid]) {
					redoPos = i
					break
				}
			}
			if (core.operations[i].opType == 'redo') {
				tmp[core.operations[i].undoes] = true
			}
		}

		return redoPos
	}

	performRedo() {
		const { core } = this.props

		const redoPos    = this.redoPos()

		if (redoPos) core.redoOperation(core.operations[redoPos].lid)
	}

	performUndo() {
		const { core } = this.props
		var undoPos    = this.undoPos()
		if (undoPos >= 0) core.undoOperation(core.operations[undoPos].lid)
	}

	render() {
		const { core } = this.props;
return null
		if (core.operations.length == 0) return 'empty stack'

		var undoButton = null
		var redoButton = null

		var undoPos    = this.undoPos()

		if (undoPos>=0) {
			undoButton = <div>
				<span className="fa fa-arrow-circle-left" onClick={this.performUndo} />{core.operations[undoPos].op} {core.operations[undoPos].str}
				</div>
		}

		var redoPos    = this.redoPos()

		if (redoPos) {
			console.log('set to redo ' + redoPos, core.operations[redoPos].op, core.operations[redoPos].str)
			redoButton = <div>
				<span className="fa fa-arrow-circle-right" onClick={() => {
						core.redoOperation(core.operations[redoPos].lid)
				}} />{core.operations[undoPos+1].op} {core.operations[undoPos+1].str} by reverting {core.operations[redoPos].op} {core.operations[redoPos].str}
				</div>
		}



		return <div>
			{undoButton}
			{redoButton}
			<div>[{undoPos} | {redoPos} / {core.operations.length}]</div>
		</div>
		/*

		var undoButton = null
		var redoButton = null

		if (core.undoPointer >= 0) undoButton = <span className="fa fa-arrow-circle-left" onClick={() => {
					if (core.undoPointer >= 0) {
						core.undoOperation(core.operations[core.undoPointer].lid)
						core.undoPointer--
					}
				}} />

		if ((core.undoPointer + 1 < core.operations.length) && (true)) redoButton = <span className="fa fa-arrow-circle-right" onClick={() => {
					if (core.undoPointer + 1 < core.operations.length) {
						core.undoPointer++
						const undoing = core.operations.find(op => op.undoes == core.operations[core.undoPointer].lid)
						console.log('redo', undoing, 'by undoing', undoing.lid);
						core.redoOperation(undoing.lid)

						//core.redoOperation(core.operations[core.undoPointer].lid)
					}
				}} />

		var mostRecent = '---'
		if (core.undoPointer > -1) mostRecent = <span>{' ' + core.operations[core.undoPointer].op + ' '}</span>; // core.operations.filter(op => op.status != 'undone').slice(-1)[0]

		return <div>
			{undoButton}
			{mostRecent}
			{redoButton}
			<div>
			[{core.undoPointer} / {core.operations.length}]
			</div>
		</div>
		*/

	render() {
		return null
	}



}

export default withStore(observer(DevNode))
