import React from "react";
import { observer } from "mobx-react";
import { withStore } from '../utility/Core'

//import ColorPicker from 'react-color-picker'
import ColorPicker from "rc-color-picker";

import UserGroups from './Settings/UserGroups'
import UserInvites from './Settings/UserInvites'
import InterfaceSettings from './Settings/InterfaceSettings'

import Storage from "../utility/Storage";
const { User } = require("../utility/User")

class Settings extends React.Component {

	constructor() {
		super()
		this.state = {
			showPicker: false,
			activeGroup: false,
		}
	}

	colorOnChange(panel, c) {
		const { core } = this.props
		core.user.color = panel.color
  }

	saveSettings() {
		const { core } = this.props
		const password = document.getElementById('password').value

		if (password !== '') {
			if (password !== document.getElementById('password-repeat').value) { alert('The new password did not match the repeat password.'); return }
			if (password.length < 8) { alert('The new password is too short.'); return }
		}

		if (document.getElementById('password-old').value === '') { alert('You need to enter your password to change your email or password.'); return }

		const storage = new Storage();

		storage.query({
			action: 'settingsUpdate',
			email: document.getElementById('email').value,
			password: password,
			password_old: document.getElementById('password-old').value,
		})
		.then((response) => {
			const reply = response.data.data
			if (typeof reply.users[core.user.uid] !== 'undefined') {
				core.user = new User(reply.users[core.user.uid])
			}
			this.setState({activeGroup: false})
		})
		.catch((thrown) => {
			console.log(thrown)
			alert('Failed to update your settings :/');
		});

	}

	render() {
		const { core } = this.props
		const { activeGroup } = this.state

		function logout() {

			const storage = new Storage();

			if ((core.nofUnsyncedOperations !== 0) && (window.confirm('You have unsynched changes. Do you really want to log out?') === false)) return

			storage.query({
				'action': 'logout',
			})
			.then(function(response) {
				core.logout();
			})
			.catch(function(thrown) {
				alert('Could not reach server to properly log you out :/');
				// core.logout();
			});
		}

		function settingsUpdate(setting) {
			switch(setting) {
				case 'username':
					if (document.getElementById('username').value !== core.user.name) core.op({
						realm: 'settings',
						op: 'settingsChange',
						key: 'username',
						value: document.getElementById('username').value
					})
					break

				case 'usershort':
					if (document.getElementById('usershort').value !== core.user.short) core.op({
						realm: 'settings',
						op: 'settingsChange',
						key: 'usershort',
						value: document.getElementById('usershort').value
					})
					break

				case 'usercolor':
					core.op({
						realm: 'settings',
						op: 'settingsChange',
						key: 'usercolor',
						value: core.user.color
					})
					break

				default:
					console.warn('undefined case ' + setting)
			}
		}

		const	colorpicker = <div id="user-color-picker">
			<ColorPicker color={core.user.color} enableAlpha={false} onChange={this.colorOnChange.bind(this)} onClose={() => { settingsUpdate('usercolor')}} />
		</div>

		var settingsButtons = <button className="col-2" onClick={ () => { this.setState({activeGroup: 'settings'}) } }>Change Settings</button>
		var passwordCheck   = null
		if (activeGroup === 'settings') {
			settingsButtons = <span className="col-2"><button onClick={ () => { this.setState({activeGroup: false}) } }>Cancel</button> <button className="col-2" onClick={this.saveSettings.bind(this)}>Save</button></span>
			passwordCheck   = <p className="row password-check"><label className="col-1" for="password">Old Password: </label><input disabled={ activeGroup !== 'settings' } defaultValue='' maxLength="64" type="password" id="password-old" /></p>
		}

		document.title = ' • Settings'

		return <div className="big-screen user-settings">
			<h1>{core.user.name}</h1>

				<div id="settings-logout-box">
				<button onClick={logout}>Log Out</button>
					<span id="settings-logout-instructions" className={ (core.nofUnsyncedOperations === 0) ? 'status' : 'warning'}>
					&nbsp; <span className="fa fa-arrow-left" /> &nbsp;
					{
						(core.nofUnsyncedOperations === 0) ? <span>You have <strong>no</strong> unsynced operations. It is safe to log out.</span> : <span>You have <strong>{core.nofUnsyncedOperations}</strong> unsynced operations! They will not be synced if you log out now.</span>
					}
					</span>
				</div>

			<div id="settings-avatar">
				<h2>Appearance</h2>
				<div className="settings-box">
					<div className="table">
						<p className="row"><label for="username">Username: </label><input onBlur={() => {settingsUpdate('username')}} defaultValue={core.user.name} maxLength="32" type="text" id="username" /></p>
						<p className="row"><label for="usershort">Shortname: </label><input onBlur={() => {settingsUpdate('usershort')}} defaultValue={core.user.short} maxLength="3" type="text" id="usershort" /></p>
						<div className="row"><label for="usercolor">Color:</label>{colorpicker}</div>
					</div>
				</div>
			</div>

			<div id="settings-userdata">
				<h2>Account Settings</h2>
				<div className="settings-box">
					<div className="table">
						<p className="row"><label className="col-1" for="email">E-Mail: </label><input disabled={ activeGroup !== 'settings' } defaultValue={core.user.email} maxLength="64" type="text" id="email" /></p>
						<p className="row"><label className="col-1" for="password">Password: </label><input disabled={ activeGroup !== 'settings' } defaultValue='' maxLength="64" type="password" id="password" /></p>
						{ activeGroup !== 'settings' ? null : <p className="row"><label className="col-1" for="password">(repeat)</label><input disabled={ activeGroup !== 'settings' } defaultValue='' maxLength="64" type="password" id="password-repeat" /></p> }
						{ passwordCheck }
						<p />
						<div className="row"><span className="col-1" > </span>{settingsButtons}</div>
					</div>
				</div>
			</div>

			<InterfaceSettings />

			<UserGroups />

			<UserInvites />

		</div>
	}
}

export default withStore(observer(Settings))
