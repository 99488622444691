import * as d3 from "d3";

export default class Graph {

	nodes
	links
	knownNodes = {}
	knownLinks = {}

	constructor(data, canvas_name, width, height) {

		this.scale = d3.scaleOrdinal(d3.schemeCategory10)
		//this.color = (d) => this.scale(d.group)
		this.color = (d) => {
			switch(d.group) {
				case 0: return '#f8f8f8'
				case 1: return 'red'
				case 2: return 'lightblue'
				case 3: return '#4c4'
			}
		}

		this.stroke = (d) => {
			if (!d) return "#fff"
			switch(d.group) {
				case 0: if (d.hint == 'tag') return '#0c0'; else return '#666'
				case 1: return '#900'
				case 2: return '#008'
				case 3: return '#0c0'
			}
		}

		this.tick = () => {
			this.link
					.attr("x1", d => d.source.x * this.z.scale + this.z.x)
					.attr("y1", d => d.source.y * this.z.scale + this.z.y)
					.attr("x2", d => d.target.x * this.z.scale + this.z.x)
					.attr("y2", d => d.target.y * this.z.scale + this.z.y)

			this.node
					.attr("cx", d => d.x * this.z.scale + this.z.x)
					.attr("cy", d => d.y * this.z.scale + this.z.y)
		}

		this.zoom = (d) => {
			this.z = {
				x: d3.event.transform.x,
				y: d3.event.transform.y,
				scale: d3.event.transform.k,
			}

			this.tick()
		}

		this.z = {
				x: 0,
				y: 0,
				scale: 1,
			}

		this.links = []
		this.nodes = []

		this.click = (e) => { console.log('native', e) }
		this.doubleClick = (e) => { console.log('native', e) }

		this.relayDoubleClick = (e) => { this.doubleClick(e); d3.event.stopPropagation() }

		this.updateNodes(data.nodes)
		this.updateLinks(data.links)

		this.simulation = d3.forceSimulation(this.nodes)
				.force("link", d3.forceLink(this.links).id(d => d.id))
				.force("charge", d3.forceManyBody())
				.force("x", d3.forceX())
				.force("y", d3.forceY());

		this.svg =  d3.select('#' + canvas_name)
									.append("svg")
									.attr("width", width)
									.attr("height", height)
									.attr("viewBox", [-width / 2, -height / 2, width, height])
									.call(d3.zoom().on("zoom", this.zoom))

		this.link = this.svg.append("g")
										.attr("stroke", "#999")
										.attr("stroke-opacity", 0.6)
										.selectAll("line")
										.data(this.links)
										.enter().append("line")
										.attr("stroke-width", d => Math.sqrt(d.value));

		this.node = this.svg.append("g")
										.selectAll("circle")
										.data(this.nodes)
										.enter().append("circle")
										.attr("r", 6)
										.attr("stroke", this.stroke)
										.attr("stroke-width", .5)
										.attr("fill", this.color)
										.on('dblclick', this.relayDoubleClick)
										.call(this.drag(this.simulation));

		this.node.append("title")
				.text(d => d.title);

		this.simulation.on("tick", this.tick)
	}

	setSize(width, height) {
		this.svg.attr("width", width)
						.attr("height", height)
						.attr("viewBox", [-width / 2, -height / 2, width, height])

		this.restart()
	}

	drag(simulation) {
		var x0, y0

		const dragstarted = (d) => {
			if (!d3.event.active) simulation.alphaTarget(0.3).restart();
			x0 = d.x
			y0 = d.y
			d.fx = x0 + ((d.x - x0) / this.z.scale)  //(d.x - this.z.x) / this.z.scale
			d.fy = y0 + ((d.y - y0) / this.z.scale)  //(d.y - this.z.y) / this.z.scale
		}

		const dragged = (d) => {
			d.fx = x0 + ((d3.event.x - x0) / this.z.scale)
			d.fy = y0 + ((d3.event.y - y0) / this.z.scale)
		}

		const dragended = (d) => {
			if (!d3.event.active) simulation.alphaTarget(0);
			d.fx = null;
			d.fy = null;
		}

		return d3.drag()
				.on("start", dragstarted)
				.on("drag", dragged)
				.on("end", dragended);
	}

	restart() {
		this.node = this.node.data(this.nodes, (d) => { return d.id;});

		this.node.exit().remove();
		this.node = this.node.enter()
			.append("circle")
			.attr("fill", this.color)
			.attr("stroke", this.stroke)
			.attr("stroke-width", .5)
			.attr("r", 6)
			.on('dblclick', this.relayDoubleClick)
			.call(this.drag(this.simulation))
			.merge(this.node)

		this.node.selectAll('title').remove()
		this.node.append("title").text(d => d.title)

		this.node.attr("fill", (d) => { return this.color(d); })
		this.node.attr("r", (d) => (d.group == 1) ? 7 : 6 )
						 .attr("stroke", this.stroke)

		// Apply the general update pattern to the links.
		this.link = this.link.data(this.links, (d) => { return d.source.id + "-" + d.target.id; });
		this.link.exit().remove();
		this.link = this.link.enter().append("line").merge(this.link);

		// Update and restart the simulation.
		this.simulation.nodes(this.nodes);
		this.simulation.force("link").links(this.links);
		this.simulation.alpha(1).restart();
	}

	updateNodes(newNodes) {
		const removables = {}
		var counter = 0

		this.nodes.map((n, i) => { removables[n.id] = i })

		newNodes.map(n => {
			delete removables[n.id]
			if (typeof this.knownNodes[n.id] !== 'undefined') {
				//console.log(this.knownNodes[n.id], this.nodes[this.knownNodes[n.id]].group, n.group, n.title)
				if (typeof this.nodes[this.knownNodes[n.id]] === 'undefined') {
					console.warn('Unknown element: this.nodes['+this.knownNodes[n.id]+'] (via this.knownNodes['+n.id+']) is undefined')
				}
				else {
					if (
						(this.nodes[this.knownNodes[n.id]].group != n.group) ||
						(this.nodes[this.knownNodes[n.id]].title != n.title) ||
						(this.nodes[this.knownNodes[n.id]].id    != n.id)
					) {
						counter++
						this.nodes[this.knownNodes[n.id]].group = n.group
						this.nodes[this.knownNodes[n.id]].title = n.title
						this.nodes[this.knownNodes[n.id]].id    = n.id
					}
				}
				return null
			}

			this.knownNodes[n.id] = this.nodes.length

			this.nodes.push(Object.create(n))
			counter++
		})


		const removeIndices = Object.entries(removables).map( r => r[1]).sort((a, b) => b - a)
		if (removeIndices.length) {
			removeIndices.map(i => {
				this.nodes.splice(i,1)
				counter++
				//Object.keys(this.knownNodes).map( k => { if (this.knownNodes[k] > i) this.knownNodes[k]-- })
			})
			this.knownNodes = {}
			this.nodes.map( (n, i) => this.knownNodes[n.id] = i )
		}

		return counter
	}

	updateLinks(newLinks) {
		const removables = {}
		var counter = 0
		this.links.map((n, i) => { removables[n.id] = i })

		newLinks.map(n => {
			delete removables[n.id]
			if (typeof this.knownLinks[n.id] !== 'undefined') {
				return null
			}

			this.knownLinks[n.id] = this.links.length
			this.links.push(Object.create(n))
			counter++
		})

		const removeIndices = Object.entries(removables).map( r => r[1]).sort((a, b) => b - a)
		if (removeIndices.length) {
			removeIndices.map(i => {
				this.links.splice(i,1)
				counter++
			})
			this.knownLinks = {}
			this.links.map( (e, i) => { this.knownLinks[e.id] = i })
		}

		return counter
	}

}
