export function makeUndo(op) {

	var undo

	switch(op.op) {

		case 'charDelete':
			undo = {
				realm: "node",
				op: "charInsert",
				nid: op.nid,
				pid: op.pid,
				pos: op.pos,
				str: op.str,
			}
			break

	  case 'charInsert':
			undo = {
				realm: "node",
				op: "charDelete",
				nid: op.nid,
				pid: op.pid,
				pos: op.pos,
				str: op.str,
			}
			break

		case 'rangeDelete':
			undo = {
				realm: "node",
				op: "rangeInsert",
				nid: op.nid,
				pid: op.pid,
				pos: op.pos,
				length: op.length,
				type: op.type,
				uuid: op.uuid,
			}
			break

		case 'rangeInsert':
			undo = {
				realm: "node",
				op: "rangeDelete",
				nid: op.nid,
				pid: op.pid,
				pos: op.pos,
				length: op.length,
				type: op.type,
				uuid: op.uuid,
			}
			break

		case 'parMerge':
			undo = {
				realm: "node",
				op: "parSplit",
				nid: op.nid,
				pid: op.pid,
				pos: op.pos,
				pid2: op.pid2,
				type2: op.type2,
			}
			break

		case 'parSplit':
			undo = {
				realm: "node",
				op: "parMerge",
				nid: op.nid,
				pid: op.pid,
				pos: op.pos,
				pid2: op.pid2,
				type2: op.type2,
				prev_mod_uuid2: op.set_mod_uuid2 || op.lid,
			}
			break

		case 'parType':
			undo = {
				op: "parType",
				realm: "node",
				nid: op.nid,
				pid: op.pid,
				val: op.old,
				old: op.val,
			}
			break

		case 'edgeDelete':
			/*
			undo = {
				realm: "edge",
				op: "edgeCreate",
				eid: op.eid,
				type: op.type,
				src: {
					nid:   op.src.nid,
					pid:   op.src.pid,
					beg:   op.src.beg,
					end:   op.src.end,
					title: op.src.title,
					prev_mod_uuid: op.src.prev_mod_uuid,
				},
				dst: {
					nid:   op.dst.nid,
					pid:   op.dst.pid,
					beg:   op.dst.beg,
					end:   op.dst.end,
					title: op.dst.title,
					prev_mod_uuid: op.dst.prev_mod_uuid,
				},
			}
			*/
			undo = {
				op: "edgeUndelete",
				realm: op.realm,
				eid: op.eid,
			}
			break

		case 'edgeUndelete':
			undo = {
				op: "edgeDelete",
				realm: op.realm,
				eid: op.eid,
			}
			break

		case 'edgeCreate':
			undo = {
				realm: "edge",
				op: "edgeDelete",
				eid: op.eid,
				type: op.type,
				src: {
					nid:   op.src.nid,
					pid:   op.src.pid,
					beg:   op.src.beg,
					end:   op.src.end,
					title: op.src.title,
					prev_mod_uuid: op.src.prev_mod_uuid,
				},
				"dst": {
					nid:   op.dst.nid,
					pid:   op.dst.pid,
					beg:   op.dst.beg,
					end:   op.dst.end,
					title: op.dst.title,
					prev_mod_uuid: op.dst.prev_mod_uuid,
				},
			}
			break

		case 'edgeUpdate':
			undo = {
				realm: "edge",
				op: "edgeUpdate",
				eid: op.eid,
				mods: {},
				prev: {},
			}
			if (typeof op.mods.src !== 'undefined') {
				const prev = {
					nid: op.mods.src.nid,
					pid: op.mods.src.pid,
					beg: op.mods.src.beg,
					end: op.mods.src.end,
					prev_mod_uuid: op.mods.src.prev_mod_uuid,
				}
				undo.mods.src = {
					nid: op.prev.src.nid,
					pid: op.prev.src.pid,
					beg: op.prev.src.beg,
					end: op.prev.src.end,
					prev_mod_uuid: op.prev.src.prev_mod_uuid,
				}
				undo.prev.src = prev
			}
			if (typeof op.mods.dst !== 'undefined') {
				const prev = {
					nid: op.mods.dst.nid,
					pid: op.mods.dst.pid,
					beg: op.mods.dst.beg,
					end: op.mods.dst.end,
					prev_mod_uuid: op.mods.dst.prev_mod_uuid,
				}
				undo.mods.dst = {
					nid: op.prev.dst.nid,
					pid: op.prev.dst.pid,
					beg: op.prev.dst.beg,
					end: op.prev.dst.end,
					prev_mod_uuid: op.prev.dst.prev_mod_uuid,
				}
				undo.prev.dst = prev
			}
			if (typeof op.mods.predicate_nid !== 'undefined') {
				undo.prev.predicate_nid = op.mods.predicate_nid
				undo.mods.predicate_nid = (op.prev && op.prev.predicate_nid) ? op.prev.predicate_nid : null
			}
			if (typeof op.mods.rpredicate_nid !== 'undefined') {
				undo.prev.rpredicate_nid = op.mods.rpredicate_nid
				undo.mods.rpredicate_nid = (op.prev && op.prev.rpredicate_nid) ? op.prev.rpredicate_nid : null
			}
			break;

		case 'groupAdd':
			undo = {
				op: "groupDel",
				realm: op.realm,
				gid: op.gid,
			}
			if (op.eid) undo.eid = op.eid
			if (op.nid) undo.nid = op.nid
			break

		case 'groupDel':
			undo = {
				op: "groupAdd",
				realm: op.realm,
				gid: op.gid,
			}
			if (op.eid) undo.eid = op.eid
			if (op.nid) undo.nid = op.nid
			break

		case 'nodeDelete':
			undo = {
				op: "nodeUndelete",
				realm: op.realm,
				nid: op.nid,
			}
			break

		case 'nodeCreate':
		case 'nodeUndelete':
			undo = {
				op: "nodeDelete",
				realm: op.realm,
				nid: op.nid,
			}
			break

		default:
			console.error('Unknown Operation to UNDO:', op.op)
			if (op.realm == 'node') return { op: 'noop', realm: 'node', nid: op.nid }
			if (op.realm == 'edge') return { op: 'noop', realm: 'edge', eid: op.eid }
			return { op: 'noop' }
	}

	undo.uid           = op.uid
	undo.prev_mod_uuid = op.lid
	undo.set_mod_uuid  = op.prev_mod_uuid
	if (typeof op.prev_mod_uuid2 !== 'undefined') {
		undo.set_mod_uuid2  = op.prev_mod_uuid2
	}

	if (op.opType == 'undo') undo.prev_mod_uuid = op.set_mod_uuid

	undo.lid           = this.uuid('l')
	undo.opType        = 'undo'

	return undo
}



export function applyOpToOp(remoteOp, op) {

	function operationShift(op, nid, pid, pos, len) {

		switch(op.op) {
			case 'charInsert':
			case 'charDelete':
				if ((op.nid == nid) && (op.pid == pid)) {
					if (pos <= op.pos) op.pos += len
				}
				break;

			case 'rangeInsert':
				if ((op.nid == nid) && (op.pid == pid)) {
					if (pos <= op.pos) {
						op.pos    = Math.max(0, op.pos + len)
						op.length = (op.pos +len) + op.length - Math.max(0, op.pos + len)
					}
					else if ((pos>op.pos) && (pos<=op.pos+op.length)) op.length = op.length + len
				}

				if (op.length <=0) op.op = 'touch-par'

				break

			case 'edgeCreate':
				// Anfang darf nicht <0 sein
				// Suspend if deleted
				if ((op.src.nid == nid) && (op.src.pid == pid)) {
					if (pos <= op.src.beg) {
						op.src.beg = Math.max(0, op.src.beg + len);
						op.src.end = Math.max(0, op.src.end + len)
					}
					else if ((pos>op.src.beg) && (pos<=op.src.end)) {
						op.src.end = op.length + len
					}
					if ((op.src.beg >= op.src.end) || (op.src.end <= 0)) {
						op.src.beg = null
						op.src.end = null
					}
				}
				if ((op.dst.nid == nid) && (op.dst.pid == pid)) {
					if (pos <= op.dst.beg) {
						op.dst.beg = Math.max(0, op.dst.beg + len);
						op.dst.end = Math.max(0, op.dst.end + len)
					}
					else if ((pos>op.dst.beg) && (pos<=op.dst.end)) {
						op.dst.end = op.length + len
					}
					if ((op.dst.beg >= op.dst.end) || (op.dst.end <= 0)) {
						op.dst.beg = null
						op.dst.end = null
					}
				}
				break

			case 'parSplit':
				if ((op.nid == nid) && (op.pid == pid)) {
					op.pos += len
				}
				break
		}
	}

	function operationBreak(op, remoteOp) {
		switch(op.op) {
			case 'charInsert':
			case 'charDelete':
				if ((op.nid == remoteOp.nid) && (op.pid == remoteOp.pid)) {
					if (remoteOp.pos <= op.pos) {
						op.pid = remoteOp.pid2
						op.pos = op.pos - remoteOp.pos
					}
				}
				break;

			case 'rangeInsert':
				if ((op.nid == remoteOp.nid) && (op.pid == remoteOp.pid)) {
					if (remoteOp.pos <= op.pos) {
						op.pid = remoteOp.pid2
						op.pos = op.pos - remoteOp.pos
					}
					else if ((remoteOp.pos > op.pos) && (remoteOp.pos < op.pos+op.length)) {
						op.length = remoteOp.pos - op.pos
					}
				}
				if (op.length <=0) op.op = 'touch-par'
				break

			case 'edgeCreate':
				if ((op.src.nid == remoteOp.nid) && (op.src.pid == remoteOp.pid)) {
					if (remoteOp.pos <= op.src.beg) {
						op.src.pid = remoteOp.pid2
						op.src.beg = Math.max(0, op.src.beg - remoteOp.pos)
						op.src.end = Math.max(0, op.src.end - remoteOp.pos)
					}
					else if ((remoteOp.pos>op.src.beg) && (remoteOp.pos<=op.src.end)) {
						if ((remoteOp.pos - op.src.beg) > (op.src.end - remoteOp.pos)) {
							op.src.end = remoteOp.pos
						}
						else {
							op.src.pid = remoteOp.pid2
							op.src.beg = 0
							op.src.end = op.src.end - remoteOp.pos
						}
					}
					if ((op.src.beg >= op.src.end) || (op.src.end <= 0)) {
						op.src.beg = null
						op.src.end = null
					}
				}
				if ((op.dst.nid == remoteOp.nid) && (op.dst.pid == remoteOp.pid)) {
					if (remoteOp.pos <= op.dst.beg) {
						op.dst.pid = remoteOp.pid2
						op.dst.beg = Math.max(0, op.dst.beg - remoteOp.pos)
						op.dst.end = Math.max(0, op.dst.end - remoteOp.pos)
					}
					else if ((remoteOp.pos>op.dst.beg) && (remoteOp.pos<=op.dst.end)) {
						if ((remoteOp.pos - op.dst.beg) > (op.dst.end - remoteOp.pos)) {
							op.dst.end = remoteOp.pos
						}
						else {
							op.dst.pid = remoteOp.pid2
							op.dst.beg = 0
							op.dst.end = op.dst.end - remoteOp.pos
						}
					}
					if ((op.dst.beg >= op.dst.end) || (op.dst.end <= 0)) {
						op.dst.beg = null
						op.dst.end = null
					}
				}
				break

			case 'parSplit':
				if ((op.nid == remoteOp.nid) && (op.pid == remoteOp.pid)) {
					if (remoteOp.pos <= op.pos) {
						op.pid = remoteOp.pid2
						op.pos = op.pos - remoteOp.pos
					}
				}
				break
		}
	}

	function operationMerge(op, remoteOp) {
		switch(op.op) {
			case 'charInsert':
			case 'charDelete':
				if ((op.nid == remoteOp.nid) && (op.pid == remoteOp.pid2)) {
					op.pid = remoteOp.pid
					op.pos = op.pos + remoteOp.pos
				}
				break

			case 'rangeInsert':
				if ((op.nid == remoteOp.nid) && (op.pid == remoteOp.pid2)) {
					op.pid = remoteOp.pid
					op.pos = op.pos + remoteOp.pos
				}
				break

			case 'edgeCreate':
				if ((op.src.nid == remoteOp.nid) && (op.src.pid == remoteOp.pid2)) {
					op.src.pid = remoteOp.pid
					op.src.beg = op.src.beg + remoteOp.pos
					op.src.end = op.src.end + remoteOp.pos
				}
				if ((op.dst.nid == remoteOp.nid) && (op.dst.pid == remoteOp.pid2)) {
					op.dst.pid = remoteOp.pid
					op.dst.beg = op.dst.beg + remoteOp.pos
					op.dst.end = op.dst.end + remoteOp.pos
				}
				break

			case 'parSplit':
				if ((op.nid == remoteOp.nid) && (op.pid == remoteOp.pid2)) {
					op.pid = remoteOp.pid
					op.pos = op.pos + remoteOp.pos
				}
				break

			case 'parMerge':
				if ((op.nid == remoteOp.nid) && (op.pid == remoteOp.pid2)) {
					op.pid = remoteOp.pid
					op.pos = op.pos + remoteOp.pos
				}
		}
	}

	switch(remoteOp.op) {
		case 'charInsert':
			operationShift(op, remoteOp.nid, remoteOp.pid, remoteOp.pos, remoteOp.str.length)
			break

		case 'charDelete':
			operationShift(op, remoteOp.nid, remoteOp.pid, remoteOp.pos, -remoteOp.str.length)
			break

		case 'parSplit':
			// vielleicht besser: rangeInsert so ändern, dass ein spillover in die Folge-paragraphs möglich wird, d.h. die op, wenn
			// in den nächsten paragraph geht, falls sie über das Ende eines paragraph hinaus lappt (und in den nächsten, und den nächsten, etc.)
			if ((op.op == 'rangeInsert') && (op.nid == remoteOp.nid) && (op.pid == remoteOp.pid) && (remoteOp.pos > op.pos) && (remoteOp.pos < op.pos+op.length)) {
				const {...dup} = op
				dup.pid    = remoteOp.pid2
				dup.length = (op.pos + op.length) - remoteOp.pos
				dup.pos    = 0
				// rolledBack.splice(i+1, 0, dup) // TODO: this was a syntax error, but why is it there?
			}
			operationBreak(op, remoteOp)
			break

		case 'parMerge':
			operationMerge(op, remoteOp)
			break

		case 'edgeCreate':
		case 'edgeDelete':
		case 'edgeInsert':
		case 'edgeUpdate':
		case 'rangeDelete':
		case 'rangeInsert':
		case 'groupAdd':
		case 'groupDel':
		case 'nodeCreate':
		case 'nodeDelete':
		case 'parType':
			// no op
			break;

		default:
			console.error('Cannot shift unknown operation ' + remoteOp.op)
	}
}
