import { makeObservable, observable, action } from "mobx";

export default class NodeHistory {
  //@observable
  recentNodes = []

  //@action
  add(node) {
    const i = this.recentNodes.findIndex( n => n.nid === node.nid )

    if (i > -1) {
      this.recentNodes.splice(i,1)
    }
    else {
      if (this.recentNodes.length >= 11) this.recentNodes.splice(11, this.recentNodes.length-11)
    }

    this.recentNodes.push({
      nid: node.nid,
      title: node.title,
    })
  }

  constructor() {
    makeObservable(this, {
      recentNodes: observable,
      add: action,
    })
  }
}
