import React from "react";
import { observer, inject, action, runInAction } from "mobx-react";
import { withStore } from '../utility/Core'
import { Link, withRouter } from 'react-router-dom';

import Storage from "../utility/Storage";

import NewEdgeDialog from "./NewEdgeDialog";
import Modal from 'react-modal'
const truncate = require('sugar/string/truncate');


Modal.setAppElement('#app')

class ModalEdgeDialog extends React.Component {

	constructor(props) {
		super(props)

		this.storage = new Storage()

		this.state = {
		}

	}

	modalClose() {
		const { core, onClose } = this.props
		onClose()
	}

	setEdge(result) {
		const { core, onClose, onSelect, marker } = this.props
		const {nid, pid, beg, end} = marker
		const node = core.nodes[nid]
		if (!node) return false
		var paragraph = node.paragraphs[pid]
		if (!paragraph) paragraph = { last_mod_uuid: null }

		// set edge
		const eid           = core.uuid('e')
		var   last_mod_uuid = core.uuid('l')
		const edge_create   = {
			op: 'edgeCreate',
			realm: 'edge',
			eid: eid,
			type: 'edge',
			src: {
				nid: nid, pid: pid, beg: beg, end: end, prev_mod_uuid: paragraph.last_mod_uuid,
			},
			dst: {
				nid: null, pid: null, beg: null, end: null, title:null
			},
			uid: core.user.uid,
			lid: last_mod_uuid,
		}

		if (result.destination.type == 'www') {
			edge_create.linked_url = result.destination.target
		}
		else {
			if (result.destination.type == 'new-card') {
				edge_create.dst.nid = core.uuid('n')
				var node_lid        = core.uuid('l')
				core.op({
					realm: 'node',
					op: 'nodeCreate',
					nid: edge_create.dst.nid,
					pid: core.uuid('p'),
					title: '' + result.destination.title,
					type: 'card',
					uid: core.user.uid,
					lid: node_lid,
				})
			}
			else {
				edge_create.dst.nid = result.destination.target
			}
		}

		core.op(edge_create)

		// set groups
		Object.keys(result.groups).forEach((gid) => {

			if (!result.groups[gid]) return
			if (gid == core.user.defaultGid) return

			const prev_last_mod_uuid = last_mod_uuid
			last_mod_uuid            = core.uuid('l')

			core.op({
				realm: 'edge',
				op:    'groupAdd',
				prev_mod_uuid: prev_last_mod_uuid,
				eid:   eid,
				gid:   parseInt(gid),
				uid:   core.user.uid,
				lid:   last_mod_uuid,
			})

			if (result.destination.type == 'new-card') {
				core.op({
					realm: 'node',
					op:    'groupAdd',
					prev_mod_uuid: node_lid,
					nid:   edge_create.dst.nid,
					gid:   parseInt(gid),
					uid:   core.user.uid,
					lid:   node_lid = core.uuid('l'),
				})
			}
		})

	}

	render() {
		const { core, onClose, onSelect, marker } = this.props

		const {nid, pid, beg, end} = marker

		const node = core.nodes[nid]
		if (!node) return null

		const modalStyle = {
			overlay: {
				zIndex:1000,
				backgroundColor: 'rgba(0, 0, 0, .75)',
			},
			content: {
				top                   : '50%',
				left                  : '50%',
				right                 : 'auto',
				bottom                : 'auto',
				marginRight           : '-50%',
				width                 : '320px',
				height                : '200px',
				transform             : 'translate(-50%, -50%)',
				overflow              : 'hidden',
			}
		}

		return <Modal
					key = "modal"
          isOpen={true}
          onRequestClose={this.modalClose.bind(this)}
          contentLabel="Set Edge"
					style={modalStyle}
        >
				<NewEdgeDialog
					nid      = {node.nid}
					onCancel = {this.modalClose.bind(this)}
					onSelect = {(result) => { this.setEdge.bind(this)(result); onSelect(result) }}
				/>
        </Modal>
	}
}


export default withStore(observer(ModalEdgeDialog))
