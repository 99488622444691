import React from "react";
import { observer, inject } from "mobx-react";
import { withStore } from '../../utility/Core'
import { withRouter } from 'react-router-dom';

//import Storage from "../../utility/Storage";
import ModalTagger from "../ModalTagger";

class Tags extends React.Component {

	constructor() {
		super()

		// this.storage     = new Storage()

		this.state = {
			showOnlyGlobal:true,
			openTagger: false,
		}

		console.log('done')
	}

	componentDidMount() {
		/*	const { core, nid, show }     = this.props
		const filter = [{
					key: 'tagged',
					value: nid,
				}]
		if (show == 'tagged') {
			this.storage.query({
				'action': 'nodelist',
				'filter': filter,
			})
			.then((response) => {
				console.log(response)
			})
		} */
	}

	componentWillUnmount() {
		// if (typeof this.storage.ajax !== 'undefined') this.storage.ajax.cancelHandler.cancel('parent component unmounted');
	}

	render() {
		const { core, nid, show }     = this.props
		var { showOnlyGlobal }  = this.state
		const duplicate = {}
		const groups = {}

		const node = core.nodes[nid]
		if (typeof node === 'undefined') return null

		var related

		if (show == 'tags') {
			related = core.edges
										.filter(e => {
											if (
												((e.type == 'tag') && (e.loaded != 'deleted')) &&
												((e.dst !== null) && (!e.linked_url) && (e.src.nid == nid))
												) {

													groups[e.groups[0].gid] = true

													if (duplicate[e.dst.nid]) {
														duplicate[e.dst.nid]++
														return false
													}
													else {
														duplicate[e.dst.nid] = 1
														return true
													}

												}

											return false
										}
										)
										.sort((a, b) => {
											const title_a = (typeof a.dst.title === 'string') ? a.dst.title : ''
											const title_b = (typeof b.dst.title === 'string') ? b.dst.title : ''
											return title_a.toLowerCase().localeCompare(title_b.toLowerCase())
										})

			//if (node.type != 'card') return null
		}

		if (show == 'tagged') {
			related = core.edges
										.filter(e => { if (((e.type == 'tag') && (e.loaded != 'deleted')) && ((e.src !== null) && (!e.linked_url) && (e.dst.nid == nid))) {
											if (duplicate[e.src.nid]) {
												duplicate[e.src.nid]++
												return false
											}
											else {
												duplicate[e.src.nid] = 1
												return true
											}
										}
										else {
											return false
										}})
										.sort((a, b) => {
											const title_a = (typeof a.src.title === 'string') ? a.src.title : ''
											const title_b = (typeof b.src.title === 'string') ? b.src.title : ''
											return title_a.toLowerCase().localeCompare(title_b.toLowerCase())
										})
			//if (node.type != 'tag') return null
		}


		var addButton = null

		if ((show == 'tags') && (node.type != 'tag')) {
			addButton = <span className="fa fa-plus-circle" title="Add a tag to the full card" onClick={() => { this.setState({ openTagger: true }) }} />
		}

		if ((related.length == 0) && (!addButton)) return null

		var groupsInfo  = ''
		const nofGroups = Object.keys(groups).length
		if (nofGroups>1) {
			groupsInfo = <span title={Object.keys(groups).map(gid => core.groupByGid(gid).name).join("\n")}> in {nofGroups} Groups</span>
		}

		const heading = (show == 'tags') ? <h1>{related.length} Tags{groupsInfo}{addButton}</h1> : <h1>{related.length} Tagged{addButton}</h1>

		const modal = (!this.state.openTagger) ? null : <ModalTagger key="modal-tagger"
			marker={{nid: nid, pid: null, beg: null, end: null }}
			onClose={() => { this.setState({ openTagger: false })	}}
			onSelect={(result) => { this.setState({ openTagger: false })	}}
			/>

		return (
		<div className="related-cards tags">
			{heading}
			<ul className={''}>
			{related.map((e) => {
					const edge    = core.edgeById(e.eid)
					const remote  = (e.src.nid == nid) ? e.dst : e.src
					const classes = ['clickable', 'tag', 'no-marks']
					const dstNode = core.nodes[remote.nid]

					var icon  = <span className='fa fa-hashtag' style={{margin:0, fontSize:'9px'}} />
					if (show == 'tagged') icon = <span className="icon fa fa-arrow-circle-o-up fa-rotate-45" />

					var   title = remote.title
					if ((typeof dstNode !== 'undefined') && (dstNode.title)) title = dstNode.title
					if (duplicate[remote.nid]>1) title = title + ' (x'+duplicate[remote.nid]+')'

					return <li key={edge.eid}
					           className={classes.join(' ')}
										 title={title}
										 //onMouseOver={() => { edge.emphasized=true }}
										 //onMouseOut={() => { edge.emphasized=false }}
										 //onClick={(e) => { if (e.ctrlKey) this.props.history.push('/edge/' + edge.eid); else this.props.history.push('/node/' + remote.nid) }}
										 onClick={(e) => { if (e.ctrlKey) this.props.history.push('/edge/' + edge.eid); else this.props.history.push('/node/' + remote.nid) }}
								 >
						{icon}{title}
					</li>
				})
			}
			</ul>
			{modal}
		</div>
		)
	}

}

export default withRouter(withStore(observer(Tags)))
