import React from "react";
import { observer, inject } from "mobx-react";
import { withStore } from '../../utility/Core'
import { withRouter } from 'react-router-dom';

import Storage from "../../utility/Storage";

class AudienceCheck extends React.Component {

	constructor() {
		super()
		this.storage     = new Storage()
		this.highlights  = {}
		this.state = {
			nodeFingerprint: false,
			edgesFingerprints: false,
		}
		this.edgeProfile = ''
	}

	getEdges() {
		const { core, nid }     = this.props
		const userCanChangeNodeGroups = core.user.permission('GA_update', 'node', core.nodes[nid])
		var profile

		const eids = core.edges
			.filter((e) => {
				return (e.type == 'edge') && (e.loaded != 'deleted') &&
				       ((e.src.nid == nid) || (e.dst.nid == nid)) &&
							 (userCanChangeNodeGroups || core.user.permission('GA_update', 'edge', e))
			})
			.map((e) => {
				profile = profile + '|' + e.eid + '/' + e.last_mod_uuid
				return e.eid
			})

		if (core.operations.filter(op => op.status != 'synced' && (op.op == 'groupAdd' || op.op == 'groupDel')).length) return

		if (profile != this.edgeProfile) {
			this.edgeProfile = profile
			//console.log('Querying for audience fingerprints', eids)
			this.storage
					.query({ action: 'audienceFingerprint', ids: eids })
					.then((response) => {
						if (response.data.status == 200) this.setState({edgesFingerprints: response.data.data})
					})
		}
	}

	componentDidMount() {
		const { core, nid }     = this.props

		this.storage
				.query({ action: 'audienceFingerprint', ids: [nid] })
				.then((response) => {
					if (response.data.status == 200) this.setState({nodeFingerprint: response.data.data[nid]})
				})

		this.getEdges()
	}

	componentDidUpdate() {
		this.getEdges()
	}

	componentWillUnmount() {
		const { core, nid }     = this.props

		Object.keys(this.highlights).map((eid) => {
			core.edgeById(eid).emphasized = false
		})
	}

	render() {
		const { core, nid }     = this.props
		var { nodeFingerprint, edgesFingerprints }  = this.state
		//console.warn('RENDER AUDIENCE CHECK')
		if (!nodeFingerprint) return null

		const faulty = []

		Object.keys(edgesFingerprints).map(key => {
			if (nodeFingerprint != edgesFingerprints[key]) faulty.push( core.edgeById(key) )
		})

		if (core.operations.filter(op => op.status != 'synced' && (op.op == 'groupAdd' || op.op == 'groupDel')).length) return <div>Cannot assess audiences while changes are not synced</div>
		if (faulty.length == 0) return null
		if (!edgesFingerprints) return null

		var nof  = faulty.length
		var verb = 'are'
		switch (faulty.length) {
			case 1: nof = 'One'; verb = 'is'; break
			case 2: nof = 'Two'; break
			case 3: nof = 'Three'; break
			case 4: nof = 'Four'; break
			case 5: nof = 'Five'; break
			case 6: nof = 'Six'; break
			case 7: nof = 'Seven'; break
			case 8: nof = 'Eight'; break
			case 9: nof = 'Nine'; break
		}

		return <div>
			<h1>Audience Check</h1>
			<p>{nof} of the links on this card {verb} visible to an audience different from the card.</p>
			{faulty.map((edge) => {
			return <span key={edge.eid}
				className="fa fa-dot-circle-o faulty-audience-link clickable"
				onMouseOver={() => { this.highlights[edge.eid] = true; edge.emphasized=true }}
				onMouseOut={() => { delete this.highlights[edge.eid]; edge.emphasized=false }}
				onClick={(e) => { this.props.history.push('/edge/' + edge.eid) }}
				/>
		})}</div>
	}

}

export default withRouter(withStore(observer(AudienceCheck)))
