import React from "react";
import { observer, inject } from "mobx-react";
import { withStore } from '../utility/Core'
import Storage from "../utility/Storage";

import Username from './PoeParsedForUI/Username'
import DirectDestination from "./DirectDestination";
import GroupSelection from "./GroupSelection";


class NewEdgeDialog extends React.Component {

	constructor(props) {
		super(props)

		this.state = {
			selectedGroups:    {},
			destination: null,
			rect: false,
		}

		this.domRef           = React.createRef()
		this.groups           = []
	}

	componentWillMount() {
		const { core } = this.props

		const selectedGroups = {}
		/* core.user.groups.map(g => { 	}) */
		selectedGroups[core.user.defaultGid] = true
		this.setState({ selectedGroups })

		this.setDestination.bind(this)(null)
	}

	componentDidMount() {
		const domRef = this.domRef.current
		const rect   = domRef ? domRef.getBoundingClientRect() : { left:0, top:0 }
		this.setState({rect})
	}

	setDestination(selectedDestination) {
		const { core, nid }  = this.props

		var destination = null

		if (selectedDestination === null) {
			destination = null
		}
		else {
			if (selectedDestination.id === 'new-url') destination = {
				type: 'www',
				target: selectedDestination.label
			}
			else if (selectedDestination.id === 'new-card') destination = {
				type: 'new-card',
				title: selectedDestination.label
			}
			else if (selectedDestination.id) destination = {
				type: 'node',
				target: selectedDestination.id
			}
		}

		this.setState({ destination })

		const selectedGroups = this.state.selectedGroups
		const groupGids      = { }

		const node           = core.nodes[nid]
		const node2          = ((destination) && (destination.type == 'node') && (typeof core.nodes[destination.target] !== 'undefined')) ? core.nodes[destination.target] : false

		groupGids[core.user.defaultGid] = true

		if (typeof node !== 'undefined') {
			node.groups.forEach( g => groupGids[g.gid] = true )
		}

		if (node2) {
			node2.groups.forEach( g => groupGids[g.gid] = true )
		}


		this.groups    = Object.keys(groupGids)
			.map( gid => core.groupByGid(gid) )
			.filter(group => group)

		// console.log(this.groups)

		this.groups.forEach( (g) => { if (typeof this.state.selectedGroups[g.gid] === 'undefined') selectedGroups[g.gid] = true } )

		this.setState({ selectedGroups })
	}

	selectGroup(gid, onoff) {
		const selectedGroups = this.state.selectedGroups
		const core = this.props.core
		if (gid != core.user.defaultGid) selectedGroups[gid]  = onoff
		this.setState({ selectedGroups })
	}

	render() {

		const { core, nid, onSelect, onCancel } = this.props

		const rect = this.state.rect || { left:0, top:0 }

		var icon        = <span className="fa fa-exclamation-circle" />
		var explanation = 'Please enter text from the card you want to link to, a URL, or a title for a new card'
		var type        = 'incomplete'


		if (this.state.destination) switch (this.state.destination.type) {

			case 'new-card':
				icon        = <span className="fa fa-plus-circle" />
				explanation = 'Creating a new card and linking to it'
				type        = 'new-card'
				break

			case 'node':
				icon        = <span className="fa fa-arrow-circle-right" />
				explanation = 'Linking to an existing card'
				type        = 'existing-card'
				break

			case 'www':
				icon        = <span className="fa fa-globe" />
				explanation = 'Linking to a webpage'
				type        = 'webpage'
				break

		}

		//console.log('offset', domRef, -rect.left + 20)

		return <div ref={this.domRef} className="dialog">
			<h1><span className="fa fa-link" />New Link</h1>

			<div className={"explanation " + type }>{icon} <span>{explanation}</span></div>

			<DirectDestination
				filter={{type: 'card'}}
				localNid={nid}
				onSelect={ this.setDestination.bind(this) }
				onCancel={ (r) => { this.setDestination.bind(this)(null) } }
				offset_top={-rect.top   + 22}
				offset_left={-rect.left + 20}
				/>

			<div className="group-select">
				<label class="group-label">Make this link available in</label>
				<GroupSelection groups={this.groups} selected={this.state.selectedGroups} doSelect={ this.selectGroup.bind(this) } />
			</div>

			<div className="dialog-buttons">
				<button disabled={!this.state.destination} onClick={() => { onSelect({ destination: this.state.destination, groups:this.state.selectedGroups }) }}>OK</button>
				<button onClick={onCancel}>Cancel</button>
			</div>

		</div>
	}
}

export default withStore(observer(NewEdgeDialog))
