import React from "react";
import { observer, inject } from "mobx-react"
import { withStore } from '../../utility/Core'
import { Link, withRouter } from 'react-router-dom'

class Nodelink extends React.Component {

	constructor() {
		super();
		this.state = {
		}
	}

	componentWillMount() {
		const { core, nid } = this.props;

		//if (typeof core.cache.userDetails[uid] === 'undefined') core.getUserInfo(uid)

	}

	render() {
		const { core, nid, children } = this.props;
		var content
		const node = core.nodes[nid]

		if (children) {
			content = children
		}
		else {
			content = <span className="fa fa-question-circle" />
			if ((node) && (node.title)) {
				content = node.title
				if (node.type == 'tag') content = <span><span className="fa fa-hashtag"/>{node.title}</span>
			}
		}

		return <Link to={'node/' + nid}>{content}</Link>
	}

}

export default withRouter(withStore(observer(Nodelink)))
