import React from "react";
import { observer, inject } from "mobx-react";
import { withStore } from '../../utility/Core'
import { Link } from 'react-router-dom';

const bytes = require('sugar/number/bytes');

class DevCore extends React.Component {

	render() {
		const { core, nid } = this.props;
		//const sizeof = require('object-sizeof');
		//const size = sizeof(core);
		const openOps = core.nofUnsyncedOperations;

		let expert = null
		if (core.user.expert) {
			expert = [<tr key="row-1">
					<td>Expert:</td><td class="val">{core.user.expert ? 'yes' : 'no'}</td>
				</tr>,<tr key="row-2">
					<td>Connectivity:</td><td class="val">{core.connectivity.forceOffline ? 'halted' : 'auto'} / {core.connectivity.online ? 'ok' : 'off'}</td>
				</tr>,<tr key="row-3">
					<td>Caret:</td><td class="val">{core.caret.pos} (#{core.caret.length ? core.caret.length : ''})</td>
				</tr>]
		}

		return (
		<div>
			<h1>Condition of State</h1>
			<table>
			<tbody>
				<tr>
					<td>Nodes loaded:</td><td class="val">{Object.keys(core.nodes).length}</td>
				</tr><tr>
				</tr><tr>
					<td>Edges loaded:</td><td class="val">{core.edges.length}</td>
				</tr><tr>
					<td><Link to="/operations">Operations:</Link></td><td class="val"><Link to="/operations"><strong>{ openOps } ► {core.operations.length - openOps}</strong></Link></td>
				</tr><tr>
					<td></td><td>&nbsp;</td>
				</tr>
				{expert}
			</tbody>
			</table>

		</div>
		)
	}

}

export default withStore(observer(DevCore))
