import React from "react";
import { observer, inject } from "mobx-react";
import { withStore } from '../../utility/Core'
import { withRouter } from 'react-router-dom';

class Related extends React.Component {

	constructor() {
		super()
		this.state = {
			showOnlyGlobal:true
		}
	}

	componentWillUnmount() {

	}

	render() {
		const { core, nid }     = this.props
		var { showOnlyGlobal }  = this.state
		const marked = {}
		const direction = {}
		const duplicate = {}

		const related = core.edges
		                    .filter(e => {
													if ((e.type == 'edge') && (e.loaded != 'deleted') && (e.dst !== null) && (!e.linked_url) && (e.src.nid == nid || e.dst.nid == nid)) {
														var remote
														if (e.src.nid == nid) {
															remote = e.dst
															console.log(remote.nid + ' outgoing:', direction[remote.nid], (direction[remote.nid] == 'incoming') ? 'incoming-outgoing' : 'outgoing')
															direction[remote.nid] = ((direction[remote.nid] == 'incoming') || (direction[remote.nid] == 'incoming-outgoing')) ? 'incoming-outgoing' : 'outgoing'
															if ((e.src.pid !== null) && (e.src.beg !== null) && (e.src.end !== null)) marked[e.eid] = true
														}
														else {
															remote = e.src
															console.log(remote.nid + ' incoming:', direction[remote.nid], (direction[remote.nid] == 'outgoing') ? 'incoming-outgoing' : 'incoming')
															if ((e.dst.pid !== null) && (e.dst.beg !== null) && (e.dst.end !== null)) marked[e.eid] = true
															direction[remote.nid] = ((direction[remote.nid] == 'outgoing') || (direction[remote.nid] == 'incoming-outgoing')) ? 'incoming-outgoing' : 'incoming'
														}

														if (typeof duplicate[remote.nid] === 'undefined') {
															duplicate[remote.nid] = 1;
															return true
														}

														duplicate[remote.nid]++
													}
													return false
												})
/*												.map((e) => {
													if (e.src.nid == nid) {

													} else {

													}
													return e
												})  */
												.sort((a, b) => {
													const remote_a = (a.src.nid == nid) ? a.dst : a.src
													const remote_b = (b.src.nid == nid) ? b.dst : b.src

													const remote_a_title = remote_a.title ? remote_a.title : 'zzz'
													const remote_b_title = remote_b.title ? remote_b.title : 'zzz'

													var r = 0
													if (duplicate[remote_a.nid] > duplicate[remote_b.nid]) return -1
													if (duplicate[remote_a.nid] < duplicate[remote_b.nid]) return 1
													return remote_a_title.localeCompare(remote_b_title)
												})

		// shortlist that only contains edges otherwise invisible
		const shortlist = related.filter(e => !marked[e.eid])

		// don't show the box if there are no edges
		if (related.length == 0) return null

		// don't show the limited view (regardless of the user settings) if there is nothing to see there
		if (shortlist.length == 0) showOnlyGlobal = false

		// switch between "unmarked edges" and "all edges"
		var modeSwitch = <div className="mode-switch">
			<span className={'clickable' + (showOnlyGlobal ? ' active' : '') + (shortlist.length == 0 ? ' forbidden' : '')} onClick={() => { this.setState({showOnlyGlobal: !this.state.showOnlyGlobal}) }}>{shortlist.length} Unmarked</span>
			<span className={'clickable' + (showOnlyGlobal ? '' : ' active') + (false ? ' forbidden' : '')} onClick={() => { this.setState({showOnlyGlobal: !this.state.showOnlyGlobal}) }}>{related.length} Total</span>
		</div>

		// non-experts only get the comprehensive view
		if (!core.user.expert) {
			modeSwitch     = null
			showOnlyGlobal = false
		}

		const list = showOnlyGlobal ? shortlist : related

		return (
		<div className="related-cards">
			<h1>{related.length} Related Cards</h1>
			{modeSwitch}
			<ul className={modeSwitch ? 'tabbed': ''}>
			{list.map((e) => {
					const edge    = core.edgeById(e.eid)
					const remote  = (e.src.nid == nid) ? e.dst : e.src
					const classes = [
						'clickable',
						marked[e.eid] ?   'marked' : 'global',
						direction[remote.nid],
					]

					const remoteTitle = remote.title ? remote.title : <span className="fa fa-question-circle-o" />

					var                                       icon = <span className="fa icon fa-stack"><span className='fa fa-arrow-circle-o-up fa-rotate-45 fa-stack-1x' /><span className='fa fa-arrow-circle-o-down fa-rotate-315 fa-stack-1x' /></span>
					if (direction[remote.nid] == 'incoming')  icon = <span className='fa icon fa-arrow-circle-o-down fa-rotate-315' />
					if (direction[remote.nid] == 'outgoing')  icon = <span className='fa icon fa-arrow-circle-o-up fa-rotate-45' />

					var                                      title = ' is connected to "' + remote.title + '"'
					if (direction[remote.nid] == 'incoming') title = '"' + remote.title + '" links here'
					if (direction[remote.nid] == 'outgoing') title = 'links to "' + remote.title + '"'

					return <li key={edge.eid}
					           className={classes.join(' ')}
										 title={title}
										 onMouseOver={() => { edge.emphasized=true }}
										 onMouseOut={() => { edge.emphasized=false }}
										 onClick={(e) => { if (e.ctrlKey) this.props.history.push('/edge/' + edge.eid); else this.props.history.push('/node/' + remote.nid) }}
								 >
						{icon} {remoteTitle} { duplicate[remote.nid]>1 ? ' (x'+duplicate[remote.nid]+')':'' }
					</li>
				})
			}
			</ul>
			{this.state.showOnlyGlobal}
		</div>
		)
	}

}

export default withRouter(withStore(observer(Related)))
