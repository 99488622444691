import React from "react";
import { observer, inject } from "mobx-react";
import { withStore } from '../utility/Core'
import { HashRouter, Switch, Route, Link, withRouter } from 'react-router-dom';


class Message extends React.Component {

	constructor() {
		super()
		this.state = {
			timer: 0
		}
	}

	componentDidMount() {
		const { core, msg } = this.props

		if (!msg.t_seen) {
			msg.t_seen = Date.now()
			this.setState({timer: Date.now() - msg.t_seen})
		}

		if (msg.timeout) {
			this.interval = setInterval(() => { this.setState({timer: Date.now() - msg.t_seen}) }, 100)
		}

	}

	componentWillUpdate(nextProps, nextState) {
		const { core, msg } = this.props

		if (msg.timeout) {
			if ((this.state.timer/1000 < msg.timeout) && (nextState.timer/1000>=msg.timeout)) {
				clearInterval(this.interval)
				if (msg.buttons.length) core.op(msg.buttons.filter(m => m.default)[0].op)
			}
		}
	}

	componentWillUnmount() {
		if (this.interval) clearInterval(this.interval)
	}

	render() {

		const { core, msg } = this.props

		const body  = <div className="body" title={msg.mid}>{msg.body}</div>
		var buttons = null
		if (msg.buttons.length) {
			buttons = <div>{ msg.buttons.map((b) => {
				var txt       = b.txt
				var className = ''
				var disabled  = false
				if ((msg.timeout) && ((msg.timeout - parseInt((this.state.timer)/1000))<= 0)) disabled = true

				if (b.default) {
					if (msg.timeout) {
						if (msg.timeout - parseInt((this.state.timer)/1000) > 0) {
							txt = '(' + (msg.timeout - parseInt((this.state.timer)/1000)) + ') ' + b.txt
						}
						className = 'default'
					}
				}
				return <button disabled={disabled} className={className} key={b.txt} onClick={() => { core.op(b.op) }}>{txt}</button>
			}) }</div>
		}


		return <div className="message">
			{body}
			{buttons}
		</div>

	}

}

export default withStore(observer(Message))
