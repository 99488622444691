//import { observable, computed, action } from "mobx";
import { Group } from "./Group"
const { observable, computed, action } = require("mobx")



export class User {

	constructor(obj = {}) {

		const groups    = (typeof obj.groups !== 'undefined') ? obj.groups.map((g) => new Group(g)) : []

		this.uid             = obj.uid ? obj.uid : false
		this.name            = obj.name ? obj.name : false
		this.short           = obj.short ? obj.short : false
		this.email           = obj.email ? obj.email : false
		this.color           = obj.color ? obj.color : '#eee'
		this.groups          = groups
		this.defaultGid      = obj.defaultGid ? obj.defaultGid : false
		this.expert          = (typeof obj.expert !== 'undefined') ? obj.expert : false
		this.notify_interval = (typeof obj.notify_interval !== 'undefined') ? obj.notify_interval : 86400
		this.perms           = (typeof obj.perms !== 'undefined')  ? obj.perms : {}
	}

	/*
		checks if the user has permission perm in the realm realm (node, link, tag, comment, like, ...) on object obj

		Called either as "permission('create', 'group/node', {gid: gid})"
		                 to check if the user has the privilege perm on the given group (makes sense only for 'create', because it does not have an object yet)

							 or as "permission('create', edge_*, node)

		           or as "permission('update', 'node', {...})
							       to check if the INTERSECTION of the user's groups and the node's groups gives the user the requested permission
	*/
	permission(perm, realm=null, obj = undefined) {

		if (typeof obj === 'undefined') return false

		if ((realm == 'node') && (obj.type == 'predicate')) {
			realm = 'edge_edge'
		}

		if (perm == 'create') {
			switch(realm) {
				case 'group':
					return obj.admin
					break

				case 'node':
					return obj.node_create
					break

				case 'tag':
					return obj.edge_tag_create // Sonderfall Tag: Tag-nodes erstellen und Tag-edges erstellen sind die gleicher permission
					break

				case 'edge_like':
				case 'edge_tag':
				case 'edge_comment':
				case 'edge_edge': {
					const permission_name = realm + '_create'
					const applicable_groups = obj.groups.filter( (g) => {
						const i = this.groups.findIndex( (ug) => { return ug.gid == g.gid} )
						if (i>-1) return this.groups[i][permission_name]
					} )
					return applicable_groups.length
					}
					break

				case 'edge_annotation': {
						const applicable_groups = obj.groups.filter( (g) => {
							const i = this.groups.findIndex( (ug) => { return ug.gid == g.gid} )
							if (i>-1) return this.groups[i]['node_view']
						} )
						return applicable_groups.length
					}
					break


				default:
					console.error('unknown realm ' + realm)
					return false;
			}


		}
		else if (perm == 'GA_update') {
			if ((typeof obj === 'undefined') || (typeof obj.groups === 'undefined')) return false

			// group associations for tags can never be changed
			if (obj.type == 'tag') return false

			return obj.groups.filter( (g) => {
				return this.groups.findIndex( (ug) => { return ((ug.gid == g.gid) && (ug.GA_update)) }) > -1
			}).length
		}
		else {
			if ((typeof obj === 'undefined') || (typeof obj.groups === 'undefined')) return false

			const permission_name = realm + '_' + perm

			if ((permission_name == 'edge_annotation_update') && (obj.uid == this.uid)) {
				return 1
			}

			if ((permission_name == 'edge_annotation_delete') && (obj.uid == this.uid)) {
				return 1
			}

			const applicable_groups = obj.groups.filter( (g) => {
				const i = this.groups.findIndex( (ug) => { return ug.gid == g.gid} )
				if (i>-1) return this.groups[i][permission_name]
			} )

			return applicable_groups.length
		}
	}

}
