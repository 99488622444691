import React from "react";
import Storage from "../utility/Storage";
import { observer, inject } from "mobx-react";
import { withStore } from '../utility/Core'
import DirectNode from "./DirectNode";


class Predicate extends React.Component {

	createPredicate(which) {
		const { core, edge } = this.props

		const newNid = core.uuid('n')
		const newPid = core.uuid('p')

		const edgegroups = edge.groups.map( g => g.gid )
		const groups = core.user.groups.filter( g => edgegroups.includes(g.gid) && (g.gid != core.user.defaultGid) ).map( g => g.gid )
		const lid = core.uuid('l')

		var mods = {}

		core.op({
			realm: 'node',
			op: 'nodeCreate',
			uid: core.user.uid,
			lid: lid,
			nid: newNid,
			pid: newPid,
			title: '',
			type: 'predicate',
			locked_uid: core.user.uid,
			locked_t: parseInt(Date.now()/1000),
			info: {
				rel: edge.eid,
			}
		})

		const node = core.nodes[newNid]

		if (this.props.simple) {
			mods = {
				predicate_nid: newNid,
				rpredicate_nid: newNid
			}
		}
		else {
			if (which == 'predicate_nid') {
				mods = { predicate_nid: newNid }
			}
			else {
				mods = { rpredicate_nid: newNid }
			}
		}

		core.op({
			op: 'edgeUpdate',
			realm: 'edge',
			eid: edge.eid,
			uid: core.user.uid,
			lid: core.uuid('l'),
			prev_mod_uuid: edge.last_mod_uuid,
			mods: mods
		})

		core.op({
			realm: 'node',
			op: 'charInsert',
			nid: newNid,
			pid: newPid,
			uid: core.user.uid,
			lid: core.uuid('l'),
			prev_mod_uuid: lid,
			str: "relates to",
			pos: 0,
		})
	}

	mirrorPredicate(which) {
		const { core, edge } = this.props

		var mods = {}

		if (which == 'predicate_nid') {
			mods = { predicate_nid: edge.rpredicate_nid }
		}
		else {
			mods = { rpredicate_nid: edge.predicate_nid }
		}

		core.op({
			op: 'edgeUpdate',
			realm: 'edge',
			eid: edge.eid,
			prev_mod_uuid: edge.last_mod_uuid,
			mods: mods
		})

	}

	render() {
		const { core, edge, reverse, contextIsReverse, fnFlipSides } = this.props
		var content  = []
		var which    = 'predicate_nid'
		var whichnot = 'rpredicate_nid'

		if (reverse) {
			which    = 'rpredicate_nid'
			whichnot = 'predicate_nid'
		}

		const switchbutton = <span key="button-switch" className="inline-button" onClick={ () => { fnFlipSides() } }><span className="fa fa-exchange"></span> Flip Sides</span>
		const editbutton   = <span key="button-edit"   className="inline-button" onClick={ () => { this.createPredicate(which) } }><span className="fa fa-plus-square"></span> Add Info</span>
		const mirrorbutton = <span key="button-mirror" className="inline-button" onClick={ () => { this.mirrorPredicate(which) } }><span className="fa fa-clone"></span> Mirror</span>

		const nid = edge[which]

		const classes = ['section', 'edge-details-predicate']
		if (Array.isArray(this.props.className)) this.props.className.map( c => classes.push(c) )

		if (nid) {
			if (reverse == contextIsReverse) {
				content.push(<DirectNode key="node" nid={nid} mode="edit" />)
			} else {
				content.push(<span key="info">Has info.</span>) // ("' + core.nodes[nid].title + '")'
				content.push(switchbutton)
			}
			classes.push('has-info')
		}
		else {

			if ((edge.linked_url) && (reverse)) {
				content.push(<span key="predicate-info">WWW-links have no reverse</span>)
				content.push(switchbutton)
			}
			else {
				if (reverse == contextIsReverse) {
					content.push(<span key="predicate-info">No info, direct link.</span>)
					content.push(editbutton)
					if (edge[whichnot]) content.push(mirrorbutton)
				}
				else {
					content.push(<span key="predicate-info">No info, direct link.</span>)
					content.push(switchbutton)
				}
			}
		}


		if (reverse == contextIsReverse) {
			classes.push('dir-active')
		}


		return <div className={classes.join(' ')}>
					<span class="icon fa fa-info-circle"></span>
					<div class="content">
					{content}
					</div>
				</div>
	}

}

export default withStore(observer(Predicate))
