import React from "react";
import { observer, inject, action, runInAction } from "mobx-react";
import { withStore } from '../utility/Core'

class DirectParagraphStyles extends React.Component {

	constructor(props) {
		super(props)

		this.domRef       = React.createRef();

		this.prev = {
			nid: undefined,
			pid: undefined,
		}
	}
	
	changeParStyle(nid, pid, event) {
		const { core, setCursorAfterOp, nodeComponentId } = this.props

		const paragraph = core.nodes[nid].paragraphs[pid]
		const pos = core.caretX[nodeComponentId].pos
		const length = core.caretX[nodeComponentId].length
		const lid = core.uuid('l')

		core.op({
			op:            'parType',
			realm:         'node',
			nid:           nid,
			pid:           pid,
			val:           event.target.value,
			old:           paragraph.type,
			uid:           core.user.uid,
			prev_mod_uuid: paragraph.last_mod_uuid,
			lid:           lid,
		})

		setCursorAfterOp(lid, pid, pos+length, -length)
	}

	render() {
		const { core, nodeComponentId, disabled } = this.props

		const empty = <div ref={this.domRef} className="block"><select disabled='disabled'><option>Paragraph</option></select></div>

		if (disabled) return empty
		if (Object.keys(core.caretX).indexOf(nodeComponentId) == -1) return empty

		const nid = core.caretX[nodeComponentId].nid

		if (typeof core.nodes[nid] === 'undefined') return empty
		if (typeof core.nodes[nid].paragraphs[core.caretX[nodeComponentId].pid] === 'undefined') return empty

		const types = [
			{key: 'h1',         name: 'Heading 1',},
			{key: 'h2',         name: 'Heading 2',},
			{key: 'h3',         name: 'Heading 3',},
			{key: 'p',          name: 'Paragraph',},
			{key: 'ul',         name: 'Bulletlist',},
			{key: 'ol',         name: 'Numbered List',},
			{key: 'pre',        name: 'Pre-Formatted',},
			{key: 'blockquote', name: 'Blockquote',},
		]

		const node = core.nodes[nid]
		const paragraph = node.paragraphs[core.caretX[nodeComponentId].pid]
		const [type] = paragraph.type.split('-')

		const parStyle = <select value={type} onChange={(e) => { this.changeParStyle(nid, core.caretX[nodeComponentId].pid, e) }}>{
			types.map((type) => {
				return <option value={type.key} key={type.key}>{type.name}</option>
			})
		}</select>

		var play = null

		return <div ref={this.domRef} className="block">{parStyle}{play}</div>
	}

}

export default withStore(observer(DirectParagraphStyles))
