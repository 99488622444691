import React from "react";
import { observer, inject } from "mobx-react";
import { withStore } from '../utility/Core'

var dateCreate = require('sugar/date/create');
var dateFormat = require('sugar/date/format');


class DateString extends React.Component {

	render () {
		const t        = parseInt(Date.now() / 1000)
		const date     = dateCreate(1000 * this.props.value)
		const tag      = (typeof this.props.tag   !== 'undefined') ? this.props.tag : 'span'
		var props      = (typeof this.props.props !== 'undefined') ? this.props.props : {}


		let contents   = dateFormat(date, '%e. %h')
		if (Math.abs(this.props.value - t) > 365 * 24 * 60 * 60) {
			contents   = dateFormat(date, '%h %Y')
		}
		else if (Math.abs(this.props.value - t) < 8 * 60 * 60) {
			contents   = dateFormat(date, '%H:%M')
		}

		props.title = dateFormat(date, '{long}')

		return React.createElement(tag, props, contents);
	}

}

export default withStore(observer(DateString))
