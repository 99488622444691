const { makeObservable, observable, computed, action } = require("mobx")
const { Mark } = require('./poe-mark.js')
const { Edge } = require('./poe-mark.js')

export class Paragraph {

	/* @computed get */
	get weight() {
		return this.context.paragraphList.findIndex( (e) => e == this.pid )
	}

	/* @computed get */
	get edges() {
		// TODO: Besser, wenn das auf parentNode.edges geht? (denn die Menge ist deutlich kleiner, wenn mehrere nodes geladen sind)
		return this.context.edges.filter( (edge) => { return (edge.src.pid == this.pid) || (edge.dst.pid == this.pid) })
	}

	/* @computed get */
	get edgeMarks() {
		// TODO: Besser, wenn das auf parentNode.edges geht? (denn die Menge ist deutlich kleiner, wenn mehrere nodes geladen sind)

		let res = this.context.edges.reduce( (result, edge) => {
			if ((edge.src.pid == this.pid) && ((edge.src.beg) || (edge.src.end) || true)) result.push({
					local: edge.src,
					remote: edge.dst,
					edge: edge,
					inverted: false,
					dir: 'src',
					emphasized: edge.emphasized,
				});
			if ((edge.dst.pid == this.pid) && ((edge.dst.beg) || (edge.dst.end) || true)) result.push({
					local: edge.dst,
					remote: edge.src,
					edge: edge,
					inverted: true,
					dir: 'dst',
					emphasized: edge.emphasized,
			});

			return result;
		}, []);

		return res;
	}

	/* @computed get */
	get DOM() {
		return document.getElementById(this.pid);
	}

	markupAt(pos) {
		return this.markup.filter( (mark) => { return (mark.beg<pos) && (mark.end>=pos) } )
	}

	edgesAt(pos) {
		return this.edgeMarks.filter( (mark) => { return (mark.local.beg<pos) && (mark.local.end>=pos) } )
	}

	//@computed get
	get orderedTagOpenClose() {
		var openclose = [];
		this.markup.map( (markup) => {
			openclose.push({ pos: markup.beg, tag: markup, isBeg: true })
			openclose.push({ pos: markup.end, tag: markup, isBeg: false })
		})

		return openclose.sort((a, b) => {
			if (a.pos > b.pos) return 1
			if (a.pos < b.pos) return -1
			return 0
		});
	}

	//@computed get
	get renderTree() {
		var  start = 0,
		      tags = [''],
					tree = [];
		const tagsInOrder = this.orderedTagOpenClose();
		const readyText = this.txt.replace(/^ | $|( ) /g, "$1\u00A0");// + ' <b>bold</b> special.';

		tagsInOrder.map( (point) => {
			tree.push({
				txt: readyText.substr(start, point.pos - start),
				tags: [...tags],
			});
			if (point.isBeg) {
				tags.push(point.tag.type);
			}
			else {
				tags.splice(tags.findIndex( (s) => {return s == point.tag.type} ), 1);
			}
			start = point.pos;
		});
		if (start < readyText.length) {
			tree.push({
				txt: readyText.substr(start, readyText.length - start),
				tags: [...tags],
			});
		}
		return tree;
	}

	/* @action */
	parMerge(op, node, edges) {
		const parIndex  = node.paragraphList.findIndex( (e) => e == op.pid )
		const parIndex2 = node.paragraphList.findIndex( (e) => e == op.pid2 )
		const that      = node.paragraphs[op.pid2]
		const length    = this.txt.length

		this.txt = this.txt + that.txt

		for(let i = that.markup.length-1; i>=0; i--) {
			const existsAt = this.markup.findIndex( (m) => ((m.type == that.markup[i].type) && (m.end == length)) )

			if (existsAt == -1) {
				this.markup.push(new Mark(that.markup[i].uuid, that.markup[i].beg + length, that.markup[i].end + length, that.markup[i].type, that.markup[i].vanishable, that.markup[i].delta))
			}
			else {
				this.markup[existsAt].end = that.markup[i].end + length;
				// todo: track side effect
			}
		}

		for(let i = that.edgeMarks.length-1; i>=0; i--) {
			that.edgeMarks[i].edge.taint('shift', op.lid)
			if (that.edgeMarks[i].local.beg !== null) that.edgeMarks[i].local.beg += length;
			if (that.edgeMarks[i].local.end !== null) that.edgeMarks[i].local.end += length;
			that.edgeMarks[i].local.pid = op.pid;
		}

		node.paragraphList.splice(parIndex2, 1)
		node.paragraphs[op.pid2] = null;
		this.last_mod_uuid = op.set_mod_uuid || op.lid
	}

  /* @action */
	parSplit(op, node, edges) {
		const parIndex = node.paragraphList.findIndex( (e) => e == op.pid )
		const newType  = op.type2

		const txt = this.txt.substr(op.pos) + '';
		this.txt  = this.txt.substr(0, op.pos)

		let markup = [];
		for(let i = this.markup.length-1; i>=0; i--) {
			if (this.markup[i].beg >= op.pos) {
				let [elm] = this.markup.splice(i, 1);
				elm.beg = elm.beg-op.pos;
				elm.end = elm.end-op.pos;
				markup.unshift(elm)
				continue;
			}

			if ((this.markup[i].beg < op.pos) && (this.markup[i].end > op.pos)) {
				let elm = new Mark(this.markup[i].uuid, this.markup[i].beg, this.markup[i].end, this.markup[i].type, this.markup[i].vanishable, this.markup[i].delta);
				this.markup[i].end = op.pos;
				elm.beg = 0;
				elm.end = elm.end - op.pos;
				markup.unshift(elm)
				continue;
			}
		}

		for (let i=this.edgeMarks.length-1; i>=0; i--) {
			if (this.edgeMarks[i].local.beg >= op.pos) {
				this.edgeMarks[i].local.beg = (this.edgeMarks[i].local.beg === null) ? null: this.edgeMarks[i].local.beg - op.pos
				this.edgeMarks[i].local.end = (this.edgeMarks[i].local.end === null) ? null: this.edgeMarks[i].local.end - op.pos
				this.edgeMarks[i].edge.taint('shift', op.lid)
				this.edgeMarks[i].local.pid = op.pid2
				continue
			}

			if ((this.edgeMarks[i].local.beg < op.pos) && (this.edgeMarks[i].local.end > op.pos)) {
				if ((op.pos - this.edgeMarks[i].local.beg) > (this.edgeMarks[i].local.end - op.pos)) {
					this.edgeMarks[i].local.end = (this.edgeMarks[i].local.end === null) ? null : op.pos;
					this.edgeMarks[i].edge.taint('shift', op.lid)
				}
				else {
					this.edgeMarks[i].local.beg = (this.edgeMarks[i].local.beg === null) ? null : 0
					this.edgeMarks[i].local.end = (this.edgeMarks[i].local.end === null) ? null : this.edgeMarks[i].local.end - op.pos
					this.edgeMarks[i].edge.taint('shift', op.lid)
					this.edgeMarks[i].local.pid = op.pid2
				}
			}
		}

		node.paragraphList.splice(parIndex+1, 0, op.pid2)
		node.paragraphs[op.pid2] = new Paragraph(
			{edges: edges, paragraphList: node.paragraphList},
			{
				pid: op.pid2,
				uid: op.uid,
				nid: op.nid,
				type: newType,
				txt:txt,
				markup: markup
			}
		);

		this.last_mod_uuid = op.set_mod_uuid || op.lid
		if (typeof op.set_mod_uuid2 !== 'undefined') {
			node.paragraphs[op.pid2].last_mod_uuid = op.set_mod_uuid2
		}
		else {
			node.paragraphs[op.pid2].last_mod_uuid = op.set_mod_uuid || op.lid
		}
	}

	/* @action */ parType(op) {
		op.old = this.type;
		this.type = op.val;
		this.last_mod_uuid = op.set_mod_uuid || op.lid
	}

	/* @action */ charInsert(op) {
		this.txt = this.txt.substr(0, op.pos) + op.str + this.txt.substr(op.pos);
		this.last_mod_uuid = op.set_mod_uuid || op.lid

		//shift markup
		this.shiftMarkup(op.pos, op.str.length);
		this.shiftEdges(op.pos, op.str.length, op.lid, op.prev_mod_uuid, op.t_local);
	}

	charDelete(op) {
		this.txt = this.txt.substr(0, Math.max(0, op.pos)) + this.txt.substr(Math.min(this.txt.length, op.pos + op.str.length));
		this.last_mod_uuid = op.set_mod_uuid || op.lid

		//shift markup
		this.shiftMarkup(op.pos, -op.str.length)
		this.shiftEdges(op.pos, -op.str.length, op.lid, op.prev_mod_uuid, op.t_local);
	}

	rangeInsert(op) {
		const mark = new Mark(op.uuid, op.pos, op.pos+op.length, op.type, true, false)
		for(let i=this.markup.length-1; i>=0; i--) {
			if ((mark.type == this.markup[i].type) && (
				 ((mark.beg >= this.markup[i].beg) && (mark.beg <= this.markup[i].end)) ||		// der Anfang des neuen liegt IN/AN einem bestehenden
				 ((mark.end >= this.markup[i].beg) && (mark.end <= this.markup[i].end))	||		// das Ende des neuen liegt IN/AN einem bestehenden
				 ((mark.beg <= this.markup[i].beg) && (mark.end >= this.markup[i].end))				// der Anfang liegt VOR/AN einem bestehenden und das Ende HINTER/AN dessen ende
				 )) {

					if ((mark.beg <= this.markup[i].end) && (mark.beg >= this.markup[i].beg)) {
						//console.log('new mark beginning touches existing end');
						mark.beg = this.markup[i].beg;
					}
					if ((mark.end >= this.markup[i].beg) && (mark.end <= this.markup[i].end)) {
						//console.log('new mark end touches existing beginning', mark.beg, this.markup[i].end);
						mark.end = this.markup[i].end;
					}

					this.markup.splice(i, 1)
			}
		}
		this.markup.push(mark);
		this.last_mod_uuid = op.set_mod_uuid || op.lid
	}

	rangeDelete(op) {
		const index = this.markup.findIndex( (m) => m.uuid == op.uuid )
		if (index > -1) {
			this.markup.splice(index, 1)
			this.last_mod_uuid = op.lid
		}
		this.last_mod_uuid = op.set_mod_uuid || op.lid
	}

	shiftMarkup(pos, amount) {
		if (amount>0) {
			for(let i=this.markup.length-1; i>=0; i--) {
				if (this.markup[i].beg >= pos) this.markup[i].beg += amount;
				if (this.markup[i].end >= pos) this.markup[i].end += amount;
			}
		}
		else if (amount<0) {
			for(let i=this.markup.length-1; i>=0; i--) {
				if (this.markup[i].beg > pos) this.markup[i].beg = Math.max(pos, this.markup[i].beg + amount);
				if (this.markup[i].end > pos) this.markup[i].end = Math.max(pos, this.markup[i].end + amount);
				if ((this.markup[i].beg >= this.markup[i].end) && (this.markup[i].vanishable)) {
					// todo: remember removed markup for UNDO
					this.markup.splice(i,1);
				}
			}
		}
	}

	shiftEdges(pos, amount, log_uuid, prev_mod_uuid, t_local) {
		for(let i=this.edgeMarks.length-1; i>=0; i--) {
			if (this.edgeMarks[i].local.beg === null) continue;
			if (this.edgeMarks[i].edge.t_updated > t_local) continue;

			if (this.edgeMarks[i].local.beg > pos) {
				this.edgeMarks[i].edge.taint('shift', log_uuid)
				this.edgeMarks[i].local.beg = Math.max(pos, this.edgeMarks[i].local.beg + amount)
			}
			if (this.edgeMarks[i].local.end > pos) {
				this.edgeMarks[i].edge.taint('shift', log_uuid)
				this.edgeMarks[i].local.end = Math.max(pos, this.edgeMarks[i].local.end + amount)
				//console.log('shift', this.edgeMarks[i].edge);
			}
			if (this.edgeMarks[i].local.end <= this.edgeMarks[i].local.beg) {

				/*if ((this.context.user.permission('delete', 'edge_' + this.edgeMarks[i].edge.type, this.edgeMarks[i].edge))) {
					this.edgeMarks[i].edge.taint('delete', log_uuid)
					const edgeAt = this.context.edges.findIndex( (e) => e.eid == this.edgeMarks[i].edge.eid )
					this.context.edges.splice(edgeAt, 1)
					console.log('DELETED edge at ' + edgeAt);
				}
				else {*/
					this.edgeMarks[i].edge.taint('shift', log_uuid)
					this.edgeMarks[i].local.beg = null;
					this.edgeMarks[i].local.end = null;
					console.log('SUSPENDED edge:', this.edgeMarks[i].edge);
				/*}*/
			}
		}

	}

	forceCaretPos(pos) {
		if (pos === false) return;
		if (typeof this.contentMap === 'undefined') {
			return;
		}

		var elm = false, sub = 0;

		this.contentMap.map( (line) => {
			if (line.start <= pos) { elm = line.node; sub = line.start }
		});

		if (elm === false) elm = document.getElementById(this.pid);

		var sel = window.getSelection();
				sel.removeAllRanges();

		var range = document.createRange();

		range.selectNodeContents(elm);
		try {
			range.setStart(elm, pos-sub);
			range.setEnd(elm, pos-sub);

			range.collapse();
			sel.addRange(range);
		}
		catch(e) {
			console.log(e, range, elm);
		}
	}

	cursorScreenPos() {
		if (!window.getSelection().anchorNode) return null;
		const caret = window.getSelection().getRangeAt(0);
		const rect  = caret.getClientRects();
		//console.log(rect[0])
		if (typeof rect[0] === 'undefined') return null;

		const doc = document.documentElement;
		const scrollLeft = (window.pageXOffset || doc.scrollLeft) - (doc.clientLeft || 0);
		const scrollTop = (window.pageYOffset || doc.scrollTop)  - (doc.clientTop || 0);

		return {
			top: (rect[0].top + scrollTop),
			bottom: (rect[0].bottom + scrollTop),
			left: (rect[0].left + scrollLeft),
			right: (rect[0].rigth + scrollLeft),
			height: (rect[0].height),
			width: (rect[0].width),
			}
	}

	exportable() {
		return {
			pid:       this.pid,
			uid:       this.uid,
			nid:       this.nid,
			type:      this.type,
			txt:       this.txt,
			t_created: this.t_created,
			t_updated: this.t_updated,
			last_mod_uuid: this.last_mod_uuid,
			markup:    this.markup,
		}
	}

	constructor(context, obj) {

		this.pid            = obj.pid
		this.uid            = obj.uid
		this.nid            = obj.nid
		this.type           = obj.type
		this.txt            = obj.txt
		this.markup         = []
		this.t_created      = obj.t_created
		this.t_updated      = obj.t_updated
		this.last_mod_uuid  = obj.last_mod_uuid
		this.last_sync_uuid = obj.last_sync_uuid
		this.contentMap     = []

		if (obj.markup) obj.markup.map( (m) => {
			//console.log(obj.pid, m)
			this.markup.push(new Mark(m.uuid, m.beg, m.end, m.type, m.vanishable ? m.vanishable : true, m.delta ? m.delta : false));
		})

		this.context = {user: context.user, edges: context.edges, paragraphList: context.paragraphList};

		makeObservable(this, {
			txt: observable,
			type: observable,
			markup: observable,
			last_mod_uuid: observable,
			last_sync_uuid: observable,
			t_updated: observable,

			parMerge: action,
			parSplit: action,
			parType: action,
			charInsert: action,
			charDelete: action,
			rangeInsert: action,
			rangeDelete: action,
			shiftMarkup: action,
			shiftEdges: action,
			forceCaretPos: action,

			weight: computed,
			edges: computed,
			edgeMarks: computed,
			DOM: computed,
			orderedTagOpenClose: computed,
			renderTree: computed,
		})
	}

}
