import React from "react";
import ReactDOM from "react-dom";
import { HashRouter, Switch, Route, Link } from 'react-router-dom';

//import Modal from 'react-modal';

import { observer, inject, Provider } from "mobx-react";
import { withStore } from '../utility/Core'

//import Annotations from './Annotations';
import Conversation from './Conversation';
import Header from "./Header";
import HoverTool from './HoverTool';
import Invite from "./Invite";
import LoadState from "./LoadState";
import LoginForm from "./LoginForm";
import Modal from './Modal';
import DirectNodeList from "./DirectNodeList"
import OpList from "./OpList";
import GroupPage from "./GroupPage";
import Settings from "./Settings";
import UserPage from "./UserPage";
import Wall from "./Wall";

import SingleEdge from "./SingleEdge";

import NewEdgeDialog from "./NewEdgeDialog";

import Topography from "./Visualization/Topography";

import DirectDestination from "./DirectDestination";

import SidebarLeft from "./SidebarLeft";
import SingleNode from "./SingleNode";

class Layout extends React.Component {

	constructor(props) {
		super(props)
		this.showNodeList = this.showNodeList.bind(this)
	}

	componentDidMount() {
		const { core } = this.props
		console.log('Layout mounted', core.ui.param.layoutLoadReady, core.connectivity.stateIsLoaded)
		core.ui.set('layoutLoadReady', true)
	}

	componentWillUnmount() {
		console.log('Layout unmounts')
	}

	showNodeList(filter, type) {
		const { core } = this.props
		const nodefilter = []
		console.log('render <Layout /> . showNodeList()')

		core.user.groups.map( (g) => {
			if (g.active) {
				nodefilter.push({
					key: 'groups',
					value: g.gid,
				})
			}
		})

		if (filter) {
			const filtersByUrl = JSON.parse(filter)

			filtersByUrl.map((f) => {
				if (nodefilter.findIndex((r) => { return ((r.key == f.key) && (r.value==f.value)) }) === -1) nodefilter.push(f)
			})

		}
// <DevAnnotations />

		return [
			<DirectNodeList key="nodelist" className="main" filter={nodefilter} type={ type ? type : undefined } />,
			<SidebarLeft key="sidebar-left" context="nodelist" />
		]
	}

	render() {
		const { core } = this.props
		console.log('render <Layout />')

		let nids = Object.keys(this.props.core.nodes)

		if (core.fatalError) {
			return (<div>Oh no! A fatal error has occured. <pre>{core.fatalError.msg}</pre> Please reload :/</div>)
		}

		if (!this.props.core.ui.param.layoutLoadReady) {
			console.log('render waitscreen')
			return <div onClick={() => { this.props.core.ui.set('layoutLoadReady', true) }}>Click here to load Shorx (and let me know that you did)</div>
		}

		if (typeof core.user.uid !== 'number') {
			return (
			<HashRouter>
				<Switch>
					<Route path="/invite/:uuid" render={({match}) => { return <Invite invite={match.params.uuid} /> }} />
					<Route component={LoginForm} />
				</Switch>
			</HashRouter>
			)
		}

		if (!core.connectivity.stateIsLoaded) {
			return <LoadState />
		}

		return (
				<HashRouter>
					<div id="routed">

						{/* Header is always present */}
						<Header />

						{/* Modals etc */}
						<Modal isOpen={core.modal.isOpen} config={core.modal.config}>{core.modal.content}</Modal>
						<HoverTool isOpen={core.hoverTool.isOpen} config={core.hoverTool.config}>{core.hoverTool.content}</HoverTool>

						{/*  Routes */}

						<Route exact path="/"   render={ ({match}) => { return this.showNodeList() } } />
						<Route path="/tags"     render={ ({match}) => { return this.showNodeList(match.params.filter, 'tag') } } />
						<Route path="/comments" render={ ({match}) => { return this.showNodeList(match.params.filter, 'comment') } } />

						<Route path="/wall" render={ () => {
							return [
								<Wall key="wall" />,
								<SidebarLeft key="sidebar-left" context='wall' />
							]
						}} />

						<Route exact path="/operations" render={ () => {
							return [
								<OpList key="oplist" />,
								<SidebarLeft key="sidebar-left" context='operations' />
							]
						}} />

						<Route exact path="/settings" render={ ({match}) => { return [
								<Settings key="settings" />,
								<SidebarLeft key="sidebar-left" context='settings' eid={match.params.eid} />
							]
							}}
						/>

						<Route path="/filtered/:filter" render={ ({match}) => { return this.showNodeList(match.params.filter) } } />

						<Route path="/user/:uid"  render={ ({match}) => { return <UserPage uid={match.params.uid} /> } } />

						<Route path="/group/:gid" render={ ({match}) => { return [
							<GroupPage key="group" gid={match.params.gid} />,
							<SidebarLeft key="sidebar-left" context='group' eid={match.params.eid} />
							]
							}}
						/>

						<Route path="/edge/:eid" render={ ({match}) => { return [
								<SingleEdge key="edge" eid={match.params.eid} />,
								<SidebarLeft key="sidebar-left" context='edge' eid={match.params.eid} />
							]
							}}
						/>

						<Route exact path="/meta/:nid" render={({match}) => {
							const nid  = match.params.nid;
							const node = core.nodes[nid]
//							if ((typeof node !== 'undefined') && (typeof node.title !== 'undefined')) document.title = ' • ' + node.title.substr(0, 20); else document.title = ' • ' +  '---'

							return [
									<SingleNode key="node" nid={match.params.nid} mode="settings" />,,
									<SidebarLeft key="sidebar-left" context='single-node' nid={nid} />
								]
							 }}
						 />

						<Route exact path="/sneak/:nid" render={({match}) => {
							//return null
							const nid  = match.params.nid;
							const node = core.nodes[nid]
							document.title = ' • SNEAK :)'
//							if ((typeof node !== 'undefined') && (typeof node.title !== 'undefined')) document.title = ' • ' + node.title.substr(0, 20); else document.title = ' • ' +  '---'
							///<DirectDestination />
							if (core.user.uid !== 2) throw('forbidden')
							return [
									<div className="main node-list big-screen" key="tests">
										...
									</div>,
									<SidebarLeft key="sidebar-left" context='single-node' nid={nid} />
								]
							 }}
						 />

						<Route exact path="/history/:nid" render={({match}) => { return [
								<SingleNode key="node" nid={match.params.nid} mode="history" />,
								<SidebarLeft key="sidebar-left" context='single-node' nid={match.params.nid} />
							]
						 }} />

						{/*  Node Rendering  */}

						<Route path="/node/:nid/:category?/:id?" render={ ({match}) => {
							const nid                = match.params.nid;
							const node               = core.nodes[nid]
							const className          = ["main", "card"]
							const config             = {}
							const commentNid         = match.params.id == 'null' ? null : match.params.id
							const commentNode        = core.nodes[commentNid]

							var edge = core.edges.filter(e => e.dst.nid == commentNid && e.type == 'comment')[0]
							if ((typeof edge !== 'undefined') && (edge.src.nid != match.params.nid)) {//edge = core.edges.filter(e => e.src.nid == match.params.nid && e.type == 'comment')[0]
								var edge2 = core.edges.filter(e => e.src.nid == match.params.nid && e.dst.nid == edge.src.nid && e.type == 'comment' )[0]
								edge = edge2
							}


							switch (match.params.category) {
								case 'comment':
									config.contentEditable      = false
									config.suppressOverlays     = true
									config.suppressNodeControls = true
									config.markEdge             = { eid: edge ? edge.eid : false, dir: 'src' }
									break;
							}

//							if ((typeof node !== 'undefined') && (typeof node.title !== 'undefined')) document.title = ' • ' + node.title.substr(0, 20); else document.title = ' • ' + '---'

							var position = null
							if ((match.params.category == 'goto') && (match.params.id)) {
								const [pid, pos] = match.params.id.split('.')
								position = {
									pid: pid,
									pos: pos
								}
							}

							return [
								<SingleNode key="direct-node" nid={nid} position={position} />,
								<SidebarLeft key="sidebar-left" context='single-node' nid={nid} key="sidebar" />,
							]

						}} />

						<Route path="/node/:nid1/comment/:nid2" render={ ({match}) => { return <Conversation className={['comment-thread']} rel={match.params.nid1} nid={(match.params.nid2 == 'null' ? null : match.params.nid2)} /> } }/>

						<Route exact path="/invite/:uuid" render={ () => { return (<div className="center-dialog">You cannot use an invite while you are logged in. Please log out first :)</div>) } } />

					</div>
				</HashRouter>
		);
	}

}



class AnnotationBox extends React.Component {
	render() {
		return <div>Comment Box</div>
	}
}

export default withStore(observer(Layout))
