import React from "react";
import { observer, inject, action, runInAction } from "mobx-react";
import { withStore } from '../../utility/Core'

import UserShort from "../UserShort";

const truncate = require('sugar/string/truncate');

class NodeContextLike extends React.Component {

	deleteEdge() {
		const { core, eid, nodeComponentId } = this.props

		const edge      = core.edgeById(eid)
		if (!edge) return null

		core.op({
			op: 'edgeDelete',
			nid: edge.src.nid,
			realm: 'edge',
			eid: edge.eid,
			type: edge.type,
			src: edge.src,
			dst: edge.dst,
			prev_mod_uuid: edge.last_mod_uuid,
			uid: core.user.uid,
			lid: core.uuid('l'),
		})
	}

	moveEdge() {
		const { core, eid, nodeComponentId } = this.props

		const edge      = core.edgeById(eid)
		if (!edge) return null

		const node      = core.nodes[edge.src.nid]
		if (!node) return null

		alert('Coming soon...')
	}

	alsoLikeEdge() {
		const { core, eid, nodeComponentId, setCursorAfterOp } = this.props

		const edge      = core.edgeById(eid)
		if (!edge) return null

		const pos = edge.src.beg
		const nid = edge.src.nid
		const pid = edge.src.pid
		const length = edge.src.end - edge.src.beg
		const paragraph = core.nodes[nid].paragraphs[pid]

		core.op({
			op: 'edgeCreate',
			realm: 'edge',
			eid: core.uuid('e'),
			type: 'like',
			src: {
				nid: nid, pid: pid, beg: Math.min(pos+length, pos), end: Math.max(pos+length, pos), prev_mod_uuid: paragraph.last_mod_uuid,
			},
			dst: {
				nid: null, pid: null, beg: null, end: null
			},
			uid: core.user.uid,
			lid: core.uuid('l'),
		})

		setCursorAfterOp(null, pid, pos, length)

	}

	render() {
		const { core, eid, nodeComponentId } = this.props

		const edge      = core.edgeById(eid)
		if (!edge) return null

		const node      = core.nodes[edge.src.nid]
		if (!node) return null

		//const paragraph = core.nodes[edge.src.nid].paragraphs[edge.src.pid]
		//const string    = paragraph.txt.substr(edge.src.beg, edge.src.end - edge.src.beg)
		const txt       = core.markedText(edge.src)

		if (!edge) return null

		var buttons = []

		if (edge.uid == core.user.uid) {
			if (core.user.permission('update', 'edge_like', edge)) {
				buttons.push(<span key="button-edit" className="micro-button" onClick={this.moveEdge.bind(this)}>Move</span>)
			}
			if (core.user.permission('delete', 'edge_like', edge)) {
				buttons.push(<span key="button-del" className="micro-button" onClick={this.deleteEdge.bind(this)}>Delete</span>)
			}
		}
		else {
			if ((core.user.perms.like) && (core.user.permission('create', 'edge_like', node))) {
				buttons.push(<span key="button-like" className="micro-button" onClick={this.alsoLikeEdge.bind(this)}>Also like</span>)
			}
		}


		//return <div className='contextual-edge edge-like'><span className="fa fa-thumbs-o-up" /> <UserShort uid={edge.uid}/> likes "{truncate(txt, 20, 'middle')}"</div>
		return <div className='contextual-edge edge-like'>
			<span className="fa fa-thumbs-o-up icon" />
			<div className="contextual-edge-details">
				<div className='contextual-edge-like-info'><UserShort uid={edge.uid}/> likes "{truncate(txt, 20, 'middle')}"</div>
				<div className='contextual-edge-operations'>{buttons}</div>
			</div>
		</div>
	}

}

export default withStore(observer(NodeContextLike))
