import React from "react";
import { observer, inject } from "mobx-react";
import { withStore } from '../../utility/Core'


class DevNode extends React.Component {

	componentWillMount() {
		this.setState({
			showMarkupDetails: false,
			showNodeDetails: false,
		})
	}

	render() {
		const { core, nid } = this.props;

		const node = core.nodes[nid];
		const pid  = core.caret.pid;

		if (typeof node === 'undefined') return null

		if (node.loaded != 'full') return null;

		if (typeof node === 'undefined') {
			return <div>
			<h1>Current Node</h1>
			-- loading... --
			</div>
		}

		let title = '---';
		if ((node.paragraphList.length>0) && (node.paragraphs[node.paragraphList[0]].txt)) {
			title = node.paragraphs[node.paragraphList[0]].txt.substr(0, 12) + '...';
		}

		let pidLine = null; let markupDetails = null; let nodeDetails = null;
    if (pid) {
			pidLine = (<tr>
				<td style={{textAlign:"center", color: '#888', 'backgroundColor': '#fff', 'border': '1px solid #ccc', fontFamily:'monospace'}} colSpan="2">#{pid}</td>
			</tr>)

			if (this.state.showMarkupDetails == pid) {
				markupDetails = (<tr>
					<td colSpan="2">DETAILS</td>
				</tr>)
			}

		}
		if (this.state.showNodeDetails == nid) {
			let paragraphs = [];
			node.paragraphList.map( (p) => {
				let markup_details = null, edge_details = null;
				if (this.state.showMarkupDetails == p) {
					markup_details = <ul>{
						node.paragraphs[p].markup.map( (mark) => {
							return <li key={mark.uuid}>{mark.beg + '-' + mark.end + '['+mark.type+']'}</li>
						})
					}</ul>
					edge_details = <ul>{
						node.paragraphs[p].edgeMarks.map( (mark) => {
							return <li key={mark.edge.eid}>{mark.local.beg + '-' + mark.local.end + '['+mark.edge.type+'] ' + mark.edge.eid}</li>
						})
					}</ul>
				}
				paragraphs.push(<div key={p} onClick={ () => {  this.setState({showMarkupDetails: this.state.showMarkupDetails ? false : p }) } }>
						{node.paragraphs[p].pid}: [{node.paragraphs[p].markup.length} | {node.paragraphs[p].edgeMarks.length}]{markup_details}{edge_details}
					</div>);
			})
			nodeDetails = (<tr>
				<td style={{fontSize:'10px' , textAlign:"left", color: '#888', 'backgroundColor': '#fff', 'border': '1px solid #ccc', fontFamily:'monospace'}} colSpan="2">...PID................M...E.{paragraphs}</td>
			</tr>)
		}

		return (
		<div>
			<h1>Node: <span style={{fontWeight:'100'}}>{title}</span></h1>
			<table>
			<tbody>
				<tr>
					<td>Scope</td><td className="val">{core.nodeScope(nid).map( (g) => {return g.name}).join(', ')}</td>
				</tr>
				<tr>
					<td style={{textAlign:"center", color: '#888', 'backgroundColor': '#fff', 'border': '1px solid #ccc', fontFamily:'monospace'}} colSpan="2">#{nid}</td>
				</tr>{pidLine}<tr onClick={() => { this.setState({showNodeDetails: this.state.showNodeDetails ? false : nid }) }}>
					<td>Nof Paragraphs:</td><td className="val">{node.paragraphList.length}</td>
				</tr>{nodeDetails}<tr>
					<td>Nof Markups:</td><td className="val">{node.paragraphList.reduce( (s, o) => { return s + node.paragraphs[o].markup.length }, 0)}</td>
				</tr><tr>
					<td>Nof Overlays:</td><td className="val">{node.paragraphList.reduce( (s, o) => { return s + node.paragraphs[o].edgeMarks.length }, 0)}</td>
				</tr><tr>
					<td colSpan="2">{ node.nofDirty + ' Paragraphs out-of-sync' }</td>
				</tr><tr>
					<td colSpan="2">{
						node.paragraphList.reduce( (sum, pid, i) => sum + node.paragraphs[pid].edgeMarks.filter(m => ((m.local.beg === null) && (m.local.end === null))).length, 0)
					} Lost edges</td>
				</tr>
			</tbody>
			</table>
		</div>
		)
	}

}

export default withStore(observer(DevNode))
