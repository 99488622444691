//<div id="sidebar-left" key="sidebar-left"><DevCore /><DevNode nid={match.params.nid} /></div>

import React from "react";
import { observer } from "mobx-react";
import { withStore } from '../utility/Core'

import GroupPosts from "./Sidebar/GroupPosts"
import Tags from "./Sidebar/Tags";
import Related from "./Sidebar/Related";
import AudienceCheck from "./Sidebar/AudienceCheck";
import NodeAttachments from "./Sidebar/NodeAttachments";

import DevCore from "./Dev/dev-Core";
import DevState from "./Dev/dev-State";
import DevNode from "./Dev/dev-Node";


import Message from "./Message";


class SidebarLeft extends React.Component {

	render() {
		const { core, context, nid } = this.props

		const boxes = []


		if (core.messages.recent.length) {
			const nofMesages = core.messages.recent.length
			boxes.push({
				name: 'messages',
				obj: <div><h3>{nofMesages} Messages</h3>
					<Message msg={core.messages.recent[nofMesages-1]} />
				</div>
			})
		}

		if (core.operations.filter( op => op.status === 'failed').length) {
			boxes.push({name: 'sync-error', obj: <div className="alert">
			<div className="heading"><span className="fa fa-exclamation-triangle" /> <strong>Ups. This is embarrassing...</strong></div><br />
			<p>It seems that there was a problem syncing your last operations to the server <span className="fa fa-frown-o" /></p><p><a href="/#/operations">Please see the List of Operations for details</a>.  Apologies!</p>
			</div>})
		}

		switch(context) {

			case 'single-node':
				boxes.push({
					name: 'tags',
					obj: <Tags nid={nid} show='tags' />
				})
				boxes.push({
					name: 'tagged',
					obj: <Tags nid={nid} show='tagged' />
				})
				boxes.push({
					name: 'related',
					obj: <Related nid={nid} />
				})
				boxes.push({
					name: 'attachments',
					obj: <NodeAttachments nid={nid} />
				})
				boxes.push({
					name: 'audienceCheck',
					obj: <AudienceCheck nid={nid} />
				})
				break;

			case 'edge':
			case 'nodelist':
				break

			default:
				console.warn('Unknown context in SidebarLeft', context)

		}

		boxes.push({
			name: 'context',
			obj: <GroupPosts />,
		})


		if (core.user.expert) {

			boxes.push({
				name: 'devState',
				expert: true,
				obj: <DevState />
			})

			boxes.push({
				name: 'devCore',
				expert: true,
				obj: <DevCore />
			})

			switch(context) {

				case 'single-node':
					boxes.push({
						name: 'devNode',
						expert: true,
						obj: <DevNode nid={nid} />
					})
					break;

				case 'edge':
				case 'nodelist':
					break

				default:
					console.warn('Unknown context in SidebarLeft', context)

			}

		}


		return <div id="sidebar-left" key="sidebar-left">
		{ boxes.map( (box) => {

			return <div key={box.name} className={'sidebox' + (box.expert ? ' expert' : '')} id={"sidebox-" + box.name}>
				{box.obj}
			</div>

		}) }
		</div>
	}
}

export default withStore(observer(SidebarLeft))
