import React from "react";
import { observer, inject } from "mobx-react";
import { withStore } from '../../utility/Core'
import { withRouter } from 'react-router-dom';

import PoeParsedForUI from '../PoeParsedForUI';

class GroupPosts extends React.Component {

	groupDoSelect(e, gid) {
		const { core } = this.props;

		if (!e.ctrlKey) core.user.groups.map((group) => {
			if ((group.gid != gid) && (group.active)) core.setGroupActive(group.gid, false)
		})

		const group = core.userGroup(gid)

		core.setGroupActive(gid, !group.active)
	}

	render() {
		const { core } = this.props;

		const groups = core.user.groups.map( (group) => {

			if (group.type == 'self') return
			if (group.type == 'friend') return

			const filter = [{key:'groups', value:group.gid}]
			let   className = 'fa fa-square-o '
			if (group.active) className = 'fa fa-square'

			return <li key={group.gid} className='clickable'>
				<span onClick={(e) => { this.groupDoSelect(e, group.gid) }} className={className} />
				&nbsp;
				<span onClick={(e) => { this.groupDoSelect(e, group.gid); this.props.history.push('/') }}>
					<PoeParsedForUI tag='span' props={{className: 'group-name cell'}} txt={group.name} key={group.gid} />
				</span>
			</li>
		})

		return <div id="sidebar-group-list">
			<h1>{core.user.groups.filter(g => g.active).length ? 'Fixed' : 'Fix'} Scope</h1>
			<ul>
			{groups}
			</ul>
			</div>
	}
}

export default withRouter(withStore(observer(GroupPosts)))
