import React from "react";
import { observer, inject, Provider } from "mobx-react";
import { withStore } from '../../utility/Core'
import { Link, withRouter } from 'react-router-dom';

import PoeParsedForUI from '../PoeParsedForUI';


class UserGroups extends React.Component {

	constructor() {
		super();
		this.state = {
			groups: []
		}
	}

	componentDidMount() {
		const { core } = this.props
		this.setState({groups: core.user.groups})
		this.groupGetList();
	}

	render() {
		const { core } = this.props

		return <div id="settings-groups">
			<h2>{core.user.groups.length} Groups</h2>
			<div class="scrollable-list settings-box">
			<ul>{
			this.state.groups.map((row) => {
				return <SettingsGroupInfo key={row.gid} group={row} />
			})
		}</ul>
		</div>
		<button onClick={this.createGroup.bind(this)}>Create New Group</button>
		</div>
	}

	groupGetList() {
		const { core } = this.props
		core.getGroups()
			.then((res) => {
				//console.log(res);
				if (res instanceof Array) this.setState({groups: res}); else this.setState({groups: []})
			})
			.catch((thrown) => {
				this.setState({groups: []})
			})
	}

	createGroup() {
		const { core } = this.props
		const pname = window.prompt('Group Name:')

		if (pname == '') return false

		const name = pname.trim()

		if (name == '') return false

		if (name.indexOf('%') != -1) {
			alert('Group names may not contain the % sign');
			return false
		}

		if (name.indexOf('&') != -1) {
			alert('Group names may not contain the & sign');
			return false
		}

		if (name.length <3) {
			alert('Group names must be longer');
			return false
		}

		if (name.toLowerCase() == 'private') {
			alert('This group name is reserved');
			return false
		}

		core.groupCreate(name)
			.then( (res) => {
				console.log('GROUP CREATED', res)
				this.groupGetList()
			})
			.catch((thrown) => {
				console.log(thrown)
				this.setState({groups: []})
			})
	}
}


class SettingsGroupInfo extends React.Component {

	render() {
		const { core, group } = this.props;

		let onOff = <span class="fa fa-circle-o"></span>

		const permissionName = {
			c: {short: 'create', long: 'create new content'},
			r: {short: 'read', long: 'read existing content'},
			u: {short: 'update', long: 'update existing content'},
			d: {short: 'delete', long: 'delete existing content by others'},
			a: {short: 'admin', long: 'administer the group'},
		}

		const perms = ['a', 'r', 'c', 'd', 'u'].map((key) => {
			if (group[key]) {
				return <span key={key} title={'You may ' + permissionName[key].long}>{permissionName[key].short}</span>;
			}
			else {
				return <span key={key} class="empty" title={'You may NOT ' + permissionName[key].long}>&nbsp;</span>
			}
		})

		if (group.nofMembers) {
			return <li>
				<span class="cell fa fa-user" />
				<PoeParsedForUI tag='span' props={{className: 'group-name cell'}} txt={group.name} />
				<span class="cell clickable"><Link to={'/group/' + group.gid}>{group.nofMembers} Members</Link></span>
				<span class="cell perms">{perms}</span>
			</li>
		}
		else {
			return <li>
				<span class="cell fa fa-user" />
				<span>{group.name}</span>
			</li>
		}
	}

}

export default withStore(observer(UserGroups))
