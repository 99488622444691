import React from "react";
import { observer, inject } from "mobx-react";
import { withStore } from '../utility/Core'

import Storage from "../utility/Storage";

import Autocomplete from 'react-autocomplete'

const debounce = require('sugar/function/debounce');
const cancel   = require('sugar/function/cancel');


class DirectDestination extends React.Component {

	constructor (props) {
    super(props)

    this.state = {
      value: '',
			result: undefined,
			list: [],
    }

		this.nodelist         = []
		this.remoteMatch      = {}
		this.storage          = new Storage()
		this.domRef           = React.createRef();

		this.onChange         = this.onChange.bind(this)
		this.shouldItemRender = this.shouldItemRender.bind(this)
		this.renderItem       = this.renderItem.bind(this)
		this.renderMenu       = this.renderMenu.bind(this)
		this.getRemote        = debounce(this.getRemote.bind(this), 250)
  }

	componentWillMount() {
		const { core, type, localNid, filter } = this.props

		this.nodelist = Object.keys(core.nodes).map((nid) => {
			const node = core.nodes[nid]

			if ((localNid) && (nid == localNid)) return null
			if (node.deleted)                    return null

			if ((filter) && (filter.type)) {
				if (node.type != filter.type) return null
			}

			return {
				id: nid,
				type: 'local',
				label: node.title || '*** untitled *** (' + node.nid + ')',
				t_seen: node.t_seen
			}
		}).filter( x => (x !== null))

		if (typeof this.nodeList !== 'undefined') this.nodeList.sort((a, b) => {
			if (a.t_seen == b.t_seen) return 0
			return a.t_seen > b.t_seen
		})

		this.setState({
			list: this.nodelist,
			result: undefined,
		})

	}

	componentDidMount() {
		this.positionWatch = setInterval( () => {
			const self    = this.domRef.current
			const DOMinput  = self ? self.refs.input : false
			const rectInput = DOMinput ? DOMinput.getBoundingClientRect() : false
			this.setState({pos: rectInput.top})
		}, 300)

		const self    = this.domRef.current
		const DOMinput  = self ? self.refs.input : false

		if (DOMinput) {
			DOMinput.focus()
			console.log('focus')
		}
	}

	componentWillUnmount() {
		const { onCancel } = this.props
		cancel(this.getRemote)
		clearTimeout(this.positionWatch)
		if (typeof this.storage.ajax !== 'undefined') this.storage.ajax.cancelHandler.cancel('parent component unmounted');
		onCancel()
	}

	getRemote(str) {
		const { filter } = this.props

		// show info-message
		const list = []
		this.localList.map( e => list.push(e) )
		list.push({
			id: 'remote-info',
			type: 'info',
			label: "Searching remotely for: " + str,
		})
		this.setState({
			list: list,
		})

		// prepare search
		const remoteFilter = [{
			'key': 's',
			'value': str
		}]

		if ((filter) && (filter.type)) {
			remoteFilter.push({
				key: 'type',
				value: filter.type
			})
		}

//		console.log(remoteFilter)

		// do search
		{
			const list = []
			const preventDoubles = {}
			this.localList.map( e => { list.push(e); preventDoubles[e.id] = true })
			this.remoteMatch = {}

			this.storage.query({
				'action': 'nodelist',
				'filter': remoteFilter,
			})
			.then((response) => {
				if (response.data.status == 200) {

					response.data.data.result.map( (e) => {
						if (str) e.context.str = str
						if (!preventDoubles[e.nid]) list.push({
							id: e.nid,
							label: e.title,
							type: 'remote',
							context: e.context,
						})

						this.remoteMatch[e.nid] = true

					})

					this.setState({
						list: list,
					})

				}
				else {
					console.error(response)
					this.setState({
						list: list,
					})
				}
			})
			.catch((thrown) => {
				console.error(thrown.message);
				if (thrown.message != 'parent component unmounted')	{
					this.setState({
						list: list,
					})
				}
			})
		}
	}

	onChange(e) {
		this.localList  = []

		const str   = e.target.value
		var matches

		const regex = RegExp('(http[s]?):\/\/|^(www\.)([^ ]+)$','g');

		if (matches = regex.exec(str)) {
			const protocol = matches[1] ? '' : 'http://'
			this.localList.push({
				id: 'new-url',
				type: 'url',
				label: protocol + str
			})
		}
		else if (str.length>1) {
			this.localList.push({
				id: 'new-card',
				type: 'new',
				label: str
			})
		}

		this.nodelist.map(e => this.localList.push(e))

		this.setState({
			value: str,
			list: this.localList,
		})

		this.getRemote(str)

	}

	shouldItemRender(item, value) {
		//console.log(item)
		return     (item.type == 'info')
		        || ((item.type == 'remote') || (this.remoteMatch[item.id]))
						|| (item.label.toLowerCase().indexOf(value.toLowerCase()) > -1)
	}

	renderItem(item, highlighted) {

		if (item.type == 'info') {
			return <div key={item.id} className="info">
				<em>{item.label}</em>
			</div>
		}

		const style   = { backgroundColor: highlighted ? '#eee' : 'transparent'}
		const context = item.context ? <div className="context">{item.context.pre}<em>{item.context.str}</em>{item.context.post}</div> : null

		var icon = null
		if (item.type == 'url')    icon = <span className="fa fa-globe" />
		if (item.type == 'new')    icon = <span className="fa fa-plus-circle" />
		if (item.type == 'local')  icon = <span className="fa fa-dot-circle-o" />
		if (item.type == 'remote') icon = <span className="fa fa-circle-thin" />

    return <div className="autocomplete-item" key={item.id} style={style} >
			{icon}{item.label}
			{context}
		</div>
	}

	renderMenu(items, value, style) {
		const { offset_left, offset_top } = this.props

		const height  = 200
		const self    = this.domRef.current
		const DOMinput  = self ? self.refs.input : false
		const DOMmenu   = self ? self.refs.menu : false
		const rectInput = DOMinput ? DOMinput.getBoundingClientRect() : false
		const rectMenu  = DOMmenu ? DOMmenu.getBoundingClientRect() : false
		const myStyle = { height:200, ...style}

		const win     = document.childNodes[1].getBoundingClientRect()
		//const height  = rectMenu.height

		if (rectInput.top > win.height - height - 16) {
			myStyle.top = (rectInput.top - height - 8)
		}
		else {
			myStyle.top = (rectInput.top + rectInput.height)
		}

		if (offset_top && myStyle.top)   myStyle.top  += offset_top
		if (offset_left && myStyle.left) myStyle.left += offset_left

		return <div className="autocomplete-menu" style={ myStyle } children={items}/>
	}

	render() {
		const { onSelect, onCancel } = this.props

		return <Autocomplete items={this.state.list}
        shouldItemRender={this.shouldItemRender}
        getItemValue={item => item.label}
        renderItem={this.renderItem}
        value={this.state.value}
        onChange={this.onChange}
        onSelect={ (value, item) => {
					if (onSelect) onSelect(item)
					this.setState({ value: value, result: item.id, selectedValue: value })
				}}
				inputProps={{
					onBlur: () => {
						const value = this.domRef.current ? this.domRef.current.refs.input.value : false
						//console.log(this.state.selectedValue != value ? 'cancel' : 'keep' + this.state.selectedValue + ' == ' + value)
						if (this.state.selectedValue != value) {
							//if (this.domRef.current) this.domRef.current.refs.input.value = ''
							this.setState({value: ''})
							onCancel()
						}
					},
				}}
				renderMenu={this.renderMenu}
				ref={this.domRef}
			/>
	}
}

export default withStore(observer(DirectDestination))
