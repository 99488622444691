import React from "react";
import { observer, inject } from "mobx-react";
import { withStore } from '../utility/Core'

import DirectNode from "./DirectNode";
import DirectNodeModeSwitch from "./DirectNodeModeSwitch";
import DirectNodeContext from "./DirectNodeContext";
import DirectParagraphStyles from "./DirectParagraphStyles";
import DirectParagraphTextStyles from "./DirectParagraphTextStyles";
import DirectParagraphIndentations from "./DirectParagraphIndentations";

import NodeViewingUsers from "./NodeViewingUsers";


import DirectNodeHistory from "./DirectNodeHistory";
import DirectNodeMeta from "./DirectNodeMeta"
import PrettyError from "./PrettyError"

import GlobalUndo from "./GlobalUndo";

class SingleNode extends React.Component {

	constructor(props) {
		super(props)
		const { core, nid, componentId } = this.props

		this.componentId = this.props.componentId ? this.componentId : core.componentId('Node')

		this.pars = {}
		this.domRef       = React.createRef();

		this.state = {
			mode: props.mode ? props.mode : 'view',
			hasError: false,
			error: undefined,
			errorDetails: undefined,
		}
	}

	componentDidCatch(error, info) {
		console.warn('SingleNode Error:', error, info)
		this.setState({ hasError: true, error: error, errorDetails: info })
	}

	componentDidMount() {
		const { core, nid } = this.props
		const { mode } = this.state
		const node     = core.nodes[nid]

		if ((node) && (node.locked_uid == core.user.uid) && (mode != 'edit')) this.setState({ mode: 'edit' })

		this.rerendering()
	}

	componentDidUpdate(prevProps) {
		const { core, nid } = this.props
		const { mode } = this.state

		if (nid != prevProps.nid) {
			if ((typeof core.nodes[nid] !== 'undefined') && (core.nodes[nid].locked_uid == core.user.uid)) {
				this.setState({mode: 'edit'})
			}
			else {
				this.setState({mode: 'view'})
			}
			return
		}

		if ((mode == 'edit') && (typeof core.nodes[nid] !== 'undefined') && (core.nodes[nid].locked_uid != core.user.uid)) {
			this.setState({mode: 'view'})
		}
		core.setNode(prevProps.nid, {t_seen: Date.now()/1000})
		this.rerendering(prevProps)
	}

	componentWillUnmount() {
		const { core, nid } = this.props
		if (core.nodes[nid].locked_uid == core.user.uid) this.releaseLock(true)
		core.setCaretX(this.componentId, false)
		core.setNode(nid, {t_seen: Date.now()/1000})
	}

	rerendering(prevProps = {}) {
		const { core, nid, position } = this.props
		const node = core.nodes[nid]

		if (node && typeof node.title !== 'undefined') {
			document.title = ' • ' + node.title.substr(0, 20)
			if (node.type == 'card') core.nodeHistory.add(node)
		}

		if ((typeof position !== 'undefined') && (position) && (position.pid) && (JSON.stringify(position) != JSON.stringify(prevProps.position))) {
			this.scrollTo(position.pid, position.pos)
		}

		core.setNode(nid, {t_seen: Date.now()/1000})
	}

	scrollTo(pid, pos=0, length=0) {
		const cardDOM = this.domRef.current
		if (!cardDOM) return null

		const scrollToPar = this.pars[pid]

		if (scrollToPar) {
			const position_y = scrollToPar.DOM.current.getBoundingClientRect().top + cardDOM.scrollTop - cardDOM.getBoundingClientRect().top
			cardDOM.scrollTop = position_y
		}
	}

	acquireLock(onSuccess, onFailure) {
		const { core, nid } = this.props
		const node = core.nodes[nid]

		if (!core.user.permission('update', 'node', node)) {
			alert('You lack the necessary permissions to edit this card.')
			return false
		}

		core.requestLock(nid)
		    .then(() => {
					onSuccess()
					})
		    .catch((e) => {
				  if (e.status == 401) {
						onFailure('Could not lock the node for editing. It is already locked by another user.')
					}
					else {
						onFailure('Could not lock the node. You may go offline to edit it locally.')
						console.error(e)
					}
				})
	}

	releaseLock(silent = false) {
		const { core, nid } = this.props;

		core.releaseLock(nid)
		    .then(() => { })
		    .catch((e) => { /* console.error(e) */ })

	}

	setCursor(pid, pos, length) {
		if (this.pars[pid]) {
			this.pars[pid].setCaretPos(pos, length);
			this.pars[pid].DOM.current.focus();
		}
	}

	setCursorAfterOp(lid, pid, pos, length, delayed = false) {

		if (this.pars[pid]) {
			if (typeof this.pars[pid]['postRender'] === 'undefined') this.pars[pid]['postRender'] = {}
			if (typeof this.pars[pid]['postRender'][lid] === 'undefined') this.pars[pid]['postRender'][lid] = []
			this.pars[pid]['postRender'][lid].push(() => {
				if (delayed) setTimeout( () => { this.setCursor.bind(this)(pid, pos, length) }, 200)
				else this.setCursor.bind(this)(pid, pos, length)
			})
			this.setCursor(pid, pos, length)
		}
		//
	}

	setMode(m) {
		const { core, nid } = this.props;
		//console.log('set mode', m)
		if (m == 'edit') {
			this.acquireLock(() => {
				if (core.nodes[nid].locked_uid == core.user.uid) this.setState({mode: 'edit'})
			}, (mes) => {
				alert(mes)
			})
		}
		else {
			this.setState({mode: m})
			if (core.nodes[nid].locked_uid == core.user.uid) this.releaseLock()
		}
	}

	mouseOverContainer() {
		const cardDOM = this.domRef.current
		if (!cardDOM) return null
		const classes = ' ' + cardDOM.getAttribute('class') + ' ';
		if (classes.indexOf(' hover ') == -1) {
			cardDOM.setAttribute('class', classes.trim() + ' hover')
		}
	}

	mouseOutContainer(e) {
		const cardDOM = this.domRef.current
		if (!cardDOM) return null
		const rect = cardDOM.getBoundingClientRect()

		if (
			(e.clientX<rect.left) ||
			(e.clientX>rect.right) ||
			(e.clientY<rect.top) ||
			(e.clientY>rect.bottom)
			) {

			const classes = ' ' + cardDOM.getAttribute('class') + ' ';
			if (classes.indexOf(' hover ') != -1) {
				cardDOM.setAttribute('class', classes.replace(' hover ', '').trim())
			}
		}
	}


	render() {
		const { core, nid, componentId }              = this.props
		const { mode, hasError, error, errorDetails } = this.state

		const node                       = core.nodes[nid]

		if (typeof node === 'undxefined') {
			return 	<div className="single-node-layout" key="single-node-layout">
								<div className={"direct-node node-container"}><h1>Node Is not Loaded</h1></div>
							</div>
		}

		const type                       = node ? node.type : 'not-loaded'
		const loaded                     = node ? node.loaded : 'not-loaded'

		const dummy_to_trigger_render    = node && node.locked_uid

		if (hasError) {
			return 	<div className="single-node-layout" key="single-node-layout">
								<div className={"direct-node node-container"}><PrettyError error={error} errorDetails={errorDetails} /></div>
							</div>
		}

		if ((typeof node !== 'undefined') && ((node.deleted) || (loaded == 'deleted'))) {
			return 	<div className="single-node-layout" key="single-node-layout">
								<div className={"direct-node node-container"}><h1>Node Is Deleted</h1></div>
							</div>
		}

		var content = null

		switch(mode) {
			case 'view':
			case 'edit':
				content = <DirectNode nid={nid} mode={mode} nodeComponentId={this.componentId} parStoreUp={(store) => {this.pars = store}} />
				break

			case 'history':
				content = <DirectNodeHistory nid={nid} nodeComponentId={this.componentId} />
				break

			case 'settings':
				content = <DirectNodeMeta nid={nid} nodeComponentId={this.componentId} />
				break

			default:
				content = <div className={"direct-node node-container " + type}>Unknown mode {mode}</div>
		}

		// <DirectNodeSpellcheck nodeComponentId={this.componentId} setCursorAfterOp={this.setCursorAfterOp.bind(this)} />

		return [
		<div className="single-node-layout" key="single-node-layout">
			<div className="direct-node-topbar">
				<DirectNodeModeSwitch nodeComponentId={this.componentId} nid={nid} mode={mode} onChange={ this.setMode.bind(this) } />
				{ mode == 'edit' ? <DirectParagraphStyles nodeComponentId={this.componentId} setCursorAfterOp={this.setCursorAfterOp.bind(this)} /> : null }
				{ mode == 'edit' ? <DirectParagraphTextStyles nodeComponentId={this.componentId} setCursorAfterOp={this.setCursorAfterOp.bind(this)} /> : null }
				{ mode == 'edit' ? <DirectParagraphIndentations nodeComponentId={this.componentId} setCursorAfterOp={this.setCursorAfterOp.bind(this)} /> : null }
				<NodeViewingUsers nid={nid} />
			</div>
			<div
				onMouseOver={this.mouseOverContainer.bind(this)}
				onMouseOut={this.mouseOutContainer.bind(this)}
				className={"direct-node node-container " + type}
				onScroll={(e) => { core.setCaretX(this.componentId, {scrollTop: e.target.scrollTop}) } }
				ref={this.domRef}
			>
				{content}
			</div>
		</div>,
		<DirectNodeContext key="single-node-context"
			nid={nid}
			nodeComponentId={this.componentId}
			setCursorAfterOp={this.setCursorAfterOp.bind(this)}
			scrollNodeTo={this.scrollTo.bind(this)}
		/>,
		<GlobalUndo key="undo" context={{type: 'node', nid: nid}} domRef={this.domRef.current} />
		]

	}
}

export default withStore(observer(SingleNode))
