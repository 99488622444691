import React from "react";
import { observer, inject, action, runInAction } from "mobx-react";
import { withStore } from '../../utility/Core'

import UserShort from "../UserShort";
import DateString from "../DateString";
import Conversation from "../Conversation";

import Modal from 'react-modal';
Modal.setAppElement('#app')

const truncate = require('sugar/string/truncate');

class NodeContextComment extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			modalIsOpen: false,
		}
	}

	componentWillMount() {
		const { core, eid, nodeComponentId } = this.props
		const edge = core.edgeById(eid)

		if (!edge) return null

		const node = core.nodes[edge.dst.nid]

		if (typeof node === 'undefined') {
			core.loadNode(edge.dst.nid)
		}

		if ((this.state.modalIsOpen == false) && (node) && (node.txt() == '')) {
			this.setState({modalIsOpen: true})
		}

	}

	modalClose() {
		const { core, eid } = this.props

		this.setState({modalIsOpen: false})

		const edge      = core.edgeById(eid)

		if (edge) {
			const nid       = edge.dst.nid
			const node      = core.nodes[nid]
			if ((node) && (node.txt() == '')) {

				this.commentDelete(eid)

			}
		}

	}

	afterModalClose() {
		const { setCursorAfterOp } = this.props
		console.log('close')
		setCursorAfterOp()
	}

	commentDelete(eid) {
		const { core } = this.props
		const edge = core.edgeById(eid)
		if (typeof edge === 'undefined') throw('edge ' + eid + ' not found')
		const nid  = edge.dst.nid
		const node = core.nodes[nid]

		if (core.user.permission('delete', 'edge_comment', edge)) {

			core.op({
				realm: 'node',
				op:    'nodeDelete',
				uid:    core.user.uid,
				nid:    nid,
				prev_mod_uuid: node.last_mod_uuid,
				uid: core.user.uid,
				lid: core.uuid('l'),
			})

			const op = {
				realm: 'edge',
				op:    'edgeDelete',
				eid:    edge.eid,
				type:   edge.type,
				src:    edge.src,
				dst:    edge.dst,
				prev_mod_uuid: edge.last_mod_uuid,
				uid: core.user.uid,
				lid: core.uuid('l'),
			}
			if ((typeof op.src !== 'undefined') && (typeof op.src.nid !== 'undefined') && (op.src.nid !== null) && (typeof op.src.pid !== 'undefined') && (op.src.pid !== null)) {
				op.src.prev_mod_uuid = core.nodes[op.src.nid].paragraphs[op.src.pid].last_mod_uuid
			}
			if ((typeof op.dst !== 'undefined') && (typeof op.dst.nid !== 'undefined') && (op.dst.nid !== null) && (typeof op.dst.pid !== 'undefined') && (op.dst.pid !== null)) {
				op.dst.prev_mod_uuid = core.nodes[op.dst.nid].paragraphs[op.dst.pid].last_mod_uuid
			}

			core.op(op)

		}
	}

	render() {
		const { core, eid, nodeComponentId } = this.props

		const edge      = core.edgeById(eid)

		if (!edge) return null

		const nid       = edge.dst.nid
		const node      = core.nodes[nid]

		const modalStyle = { content: {
			top                   : '50%',
			left                  : '50%',
			right                 : 'auto',
			bottom                : 'auto',
			marginRight           : '-50%',
			width                 : '40em',
			height                : '80%',
			transform             : 'translate(-50%, -50%)',
			overflow              : 'hidden',
		}}

		const txt = function(node) {
			var txt = '...'
			if ((node) && (node.paragraphList.length)) {
				txt = node.paragraphList.map( pid => (typeof node.paragraphs[pid] === 'undefined') ? '...' : node.paragraphs[pid].txt ).join("\n")
			}
			return txt;
		}

		const conversation = core.edges
														 .filter( e => (e.src.nid == nid && e.type == 'comment' && e.loaded != 'deleted') )
														 .sort( (edge_a, edge_b) => { if (edge_a.t_created > edge_b.t_created) return 1; if (edge_a.t_created < edge_b.t_created) return -1; return 0 } )
														 .map( e => e )

		const lastEdge = conversation.length ? conversation[conversation.length - 1] : null

		const meta = []
		if (conversation.length == 0) meta.unshift(<span key="created-on">Posted <DateString value={edge.t_created} /> by <UserShort uid={edge.uid} /></span>)
		if (conversation.length > 0) meta.unshift(<span key="created-on">Last Reply: <DateString value={lastEdge.t_created} /> by <UserShort uid={lastEdge.uid} /></span>)
		meta.unshift(<span key="length">{conversation.length} Replies | </span>)


		const modal = <Modal
					key = "modal"
          isOpen={this.state.modalIsOpen}
          onAfterOpen={() => { this.open = true /* workaround bec. onAfterClose currently seems to fire on unmount? */ }}
          onAfterClose={() => { if (this.open) { this.open = false; this.afterModalClose.bind(this)() } }}
          onRequestClose={this.modalClose.bind(this)}
          contentLabel="Comments"
					style={modalStyle}
        >
				<Conversation nid={nid} />
        </Modal>

		//return <div className='contextual-edge edge-comment'><span className="fa fa-comment-o" /> <UserShort uid={edge.uid}/>: "{truncate(txt, 60, 'middle')}"<div className="node-context-comment-meta">? replies</div></div>
		//return <div className='contextual-edge edge-comment'><span className="fa fa-comment-o" /> <UserShort uid={edge.uid}/>: "{txt}"<div className="node-context-comment-meta">? replies</div></div>
		return [<div key="e" className="contextual-edge edge-comment clickable" onClick={ () => { this.setState({modalIsOpen: true}) }}>
			<span className="fa fa-comment-o icon" />
			<div className="contextual-edge-details">
				<div className='contextual-edge-comment-comment'><UserShort uid={edge.uid}/>: "{truncate(txt(node), 60, 'middle')}"</div>
				<div className='contextual-edge-operations'>{meta.map( o => o ) }</div>
			</div>
		</div>,
		modal
		]
	}

}

export default withStore(observer(NodeContextComment))
