import React from "react";
import { observer, inject, action, runInAction } from "mobx-react";
import { withStore } from '../utility/Core'
import { Link, withRouter } from 'react-router-dom';

import Storage from "../utility/Storage";

import TaggerBox from "./TaggerBox";
import Modal from 'react-modal'

const truncate = require('sugar/string/truncate');


Modal.setAppElement('#app')

class ModalTagger extends React.Component {

	constructor(props) {
		super(props)

		this.storage = new Storage()

		this.state = {
		}

	}

	modalClose() {
		const { core, onClose } = this.props
		onClose()
	}

	updateTagging(nids) {
		const { core, onClose, onSelect, marker } = this.props
		const {nid, pid, beg, end} = marker
		const node = core.nodes[nid]
		if (!node) return false
		var paragraph = node.paragraphs[pid]
		if (!paragraph) paragraph = { last_mod_uuid: null }

		//console.log(nids)

		nids.forEach((tagNid)=>{
			var gid = false
			if (typeof core.nodes[tagNid] !== 'undefined') {
				if (core.nodes[tagNid].groups.length) gid = core.nodes[tagNid].groups[0].gid
			}

			const op = {
				op: 'edgeCreate',
				realm: 'edge',
				eid: core.uuid('e'),
				type: 'tag',
				src: {
					nid: nid, pid: pid, beg: beg, end: end, prev_mod_uuid: paragraph.last_mod_uuid,
				},
				dst: {
					nid: tagNid, pid: null, beg: null, end: null
				},
				groups: [gid],
				uid: core.user.uid,
				lid: core.uuid('l'),
			}

			core.op(op)

		})
	}

	render() {
		const { core, onClose, onSelect, marker } = this.props

		const {nid, pid, beg, end} = marker

		const node = core.nodes[nid]
		if (!node) return null

		const modalStyle = {
			overlay: {
				zIndex:1000,
				backgroundColor: 'rgba(0, 0, 0, .75)',
			},
			content: {
				top                   : '50%',
				left                  : '50%',
				right                 : 'auto',
				bottom                : 'auto',
				marginRight           : '-50%',
				width                 : '320px',
				height                : '160px',
				transform             : 'translate(-50%, -50%)',
				overflow              : 'hidden',
			}
		}

		return <Modal
					key = "modal"
          isOpen={true}
          onRequestClose={this.modalClose.bind(this)}
          contentLabel="Set Tag"
					style={modalStyle}
        >
				<TaggerBox
					groups={node.groups}
					onCancel={this.modalClose.bind(this)}
					onSelect={(result) => { this.updateTagging.bind(this)(result); onSelect(result) }}
				/>
        </Modal>
	}
}

export default withStore(observer(ModalTagger))
