import React from "react";
import { observer, inject, action, runInAction } from "mobx-react";
import { withStore } from '../utility/Core'
import { Link, withRouter } from 'react-router-dom';

import Storage from "../utility/Storage";

import Modal from 'react-modal'

const truncate = require('sugar/string/truncate');

Modal.setAppElement('#app')

class ModalAttachments extends React.Component {

	constructor(props) {
		super(props)

		this.storage = new Storage()

		this.state = {
			quotaGroup: 0,
		}

		this.deleteAttachment = this.deleteAttachment.bind(this)
		this.onChangeHandler  = this.onChangeHandler.bind(this)
		this.quotaGroupSelection = this.quotaGroupSelection.bind(this)

	}

	modalClose() {
		const { core, onClose } = this.props
		onClose()
	}

	onChangeHandler(e) {
		const { core, nid } = this.props
		const { quotaGroup } = this.state
		const nof = e.target.files.length

		const uploadLimit = 15 * 1024 * 1024 // 15MB

		for(var i=0; i<nof; i++) {
			const fid = core.uuid('f')
			const file = e.target.files[i]

			if (file.size > uploadLimit) {
				alert('File ' + file.name + ' is too big')
				continue
			}

			core.op({
				op: 'fileAdd',
				nid,
				realm: 'node',
				file: {
					fid,
					quotaGroup,
					status: 'pending',
					filename: file.name,
					size: file.size,
					mimetype: file.type
				},
				doNotLog: true,
			})

			core.queueForUpload(file, fid, nid, quotaGroup)
		}

		document.getElementById('node-file-attachment-upload-input').value = ''
	}

	deleteAttachment(fid) {
		const { core, nid } = this.props
		if (window.confirm('Are you sure you want to delete the file? This action cannot be undone.')) core.op({
			op: 'fileDel',
			nid,
			realm: 'node',
			file: {
				fid
			}
		})
	}

	quotaGroupSelection(e) {
		this.setState({ quotaGroup: parseInt(e.target.value) })
	}

	render() {
		const { core, onClose, nid } = this.props
		const { quotaGroup } = this.state

		const node = core.nodes[nid]
		if (!node) return null

		const modalStyle = {
			overlay: {
				zIndex:1000,
				backgroundColor: 'rgba(0, 0, 0, .75)',
			},
			content: {
				top                   : '50%',
				left                  : '50%',
				right                 : 'auto',
				bottom                : 'auto',
				marginRight           : '-50%',
				width                 : '480px',
//				height                : '160px',
				transform             : 'translate(-50%, -50%)',
				overflow              : 'hidden',
			}
		}

		const attachments = node.attachments.map((f, i) => {
			return <tr key={f.fid}>
				<td>{i+1}</td>
				<td>{f.filename}</td>
				<td>{f.status}</td>
				<td style={{textAlign:'right'}}><span title="Delete the file (NO UNDO)" className="fa fa-minus-circle clickable red" onClick={() => { this.deleteAttachment(f.fid) }}></span></td>
			</tr>
		})

		const quotaGroupSelected = (core.user.groups.filter(g => g.gid === quotaGroup).length) && (core.user.groups.filter(g => g.gid === quotaGroup)[0].storageQuota)

		return <Modal
					key = "modal"
          isOpen={true}
          onRequestClose={this.modalClose.bind(this)}
          contentLabel="Set Tag"
					style={modalStyle}
        >
					<div>
						<div style={{borderBottom:'1px solid #000'}}>
							<strong>Manage Node Attachments</strong>
							<span onClick={this.modalClose.bind(this)} style={{float:'right'}} className="fa fa-times clickable red" />
						</div>
						<div>
							<p>Attachments:</p>
							<table width="100%" border="0" cellSpacing="2" style={{border:'1px solid #000'}}>
								<tbody>
									{attachments}
								</tbody>
							</table>
						</div>
						<div style={{marginTop: '32px', borderTop: '1px dotted #444', paddingTop:'8px'}}>
							<div style={{marginBottom:16}}>Upload:</div>
							<div>
								<select onChange={this.quotaGroupSelection}>
									<option value={0}>- Select a Quota Group</option>
									{core.user.groups.filter(g => g.storageQuota).map(g => <option key={g.gid} value={g.gid}>{g.name}</option>)}
								</select>
								&nbsp;:&nbsp;
							 	<input id="node-file-attachment-upload-input" type="file" multiple name="file" onChange={this.onChangeHandler} disabled={ quotaGroupSelected ? false : true } />
							</div>
							<p>
								<small>{"An attachment's visibility is independent of its Quota Group and determined only by the visibility of the node it is attached to. The Quota Group merely determines which account will provide the required storage space."}</small>
							</p>
						</div>
					</div>
        </Modal>
	}
}

export default withStore(observer(ModalAttachments))
