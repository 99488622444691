import React from "react";
import { observer, inject } from "mobx-react";
import { withStore } from '../utility/Core'
import { action } from "mobx";

import Storage from "../utility/Storage";

const sync_interval_regular         =      2 * 1000
const sync_interval_slow            =     15 * 1000
const sync_moving_wall              =     10 * 1000
const hard_timeout                  =     60 * 1000
const sync_max_wait_for_completion  = 1 * 60 * 1000

const timeoutShort                  =      2 * 1000
const timeoutLong                   =     10 * 1000

class Sync extends React.Component {

	constructor() {
		super()
		this.state         = { polling: false }
		this.lastBegun     = 0;
		this.lastCompleted = 0;
		this.pollTimeout   = 2000;
		this.interval      = sync_interval_regular;
		this.storage       = new Storage();
	}

	nextPolling(interval, state, error) {
		const { core } = this.props;
		//console.log('POLL again: ', interval, state, '<--',  error, '-->')

		this.setState({polling: false});
		this.interval = interval;

		if (state) core.setConnectivity('lastError', state);
	}

	getSyncableOps() {
		const { core } = this.props
		const chunk    = []
		const t0       = Date.now()

		for(let i=0; i<core.operations.length; i++) {

			const op              = core.operations[i];
			const nofOpsInChunk   = chunk.length;

			var nodeIsLocked      = ((typeof op.nid !== 'undefined') && (typeof core.nodes[op.nid] !== 'undefined') && (core.nodes[op.nid].locked_uid == core.user.uid))
			var nodeIsLocked      = nodeIsLocked || ((typeof op.src !== 'undefined') && (typeof op.src.nid !== 'undefined') && (typeof core.nodes[op.src.nid] !== 'undefined') && (core.nodes[op.src.nid].locked_uid == core.user.uid))
			var nodeIsLocked      = nodeIsLocked || ((typeof op.dst !== 'undefined') && (typeof op.dst.nid !== 'undefined') && (typeof core.nodes[op.dst.nid] !== 'undefined') && (core.nodes[op.dst.nid].locked_uid == core.user.uid))

			// fix hanging syncs after three minutes
			if ((op.status  == 'syncing') && ((Date.now() - op.t_sync) > sync_max_wait_for_completion)) {
				op.status = 'done';
				break;
			}

			// end collection as soon as we hit the first item that is not up for sync
			if ((op.status != 'done') && (op.status != 'synced')) { break }

			// skip ops that are already synced
			if (op.status  == 'synced')              { continue }

			// skip ops that should not be synced (yet) if the node is still editable
			if ((nodeIsLocked) && (op.t_local >= t0 - sync_moving_wall)) { break }

			// for editable nodes: sync node-/paragraph-wise (i.e. stop collecting if we hit a paragraph break/merge,
			//                     or if the current op is in a different paragraph or node than the previous
			if ((nodeIsLocked) && (nofOpsInChunk)) {
				if (op.op == 'parSplit') break
				if (op.op == 'parMerge') break
				if (op.nid != chunk[nofOpsInChunk-1].nid) break
				if (op.pid != chunk[nofOpsInChunk-1].pid) break
			}

			op.status = 'syncing'
			op.t_sync = Date.now()

			// copy the operation, minus those properties we don't even want to know on the server side
			// const { status,  ...transferable_op} = op
			const { ...transferable_op } = op

			chunk.push(transferable_op)
		}

		// console.log('Chunk to sync', chunk)

		return chunk
	}

	processRemoteSyncOps(ops) {
		console.log('processRemoteSyncOps', ops)
		const { core } = this.props;
		ops.map( (row, index) => {
			const i = core.operations.findIndex( (l) => l.lid == row.op )
			if (row.status == 200) {
				core.operations[i].status = 'synced'
				if (typeof core.nodes[core.operations[i].nid] !== 'undefined') {
					core.nodes[core.operations[i].nid].last_sync_uuid = core.operations[i].lid;
					if (typeof core.nodes[core.operations[i].nid].paragraphs[core.operations[i].pid] !== 'undefined') {
						core.nodes[core.operations[i].nid].paragraphs[core.operations[i].pid].last_sync_uuid = core.operations[i].lid;
					}
				}

				if ((typeof row.syncDetails !== 'undefined') && (typeof row.syncDetails.status !== 'undefined') && (row.syncDetails.status == 409)) {
					for(var n = index+1; n<core.operations.length; n++) {
						if ((typeof row.syncDetails !== 'undefined') && (typeof row.syncDetails.status !== 'undefined') && (row.syncDetails.status == 424)) {
							core.operations[n].syncDetails = {}
							core.operations[n].status  = 'done'
							core.operations[n].details = 'was part of a failed queue; restored'
						}
					}
				}

			}
			else if (row.status == 409) {
				core.operations[i].status  = 'deferred'
				core.operations[i].details = row.details
			}
			else {
				core.operations[i].status  = 'failed'
				core.operations[i].details = 'failed'
			}
			if (row.details) {
				core.operations[i].syncDetails = {
					status: row.status,
					details: row.details,
				}
			}
		})
	}

	rollbackRemoteSyncOps(ops) {
		ops.map( (row) => {
			row.status = 'done'
		})
	}

	poll() {
		const { core } = this.props;
		const t0       = parseInt(Date.now()/1000)

		if (core.connectivity.forceOffline) return

		if (Date.now() < this.lastCompleted + this.interval) return
		if (Date.now() < this.lastBegun + this.pollTimeout + this.interval) return

		if (this.state.polling && this.lastCompleted && (this.lastCompleted + hard_timeout < Date.now())) {
			console.log('Ran into hard timeout');
			this.nextPolling(false, 'stop', 'Request never ended...')
		}
		//console.log(this.lastCompleted, this.interval , Date.now())

		this.setState({polling: true});

		// collect local operations in a chunk to send upstream
		const syncableChunk = this.getSyncableOps();

		if (syncableChunk.length) {

			this.storage
				.query({ action: 'storeOps', t: Date.now(), ops: syncableChunk })
				.then((response) => {
					this.lastCompleted = Date.now();
					if (response.data.status == 200) {

						this.processRemoteSyncOps(response.data.data)
						this.nextPolling(sync_interval_regular, 'online')

					}
					else {
						this.nextPolling(false, 'stop', (response.status == 500) ? 'Internal Remote Sync Processing Error' : response.status + ' ' + JSON.stringify(response.data))
					}
				})
				.catch((thrown) => {
					this.lastCompleted = Date.now();

					// authentication error
					if ((typeof thrown.response  !== 'undefined') && (thrown.response.status == 401)) {
						core.logout();
						return;
					}

					// some other remote error
					this.nextPolling(sync_interval_slow, 'offline', thrown)
					this.rollbackRemoteSyncOps(syncableChunk);
				});
		}
		else {
			this.lastBegun = Date.now()
			//Object.keys(core.nodes)
			const nids = Object.entries(core.nodes)
			                   .filter( r => r[1].isClaimed() )
												 .map( r => r[0] )

			this.storage.query({ action: 'viewing', nids: nids, n_since: core.connectivity.maxLid })
				.then((response) => {
					this.lastCompleted = Date.now();
					this.setState({polling: false})

					if (typeof response.data.data.nodes !== 'undefined') Object.keys(response.data.data.nodes).map((nid) => {
						const row = response.data.data.nodes[nid]

						const viewing = {}
						row.viewing.split(',').forEach(uid => viewing[uid] = t0)
						core.nodes[nid].setAttr('viewing', viewing)
						if (typeof row.locked_uid !== 'undefined') {
							core.nodes[nid].setAttr('locked_uid', row.locked_uid)
							core.nodes[nid].setAttr('locked_t', row.locked_t)
						}
					})

					if (response.status == 200) {
						core.setConnectivity('online', true)
						this.nextPolling(sync_interval_regular, 'online')
						if (Date.now() - this.lastBegun < timeoutShort) {
							this.storage.axiosParms.timeout = timeoutShort
						}

						core.injectRemoteUpdates(response.data.data.log)
						core.setConnectivity('maxLid', response.data.data.maxLid)
					}
					else {
						this.nextPolling(false, 'stop', response.status + ' ' + JSON.stringify(response.data))
					}

				})
				.catch((thrown) => {
					this.lastCompleted = Date.now();
					if ((typeof thrown.response  !== 'undefined') && (thrown.response.status == 401)) {
						core.logout();
					}

					if (('' + thrown).substr(0,14) == "Error: timeout") {
						this.storage.axiosParms.timeout = timeoutLong
					}

					this.nextPolling(sync_interval_slow, 'offline', thrown)
				});

		}
	}

	componentDidMount() {
		const { core } = this.props;

		this.pollingTimer = setInterval(this.poll.bind(this), 500);
		window.onbeforeunload = function() {
			if (core.fileUploadQueue.length) return 'There are still file uploads in progress. If you leave now, these will be aborted and potentially result in an undefined state'
			if (core.operations.filter( (row) => row.status != 'synced' ).length) return "If you leave this page you will lose your unsaved changes."
			return null
		}
	}

	componentWillUnmount() {
		clearInterval(this.pollingTimer);
		if (typeof this.storage.ajax !== 'undefined') this.storage.ajax.cancelHandler.cancel('component unmounted');
	}

	render() {

		const { core } = this.props;
		var state = '';
		var title = '';

		var toggleSyncState = () => {
			if (core.connectivity.forceOffline) {
				core.setConnectivity('forceOffline', false);
			} else
				core.setConnectivity('forceOffline', true);
		}

		if (core.connectivity.forceOffline) {
			state = <span className="fa fa-pause"></span>;
			if (core.connectivity.lastError)
				title = "Sync is paused after error \""+core.connectivity.lastError+"\" -- Click to resume."
			else
				title = "Sync is paused. Click to resume."
		}
		else {
			if (core.connectivity.online) {
				title = 'sync is working regularly'
				state = <span className="fa fa-cloud online"></span>
				if (this.state.polling) state = <span className="fa fa-cloud-download online"></span>
			}
			else {
				state = <span className="fa fa-cloud offline"></span>
				if (this.state.polling) state = <span className="fa fa-cloud-download offline"></span>
				if (core.connectivity.lastError)
					title = "Sync failed with error \""+core.connectivity.lastError+"\" -- Click twice to resume."
				else
					title = "Sync is inactive and will resume as soon as possible."
			}
		}

		if (core.operations.filter(o => o.status == 'failed').length) {
			title = 'Sync failed :('
			state = <span className="fa fa-exclamation-triangle" />
		}

		let nof = null
		if (core.nofUnsyncedOperations) nof = <span id="sync-status-openOps">{core.nofUnsyncedOperations}</span>;
		return (<span title={title} id="sync-status" onClick={toggleSyncState}>{state}{nof}</span>);
	}

}

export default withStore(observer(Sync))
