import React from "react";
import Storage from "../utility/Storage";
import { observer, inject } from "mobx-react";
import { withStore } from '../utility/Core'

import Username from './PoeParsedForUI/Username'
import PoeParsedForUI from './PoeParsedForUI'


class GroupAssociation extends React.Component {

	constructor() {
		super()
		this.state = {
			filter: '',
			hasError: false,
		}
	}

	componentDidCatch(error, info) {
		console.error('ERROR', error, info)
		this.setState({ hasError: true });
	}


	componentWillUnmount() {
		const { core } = this.props
		if (core.modal.isOpen) core.closeModal();
	}

	componentDidUpdate() {
		const { core, action, edge } = this.props
		if (core.modal.isOpen) core.modal.content = this.modal()
	}

	filterChanged(event) {
		this.setState({
			filter: event.target.value,
		})
	}

	addGroup(g) {
		const { core, type, obj } = this.props

		if (g.gid == core.user.defaultGid) {
			alert('You cannot set or unset \'Private\'.');
			return false;
		}

		if (type == 'node') {
			core.op({
				realm: 'node',
				op:    'groupAdd',
				prev_mod_uuid: obj.last_mod_uuid,
				nid:   obj.nid,
				gid:   g.gid,
				uid:   core.user.uid,
				lid:   core.uuid('l'),
			})
		}
		else if (type == 'edge') {
			core.op({
				realm: 'edge',
				op:    'groupAdd',
				prev_mod_uuid: obj.last_mod_uuid,
				eid:   obj.eid,
				gid:   g.gid,
				uid:   core.user.uid,
				lid:   core.uuid('l'),
			})
		}

		if (core.modal.isOpen) core.closeModal();
	}

	delGroup(gid) {
		const { core, type, obj } = this.props

		if (gid == core.user.defaultGid) {
			alert('You cannot set or unset \'Private\'.');
			return false;
		}

		if (type == 'node') {
			core.op({
				realm: 'node',
				op:    'groupDel',
				prev_mod_uuid: obj.last_mod_uuid,
				nid:   obj.nid,
				gid:   gid,
				uid:   core.user.uid,
				lid:   core.uuid('l'),
			})
		}
		else if (type == 'edge') {
			core.op({
				realm: 'edge',
				op:    'groupDel',
				prev_mod_uuid: obj.last_mod_uuid,
				eid:   obj.eid,
				gid:   gid,
				uid:   core.user.uid,
				lid:   core.uuid('l'),
			})
		}
	}

	modal() {
		const { core, action, obj, type } = this.props
		const search = this.state.filter.toLowerCase();

		const modal = <div key="group-select-dialog" id="group-select-dialog" className="dialog-select">
			<input autoFocus key="input" type="text" value={this.state.filter} onChange={this.filterChanged.bind(this)} />
			<ul key="xlist" className="item-list" >
			{
				core.user.groups.filter(g => {

					if ((type == 'node') && (!core.user.permission('create', 'node', g))) return false
					if ((type == 'edge') && (!core.user.permission('create', 'edge_' + obj.type, {groups: [g]}))) return false

					const u1 = /%([0-9]+)[^%]+%([0-9]+)/.exec(g.name)
					if (u1) {
						const cu1 = core.cache.userDetails[u1[1]]
						const cu2 = core.cache.userDetails[u1[2]]

						if ((cu1) && (cu1.short) && ((cu1.short.toLowerCase().indexOf(search)>-1 ) || (cu1.name.toLowerCase().indexOf(search)>-1 ))) return true
						if ((cu2) && (cu2.short) && ((cu2.short.toLowerCase().indexOf(search)>-1 ) || (cu2.name.toLowerCase().indexOf(search)>-1 ))) return true
					}

					return g.name.toLowerCase().indexOf(search) >= 0
				}).map( g => <li key={g.gid} className="clickable" onClick={() => { this.addGroup.bind(this)(g) }}><PoeParsedForUI txt={g.name} /></li> )
			}
			</ul>
		</div>

		return modal
	}

	showModal() {
		const { core, action, edge } = this.props
		this.setState({
			filter: '',
		})
		core.showModal(this.modal(), {width:'50%', height:'50%'})
	}

	render() {
		if (this.state.hasError) return 'has error'

		const { core, type, obj } = this.props

		const potentialGroups = []
		const objGroups       = obj.groups.map( g => g.gid )
		const groups          = core.user.groups.filter( (g) => {
			if (objGroups.includes(g.gid)) {
				return true
			}
			else {
				// if the group is not assigned to the object, keep a list if it theoretically could be assigned
				if ((type == 'node') && (core.user.permission('create', 'node', g))) potentialGroups.push(g)
				if ((type == 'edge') && (core.user.permission('create', 'edge_' + obj.type, obj))) potentialGroups.push(g)
				return false
			}
		}).map( g => g.gid )
		const re = /^%([0-9]+) and %([0-9]+)$/

		const grouplist = []
		groups.map( (gid) => {

			var icon  = <span onClick={() => () => { alert('You are not allowed to remove group associations.') } } class="fa fa-check-square disabled" title="You cannot remove this group" />
			if (
				((type == 'edge') && (core.user.permission('GA_update', 'edge', obj))) ||
				((type == 'node') && (core.user.permission('GA_update', 'node', obj)))
			) {
				icon  = <span onClick={() => { this.delGroup.bind(this)(gid) }} class="fa fa-times clickable" title="remove group" />
			}

			var   name  = core.userGroup(gid).name
			var   match = false
			if (match = re.exec(name)) {
				var uid = match[1]
				if (uid == core.user.uid) uid = match[2]
				name = <span>You and <Username uid={uid} /></span>
			}

			grouplist.push(<div key={gid} className="group">
				{ icon }
				{' '}
				{ name }
			</div>)
		})

		var addButton = null

		if (
			((type == 'edge') && (core.user.permission('GA_update', 'edge', obj)) && (potentialGroups.length)) ||
			((type == 'node') && (core.user.permission('GA_update', 'node', obj)) && (potentialGroups.length))
			) {
			addButton = <button onClick={this.showModal.bind(this)} className="light"><span className="fa fa-plus" /> Add a Group</button>
		}

		return <div class="groupAssociation" title={"Associated with " + obj.groups.length + " groups"}>
			{ grouplist }
			{ addButton }
		</div>
		//return {edge.groups.map( (g) => { return <span key={g.gid}><PoeParsedForUI tag='span' props={{className: 'inactive'}} txt={'%g-' + g.gid} /></span> } )}
	}

}

export default withStore(observer(GroupAssociation))
