import React from "react"
import { observer, inject } from "mobx-react"
import { HashRouter, Switch, Route, Link, withRouter } from 'react-router-dom'
import { withStore } from '../utility/Core'

import Comment from './Comment'
import CommentInput from './CommentInput'
import PoeParsedForUI from './PoeParsedForUI'

class Conversation extends React.Component {

	constructor() {
		super();
		this.state = {
			readyToPost: false,
			hasError: false,
			myEdge: undefined,
			relEdge: undefined,
			relNode: undefined,
		}

		this.loadedNodes = {}
	}

	loadNode(nid) {
		const { core } = this.props
		this.loadedNodes[nid] = true
		core.loadNode(nid, 'full', this.componentId)
	}

	fixPrerequisites() {
		const { core, nid }                = this.props
		const { myEdge, relEdge, relNode } = this.state

		console.log('Fixing prerequisites', myEdge, relEdge, relNode, (core.nodes[nid] ? core.nodes[nid].loaded : '--') )

		// the node to be rendered must be present
		if ((typeof core.nodes[nid] === 'undefined') || (core.nodes[nid].loaded != 'full')) {
			if ((typeof core.nodes[nid] === 'undefined') || ((core.nodes[nid].loaded != 'fail') && (core.nodes[nid].loaded != 'loading'))) this.loadNode(nid)
		}

		if (((core.nodes[nid].loaded == 'full') || (core.nodes[nid].loaded == 'deleted')) || (core.nodes[nid].createdOnTheFly)) {
			console.log('STATUS: ', core.nodes[nid].loaded, core.nodes[nid].createdOnTheFly)
			const edge = core.edges.filter(e => e.dst.nid == nid && e.type == 'comment' && e.loaded != 'deleted')[0]

			if (typeof edge === 'undefined') {
				console.warn(nid + ' has no incoming comment edge');
			}
			else {
				if (typeof myEdge === 'undefined') {
					this.setState({myEdge: edge})
				}
			}
		}



		if (typeof this.state.myEdge !== 'undefined') {

			if (typeof this.state.relEdge === 'undefined') {
				const node  = core.nodes[this.state.myEdge.src.nid]

				if (typeof node === 'undefined') {
					this.loadNode(this.state.myEdge.src.nid)
				}
				else {
					if (node.loaded == 'full') {
						if ((node.type == 'card') || (node.type == 'tag')) {
							this.setState({
								relEdge: this.state.myEdge,
								relNode: node,
							})
						}
						else if (node.type == 'comment') {
							const edge = core.edges.filter(e => e.dst.nid == node.nid && e.type == 'comment' && e.loaded != 'deleted')[0]
							if (typeof edge === 'undefined') {
								throw('cannot find rel-edge')
							}
							else {
								if (typeof relEdge === 'undefined') this.setState({
									relEdge: edge
								})

								const node = core.nodes[edge.src.nid]

								if (typeof node === 'undefined') {
									this.loadNode(edge.src.nid)
								}
								else {
									if ((node.loaded == 'full') && (typeof relNode === 'undefined')) this.setState({relNode: node})
								}
							}
						}
						else {
							throw('huh? edge type ' + node.type)
						}
					}
				}

			}
		}

	}

	componentDidMount() {
		const { core, nid } = this.props

		this.componentId = core.componentId('Conversation')
		this.fixPrerequisites()
	}

	componentDidUpdate() {
		const { core, nid } = this.props
		// console.log('CONVERSATION DID UPDATE')
		this.fixPrerequisites()
	}

	componentWillUnmount() {
		const { core } = this.props
		Object.keys(this.loadedNodes).map(nid => core.unloadNode(nid, this.componentId))

		if (typeof this.conversation !== 'undefined') {
			const lastEdge = this.conversation[this.conversation.length-1]
			const lastNode = core.nodes[lastEdge.dst.nid]

			if (lastNode) core.releaseLock(lastNode.nid)
		}
	}

	render() {
		const { core, nid }                = this.props
		const { myEdge, relEdge, relNode } = this.state

		const node = core.nodes[nid]

		const className = ['conversation'];
		if ((this.props.className) && (this.props.className.length)) this.props.className.map( (row) => className.push(row) )

		if ((typeof node === 'undefined') || ((node.loaded=='fail') && (!node.createdOnTheFly))) return <div className={className.join(' ')}>Comment/Reply {nid} does not exist</div>

		if (typeof myEdge  === 'undefined') return <div className={className.join(' ')}>Missing edge to {nid} on status "{node.loaded}" {node.createdOnTheFly ? 'fly' : 'nofly'} </div>

		if (myEdge.loaded == 'deleted') return <div className={className.join(' ')}>The comment has been deleted. <Link to={'/node/' + myEdge.src.nid}>Return to the corresponding card</Link></div>

		if (typeof core.nodes[myEdge.src.nid] === 'undefined') return <div className={className.join(' ')}>Missing first comment in chain</div>
		if (typeof relEdge === 'undefined') return <div className={className.join(' ')}>Missing rel-edge via {core.nodes[myEdge.src.nid].loaded} </div>
		if (typeof relNode === 'undefined') return <div className={className.join(' ')}>Missing rel-node</div>

		this.conversation = core.edges
														 .filter( e => (e.src.nid == relEdge.dst.nid && e.type == 'comment' && e.loaded != 'deleted') )
														 .sort( (edge_a, edge_b) => { if (edge_a.t_created > edge_b.t_created) return 1; if (edge_a.t_created < edge_b.t_created) return -1; return 0 } )
														 .map( e => e )

    this.conversation.unshift(relEdge)

		const lastEdge = this.conversation[this.conversation.length-1]
		const lastNode = core.nodes[lastEdge.dst.nid]

		const editLastComment = (core.user.permission('update', 'edge_comment', lastEdge)) ? <div>(you can still edit the above)</div> : <div>(editing of comments is not allowed)</div>

		var conversationControls = null


		if ((typeof lastNode !== 'undefined') && (lastNode.uid !== core.user.uid)) {
			conversationControls = <CommentInput relEdge={relEdge} />
		}

		console.log('RENDER conversation', this.conversation)

		return <div className={className.join(' ')}>
			<div class="vscroll">
			{
				this.conversation.map((e, i) => {
					const locked   = (typeof core.nodes[e.dst.nid] === 'undefined') ? false : (core.nodes[e.dst.nid].locked_uid == core.user.uid)
					const expanded = (i==0) || (this.conversation.length - i < 2)
					console.log('Comment ' + e.dst.title + ': ' + expanded)
					return <Comment key={e.eid} overrideMakeEditable={locked} eid={e.eid} expanded={expanded} contextNid={nid} relNode={relNode} />
				})
			}
			{
				conversationControls
			}
			</div>
		</div>
	}
}

export default withRouter(withStore(observer(Conversation)))
