import React from "react";
import { observer, inject } from "mobx-react";
import { withStore } from '../utility/Core'
import Storage from "../utility/Storage";

import Username from './PoeParsedForUI/Username'
import DirectDestination from "./DirectDestination";


class GroupSelection extends React.Component {


	render() {

		const { core, groups, selected, doSelect } = this.props

		// disabled={!exists}

		return <div className="group-selection">{ groups.map((g) => {

			const group  = core.groupByGid(g.gid)

			if (typeof group === 'undefined') return null
			if (group === false) return null

			var groupName = group.name

			var   match = false
			if (match = /^%([0-9]+) and %([0-9]+)$/.exec(groupName)) {
				var uid = match[1]
				if (uid == core.user.uid) uid = match[2]
				groupName = <span>You and <Username uid={uid} /></span>
			}

			return  <div key={g.gid}>
								<input
									id={'group-' + g.gid}
									className="icon"
									type="checkbox"
									checked={selected[group.gid] ? true : false}
									onChange={(e) => {doSelect(group.gid, e.target.checked) }}
								/>
								<label for={'group-' + g.gid}>{groupName}</label>
							</div>
			})
		}

		</div>
	}
}

export default withStore(observer(GroupSelection))
