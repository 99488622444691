const { makeObservable, observable, computed, action } = require("mobx")

export class Edge {

	constructor (obj) {
		const t0 = parseInt(Date.now() / 1000)

		this.eid             = obj.eid
		this.uid             = obj.uid
		this.type            = obj.type
		this.predicate_nid   = obj.predicate_nid
		this.rpredicate_nid  = obj.rpredicate_nid
		this.src             = {
												  	nid: (obj.src.nid === null) ? null : obj.src.nid,
												  	pid: (obj.src.pid === null) ? null : obj.src.pid,
												  	beg: (obj.src.beg === null) ? null : parseInt(obj.src.beg),
												  	end: (obj.src.end === null) ? null : parseInt(obj.src.end),
												  	title: (obj.src.title === null) ? null : obj.src.title,
												  }

		this.dst             = {
												  	nid: (obj.dst.nid === null) ? null : obj.dst.nid,
												  	pid: (obj.dst.pid === null) ? null : obj.dst.pid,
												  	beg: (obj.dst.beg === null) ? null : parseInt(obj.dst.beg),
												  	end: (obj.dst.end === null) ? null : parseInt(obj.dst.end),
												  	title: (obj.dst.title === null) ? null : obj.dst.title,
												  }

		this.t_created       = obj.t_created ? obj.t_created : t0
		this.t_updated       = this.t_created
		this.perms           = obj.perms || this.perms
		this.groups          = obj.groups || []
		this.last_mod_uuid   = obj.last_mod_uuid

		this.linked_url      = obj.linked_url || false

		this.locked_uid     = obj.locked_uid
		this.locked_t       = obj.locked_t
		this.emphasized     = false
		this.loaded         = obj.loaded || 'slim'
		this.deleted        = obj.deleted || false

		makeObservable(this, {
			type: observable,
			predicate_nid: observable,
			rpredicate_nid: observable,
			src: observable,
			dst: observable,
			t_created: observable,
			t_updated: observable,
			perms: observable,
			last_mod_uuid: observable,
			groups: observable,
			linked_url: observable,
			locked_uid: observable,
			locked_t: observable,
			emphasized: observable,
			loaded: observable,
			deleted: observable
		})
	}

	taint(op, lid) {
		const t0 = parseInt(Date.now() / 1000)
		this.last_mod_uuid = lid
		this.t_modified = t0
	}

	update(op) {
		const t0   = parseInt(Date.now() / 1000)
		const mods = op.mods

		if (mods.src) {
			if (typeof mods.src.nid   !== 'undefined')  this.src.nid   = mods.src.nid
			if (typeof mods.src.pid   !== 'undefined')  this.src.pid   = mods.src.pid
			if (typeof mods.src.beg   !== 'undefined')  this.src.beg   = mods.src.beg
			if (typeof mods.src.end   !== 'undefined')  this.src.end   = mods.src.end
			if (typeof mods.src.title !== 'undefined')  this.src.title = mods.src.title
		}

		if (mods.dst) {
			if (typeof mods.dst.nid   !== 'undefined')  this.dst.nid   = mods.dst.nid
			if (typeof mods.dst.pid   !== 'undefined')  this.dst.pid   = mods.dst.pid
			if (typeof mods.dst.beg   !== 'undefined')  this.dst.beg   = mods.dst.beg
			if (typeof mods.dst.end   !== 'undefined')  this.dst.end   = mods.dst.end
			if (typeof mods.dst.title !== 'undefined')  this.dst.title = mods.dst.title
		}

		if (typeof mods.predicate_nid  !== 'undefined') this.predicate_nid  = mods.predicate_nid
		if (typeof mods.rpredicate_nid !== 'undefined') this.rpredicate_nid = mods.rpredicate_nid

		if (typeof mods.linked_url  !== 'undefined') this.linked_url  = mods.linked_url

		this.t_modified = t0
		this.last_mod_uuid = op.set_mod_uuid || op.lid

	}

	hasGroup(gid) {
		const index = this.groups.findIndex( (g) => g.gid == gid )
		return index >= 0
	}

	groupAdd(op) {
		const t0  = parseInt(Date.now() / 1000)
		const gid = op.gid
		const pos = this.groups.findIndex( g => g.gid == gid)
		if (pos == -1) this.groups.push({gid: gid})
		this.last_mod_uuid = op.set_mod_uuid || op.lid
		this.t_modified = t0
	}

	groupDel(op) {
		const t0  = parseInt(Date.now() / 1000)
		const gid = op.gid
		const pos = this.groups.findIndex( g => g.gid == gid)
		if (pos>-1) {
			this.groups.splice(pos, 1)
		}
		this.last_mod_uuid = op.set_mod_uuid || op.lid
		this.t_modified = t0
	}
}
