import React from "react";
import { observer, inject } from "mobx-react"
import { withStore } from '../../utility/Core'
import { Link, withRouter } from 'react-router-dom'

class Username extends React.Component {

	constructor() {
		super();
		this.state = {
			user: false
		}
	}

	componentDidMount() {
		const { core, uid } = this.props;
		if (typeof core.cache.userDetails[uid] === 'undefined') core.getUserInfo(uid)
	}

	render() {
		const { core, uid } = this.props;

		if (typeof core.cache.userDetails[uid] === 'undefined') return <span className="link-to-user">user {uid}</span>

		const user = core.cache.userDetails[uid]
		return <span className="link-to-user" title={user.name ? user.name : ''}><span className="fa fa-user" style={{color: user.color}} /> {user.short}</span>
	}

}

export default withRouter(withStore(observer(Username)))
