import React from "react";
import { observer, inject } from "mobx-react";
import { withStore } from '../utility/Core'

import DirectNodeModeSwitch from "./DirectNodeModeSwitch";

import DirectParagraph from "./DirectParagraph";

class DirectNode extends React.Component {

	constructor(props) {
    super(props)
		const { core } = this.props

		// either use a componentId passed to the component or create one on the fly
		this.componentId = this.props.nodeComponentId ? this.props.nodeComponentId : core.componentId('Node')
	}

	componentDidCatch(error, info) {
		console.log('ERROR', error, info)
		this.setState({ hasError: true, errorDetails: info });
	}

	componentDidMount() {
		const { core, nid } = this.props;
		core.loadNode(nid, 'full', this.componentId)
	}

	componentWillUnmount() {
		const { core, nid, style} = this.props

		if (typeof core.nodes[nid] === 'undefined') return
		//if (core.nodes[nid].locked_uid == core.user.uid) this.releaseNodeLock(true)
		//if (core.nodes[nid].loaded != 'not') core.unloadNode(nid, this.componentId)

		//core.edgeEditSet(false, false, false)
	}

	render() {
		const { core, nid, mode, parStoreUp } = this.props
		const node = core.nodes[nid]

		if (typeof node === 'undefined') return null
		if (node.loaded !== 'full') return null

		return <div>{
		node.paragraphList.map( (p) => {
			return <p key={p}>{node.paragraphs[p].txt}</p>
		})
		}</div>

	}

}

export default withStore(observer(DirectNode))
