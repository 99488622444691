import React from "react";
import { Link, withRouter } from 'react-router-dom';

import { action } from "mobx";
import { observer, inject } from "mobx-react";
import { withStore } from '../utility/Core'

import UserShort from "./UserShort";
import PoeParsedForUI from './PoeParsedForUI';

class NodeListLine extends React.Component {

	render() {
		const {node, core} = this.props
		var content   = ''
		var targetNid = node.nid

		var  loadStatus = <span className="fa fa-circle-thin" title="The card is NOT fully loaded" />
		if (node.loaded == 'full') loadStatus = <span className="fa fa-dot-circle-o" title="The card is fully loaded and will be available offline..." />

		var extraInfo     = null // core.fulldate(node.t_seen)
		if (node.type == 'tag') {
			extraInfo  = <span className="fa fa-question-circle" onClick={(e) => { core.loadNode(node.nid, 'full', null); e.stopPropagation(); return false; }} />
			if (node.loaded == 'full') {
				extraInfo = core.edges.filter(e => e.type == 'tag' && e.dst.nid == node.nid).length
			}
		}

		switch(node.type) {

			case 'tag':
				content = node.title ? <span><span className="fa fa-hashtag" />{node.title}</span> : <span className="fa fa-question-circle" />
				break;

			case 'comment':
				const parentNodeNid   = (node.rel) ? node.rel : false
				const parentNode      = (parentNodeNid && core.nodes[parentNodeNid]) ? core.nodes[parentNodeNid] : false
				const parentNodeTitle = parentNode.title ? <span>{parentNode.title}</span> : <span className="fa fa-question-circle" />

				targetNid = parentNodeNid

				content = <div>
					<div class="parentNodeTitle">Comment by <UserShort uid={node.uid} /> on "{parentNodeTitle}"</div>
					<div class="nodeTitle"><PoeParsedForUI tag="span" props={{className:"userbox"}} txt={ '%' + node.uid } /> {node.title}</div>
				</div> /**/

				/*
				content = <div>
				 {node.title}
				 <div class="parentNodeTitle">Comment by {node.uid} on {parentNodeTitle}</div>
				</div>
				*/

				break;

			default:
				content = node.title ? node.title : <span className="fa fa-question-circle" />
		}

		const cssClasses = [((node.t_seen) && (parseInt(node.t_seen) >= parseInt(node.t_updated))) ? 'seen' : 'new']
		cssClasses.push('nodeList-' + node.type)

		return <tr key={"row-" + targetNid} onClick={() => { this.props.history.push('/node/'+targetNid) }} className={ cssClasses.join(' ') }>
				<td>{loadStatus}</td>
				<td>
					{content}
				</td>
				<td title={core.fulldate(node.t_updated)}>{core.mindate(node.t_updated)}</td>
				<td title={core.fulldate(node.t_created)}>{core.mindate(node.t_created)}</td>
				<td title=''>{extraInfo}</td>
			</tr>

	}

}

export default withRouter(withStore(observer(NodeListLine)))
