import React from "react"
import { observer, inject } from "mobx-react"
import { withStore } from '../utility/Core'
import { Link } from 'react-router-dom'

import Storage from "../utility/Storage"

import JSONPretty from 'react-json-pretty'

import DateString from './DateString'
import Username from './PoeParsedForUI/Username'
import { smartDate } from '../utility/dateString.js'
import WallEvent from './WallEvent'
import { InView } from 'react-intersection-observer'

const { StreamEvent } = require('../cron/StreamEvent.js')

var dateCreate = require('sugar/date/create');
var dateFormat = require('sugar/date/format');

class Wall extends React.Component {

	constructor(props) {
		super(props)

		this.storage          = new Storage()

		this.gids = props.core.user.groups.filter(g => g.active).map(g => g.gid).join('-')

		this.state = {
			loadState: false,
			wallData: {}
		}
	}

	componentDidMount() {
		const { core } = this.props;

		this.loadWall.bind(this)()
	}

	componentDidUpdate() {
		const { core } = this.props
		const gids     = core.user.groups.filter(g => g.active).map(g => g.gid).join('-')
		if (gids != this.gids) {
			this.gids = gids
			this.loadWall()
		}
	}

	componentWillUnmount() {
		// clearTimeout(this.positionWatch)
		if (typeof this.storage.ajax !== 'undefined') this.storage.ajax.cancelHandler.cancel('parent component unmounted');
	}

	loadWall(offset = false) {
		const { core } = this.props
		const showOwn  = core.ui.param.wall.showOwn

		const gids     = core.user.groups.filter(g => g.active).map(g => g.gid)

		this.setState({
			loadState: 'loading'
		})

		this.storage.query({action: 'wall', showOwn, offset, gids})
			.then((res) => {
					const nodes = res.data.data.context.nodes.filter(n => n.loaded != false)
					core.bulkInsert(nodes)
					const newEvents = [] // res.data.data.events.map(e => new StreamEvent(e, 'transfer')) // TODO: fix this
					//const newEvents = res.data.data.events.map(e => new StreamEvent(e, 'transfer')) // TODO: fix this
					res.data.data.events.forEach((e) => {
						newEvents.push(new StreamEvent(e, 'transfer'))
					})

					this.setState({
						loadState: 'loaded',
						wallData: {
							events: (offset) ? [...this.state.wallData.events, ...newEvents] : newEvents,
							last_lid: res.data.data.last_lid,
							hasMore: (newEvents.length != 0),
						}
					})
				})
			.catch((thrown) => {
				this.setState({
					loadState: 'fail'
				})
			})

	}

	toggleShowOwn() {
		const { core } = this.props
		const wall = core.ui.param.wall

		wall['showOwn'] = ! wall['showOwn']

		core.ui.set('wall', wall)

		this.loadWall.bind(this)()
	}

	render() {
		const { core } = this.props
		const { loadState, wallData } = this.state
		const groups = core.user.groups.filter(g => g.active)

		var wall
		var lastEventId = undefined
		var prevUid     = -1

		document.title = ' • ' + 'Activity Stream'

		if ((wallData.events) && (wallData.events.length)) {
			wall = []
			var last_day = dateFormat(new dateCreate(Date.now()), '%Y-%m-%d')
			wallData.events.forEach((row, i) => {

				if (dateFormat(new dateCreate(1000 * row.t_from), '%Y-%m-%d') != last_day) {
					const current_day = new dateCreate(1000 * row.t_from)
					last_day          = dateFormat(current_day, '%Y-%m-%d')
					wall.push(<span className="day-separator" title={last_day} key={last_day}>{dateFormat(current_day, '%d. %b')}</span>)
					prevUid = -1
				}

				wall.push(<WallEvent key={row.id} logEvent={row} rendered_as={i} hideUser={row.uid === prevUid} />)

				lastEventId       = (typeof lastEventId === 'undefined') ? row.id : Math.min(lastEventId, row.id)
				prevUid           = row.uid
			})
		}
		else {
			wall = '---'
		}

		var showOwnButton = null
		if (core.ui.param.wall.showOwn) {
			showOwnButton = <span className="wall-show-own clickable" onClick={this.toggleShowOwn.bind(this)}>Hide own activity</span>
		}
		else {
			showOwnButton = <span className="wall-show-own clickable" onClick={this.toggleShowOwn.bind(this)}>Show own activity</span>
		}

		var moreButton = null
		if (lastEventId) {
			if (wallData.hasMore) {
				if (loadState == 'loading') {
					moreButton = <span><span className="fa fa-spinner fa-pulse" /> loading...</span>
				}
				else {
					moreButton = <InView as="span"
						onClick={() => { this.loadWall.bind(this)(lastEventId) }}
						onChange={(inView) => { if ((inView) && (loadState != 'loading')) this.loadWall.bind(this)(lastEventId) }}>
						load more (&lt;{lastEventId})
					</InView>
				}
			}
			else {
				moreButton = <span>[nothing to load]</span>
			}
		}

		return <div className="big-screen wall">
			{ showOwnButton }
			<h1 onClick={ () => this.loadWall.bind(this)() }>Activity Stream {groups.map(group => group.name).join(', ')} { (loadState == 'loading') ? <span><span className="fa fa-spinner fa-pulse" /> loading</span> : null }</h1>
			{ wall }
			{ moreButton }
		</div>
	}
}

export default withStore(observer(Wall))
