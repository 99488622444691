import React from "react";
import { observer, inject } from "mobx-react";
import { withStore } from '../utility/Core'
import { HashRouter, Switch, Route, Link, withRouter } from 'react-router-dom';

import Storage from "../utility/Storage";

import EdgeTargetSelector from "./EdgeTargetSelector";
import EdgeAudienceCheck from "./EdgeAudienceCheck";
import DirectNode from "./DirectNode";
import Predicate from "./Predicate";
import NodeSelector from "./NodeSelector";
import DateString from "./DateString";
import PoeParsedForUI from "./PoeParsedForUI";
import GroupAssociation from "./GroupAssociation";

import JSONPretty from 'react-json-pretty';



class SingleEdge extends React.Component {

	constructor(props) {
    super(props);
		this.storage = new Storage();
    this.state = {
			hasError: false,
			loaded_eid: false,
			reverse: false,
			section: 0,
			loading: false,
			lastSrcNid: false,
			lastDstNid: false,
		}
  }


	componentDidCatch(error, info) {
		console.error('ERROR', error, info)
		this.setState({ hasError: true });
	}

	unstable_handleError(error, info) {
		console.log('ERROR', error, info)
	}

	acquireEdgeLock() {
		const { core, eid } = this.props;
		const edge = core.edgeById(eid)

		if (!core.user.permission('update', 'edge_' + edge.type, edge)) {
			alert('You lack the necessary permissions to edit this link.')
			return false
		}

		core.requestLock(eid)
		    .then(() => { })
		    .catch((e) => {
					if (e.status == 401) alert('Could not lock the link for editing. It is already locked by another user.')
					console.error(e)
					})
	}

	releaseEdgeLock(silent = false) {
		const { core, eid } = this.props;

		core.releaseLock(eid)
		    .then((e) => { /* console.error(e) */ })
		    .catch((e) => { /* console.error(e) */ })

	}


	removeLinkDestination() {
		const { core, nid, eid } = this.props
		const edge = core.edgeById(eid)
		core.op({
			realm: 'edge',
			op:    'edgeUpdate',
			prev_mod_uuid: edge.last_mod_uuid,
			eid:    edge.eid,
			mods: {
				dst: { nid: null, title: null },
				linked_url: null
			},
			uid: core.user.uid,
			lid: core.uuid('l'),
		})
	}

	load() {
		this.setState({loaded_eid: this.props.eid})
		this.setState({hasError: false})
		this.setState({loading: true})

		const dummy = this.props.core.loadEdge(this.props.eid)
		.then((e) => {
			const edge = this.props.core.edgeById(this.props.eid)
			this.setState({loading: false});
			// console.log('edge is loaded')
		})
		.catch((thrown) => {
			this.setState({hasError: true})
		})
	}

	componentDidMount() {
		const { core, eid } = this.props
		const edge          = core.edgeById(eid)
		if ((typeof edge !== 'undefined') && (edge.src.nid)) this.setState({lastSrcNid: edge.src.nid})
		if ((typeof edge !== 'undefined') && (edge.dst.nid)) this.setState({lastDstNid: edge.dst.nid})

		this.load()
/*
		core.messages.recent.push({
			mid: core.uuid('m'),
			type: 'action',
			uid: 0,
			groups: [core.user.defaultGid],
			body: 'The groups will be added to the link...' + eid,
			buttons: [{ txt: 'OK', default: true, op: {
				realm: 'edge',
				op:    'groupAdd',
				prev_mod_uuid: edge.last_mod_uuid,
				eid:   edge.eid,
				gid:   17,
				uid:   core.user.uid,
				lid:   core.uuid('l'),
			} }, { txt: 'Cancel' }],
			timeout: 3,
			t_created: Date.now(),
			t_seen: undefined,
			deleted: false,
		})
*/

	}

	componentWillUpdate() {
		const { core, eid } = this.props
		const edge   = core.edgeById(eid)
		if ((typeof edge !== 'undefined') && (edge.src.nid) && (this.state.lastSrcNid != edge.src.nid)) this.setState({lastSrcNid: edge.src.nid})
		if ((typeof edge !== 'undefined') && (edge.dst.nid) && (this.state.lastDstNid != edge.dst.nid)) this.setState({lastDstNid: edge.dst.nid})
	}

	componentDidUpdate() {
		if (this.props.eid != this.state.loaded_eid) this.load();
	}

	componentWillUnmount() {
		const { core, eid } = this.props;
		const edge = core.edgeById(eid)

		if ((typeof edge !== 'undefined') && (edge.locked_uid == core.user.uid)) this.releaseEdgeLock(true)
	}

	render() {
		const { core, nid, eid } = this.props
		const edge   = core.edgeById(eid)

		if (this.state.hasError) {
      return <div id="content-area">
				<div class="dual-node center loading">
					Cannot display edge {JSON.stringify(this.props.eid)}
				</div>
			</div>;
    }

		if (typeof edge === 'undefined') {
			if (this.state.loading) {
				return <div id="content-area"><div class="dual-node center loading"><span><span className="fa fa-spinner fa-pulse" /> ... loading</span></div></div>
			}
			else {
				return <div id="content-area">
					<div class="dual-node center loading">
						<span>Edge is not available.</span>
						{ this.state.lastSrcNid ? <p><Link to={'/node/' + this.state.lastSrcNid}><span className="fa fa-arrow-left" /> {core.nodes[this.state.lastSrcNid].title}</Link></p>  : null }
						{ this.state.lastDstNid ? <p><Link to={'/node/' + this.state.lastDstNid}>{core.nodes[this.state.lastDstNid].title} <span className="fa fa-arrow-right" /></Link></p>  : null }
					</div>
				</div>
			}
		}

		if ((edge.deleted) || (edge.loaded == 'deleted')) {
			return <div id="content-area"><div class="dual-node center loading"><span><span className="fa fa-exclamation-triangle" /> Link has been deleted.</span></div></div>
		}

		const config = [
			{
				contentEditable: false,
				markEdge:        { eid: edge.eid, dir: 'src' },
				suppressNodeControls: true,
				suppressOverlays: true,
			},{
				contentEditable: false,
				markEdge:        { eid: edge.eid, dir: 'dst' },
				suppressNodeControls: true,
				suppressOverlays: true,
			}
		]

		const locked = (edge.locked_uid == core.user.uid)

		let node1 = <div class="dual-node left with-highlight" key="node-left">null</div>
		let node2 = <div class="dual-node right with-highlight" key="node-right">null</div> // <span>Empty.</span>;

		if (edge.src.nid) node1 = <div onClick={() => { if (!locked) this.props.history.push('/node/'+edge.src.nid) }} className={"dual-node node left with-highlight" + (locked ? '' : ' clickable')} key="node-left">
			<DirectNode nid={edge.src.nid} mode="marker" mark={edge} />
		</div>

		if (edge.dst.nid) {
			node2 = <div onClick={() => { if (!locked) this.props.history.push('/node/'+edge.dst.nid) }} className={"dual-node node right with-highlight" + (locked ? '' : ' clickable')} key="node-right">
				<DirectNode nid={edge.dst.nid} mode="marker" mark={edge} />
			</div>
		}
		else if (edge.linked_url) {
			node2 = <div class="dual-node node right"><a href={edge.linked_url}>{edge.linked_url}</a></div>
		}
		else {
			if (locked) {
				node2 = <div class="dual-node right" key="node-right"><EdgeTargetSelector edge={edge} nid={nid} /></div>
			}
			else {
				node2 = null
			}
		}

		function deleteEdge(edge) {
			if (core.user.permission('delete', 'edge_' + edge.type, edge)) {
				const op = {
					realm: 'edge',
					op:    'edgeDelete',
					eid:    edge.eid,
					type:   edge.type,
					src:    edge.src,
					dst:    edge.dst,
					prev_mod_uuid: edge.last_mod_uuid,
					uid: core.user.uid,
					lid: core.uuid('l'),
				}
				if ((typeof op.src !== 'undefined') && (typeof op.src.nid !== 'undefined') && (op.src.nid !== null) && (typeof op.src.pid !== 'undefined') && (op.src.pid !== null)) {
					if ((typeof core.nodes[op.src.nid].paragraphs !== 'undefined') && (typeof core.nodes[op.src.nid].paragraphs[op.src.pid] !== 'undefined')) op.src.prev_mod_uuid = core.nodes[op.src.nid].paragraphs[op.src.pid].last_mod_uuid
				}
				if ((typeof op.dst !== 'undefined') && (typeof op.dst.nid !== 'undefined') && (op.dst.nid !== null) && (typeof op.dst.pid !== 'undefined') && (op.dst.pid !== null)) {
					if ((typeof core.nodes[op.dst.nid].paragraphs !== 'undefined') && (typeof core.nodes[op.dst.nid].paragraphs[op.dst.pid] !== 'undefined')) op.dst.prev_mod_uuid = core.nodes[op.dst.nid].paragraphs[op.dst.pid].last_mod_uuid
				}
				core.op(op)
			}
		}

		var deleteButton = null

		if ((locked) && (core.user.permission('delete', 'edge_' + edge.type, edge))) { deleteButton =
			<div class="section edge-details-settings">
				<span class="icon fa fa-ellipsis"></span>
				<div class="content">
					<button onClick={ () => { deleteEdge(edge) } } className="light"><span className="fa fa-trash" /> Delete this link</button>
				</div>
			</div>
		}

		var target                     = <span className="fa fa-file destination no-marks" />
		if (! edge.dst.nid)  target    = <span className="fa fa-cross destination clickable no-marks"/>
		if (edge.type == 'tag') target = <span className="fa fa-tag destination no-marks"/>
		if (edge.type != 'tag') {
			if (edge.linked_url) target    = <span onClick={ this.removeLinkDestination.bind(this) } title="Click to remove the link target" className="fa fa-globe destination clickable" />
			else target                    = <span onClick={ this.removeLinkDestination.bind(this) } title="Click to remove the link target" className="fa fa-file destination clickable" />
		}

		//
		const updButton = (core.user.permission('update', 'edge_' + edge.type, edge) && (!locked)) ? <div>Click to <button className="clickable" onClick={() => { core.requestLock(edge.eid) }}>modify</button></div> : null
		const delButton =  core.user.permission('delete', 'edge_' + edge.type, edge)               ? <div>Click to <button className="clickable" onClick={() => { deleteEdge(edge) }}>delete</button></div> : null

		var predicate = <div class="dual-node center warning" key="edge-info">
			<div><span className="fa fa-exclamation-triangle" /> Link is incomplete.</div><br />
			{updButton}<br />
			{delButton}
		</div>




		var lockButton = <span class={"icon fa fa-pencil lock " + ((core.user.permission('update', 'edge_' + edge.type, edge)) ? 'clickable' : 'forbidden') } onClick={this.acquireEdgeLock.bind(this)}></span>
		if (locked) {
			lockButton = <span class={"icon fa fa-pencil lock clickable active"} onClick={this.releaseEdgeLock.bind(this)}></span>
		}

		if ((edge.src.nid) && ((edge.dst.nid) || (edge.linked_url))) {
			predicate = <div class="dual-node center edge-details" key="edge-info">

				<div class="section edge-details-header">
					{ lockButton }
					<div class="content">Link created on <DateString value={edge.t_created} /> by <PoeParsedForUI tag='span' props={{className: 'inactive'}} txt={'%' + edge.uid} /></div>
				</div>
		 { (locked || edge.predicate_nid || edge.rpredicate_nid) ?
				<Predicate className={(this.state.section == 0)? ['active'] : []} edge={edge} reverse={this.state.reverse} simple={true} contextIsReverse={this.state.reverse} fnFlipSides={ () => { this.setState({reverse: !this.state.reverse}) } } />
		   :null }
		 { locked ?
				<div class="section edge-details-groups">
				{ /* <span class="icon fa fa-user-circle-o"></span> */ }
					<div class="content">
						<GroupAssociation type="edge" obj={edge} />
					</div>
				</div> : null }

		{ locked ?
				<div class="section edge-details-type">
					<span class="icon"></span>
					<div class="content">
					<div title={'Edge type: ' + edge.type}>
						<span className="fa fa-file source" /> <span className={'fa ' +  (this.state.reverse ? 'fa-long-arrow-left' : 'fa-long-arrow-right') } /> <span className="fa fa-square-o" /> <span className={'fa ' +  (this.state.reverse ? 'fa-long-arrow-left' : 'fa-long-arrow-right') } /> {target}
					</div>
					</div>
				</div> : null }

				{ deleteButton }


				<EdgeAudienceCheck eid={edge.eid} />

			</div>
		}

		// Set Window Title
		var title1 = '---'
		var title2 = '---'
		if ((typeof core.nodes[edge.src.nid] !== 'undefined') && (typeof core.nodes[edge.src.nid].title !== 'undefined')) title1 = core.nodes[edge.src.nid].title
		if ((typeof core.nodes[edge.dst.nid] !== 'undefined') && (typeof core.nodes[edge.dst.nid].title !== 'undefined')) title2 = core.nodes[edge.dst.nid].title
		if (edge.linked_url) title2 = 'WWW'
		document.title = ' • Link between "' + title1 + '" and "' + title2 + '"'

		return <div id="content-area">

			{node1}

			{predicate}

			{node2}

		</div>
	}

}



export default withRouter(withStore(observer(SingleEdge)))
