import React from "react";
import { action } from "mobx";
import { observer, inject } from "mobx-react";
import { withStore } from '../utility/Core'

import Storage from "../utility/Storage";
class CoreCompletion extends React.Component {

	constructor(props) {
		super(props)

		this.state = {
			working: false,
			loading: false,
		}

		this.storage = new Storage()
	}

	componentDidMount() {

	}

	loadChunk() { //  todo: had decorator inject
		const { core } = this.props
		const { working, loading } = this.state
		const nids       = Object.keys(core.nodes)
		const incomplete = nids.filter(nid => core.nodes[nid].loaded != 'full')

		if (loading) {
			console.warn('loadChunk in progress, don\'t trigger new loadChunk')
		}

		//this.setState({ loading: true })

		core.loadNode(incomplete[0], 'full')
	}

	componentDidUpdate() {
		const { core } = this.props
		const { working } = this.state
		const nids       = Object.keys(core.nodes)
		const incomplete = nids.filter(nid => core.nodes[nid].loaded != 'full')

		if (working) {
			if (incomplete.length == 0) this.setState({ working: false })
			if (!this.timer) this.timer = setInterval(this.loadChunk.bind(this), 1000)
		}
		else {
			if (this.timer) { clearInterval(this.timer) ; this.timer = false }
		}
	}

	componentWillUnmount() {
		if (this.timer) { clearInterval(this.timer) ; this.timer = false }
	}

	render() {
		const { core } = this.props
		const { working } = this.state

		const nids       = Object.keys(core.nodes)
		const incomplete = nids.filter(nid => core.nodes[nid].loaded != 'full')

		const txt        = working ? 'Charging' : 'Charge Core'

		return <span className="clickable" onClick={() => { this.setState({working: !working}) }}>{txt} ({ nids.length - incomplete.length } / {nids.length})</span>
	}

}


export default withStore(observer(CoreCompletion))
