import React from "react";
import { observer, inject } from "mobx-react";
import { withStore } from '../utility/Core'

import Storage from "../utility/Storage";

class LoginForm extends React.Component {

	constructor() {
		super()
		this.storage = new Storage();
		this.state = {
			loading: false,
			recoveryMode: false,
		}
		this.whoami = this.whoami.bind(this)
	}

	componentDidMount() {
		const { core } = this.props

		console.log('login screen mounted')
		this.whoami()
	}

	componentWillUnmount() {
		console.log('login screen unmounts')
	}

	async whoami() {
		const { core } = this.props

		try {
			const r = await this.storage.query({ action:'whoami' })
			if ((r.status === 200) && (typeof r.data.data.user.uid !== 'undefined')) {
				console.log(r.data.data)
				core.login(r.data.data)
			}
		}
		catch(e) {
			console.error(e)
		}

	}

	remoteLogin(e) {
		e.preventDefault();
		const { core } = this.props

		this.setState({ loading: true })

		this.storage.query({
			'action': 'login',
			'username': document.getElementById("loginFormUsername").value,
			'password': document.getElementById("loginFormUserpass").value,
			})
			.then((response) => {
				this.setState({ loading: false })
				if (response.data.data.status == 401) {
					alert('You provided invalid information and were unable to log in.')
					return;
				}
				core.login(response.data.data);
			})
			.catch((thrown) => {
				console.error(thrown)
				this.setState({ loading: false })
				alert("Could not log you in :/\nServer/Connection problem...\nApologies!");
			})
	}

	passwordRecovery(e) {
		e.preventDefault();
		const { core } = this.props

		this.setState({ loading: true })

		this.storage.query({
			'action': 'passwordRecovery',
			'username': document.getElementById("loginFormUsername").value,
			})
			.then((response) => {
				this.setState({ loading: false })
				if (response.data.data.status == 200) {
					alert('A one-time login has been sent to your email address (if the address you entered already has an account).')
				}
				else {
					alert('There was some error :/')
				}
			})
			.catch((thrown) => {
				console.error(thrown)
				this.setState({ loading: false })
				alert("Could not reach server :/\nServer/Connection problem...\nApologies!");
			})
	}

	render() {
		const { core } = this.props;
		const { recoveryMode } = this.state;

		if (recoveryMode) {
		return (
		<div id="login-form" className={ this.state.loading ? 'loading' : '' }>
			<h1>Welcome to SHORX!</h1>
			<form action="/">
				<div  className="form-element"><label htmlFor="loginFormUsername">email</label>&nbsp;<input disabled={this.state.loading} id="loginFormUsername" autoComplete="username email" type="text" /></div>
				<div  className="form-element"><label /><button disabled={this.state.loading} type="submit" onClick={this.passwordRecovery.bind(this)}>Submit</button></div>
				<span className="form-element" id="recovery-link" onClick={() => {this.setState({recoveryMode: false}) }}>login</span>
			</form>
		</div>
		);
		}

		return (
		<div id="login-form" className={ this.state.loading ? 'loading' : '' }>
			<h1>Welcome to SHORX!</h1>
			<form action="/">
				<div  className="form-element"><label htmlFor="loginFormUsername">email</label>&nbsp;<input disabled={this.state.loading} id="loginFormUsername" autoComplete="username email" type="text" /></div>
				<div  className="form-element"><label htmlFor="loginFormUserpass">password</label>&nbsp;<input disabled={this.state.loading} id="loginFormUserpass" autoComplete="current-password" type="password" /></div>
				<div  className="form-element"><label /><button disabled={this.state.loading} type="submit" onClick={this.remoteLogin.bind(this)}>Submit</button></div>
				<span className="form-element" id="recovery-link" onClick={() => {this.setState({recoveryMode: true}) }}>recover password</span>
			</form>
		</div>
		);
	}

}

export default withStore(observer(LoginForm))
