import { observable, computed, action, extendObservable, useStrict, autorun, set } from "mobx";


const Core_ui = {

	/* @observable */
	param: {

		layoutLoadReady: false,

		// Node config
		singleNodeContextType: 1,
		spellchecker: true,

		// Nodelist
		nodeListView: 'list',
		nodeListSearch: [{
			type: 'card',
			field: 'title',
			searchPattern: '',
		}],

		// Wall
		wall: {
			showOwn: false,
		},

		notifications: {
//			cycle: 86400,
			cooloff: 3600,
		},

	},

	/* @action */
	set(key, value) {
		this.param[key] = value
	},

}


export { Core_ui }
