import React from "react";
import { observer, inject } from "mobx-react";
import { withStore } from '../utility/Core'

import DirectNodeModeSwitch from "./DirectNodeModeSwitch";

import DirectParagraph from "./DirectParagraph";
import PrettyError from "./PrettyError"

window.DOM = {}
const debug = false

class DirectNode extends React.Component {

	constructor(props) {
    super(props)

		const { core } = this.props

		this.domRef    = React.createRef();

		this.pars = {}
		this.postRenderTasks = {}

		// either use a componentId passed to the component or create one on the fly
		this.componentId = this.props.nodeComponentId ? this.props.nodeComponentId : core.componentId('Node')

		this.state = {
			hasError: false,
			error: undefined,
			errorDetails: undefined,
		}
	}

	componentDidCatch(error, info) {
		this.setState({ hasError: true, error: error, errorDetails: info });
	}

	componentDidMount() {
		const { core, nid } = this.props;
		this.load(nid)
	}

	componentDidUpdate(prevProps) {
		const { core, nid } = this.props

		if (prevProps.nid != nid) {

			this.unload(prevProps.nid)
			this.load(nid)

		}

	}

	componentWillUnmount() {
		const { core, nid } = this.props
		const node = core.nodes[nid]

		core.setCaretX(this.componentId, false)

		if (typeof node === 'undefined') return

		this.unload(nid)
	}

	load(nid) {
		const { core } = this.props
		core.loadNode(nid, 'full', this.componentId)
	}

	unload(nid) {
		const { core } = this.props
		const node = core.nodes[nid]

		if (typeof node !== 'undefined') node.release(this.componentId)

		//if (node) && (node.locked_uid == core.user.uid) {
		//// release lock
		//}
		core.setCaretX(this.componentId, false)
	}

	error(content, classes = false) {
		const { core, nid, mode } = this.props
		return <div className={"node-body" + (classes ? ' ' + classes : '')} data-nid={nid} ref={this.domRef}>
							{content}
						</div>
	}

	render() {
		const { core, nid, mode, mark, parStoreUp } = this.props

		if (this.state.hasError) {
			return <div className="node-body not-loaded error" data-nid={nid}>
				<PrettyError error={this.state.error} errorDetails={this.state.errorDetails} context={'Error in Node ' + nid + ', mode "' + mode + '"'} />
			</div>
		}

		const node = core.nodes[nid]

		if (typeof node === 'undefined') return this.error('Node does not exist...', 'not-loaded')
		if (node.deleted)                return this.error('Node is deleted', 'not-loaded')
		if (node.loaded == 'deleted')    return this.error('Node is deleted', 'not-loaded')
		if (node.loaded != 'full')       return this.error('Node is loaded only as ' + node.loaded, 'not-loaded')

		//
		const locked_uid  = node.locked_uid
		var   lock_status = ''

		if ((mode == 'edit') && (locked_uid == core.user.uid))      lock_status = ' locked'
		if ((mode == 'edit') && (!locked_uid))                      lock_status = ' locking'
		else if ((mode == 'edit') && (locked_uid != core.user.uid)) lock_status = ' lock-fail'

		// Store paragraph data in a way that paragraphs can access each others' details
		const parStore = function(pid, key, value) {
			if (typeof value === 'undefined') {
				if ((typeof this.pars[pid] !== 'undefined') && (typeof this.pars[pid][key] !== 'undefined')) return this.pars[pid][key]
				return undefined
			}

			if (typeof this.pars[pid] === 'undefined') this.pars[pid] = {}
			this.pars[pid][key] = value
			// console.log('ParStore: ['+pid+']['+key+'] = ', value)
		}

		// hand the list of paragraphs to the parent
		if (typeof parStoreUp !== 'undefined') parStoreUp(this.pars)

		// Store/execute post-Render-Tasks
		const postRenderTask = function(pid, lid, task) {

			// if no task is given, execute the task that is stored
			if (typeof task === 'undefined') {
				if ((typeof this.pars[pid] !== 'undefined') && (typeof this.pars[pid]['postRender'] !== 'undefined')) {
					if (typeof this.pars[pid]['postRender'][lid] !== 'undefined') {
						const fs = this.pars[pid]['postRender'][lid]
						fs.map( (f) => { f() } )
						delete this.pars[pid]['postRender'][lid]
					}
					if (typeof this.pars[pid]['postRender'][null] !== 'undefined') {
						const fs = this.pars[pid]['postRender'][null]
						fs.map( (f) => { f() } )
						delete this.pars[pid]['postRender'][null]
					}
				}
			}
			else {
				if (typeof this.pars[pid] === 'undefined') this.pars[pid] = {}
				if (typeof this.pars[pid]['postRender'] === 'undefined') this.pars[pid]['postRender'] = {}
				if (typeof this.pars[pid]['postRender'][lid] === 'undefined') this.pars[pid]['postRender'][lid] = []
				this.pars[pid]['postRender'][lid].push(task)
			}
		}

		// Debugging
		// window.parStore = parStore.bind(this)


		const collector = {
			type: undefined,
			level: undefined,
			store: [],
			olBegin: [],

			flush(pile) {
				const i        = pile.length
				const firstPid = this.store[0].key
				const key      = 'list-at-' + firstPid
				const prev     = i<1 ? false : pile[i-1]
				var nested     = false

				if (prev) {
					const prevPid = prev.key.replace(/^list\-at\-/, '')

					const prevPar = node.paragraphs[prevPid]
					if (prevPar.type.substr(0,2) == node.paragraphs[firstPid].type.substr(0,2)) {
						nested = true
					}
				}


				const start = parseInt( this.olBegin[collector.level] || 0 ) + 1

				switch (this.type) {
					case 'ul':
						pile.push(<ul className={'a indent-' + collector.level + (nested ? ' nested':'' )} key={key}>{collector.store}</ul>)
						break;
					case 'ol':
						pile.push(<ol start={start} className={'a indent-' + collector.level + (nested ? ' nested':'' )} key={key}>{collector.store}</ol>)
						break;
					default:
						throw('unknown paragraph type')
				}

				if (collector.type == 'ol') {
					this.olBegin[collector.level] = start + collector.store.length - 1
					this.olBegin = this.olBegin.map( (e, i) => { return i>collector.level ? 0 : e } )
				}
			},

			clear(full = false) {
				if (full) this.olBegin = []
				this.type  = undefined
				this.level = undefined
				this.store = []
			},

			push(data, pid, nesting) {
				this.type  = data.type
				this.level = data.level
				this.store.push(<li key={pid}>{data.par}</li>)
			}

		}

		var renderedParagraphs = []
		var nested = false

		for(var i=0; i<node.paragraphList.length; i++) {
			const pid = node.paragraphList[i]
			const paragraph = node.paragraphs[pid]
			const [type, level] = (paragraph.type+'-1').split('-')
			const fulltype = type + '-' + level

			const par = <DirectParagraph
				nid={nid}
				pid={pid}
				key={pid}
				nodeComponentId={this.componentId}
				parStore={parStore.bind(this)}
				postRenderTask={postRenderTask.bind(this)}
				editable={(mode=='edit') && (node.locked_uid == core.user.uid)}
				mode={mode}
				mark={mark}
			/>

			if ((type == 'ul') || (type == 'ol'))  {

				if (((type != collector.type) || (level != collector.level)) && (collector.store.length)) {
					collector.flush(renderedParagraphs)
					collector.clear()
				}

				collector.push({
					type: type,
					level: level,
					par: par
				}, pid)


				continue
			}

			// Not a collectable, but collector is filled -> flush
			if (collector.store.length) {
				collector.flush(renderedParagraphs)
			}

			collector.clear(true)

			renderedParagraphs.push(par)
		}

		// done -> flush
		if (collector.store.length) {
			collector.flush(renderedParagraphs)
		}


		return  <div className={"node-body" + lock_status} data-nid={nid} ref={this.domRef}>
							{renderedParagraphs}
						</div>
	}

}

export default withStore(observer(DirectNode))
