import React from "react";
import { Link } from 'react-router-dom';

import { observer, inject } from "mobx-react";
import { withStore } from '../utility/Core'

import JSONPretty from 'react-json-pretty';

import OperationDetails from "./OperationDetails"
import Storage from "../utility/Storage";

const date = require('sugar/date');
window.d = date;

class OpList extends React.Component {

	constructor() {
		super()
		this.state = {
			showExportable: false,
			showImportable: false,
			showPrettyJson: false,
			hasError: false,
			isSending: false,
		}
	}

	componentDidCatch(error, info) {
		console.error('ERROR', error, info)
		this.setState({ hasError: true });
	}

	componentDidCatch(error, info) {
		console.error('ERROR', error, info)
		this.setState({ hasError: true });
	}

	componentDidUpdate() {
		const { core }  = this.props;
		document.title = 'shorx • ' + core.operations.length + ' Operations';
	}

	inject(s) {
		const { core }  = this.props;
		try {
			var struct = JSON.parse(s)
			struct.forEach((row) => {
				row.status = '';
				core.op(row)
			})
		}
		catch(e) {
			alert(e)
		}
	}

	componentWillMount() {
		const { core } = this.props
		//core.operations = JSON.parse('[{"op":"edgeCreate","realm":"edge","eid":"e-pn108wnl-m4x6g78o","type":"edge","src":{"nid":"n-3dc751f8-bb63c0f9","pid":"p-3dc751f8-fcecbefc","beg":11,"end":23,"title":"Control Societies (Deleuze) "},"dst":{"nid":null,"pid":null,"beg":null,"end":null},"lid":"l-pn108wnl-mkm5h83t","locked_uid":2,"locked_t":1534189435,"uid":2,"status":"done","t_local":1534189434858},{"realm":"edge","op":"edgeUpdate","prev_mod_uuid":"l-pn108wnl-mkm5h83t","eid":"e-pn108wnl-m4x6g78o","mods":{"dst":{"nid":"n-50e23a6e-0f77b2e9"},"linked_url":""},"lid":"l-pn108wnl-glqdffnb","uid":2,"status":"done","t_local":1534189446982},{"realm":"node","op":"nodeCreate","nid":"n-pn108wnl-sjqb97l6","pid":"p-pn108wnl-31i5bbep","title":"","type":"predicate","locked_uid":2,"locked_t":1534189450,"info":{"rel":"e-pn108wnl-m4x6g78o"},"lid":"l-pn108wnl-hfbamj8z","uid":2,"status":"done","t_local":1534189450288},{"op":"edgeUpdate","realm":"edge","eid":"e-pn108wnl-m4x6g78o","prev_mod_uuid":"l-pn108wnl-mkm5h83t","mods":{"predicate_nid":"n-pn108wnl-sjqb97l6","rpredicate_nid":"n-pn108wnl-sjqb97l6"},"lid":"l-pn108wnl-st9d3kzw","uid":2,"status":"done","t_local":1534189450289},{"realm":"node","op":"charInsert","nid":"n-pn108wnl-sjqb97l6","pid":"p-pn108wnl-31i5bbep","str":"relates to someht","pos":0,"prev_mod_uuid":"l-pn108wnl-hfbamj8z","lid":"l-pn108wnl-6je61gs1","uid":2,"status":"done","t_local":1534189453436},{"realm":"node","op":"charDelete","nid":"n-pn108wnl-sjqb97l6","pid":"p-pn108wnl-31i5bbep","str":"ht","pos":15,"prev_mod_uuid":"l-pn108wnl-6je61gs1","lid":"l-pn108wnl-kw0opope","uid":2,"status":"done","t_local":1534189454090},{"realm":"node","op":"charInsert","nid":"n-pn108wnl-sjqb97l6","pid":"p-pn108wnl-31i5bbep","str":"thing, somehow","pos":15,"prev_mod_uuid":"l-pn108wnl-kw0opope","lid":"l-pn108wnl-bw1dvn3e","uid":2,"status":"done","t_local":1534189456067}]')
	}

	sendOpsViaEMail() {
		const { core } = this.props
		const storage = new Storage;
		this.setState({isSending: true})

		storage.query({
			action: 'sendOperations',
			operations: core.operations,
		})
		.then((response) => {
			const reply = response.data.data
			alert(reply.details)
			this.setState({isSending: false})
		})
		.catch((thrown) => {
			console.log(thrown)
			alert('Failed to send your operations via eMail :/');
			this.setState({isSending: false})
		});

	}

	render() {
		if (this.state.hasError) return '<OpList /> has error'
		const { core } = this.props
		const classes  = ['big-screen', 'op-list', 'operations-list']
		// if (this.props.classNames.length) this.props.classNames.map( (row) => { classes.push(row) } )
		document.title = 'shorx • ' + core.operations.length + ' Operations';

		let exportOperations = null
		let importOperations = null
		let prettyJson       = null

		if (core.operations.length) {

			exportOperations = <div id="operations-export" key="exportable"><p className="clickable" onClick={() => { this.setState({showExportable: 'all'}) }}>► Show Operations as Exportable JSON</p></div>

			if (this.state.showExportable) {
				exportOperations = <div id="operations-export" key="exportable">
					<p className="clickable" onClick={() => { this.setState({showExportable: false}) }}>▼ Hide Exportable JSON</p>
					<textarea readOnly={true} value={JSON.stringify(core.operations)} rows="5" />
				</div>
			}

		}

		// core.user.expert
		if (core.user.expert) {

			prettyJson = <div id="operations-pretty" key="pretty"><p className="clickable" onClick={() => { this.setState({showPrettyJson: true}) }}>► View Operations Details</p></div>

			if (this.state.showPrettyJson) {
				prettyJson = <div id="operations-pretty" key="pretty">
					<p className="clickable" onClick={() => { this.setState({showPrettyJson: false}) }}>▼ Hide Operations Details</p>
					<JSONPretty id="json-pretty" json={core.operations}></JSONPretty>
				</div>
			}

			importOperations = <div id="operations-import" key="import"><p className="clickable" onClick={() => { this.setState({showImportable: true}) }}>► Show Operations Import (only do this if you know what you are doing!)</p></div>

			if (this.state.showImportable) {
				importOperations = <div id="operations-import" key="import">
					<p className="clickable" onClick={() => { this.setState({showImportable: false}) }}>▼ Hide Operations Import</p>
					<div className="json-inject">
						<input type="text" id="op-inject" />
						<button onClick={() => { this.inject(document.getElementById('op-inject').value) }}>Inject JSON (replay)</button>
					</div>
				</div>
			}
		}

		const emergencySave = <div className={"emergency-save" + ( core.operations.filter(o => o.status == 'failed').length ? ' active' : '' )}>
				<button onClick={this.sendOpsViaEMail.bind(this)} disabled={this.state.isSending}>Send via eMail</button>
				<span className="explanation"><span className="fa fa-info-circle" /> If you have trouble saving your changes to the server, you can use the eMail button to send the changes to support@shrimpp.de for manual inclusion; if this fails, you may copy and send the JSON code below using your regular email program.</span>
			</div>

		let i = 0;

		return <div class={classes.join(' ')}>
			<h1>{core.operations.length} Operations</h1>
			{ emergencySave }
			<table className='op-history'><tbody>{ core.operations.map((op) => { return <OperationDetails elm='table' key={op.lid} op={op} i={++i} /> } ).reverse() }</tbody></table>
			{ exportOperations }
			{ importOperations }
			{ prettyJson }
		</div>
	}
}

export default withStore(observer(OpList))
