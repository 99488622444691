import React from "react";
import { observer, inject } from "mobx-react";
import { withStore } from '../utility/Core'

import Storage from "../utility/Storage";

class LoadState extends React.Component {

	constructor() {
		super()
		this.storage = new Storage();
		this.state = {
			loading: false,
			t_start: 0,
			msg: [],
		}

		this.loadState = this.loadState.bind(this)
	}

	componentDidMount() {
		const { core } = this.props
		console.log('LoadState did mount')

		this.setState({
			loading: false,
			t_start: 0,
			msg: [],
		})

		// this.loadState()

		this.interval = setInterval(() => {
			if (this.state.t_start > 0) {
				this.setState({t_start: this.state.t_start-1})
			}
			else {
				if (!this.state.loading) {
					if (this.state.msg.length < 6) {
						this.setState({t_start: 15, attempt: this.state.attempt + 1})
						this.loadState()
					}
					else {
						if (!this.state.alerted) {
							alert('Failed to load repeatedly. Please reload the page and/or try again later.');
							this.setState({alerted: true})
						}
					}
				}
			}
		}, 1000)
	}

  loadState() {
		const { msg, loading } = this.state
		const { core } = this.props

		if (loading) return
		msg.push('Loading repository ... ')
		this.setState({msg, loading:true})
		console.log(msg)

		this.storage.query({
			'action': 'loadState',
			})
			.then((response) => {
				response.data.data.nodes.forEach(node => core.insertNode(node))
				core.loadState(response.data.data.t0, response.data.data.maxLid)
				this.setState({loading:false})
			})
			.catch((thrown) => {
				if (typeof thrown.response !== 'undefined') {
					console.log('NR: #', thrown.response.status + ': ' + thrown.response.data.data.reason, thrown.response.data)
					if (thrown.response.data.data.reason) msg.push('Failed to load repository: '+thrown.response.data.data.reason+'.'); else msg.push('Failed to load repository.');
					this.setState({msg})
				}
				this.setState({loading:false})
			})
	}

	componentWillUnmount() {
		console.log('LoadState unmounts')
		clearInterval(this.interval)
	}

	cookieIndicator() {
		const style = {
				position:'absolute',
				right:'8px',
				top:'4px',
				borderRadius:'16px',
				backgroundColor:'#0a0',
				color:'#fff',
				fontSize:'11px',
				width:'16px',
				height:'16px',
				textAlign:'center',
				lineHeight:'16px',
			}
		if ((document.cookie) && (document.cookie.indexOf('poe2') >=0)) {
			return <span style={style} title="Cookie present">+</span>
		}
		else {
			style.backgroundColor = '#a00'
			return <span style={style} title="No cookie present">-</span>
		}
	}

	render() {
		const { core } = this.props;

		return (
		<div id="login-form" className=''>
			{ this.cookieIndicator() }
			{ this.state.msg.map( (e, i) => {
				return <div style={{fontSize:'11px', marginBottom: '6px'}} key={i}>{e}</div>
			} )}
			<div style={{fontSize:'11px'}}>(waiting: {this.state.t_start})</div>
		</div>
		)
	}

}

export default withStore(observer(LoadState))
