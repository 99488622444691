import React from "react";
import { observer, inject, action, runInAction } from "mobx-react";
import { withStore } from '../../utility/Core'
import { Link, withRouter } from 'react-router-dom';

import Modal from 'react-modal'

const truncate = require('sugar/string/truncate');

Modal.setAppElement('#app')

class NodeContextTag extends React.Component {

	constructor(props) {
		super(props)

		this.state = {
			setDestination: false
		}

	}

	componentWillMount() {
		const { core, eid, localNid, nodeComponentId } = this.props
		const edge = core.edgeById(eid)

		if (!edge) return null

		const localNode  = core.nodes[localNid]
		const dstNid     = localNode.getEdgeRemote(edge).nid

		if (dstNid) {
			const dstNode    = core.nodes[dstNid]
			if (typeof dstNode === 'undefined') core.loadNode(dstNid)
		}

	}

	componentDidUpdate() {
		const { core, eid, localNid, nodeComponentId } = this.props

		const edge = core.edgeById(eid)
		const localNode = core.nodes[localNid]

		if (!edge) return null
		if (!localNode) return null

		const remoteNid    = localNode.getEdgeRemote(edge).nid
		if (remoteNid) {
			const dstNode      = core.nodes[remoteNid]
		  if (!dstNode) core.loadNode(remoteNid, 'min')
		}
	}

	deleteEdge() {
		const { core, eid, nodeComponentId, localNid } = this.props
		const edge = core.edgeById(eid)
		const localNode = core.nodes[localNid]

		if (!edge) return null
		if (!localNode) return null

		if (core.user.permission('delete', 'tag', localNode)) return false

		core.op({
			op: 'edgeDelete',
			nid: edge.src.nid,
			realm: 'edge',
			eid: edge.eid,
			type: edge.type,
			src: edge.src,
			dst: edge.dst,
			prev_mod_uuid: edge.last_mod_uuid,
			uid: core.user.uid,
			lid: core.uuid('l'),
		})
	}

	updateTagging(tasks) {
		const { core, eid, localNid } = this.props
		const edge = core.edgeById(eid)
		const localNode = core.nodes[localNid]
		if (!localNode) return null

		tasks.forEach((row)=>{
			switch(row.op) {
				case 'setTag':
					core.op({

					})
					break;

				case 'addTag':
					break;
			}
		})
	}

	render() {
		const { core, eid, nodeComponentId, localNid } = this.props

		const edge = core.edgeById(eid)
		const localNode = core.nodes[localNid]

		if (!edge) return null
		if (!localNode) return null

		const modalStyle = { content: {
			top                   : '50%',
			left                  : '50%',
			right                 : 'auto',
			bottom                : 'auto',
			marginRight           : '-50%',
			width                 : '20em',
			height                : '10em',
			transform             : 'translate(-50%, -50%)',
			overflow              : 'hidden',
		}}


		var missing = <span><span className="fa fa fa-exclamation-triangle" />Tag is missing.</span>

		const remoteNid    = localNode.getEdgeRemote(edge).nid
		if (!remoteNid) return <div className='contextual-edge edge-tag'><span className="fa fa-hashtag" />{missing}</div>

		const dstNode      = core.nodes[remoteNid]

		if ((!dstNode) || (!dstNode.loaded)) return <div className='contextual-edge edge-tag'><span className="fa fa-hashtag" />loading</div>


		var tagName        = <span className="fa fa-question-circle" />
		var modifyLink     = null
		var deleteLink     = null
		var groupShortName = <span className="tag-group" title="Could not determine group name :("><span className="fa fa-question-circle-o" /></span>

		if (dstNode) {
			if (dstNode.title) {
				tagName = <Link to={'/node/' + remoteNid}>{dstNode.title}</Link>
			}
			if (typeof dstNode.groups !== 'undefined') {
				if ((dstNode.groups.length) && (typeof dstNode.groups[0].gid !== 'undefined')) groupShortName = <span className="tag-group" title={core.groupByGid(edge.groups[0].gid).name}>{core.groupByGid(edge.groups[0].gid).short}</span>
			}

			if (core.user.permission('update', 'edge_tag', dstNode)) modifyLink = <Link className="micro-button" to={'/edge/' + edge.eid + '/edit'}>Modify</Link>
			if (core.user.permission('delete', 'edge_tag', dstNode)) deleteLink = <span key="button-edit" className="micro-button" onClick={this.deleteEdge.bind(this)}>Delete</span>
		}



		return <div className='contextual-edge edge-tag' data-eid={eid}>
			<span className="icon fa fa-hashtag" />
			<span className="contextual-edge-details">
				<span className="tag">
					{tagName} {groupShortName}
				</span>
				<div className='contextual-edge-operations'>{modifyLink} {deleteLink}</div>
			</span>
		</div>
	}

}

export default withStore(observer(NodeContextTag))
