import React from "react";
import { observer, inject } from "mobx-react";
import { HashRouter, Switch, Route, Link, withRouter } from 'react-router-dom';
import { withStore } from '../utility/Core'

import DirectNode from './DirectNode';
import PoeParsedForUI from './PoeParsedForUI';

class Comment extends React.Component {

	constructor(props) {
		super(props)
		const { core } = this.props

		this.componentId = core.componentId('Comment')

		this.state = {
			expanded: false,
		}

	}

	componentDidMount() {
		const { core, eid } = this.props

		const edge = core.edgeById(eid)

		if (typeof edge !== 'undefined') {
			const node = core.nodes[edge.dst.nid]
			if (typeof node !== 'undefined') {
				node.claim(this.componentId)
			}
		}

		if (this.props.expanded) this.setState({expanded:true})
	}

	componentDidUpdate() {
		if ((this.props.expanded) && (!this.state.expanded)) this.setState({expanded:true})
	}

	componentWillUnmount() {
		const { core, eid } = this.props
		const edge = core.edgeById(eid)
		if (typeof edge !== 'undefined') {
			const node = core.nodes[edge.dst.nid]
			if (typeof node !== 'undefined') node.release(this.componentId)
		}

		this.releaseLock()
	}

  //

	commentDelete() {
		const {core, eid } = this.props
		const edge = core.edgeById(eid)
		if (typeof edge === 'undefined') throw('edge ' + eid + ' not found')
		const nid  = edge.dst.nid
		const node = core.nodes[nid]

		if (core.user.permission('delete', 'edge_comment', edge)) {

			if (typeof node !== 'undefined') {
				this.releaseLock()
				node.release(this.componentId)
			}

			core.op({
				realm: 'node',
				op:    'nodeDelete',
				uid:    core.user.uid,
				nid:    nid,
				prev_mod_uuid: node.last_mod_uuid,
				uid: core.user.uid,
				lid: core.uuid('l'),
			})

			const op = {
				realm: 'edge',
				op:    'edgeDelete',
				eid:    edge.eid,
				type:   edge.type,
				src:    edge.src,
				dst:    edge.dst,
				prev_mod_uuid: edge.last_mod_uuid,
				uid: core.user.uid,
				lid: core.uuid('l'),
			}
			if ((typeof op.src !== 'undefined') && (typeof op.src.nid !== 'undefined') && (op.src.nid !== null) && (typeof op.src.pid !== 'undefined') && (op.src.pid !== null)) {
				op.src.prev_mod_uuid = core.nodes[op.src.nid].paragraphs[op.src.pid].last_mod_uuid
			}
			if ((typeof op.dst !== 'undefined') && (typeof op.dst.nid !== 'undefined') && (op.dst.nid !== null) && (typeof op.dst.pid !== 'undefined') && (op.dst.pid !== null)) {
				op.dst.prev_mod_uuid = core.nodes[op.dst.nid].paragraphs[op.dst.pid].last_mod_uuid
			}

			core.op(op)

		}
	}

	acquireLock() {
		const { core, eid } = this.props
		const edge = core.edgeById(eid)
		if (typeof edge === 'undefined') throw('edge ' + eid + ' not found')
		const nid  = edge.dst.nid
		const node = core.nodes[nid]

		if (!core.user.permission('update', 'node', node)) {
			alert('You lack the necessary permissions to edit this card.')
			return false
		}

		core.requestLock(nid)
		    .then(() => { })
		    .catch((e) => {
				  if (e.status == 401) alert('Could not lock the node for editing. It is already locked by another user.')
					console.error(e)
				})
	}

	releaseLock() {
		const {core, eid, overrideMakeEditable, contextNid} = this.props

		const edge = core.edgeById(eid)

		if (typeof edge === 'undefined') {
			console.warn('Could not find edge to release a potential lock')
			return false
		}

		const nid  = edge.dst.nid
		const node = core.nodes[nid]

		if (typeof node === 'undefined') {
			console.warn('Node not present to release')
			return
		}

		console.log('RELEASE ' + node.title)

		core.releaseLock(nid)
				.then((r) => { console.log(r) })
				.catch((e) => { console.error(e) })

	}


	render() {
		const {core, eid, overrideMakeEditable, contextNid, relNode} = this.props
		const edge = core.edgeById(eid)
		if (typeof edge === 'undefined') {
			return(<div>edge {eid} not found</div>)
		}
		const nid  = edge.dst.nid
		const node = core.nodes[nid]

		var content


		if (this.state.expanded) {
			content = <DirectNode nid={nid} mode={ (node && node.locked_uid == core.user.uid) ? 'edit' : 'view' } />
		}
		else {
			content = <div className="node-container comment clickable" onClick={ () => {this.setState({expanded:true})} } title="Click to load full comment">
				<div className="node comment">
					<span className="preview">{edge.dst.title}</span><span className="buttonMore" />
				</div>
			</div>
		}

		if ((typeof node !== 'undefined') && (node.locked_uid) && (node.locked_uid != core.user.uid)) content = <em>typing</em>

		const buttons = []
		if (overrideMakeEditable) {
			buttons.push(<span key="release" className="micro-button" onClick={this.releaseLock.bind(this)}>OK</span>)
		}
		else {
			if (this.state.expanded) {

				// EIGENTLICH:
				// 				if (core.user.permission('update', 'edge_comment', relNode)) buttons.push(<span key="modify" className="micro-button" onClick={this.acquireLock.bind(this)}>Modify</span>)
				//        if (core.user.permission('delete', 'edge_comment', relNode)) buttons.push(<span key="delete" className="micro-button" onClick={this.commentDelete.bind(this)}>Delete</span>)
				// aber das geht erst, wenn relNode-comment-privileges auch ein locking erlauben...


				if (core.user.permission('update', 'edge_comment', edge)) buttons.push(<span key="modify" className="micro-button" onClick={this.acquireLock.bind(this)}>Modify</span>)
				if (core.user.permission('delete', 'edge_comment', edge)) buttons.push(<span key="delete" className="micro-button" onClick={this.commentDelete.bind(this)}>Delete</span>)
			}
			// if (core.user.permission('create', 'edge_like', node)) buttons.push(<span>Like</span>) // NOPE -> this needs to refer to the node that this is a comment to...
		}

		const commentButtons = <div className="comment-inline-meta"><span title={"Last Modified: " + core.fulldate(edge.t_updated)}>{core.mindate(edge.t_created)}</span><div className="comment-buttons">{buttons}</div></div>

		return (
			<div class={"comment-container"
				+ ((contextNid == edge.dst.nid) ? ' active': '')
				+ ((this.state.expanded) ? '' : ' preview')
				} data-nid={nid} title={'Created on ' + edge.t_created }>
				<div class="comment-meta">
					<PoeParsedForUI tag="span" txt={ '%' + edge.uid } />
				</div>
				<div className={"nodebox-container" + ((overrideMakeEditable) ? ' editable':'')}>
					{content}
					{commentButtons}
				</div>
		</div>
		)
	}
}

export default withStore(observer(Comment))
