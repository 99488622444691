import React from "react";
import { Link, withRouter } from 'react-router-dom';

import { action } from "mobx";
import { observer, inject } from "mobx-react";
import { withStore } from '../utility/Core'

import DateString from "./DateString";
import PoeParsedForUI from './PoeParsedForUI';
import Storage from "../utility/Storage";
import CoreCompletion from "./CoreCompletion";

import NodelistLine from "./NodelistLine";

import Topography from "./Visualization/Topography";

// this.nodes[nid].claim(componentId)
const debounce = require('sugar/function/debounce');
const cancel   = require('sugar/function/cancel');

class DirectNodeList extends React.Component {

	constructor(props) {

		super(props)

		this.storage = new Storage()

		this.state = {
			chunk: 0,
			filter: [{
				key: 'type',
				value: props.core.ui.param.nodeListSearch[0].type
			}],
			order: [{
				key: 't_updated',
				dir: -1
			}],
			remoteMatches: {},
		}

		if (props.core.ui.param.nodeListSearch[0].searchPattern) {
			this.state.filter.push({
				key: props.core.ui.param.nodeListSearch[0].field,
				value: props.core.ui.param.nodeListSearch[0].searchPattern,
			})
		}

		this.updateSearch        = debounce(this.updateSearch.bind(this), 350)
	}

	focusInput(delay = false) {
		if (delay) {
			setTimeout(() => document.getElementById('nodelist-search-filter').focus(), 200)
		}
		else {
			document.getElementById('nodelist-search-filter').focus()
		}
	}

	componentDidMount() {
		console.log('DirectNodeList did mount')
		this.loadToCore()
		this.focusInput()
	}

	componentDidUpdate(prevProps, prevState) {
		this.loadToCore()
	}

	componentWillUnmount() {
		cancel(this.updateSearch)
		console.log('DirectNodeList unmounts')
		//if (typeof this.storage.ajax !== 'undefined') this.storage.ajax.cancelHandler.cancel('parent component unmounted');
	}

	updateSearch() {
		const { core } = this.props
		const { filter } = this.state

		const { type, searchPattern, field } = core.ui.param.nodeListSearch[0]

		var oldSearchPattern = ""

		const bodyFilter  = filter.findIndex(e => e.key == 'body')
		const titleFilter = filter.findIndex(e => e.key == 'title')
		const typeFilter  = filter.findIndex(e => e.key == 'type')

		// remove all body and title filters, but remember their search pattern
		if (bodyFilter >= 0) {
			if (filter[bodyFilter].value) oldSearchPattern = filter[bodyFilter].value
			filter.splice(bodyFilter, 1)
		}

		if (titleFilter >= 0) {
			if (filter[titleFilter].value) oldSearchPattern = filter[titleFilter].value
			filter.splice(titleFilter, 1)
		}

		// set the new filter (body or title)
		const newSearchPattern = (typeof searchPattern === 'undefined') ? oldSearchPattern : searchPattern

		if (newSearchPattern != '') {
			filter.push({
				key: field,
				value: newSearchPattern
			})
		}


		// set the type filter
		if (typeFilter >= 0) {
			if (filter[typeFilter].value != type) filter[typeFilter].value = type
		}

		this.setState({filter, filter})
	}

	makeFilter() {
		const { core } = this.props
		const { filter } = this.state

		const activeGroups = core.user.groups.filter(g => g.active)

		const myFilter = [...filter]

		activeGroups.map((g) => {
			myFilter.push({
				key: 'group',
				value: g.gid
			})
		})

		return myFilter
	}


	loadToCore() {
		const { core } = this.props
		const { order, chunk } = this.state

		const myFilter = this.makeFilter()

		const config = {
			filter: myFilter,
			order: order,
		}

		this.oldConfigFingerprint = this.configFingerprint
		this.configFingerprint = JSON.stringify(config)

		if ((this.oldConfigFingerprint != this.configFingerprint) || (chunk != this.oldChunk)) {

			const newState = {
				loading: true,
			}

			if (this.oldConfigFingerprint != this.configFingerprint) {
				newState.chunk = 0;
				newState.remoteMatches = {}
			}

      this.setState(newState)
			console.log('########################')
			console.log('loadToCore')

			this.storage.query({ action: 'nodelist', filter: myFilter, order: order, limit: (chunk*25) + ', ' + 25 })
				.then((response) => {
					const remote        = response.data.data
					const remoteMatches = this.state.remoteMatches

					core.bulkInsert(remote.result, remoteMatches)

					this.setState({
						loading: false,
						availableRemotely: remote.nof,
						remoteMatches: remoteMatches
					})

				})
				.catch((thrown) => {
					this.setState({
						loading:false,
						availableRemotely: null
					})
					console.warn(thrown);
				})

		}

		this.oldChunk = chunk
	}

	render() {
		const { core } = this.props
		const { order, availableRemotely, remoteMatches, loading, chunk, filter } = this.state

		const mode           = core.ui.param.nodeListView
		const nodeListSearch = core.ui.param.nodeListSearch[0]
		const { type, field, searchPattern } = nodeListSearch

		const dummyForReload1 = core.user.groups.map((group) => { return group.active })
		const dummyForReload2 = Object.keys(core.nodes).join('')

		const myFilter = this.makeFilter()
		const filterFn = (node) => {

			if (node.deleted)             return false
			if (node.loaded == 'deleted') return false

			return myFilter.reduce((acc, f) => {
				const { key, value } = f

				switch (key) {

					case 'type':
						if (node.type != value) return false
						break;

					case 'group':
						if (!node.hasGroup(value)) return false
						break;

					case 'body':
						//console.log(node.title, remoteMatches[node.nid])
						if ((node) && (((node.txt) && (node.txt().toLowerCase().indexOf(value.toLowerCase()) >= 0)) || (remoteMatches[node.nid]))) return acc
						return false
						break;

					case 'title':
						if ((node) && (node.title) && (node.title.toLowerCase().indexOf(value.toLowerCase()) >= 0)) return acc
						return false
						break;

				}

				return acc
			}, 1)
		}

		let i=1

		const nids = Object.keys(core.nodes)

		order.map((o) => {
			nids.sort((a, b) => {
				switch(o.key) {
					case 'title':
						const value_a = core.nodes[a][o.key] ? core.nodes[a][o.key] : ''
						const value_b = core.nodes[b][o.key] ? core.nodes[b][o.key] : ''
						return value_a.localeCompare(value_b)
						break;

					default: // numerical sort
						if (core.nodes[a][o.key] == core.nodes[b][o.key]) return 0
						return (core.nodes[a][o.key] > core.nodes[b][o.key]) ? o.dir : -o.dir
				}
			})
		})

		const nodelist = nids.map(nid => {

			const node = core.nodes[nid]

			if (!filterFn(node)) return null

			return <NodelistLine key={"row-"+nid} node={node} />

		}).filter(n => n !== null)

		let remotely = <span className="fa fa-exclamation-circle" />
		if (loading) {
			remotely = <span className="fa fa-cog fa-spin" />
		}
		else {
			if (availableRemotely !== null) remotely = availableRemotely
		}

		const searchExplain = null // <span className="fa fa-search" />

		var loadMoreButton = null
		var miniMore       = null
		if ((availableRemotely !== null) && (nodelist.length < availableRemotely)) {
				loadMoreButton  = <div id="nodelist-footer">
					<span className={"load-more button clickable" + (loading ? ' loading': null)} onClick={() => { this.setState({ chunk: (chunk+1)}) }}>
						{ loading ? <span className="fa fa-cog fa-spin" /> : <span><span className="fa fa-cloud-download" /> load chunk {chunk+2} </span> }
					</span>
				</div>
				miniMore = loading ? null : <span className="clickable mini-more" onClick={() => { this.setState({ chunk: (chunk+1)}) }} title={"Load more... (Chunk " + (chunk+2) + ")"}>
					<span className="fa fa-cloud-download" />
				</span>
		}

		const indicator = (key) => {
			if (this.state.order[0].key == key) {

				return this.state.order[0].dir < 0 ? <span className="fa fa-sort-desc" /> : <span className="fa fa-sort-asc" />
			}

			return <span className="fa fa-sort" />
		}

		var output = null

		var extraInfoInfo = null
		if (filter[0].value == 'tag') {
			extraInfoInfo = <span className="fa fa-hashtag" title="Number of cards tagged with this (only shown for fully loaded tags)" />
		}

		if (mode == 'list') {
			output = [<div id="nodelist-table-header" key="header">
				<table>
					<colgroup>
						<col span="1" style={{width: "2em"}} />
						<col span="1" style={{width: "auto"}} />
						<col span="1" style={{width: "5em"}} />
						<col span="1" style={{width: "5em"}} />
						<col span="1" style={{width: "5em"}} />
					</colgroup>
					<thead>
						<tr>
							<td></td>
							<td onClick={() => { const dir = (this.state.order[0].key == 'title') ? -this.state.order[0].dir : -1
								this.setState({order: [{
									key: 'title',
									dir: dir
								}]}); }}>{indicator('title')} Name</td>
							<td onClick={() => { const dir = (this.state.order[0].key == 't_updated') ? -this.state.order[0].dir : -1
								this.setState({order: [{
									key: 't_updated',
									dir: dir
								}]}); }}>{indicator('t_updated')} Updated</td>
							<td onClick={() => { const dir = (this.state.order[0].key == 't_created') ? -this.state.order[0].dir : -1
								this.setState({order: [{
									key: 't_created',
									dir: dir
								}]}); }}>{indicator('t_created')} Created</td>
							<td>{extraInfoInfo}</td>
						</tr>
					</thead>
				</table>
			</div>,
			<div id="nodelist-listing" key="listing">
				<table>
					<colgroup>
						<col span="1" style={{width: "2em"}} />
						<col span="1" style={{width: "auto"}} />
						<col span="1" style={{width: "5em"}} />
						<col span="1" style={{width: "5em"}} />
						<col span="1" style={{width: "5em"}} />
					</colgroup>
				<tbody>
					{nodelist}
				</tbody>
				</table>
				{loadMoreButton}
			</div>]
		}
		else {
			output = <Topography className="context-topography" contextNid={null} filter={this.makeFilter()} nodeMatchOverride={remoteMatches} />
		}

		var typeSelector = null
		switch (filter[0].value) {
			case 'card':
				typeSelector = <span className="search-area-type-selector clickable no-marks fa fa-file-o" onClick={() => {
					core.ui.set('nodeListSearch', [{ ...nodeListSearch, type: 'tag'}])
					this.updateSearch()
					this.focusInput()
					}} />
				break

			case 'tag':
				typeSelector = <span className="search-area-type-selector clickable no-marks fa fa-hashtag" onClick={() => {
					core.ui.set('nodeListSearch', [{ ...nodeListSearch, type: 'comment'}])
					this.updateSearch()
					this.focusInput()
				}} />
				break

			case 'comment':
				typeSelector = <span className="search-area-type-selector clickable no-marks fa fa-comment-o" onClick={() => {
					core.ui.set('nodeListSearch', [{ ...nodeListSearch, type: 'card'}])
					this.updateSearch()
					this.focusInput()
				}} />
				break
		}

		const selector = (field == 'title') ?
			<span className="search-area-selector clickable" onClick={() => {
				core.ui.set('nodeListSearch', [{ ...nodeListSearch, field: 'body' }])
				this.updateSearch()
				this.focusInput()
			}}>Title:</span> :
			<span className="search-area-selector clickable" onClick={() => {
				core.ui.set('nodeListSearch', [{ ...nodeListSearch, field: 'title' }])
				this.updateSearch()
				this.focusInput()
			}}>Body:</span>

		var resetButton = null
		if (searchPattern) {
			resetButton = <span className="fa fa-times-circle-o reset-form clickable" title="Reset the search form" onClick={() => {
				core.ui.set('nodeListView', 'list');
				core.ui.set('nodeListSearch', [{ type: 'card', field: 'title', searchPattern: '' }]);
				this.updateSearch();
				this.focusInput(); }}
			/>
		}

		document.title = ' • ' + 'Listing ' + nodelist.length+' of '+ availableRemotely + ' Cards'

		return <React.StrictMode><div className="node-list">
			<div id="nodelist-header">
			{typeSelector} {selector} <input type="text" id="nodelist-search-filter" className={resetButton ? 'non-empty' : 'empty' } onChange={e => {
					core.ui.set('nodeListSearch', [{ ...nodeListSearch, searchPattern: e.target.value }]);
					this.updateSearch();
				}} value={searchPattern} /> {resetButton}
				<div id="nodelist-meta">
					<div id="nodelist-info">Showing {nodelist.length} of {remotely} Cards {miniMore}</div>
					<CoreCompletion /><br />
					<span className="button clickable" onClick={() => { if (mode == 'list') core.ui.set('nodeListView', 'graph'); else core.ui.set('nodeListView', 'list'); this.focusInput(); }}>
						{ mode == 'list' ? 'Show Topography' : 'Show List' }
					</span>
					{searchExplain}
				</div>
			</div>
			{output}
		</div></React.StrictMode>
	}

}

export default withRouter(withStore(observer(DirectNodeList)))
