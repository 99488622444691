import React from "react";
import { observer, inject } from "mobx-react";
import { withStore } from '../utility/Core'
import { withRouter } from 'react-router-dom';

import Storage from "../utility/Storage";

class EdgeAudienceCheck extends React.Component {

	constructor() {
		super()
		this.storage = new Storage()
		this.state = {
			loading: false,
			edgeData: [],
			hash: '',
		}
	}

	getAudienceData() {
		const { core, eid }      = this.props
		const edge               = core.edgeById(eid)
		const nofUnsyncedChanges = core.operations.filter(op => op.status != 'synced' && (op.op == 'groupAdd' || op.op == 'groupDel')).length
		const hash               = edge.groups.map(g => g.gid).join('-') + '/' + edge.src.nid + '/' + edge.dst.nid + edge.linked_url + '~' + nofUnsyncedChanges


		if (typeof edge === 'undefined') return null

		const userCanChangeEdgeGroups = core.user.permission('GA_update', 'edge', edge)

		this.setState({ hash: hash })
//console.log('GET AUDIENCE DATA', hash)
		if (nofUnsyncedChanges) {
			this.setState({loading: 'pending'})
			return
		}

		this.setState({loading: 'loading'})
		console.log(edge.dst.nid);
		this.storage
					.query({ action: 'edgeAudienceCheck', eid: eid, nid1: edge.src.nid, nid2: edge.dst.nid })
					.then((response) => {
						this.setState({loading: false})
						if (response.data.status == 200) {
							this.setState({ edgeData: response.data.data })
						}
					})
					.catch((e) => {
						console.error(e)
						this.setState({loading: false})
					})
	}

	componentDidMount() {
		this.getAudienceData()
	}

	componentDidUpdate() {
		const { core, eid }     = this.props
		const edge = core.edgeById(eid)
		const nofUnsyncedChanges = core.operations.filter(op => op.status != 'synced' && (op.op == 'groupAdd' || op.op == 'groupDel')).length
		const hash               = edge.groups.map(g => g.gid).join('-') + '/' + edge.src.nid + '/' + edge.dst.nid + edge.linked_url + '~' + nofUnsyncedChanges
//console.log('DID UPDATE ', this.state.hash, hash)
		if (this.state.hash != hash) this.getAudienceData()
	}

	render() {
		const { core, eid }              = this.props
		const { edgeData, loading }      = this.state
		const edge = core.edgeById(eid)
		const nofUnsyncedChanges = core.operations.filter(op => op.status != 'synced' && (op.op == 'groupAdd' || op.op == 'groupDel')).length
		const hash               = edge.groups.map(g => g.gid).join('-') + '/' + edge.src.nid + '/' + edge.dst.nid + edge.linked_url + '~' + nofUnsyncedChanges
		const userCanChangeEdgeGroups = core.user.permission('GA_update', 'edge', edge)

		const userType         = edge.uid == core.user.uid ? 'is-author' : 'is-user'

		if (!userCanChangeEdgeGroups) return null

		if (typeof edge === 'undefined') return null
		if (edgeData.length == 0) return null

		if (loading == 'pending') return <div className='edge-audience-check loading'><span className="fa fa-spinner fa-pulse" /> Audience Check: waiting for local changes to sync...</div>
		if (loading == 'loading') return <div className='edge-audience-check loading'><span className="fa fa-spinner fa-pulse" /> Audience Check: loading...</div>

		if ((edgeData[0] == 0) && (edgeData[1] == 0)) {
			return <div className={'edge-audience-check status-ok ' + userType}>
				<span className="fa fa-check-circle" /> Audience Check: All users who can see the link can also see both connected cards.
			</div>
		}

		var report = []
		if (edgeData[0] != 0) report.push(edgeData[0] + ' user(s) can see the link, but cannot see the source card (on the left).')
		if (edgeData[1] != 0) report.push(edgeData[1] + ' user(s) can see the link, but cannot see the destination card (on the right).')

		return <div className={'edge-audience-check status-warning ' + userType}>
			<span className="fa fa-exclamation-triangle" /> <strong>Attention - Audience Check</strong>:<ul>
			{report.map((line, i) => { return <li key={i}>{line}</li> })}
			</ul>
			Ideally, you should set the audience of a link so that all users can see the connected cards.
		</div>
	}

}

export default withRouter(withStore(observer(EdgeAudienceCheck)))
