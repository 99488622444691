import React from "react";
import { observer, inject, action, runInAction } from "mobx-react";
import { withStore } from '../utility/Core'

class DirectParagraphTextStyles extends React.Component {

	constructor(props) {
		super(props)

		this.domRef       = React.createRef();

		this.prev = {
			nid: undefined,
			pid: undefined,
		}
	}

	changeParStyle(nid, pid, event) {
		const { core } = this.props

		const paragraph = core.nodes[nid].paragraphs[pid]

		core.op({
			op:            'parType',
			realm:         'node',
			nid:           nid,
			pid:           pid,
			val:           event.target.value,
			old:           paragraph.type,
			uid:           core.user.uid,
			prev_mod_uuid: paragraph.last_mod_uuid,
			lid:           core.uuid('l'),
		})

	}

	render() {
		const { core, nodeComponentId, setCursorAfterOp } = this.props

		if (Object.keys(core.caretX).indexOf(nodeComponentId) == -1) return null

		const types = {
			strong: {key: 'strong',    name: 'Bold',  icon: 'fa-bold', hotkey: 'Strg + b'},
			u: {key: 'u',    name: 'Underscore',  icon: 'fa-underline', hotkey: 'Strg + u'},
			i: {key: 'i',    name: 'Italics',  icon: 'fa-italic', hotkey: 'Strg + i'},
			s: {key: 's',    name: 'Strikethrough',  icon: 'fa-strikethrough', hotkey: 'Strg + e'},
			sup: {key: 'sup',  name: 'Superscript',  icon: 'fa-superscript'},
			sub: {key: 'sub',  name: 'Subscript',  icon: 'fa-subscript'},
		}


		const nid = core.caretX[nodeComponentId].nid
		const pid = core.caretX[nodeComponentId].pid
		const pos = core.caretX[nodeComponentId].pos
		const length = core.caretX[nodeComponentId].length

		if (typeof core.nodes[nid] === 'undefined') return null
		if (typeof core.nodes[nid].paragraphs[pid] === 'undefined') return null

		const paragraph = core.nodes[nid].paragraphs[pid]

		const markups   = paragraph.markupAt(pos)
		markups.map((m) => {
			if (typeof types[m.type] !== 'undefined') {
				types[m.type]['selected'] = true
				types[m.type]['mark'] = m
			}
		})

		return <div className="block" ref={this.domRef}>{Object.keys(types).map((k) => {
			const m = types[k]
			const classNames = ['direct-node-topbar-button', 'fa', m.icon]
			var title        = 'Mark text and click here to format it as ' + m.name + ((m.hotkey) ? ' (' + m.hotkey + ')' : '')
			var onClick      = null

			if (core.caretX[nodeComponentId].length) {
				title = 'Click to format it as ' + m.name + ((m.hotkey) ? ' (' + m.hotkey + ')' : '')
				classNames.push('clickable')
				onClick = () => {
					const lid = core.uuid('l')
					core.op({
						realm: 'node',
						op: 'rangeInsert',
						nid: nid,
						pid: pid,
						uuid: core.unique_id(),
						pos: pos + length,
						length: -length,
						type: m.key,
						uid: core.user.uid,
						lid: lid,
						prev_mod_uuid: paragraph.last_mod_uuid
					})
					setCursorAfterOp(lid, pid, pos+length, -length)
					}
			}

			if (m.selected) {
				classNames.push('active')
				classNames.push('clickable')
				title = 'Text is formatted as ' + m.name + '. Click to remove the formatting.'
				onClick = () => {
					const lid = core.uuid('l')
					core.op({
						realm: 'node',
						op: 'rangeDelete',
						nid: nid,
						pid: pid,
						pos: m.mark.pos,
						length: m.mark.length,
						type: m.key,
						uuid: m.mark.uuid,
						prev_mod_uuid: paragraph.last_mod_uuid,
						uid: core.user.uid,
						lid: lid,
					})
					setCursorAfterOp(lid, pid, pos+length, -length)
				}
			}
			return <span key={k} title={title} className={classNames.join(' ')} onClick={onClick}></span>
		})}</div>
	}

}

export default withStore(observer(DirectParagraphTextStyles))
