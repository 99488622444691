const { observable, computed, action } = require("mobx")


export class Group {

	constructor(g) {
		this.gid                   = undefined
		this.type                  = undefined
		this.short                 = undefined
		this.uid                   = undefined
		this.nofMembers            = undefined
		this.name                  = undefined
		this.active                = undefined
		this.admin                 = undefined
		this.GA_update             = undefined
		this.node_create           = undefined
		this.node_read             = undefined
		this.node_update           = undefined
		this.node_delete           = undefined
		this.edge_edge_create      = undefined
		this.edge_edge_read        = undefined
		this.edge_edge_delete      = undefined
		this.edge_edge_update      = undefined
		this.edge_tag_create       = undefined
		this.edge_tag_read         = undefined
		this.edge_tag_update       = undefined
		this.edge_tag_delete       = undefined
		this.edge_like_create      = undefined
		this.edge_like_read        = undefined
		this.edge_like_update      = undefined
		this.edge_like_delete      = undefined
		this.edge_comment_create   = undefined
		this.edge_comment_read     = undefined
		this.edge_comment_update   = undefined
		this.edge_comment_delete   = undefined

		Array(
			'gid',
			'type',
			'name',
			'uid',
			'nofMembers',
			'storageQuota',
			'storageUsed'
		).map((key) => {
			this[key] = (typeof g[key] !== 'undefined') ? g[key] : undefined
		});

		[
			'active',

			'admin',
			'GA_update',

			'node_create',
			'node_read',
			'node_update',
			'node_delete',

			'edge_edge_create',
			'edge_edge_read',
			'edge_edge_update',
			'edge_edge_delete',

			'edge_tag_create',
			'edge_tag_read',
			'edge_tag_update',
			'edge_tag_delete',

			'edge_like_create',
			'edge_like_read',
			'edge_like_update',
			'edge_like_delete',

			'edge_comment_create',
			'edge_comment_read',
			'edge_comment_update',
			'edge_comment_delete',
		].map((key) => {
			this[key] = (typeof g[key] !== 'undefined') ? g[key] : false
		})

		if ((typeof g.short !== 'undefined') && (g.short)) this.short = g.short; else this.short = this.name.substr(0,5)

		//console.log('new group ', g.name, this, ' based on ', g)
	}
}
