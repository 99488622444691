import React from "react";
import { observer, inject, action, runInAction } from "mobx-react";
import { withStore } from '../utility/Core'


class NodeContextLike extends React.Component {

	constructor(props) {
		super(props)
	}

	componentDidMount() {
		const { core, uid } = this.props
		if (typeof core.cache.userDetails[uid] === 'undefined') core.getUserInfo(uid)
	}

	render() {
		const { core, uid } = this.props
		const user = core.cache.userDetails[uid]

		const shortname = (typeof user === 'undefined') ? '???' : <span style={{borderBottom:'1px solid ' + user.color}} title={user.name.toString()}>
			{user.short}
		</span>

		return <span>{shortname}</span>
	}

}

export default withStore(observer(NodeContextLike))
