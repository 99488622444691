import React from "react";
import { observer, inject } from "mobx-react";
import { withStore } from '../utility/Core'
import { Link } from 'react-router-dom';

import Storage from "../utility/Storage";

import JSONPretty from 'react-json-pretty';

import DateString from './DateString';
import PoeParsedForUI from './PoeParsedForUI';
import Username from './PoeParsedForUI/Username';
import Nodelink from './PoeParsedForUI/Nodelink';
import Edgelink from './PoeParsedForUI/Edgelink';
import { InView } from 'react-intersection-observer'
import { smartDate } from '../utility/dateString.js'

var dateCreate = require('sugar/date/create');
var dateFormat = require('sugar/date/format');

class WallEvent extends React.Component {

	constructor(props) {
		super(props)

		const { logEvent, rendered_as } = props

		this.storage          = new Storage()

		this.state = {
			size: Math.min(5, 10 * logEvent.minimalOps.length) // + rendered_as
		}

	}

	componentDidMount() {
		const { core, logEvent } = this.props;
		this.counter = setInterval(() => {
			var size = this.state.size
			if (this.viewing) {
				if (size>1) {
					size--
					this.setState({size})
				}
				else if (!logEvent.t_seen) {
					this.toggleEyes()
				}
			}
		}, 1000)
	}

	componentWillUnmount() {
		clearTimeout(this.counter)
		if (typeof this.storage.ajax !== 'undefined') this.storage.ajax.cancelHandler.cancel('parent component unmounted');
	}

	toggleEyes() {
		const { core, logEvent } = this.props
		const parm = (logEvent.t_seen) ? { action: 'toggleLogEventEyes', id: logEvent.id, state:false } : { action: 'toggleLogEventEyes', id: logEvent.id, state:true, lid_to: logEvent.lid_to }

		if (this.counter) clearTimeout(this.counter)
		this.setState({size: 0})

		this.storage.query(parm)
			.then((response) => {
				if (response.status == 200) {
					logEvent.t_seen = response.data.data.t_seen
					logEvent.lid_seen = false
				}
			})
			.catch((e) => {
				console.error(e)
			})

	}

	logEventOpExplain(row) {
		const { core } = this.props
		switch(row.op) {

			case 'groupAdd':
				{
				const group = core.groupByGid(row.gid)
				return <span>shared {row.type == 'node' ? 'card' : 'link' } {this.logEventLink(row.anchor)} to {group ? group.name : row.gid}</span>
				}
				break;

			case 'groupDel':
				{
				const group = core.groupByGid(row.gid)
				return <span>unshared {row.type == 'node' ? 'card' : 'link' } {this.logEventLink(row.anchor)} from {group ? group.name : row.gid}</span>
				}
				break;

			case 'nodeCreate':
				switch(row.type) {

					case 'tag':
						return <span>added a new tag: {this.logEventLink(row.nid)}</span>
						break;

					case 'comment':
						return <span>commented on {this.logEventLink(row.nid)}</span>
						break;

					default:
						return <span>added a new {row.type}: {this.logEventLink(row.nid)}</span>
				}

				break;

			case 'nodeEdit':
				switch(row.type) {

					case 'comment':
						return <span>edited the comment {this.logEventLink(row.nid)}</span>
						break

					default:
						return <span>edited {row.type} {this.logEventLink(row.nid)}</span>
				}
				break;

			case 'nodeDelete':
				return <span>deleted {row.type} {this.logEventLink(row.nid)}</span>
				break;

			case 'edgeCreate':
				switch(row.type) {

					case 'tag':
						return <span>tagged {this.logEventLink(row.anchor)} as {this.logEventLink(row.tag)}</span>
						break;

					case 'like':
						return <span>liked a passage on {this.logEventLink(row.anchor)}</span>
						break;

					case 'annotation':
						return <span>annotated {this.logEventLink(row.eid)}</span>
						break;

					case 'edge':
						return <span>linked {this.logEventLink(row.eid)}</span>
						break;

					default:
						return <span>added {row.type} {this.logEventLink(row.eid)}</span>
				}
				break;

			case 'edgeUpdate':
				return <span>edited edge {this.logEventLink(row.eid)}</span>
				break;

			case 'edgeDelete':
				return <span>deleted edge {this.logEventLink(row.eid)}</span>
				break;

		}
		return row.uid + ' ~ ' + row.op
	}

	logEventLink(id, overrideText = null) {
		const type = (id.substr(0,2) == 'e-') ? 'edge' : 'node'

		if (type == 'node') return <Nodelink nid={id}>{overrideText}</Nodelink>
		if (type == 'edge') return <Edgelink eid={id}>{overrideText}</Edgelink>

		//if (overrideText == '{the-type}') overrideText = 'the ' + type

		var   text = overrideText ? overrideText : id

		return <Link to={type + '/' + id}>{text}</Link>
	}

	inView(inView) {
		this.viewing = inView
	}

	render() {
		const { core, logEvent, hideUser } = this.props
		const { size }                     = this.state

		var dateline = <span className="event-date" title={core.fulldate(logEvent.t_to)}>{dateFormat(new dateCreate(1000 * logEvent.t_to), '%H:%M')}</span>
		if (logEvent.t_to - logEvent.t_from > 60) {
			dateline = <span className="event-date" title={'From ' + core.fulldate(logEvent.t_from) + ' to ' + core.fulldate(logEvent.t_to)}>{dateFormat(new dateCreate(1000 * logEvent.t_to), '%H:%M')}</span>
		}

		var content = null, buttonSeen = null

		if (logEvent.minimalOps.length == 1) {
			content = <h2>{ this.logEventOpExplain(logEvent.minimalOps[0]) }</h2>
		}
		else {
			content = [
				<h2 key="headline">{this.logEventLink(logEvent.ident.id)}</h2>,
				<ul key="eventDetails">
				{ logEvent.minimalOps.map((op, i) => {
					return <li key={i}>{this.logEventOpExplain(op)}</li>
				}) }
				</ul>
			]
		}

		if (logEvent.t_seen) {
			buttonSeen = <span onClick={this.toggleEyes.bind(this)} className="log-event-seen seen" title={'Seen by you at ' + core.fulldate(logEvent.t_seen)}><span className="fa fa-check-circle-o" /></span>
		}
		else {
			buttonSeen = <span onClick={this.toggleEyes.bind(this)} className="log-event-seen seen" title={'Seen by you at ' + core.fulldate(logEvent.t_seen)}><span className="view-timer">{size ? size + ' ' : null}</span><span className="fa fa-circle-o" /></span>
		}

		const userName = hideUser ? null : <Username uid={logEvent.uid} />

		return <InView as="div" onChange={this.inView.bind(this)} className={"log-event" + (logEvent.t_seen ? ' seen':'') } key={logEvent.id} title={"Event #" + logEvent.id + ': User ' + logEvent.uid + ' performed ' + logEvent.minimalOps.length + ' operation(s) on ' + logEvent.ident.type + ' ' + logEvent.ident.id + ' on ' + smartDate(logEvent.t_from) }>
			{dateline}
			{buttonSeen}
			{userName}
			<div className="details">{content}</div>
		</InView>


	}
}

export default withStore(observer(WallEvent))
