import React from "react";
import { Link } from 'react-router-dom';

import Storage from "../utility/Storage";

import { observer, inject } from "mobx-react";
import { withStore } from '../utility/Core'

const debounce = require('sugar/function/debounce');

class NodeSelector extends React.Component {

	constructor() {
		super()

		this.state = {
			searchString: '',
			nodes: [],
			disregardGroup: {},
			hasError: false,
		}

		this.groups = []

		this.storage = new Storage();

		this.refresh = function() {
			const { edge, core } = this.props

			this.setState({nodes: [{nid: 0, title: <span key="spinner-container"><span key="spinner" className="fa fa-refresh fa-spin" /> loading</span>}]})

			debounce(() => {

				const filter = [{
					'key': 's',
					'value': this.state.searchString
				}]

				const groupFilter = this.groups.filter(gid => !this.state.disregardGroup[gid])

				if (groupFilter.length) filter.push({
					'key': 'groups',
					'value': groupFilter.join(', ')
				})

				console.log('Remote search for ' + this.state.searchString, filter);

				this.storage.query({
					'action': 'nodelist',
					'filter': filter,
				})
				.then((response) => {
					if (response.data.status == 200) {
						this.setState({
							nodes: response.data.data.result
						})
					}
					else {
						console.error(response)
					}
				})
				.catch((thrown) => {
					console.error(thrown);
				})
			}, 600)()

		}
	}

	componentDidCatch(error, info) {
		console.error('ERROR', error, info)
		this.setState({ hasError: true });
	}

	componentWillMount() {
		const { edge, core } = this.props
    this.groups = edge.groups
				.filter(g => (
					(g.gid != core.user.defaultGid) &&
					(core.user.groups.findIndex( ug => g.gid == ug.gid) > -1)
				))
				.map( g => g.gid )
		console.log(edge, this.groups.map(g => g).join(','))
	}

	componentDidUpdate() {
		const { core, action, edge } = this.props
		if (core.modal.isOpen) core.modal.content = this.drawModal()
	}

	componentWillUnmount() {
		const { core } = this.props
		if (core.modal.isOpen) core.closeModal();
	}

	changed(event) {
		this.setState({
			searchString: event.target.value,
		})
		this.refresh()
	}

	drawModal() {
		const { core, action, edge } = this.props

		const selectbox = <div id="node-selector-dialog" className="dialog-select" key="selectbox">
			<h1 key="title">Select a Target</h1>
			<input autoFocus key="input" type="text" value={this.state.searchString} onChange={this.changed.bind(this)} />
			<div key="group-filter" id="node-selector-group-filter" title="Limit the search to cards that are in all of these groups">
			  { this.groups.map(gid => {
					const group = core.userGroup(gid)
					var name  = group ? group.name : '[' + group.gid + ']'
					if (this.state.disregardGroup[group.gid]) name = <strike>{name}</strike>

					return <span key={group.gid} onClick={() => { const disregardGroup = this.state.disregardGroup; disregardGroup[group.gid] = ! this.state.disregardGroup[group.gid]; this.setState({disregardGroup: disregardGroup}); this.refresh.bind(this)() }}>{name}</span>
				}) }
			</div>
			<ul key="xlist" id="node-selector-results" className="item-list">{
				this.state.nodes.map( (node) => {
					if ((core.nodes[node.nid]) && (core.nodes[node.nid].deleted)) return null
					return <li key={node.nid} class="clickable" onClick={() =>{ action(node.nid, node.title) }}>{node.title}</li>
				} )
			}</ul>
		</div>

		return selectbox
	}

	showModal(nid) {
		const { core, action, edge } = this.props
		core.showModal(this.drawModal(), { width:'50%', height:'50%' })
	}

	render() {
		const { core, edge, disabled } = this.props
		if (this.state.hasError) return '<NodeSelector /> has error'
		return <button disabled={disabled} onClick={() => { this.showModal.bind(this)() } }>Select a target</button>
	}
}

export default withStore(observer(NodeSelector))
