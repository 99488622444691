import React from "react";
import { observer, inject, Provider } from "mobx-react";
import { withStore } from '../../utility/Core'

import PoeParsedForUI from '../PoeParsedForUI';

class UserInvites extends React.Component {

	constructor() {
		super();
		this.state = {
			invites: [],
			failed: false
		}
	}

	componentDidMount() {
		const { core } = this.props
		this.setState({failed: false})
		//todo: make this a storage-op (since it's not done via CORE, technically, and cancel it on unmount
		core.getInvites()
			.then((res) => {
				if (res instanceof Array) this.setState({invites: res}); else this.setState({failed: true})
			})
			.catch((thrown) => {
				this.setState({failed: true})
			})
	}

	render() {
		const { core } = this.props
		const { invites } = this.state

		if (this.state.failed) {
			return <div id="settings-invites">
				<h2>{invites.length} Invites ({invites.filter( (invite) => invite.used==0 ).length} Pending)</h2>
				<div class="scrollable-list settings-box failed">
					<em>Could not load invites :/ </em>
				</div>
			</div>
		}

		function createInvite() {
			let name = window.prompt('email address to invite:')
			if ((name) && (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(name))) {
				core.sendInvite(name)
					.then(() => { return core.getInvites() })
					.then((res) => {
						if (res instanceof Array) this.setState({invites: res}); else this.setState({failed: true})
					})
					.catch((thrown) => {
						console.log(thrown)
						alert('Could not load invites. Sorry.');
					})
			}
		}

		return <div id="settings-invites">
			<h2>{invites.length} Invites ({invites.filter( (invite) => invite.used==0 ).length} Pending)</h2>
			<div class="scrollable-list settings-box">

			<ul>{
				invites.sort( (a,b) => { if (a.t_created < b.t_created) return 1; if (a.t_created > b.t_created) return -1; return 0 } ).map((row) => {
					return <InviteInfo key={row.n} invite={row} />
				})
			}</ul>

			</div>
			<button onClick={ createInvite.bind(this) }>Create New Invite</button>
		</div>
	}
}

class InviteInfo extends React.Component {

	inviteRevoke() {
		const {core, invite} = this.props
		alert('The feature to revoke invite ' + invite.n + ' will be available soon :)')
	}

	render() {
		const {core, invite} = this.props

		let taken = <span class="fa fa-square-o cell" title="The invite is still pending..." />
		if (invite.used) taken = <span class="fa fa-check cell" title="The invite has been used." />

		let details = <span class="cell clickable" onClick={this.inviteRevoke.bind(this)}><span class="fa fa-times" /> revoke</span>
		if (invite.used) details = <PoeParsedForUI tag='span' props={{className: 'cell'}} txt={'Joined as %' + invite.used + ''} />

		return <li>
			{taken}
			<span class="cell invitee-email">{invite.email}</span>
			{details}
		</li>
	}
}

export default withStore(observer(UserInvites))
