const { observable, computed, action } = require("mobx")

export class StaticState {

	constructor(obj) {
		this.storable_claims    = {}
		this.storable_t_updated = 0
	}

	claim(id) {
		if (typeof this.storable_claims[id] === 'undefined') this.storable_claims[id] = 0
		this.storable_claims[id]++
		this.storable_t_updated = Date.now()
	}

	release(id) {
		if (typeof this.storable_claims[id] !== 'undefined') {
			this.storable_claims[id]--
			if (this.storable_claims[id] == 0) delete this.storable_claims[id]
		}
		this.storable_t_updated = Date.now()
	}

	releaseAll() {
		this.storable_claims = {}
	}

	isClaimed() {
		return Object.keys(this.storable_claims).filter(key => this.storable_claims[key] > 0).length
	}

}
