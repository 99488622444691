import React from "react"
import { observer, inject } from "mobx-react"
import { withStore } from '../utility/Core'

import UserShort from "./UserShort"
import DateString from "./DateString"
import GroupAssociation from "./GroupAssociation"

import Select from 'react-select';
import AsyncSelect from 'react-select/async';

class DirectNodeMeta extends React.Component {

	constructor(props) {
		super(props)

		const { core, nid, componentId } = props
		const node = core.nodes[nid]

		const groups = node.groups.map(g => ({value:g.gid, label: g.name}))
		//{value: "4", label: "Private"}

		this.state = {
			selected : groups
		}
	}

	/*
		<Select
				value={this.state.selected}
				isMulti
				onChange={(o) => {console.log(o); this.setState({selected: o})}}
				options={options}
		/>
	*/


	deleteNode() {
		const { core, nid } = this.props
		const node = core.nodes[nid]
		if (typeof node === 'undefined') return false

		if (window.prompt("Deleting this card will PERMANENTLY remove it.\nIt will also delete ALL INCOMING AND OUTGOING LINKS it has.\n\nAre you sure? (type YES)") === 'YES') {
			core.op({
				realm: 'node',
				op:    'nodeDelete',
				uid:    core.user.uid,
				nid:    nid,
				prev_mod_uuid: node.last_mod_uuid,
				uid: core.user.uid,
				lid: core.uuid('l'),
			})
		}
	}

	render() {
		const { core, nid, componentId } = this.props
		const node = core.nodes[nid]
		const options = core.groups.map( g => ({value: g.gid, label: g.name}) )

		//	var deletable = false
		//	if (node.type == 'tag')
		//		deletable = core.user.permission('delete', 'edge_tag', node) // for tags, the permission for untagging equals tag deletion?
		//	else
		//	deletable = core.user.permission('delete', 'node', node)

		const deletable = core.user.permission('delete', 'node', node)

		return <div className={"direct-node node-container direct-node-meta " + node.type}>
			<h1>{node.title}</h1>
			<p>Created on: <DateString value={node.t_created} /></p>
			<p>Created by: <UserShort uid={node.uid} /></p>
			<p>Last modified on: <DateString value={node.t_updated} /></p>
			<h2>{node.groups.length} Group{node.groups.length > 1 ? 's' : ''}</h2>
			<div>
				<GroupAssociation type="node" obj={node} />
			</div>
			<h2>Extra</h2>
			<button onClick={this.deleteNode.bind(this)} disabled={!deletable}>Delete this Card</button>
		</div>
	}

}

export default withStore(observer(DirectNodeMeta))
