var dateCreate = require('sugar/date/create');
var dateFormat = require('sugar/date/format');


export function smartDate (value) {
	const t        = parseInt(Date.now() / 1000)
	const date     = dateCreate(1000 * value)
	let contents   = dateFormat(date, '%d %h')
	if (Math.abs(value - t) > 365 * 24 * 60 * 60) {
		contents   = dateFormat(date, '%h %Y')
	}
	else if (Math.abs(value - t) < 12 * 60 * 60) {
		contents   = dateFormat(date, '%H:%M')
	}

	return contents
}