import React from "react";
import { observer, inject } from "mobx-react";
import { withStore } from '../utility/Core'
import { action } from "mobx";

import Storage from "../utility/Storage";

class FileSync extends React.Component {

	constructor() {
		super()
		this.fileUploader = new Storage('/putFile/')
		this.fileUploader.axiosParms.timeout = 10 * 60 * 10000
	}
	
	componentWillUnmount() {
		clearInterval(this.pollingTimer);
		if (typeof this.fileUploader.ajax !== 'undefined') this.fileUploader.ajax.cancelHandler.cancel('component unmounted');
	}

	componentDidUpdate() {
		const { core } = this.props
		console.log('FileSync change')
		if (core.fileUploadQueue.length) {
			if (core.fileUploadQueue[0].status === 'pending') {
				const { file, nid, fid, quotaGroup } = core.fileUploadQueue[0]
				core.fileUploadQueueSetStatus(0, 'uploading')

				const data = new FormData()
		    data.append('file', file)
		    data.append('nid', nid)
		    data.append('fid', fid)
		    data.append('quotaGroup', quotaGroup)

				this.fileUploader.query(data)
		       .then(res => { // then print response status
				      if (res.statusText === 'OK') {
							  core.fileUploadQueueSetStatus(0, null)
						  }
						  else {
		         	  console.log('AJAX Query Result: ', res.statusText)
							}
		       })
			}
		}
	}

	render() {
		const { core } = this.props;

		if (core.fileUploadQueue.length === 0) return null

		return <div id="header-button-filesync" className="header-button clickable" title={core.fileUploadQueue.length + ' pending uploads'}>
			<span>
				{ core.fileUploadQueue.length }
			</span>
		</div>
	}
}

export default withStore(observer(FileSync))
