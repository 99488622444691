import React from "react";
import { observer, inject } from "mobx-react";
import { withStore } from '../utility/Core'

class GlobalUndo extends React.Component {

	constructor(props) {
		super(props)
		this.performUndo = this.performUndo.bind(this)
		this.performRedo = this.performRedo.bind(this)
		this.keypress    = this.keypress.bind(this)
		this.coreops     = []
	}

	componentDidUpdate() {
		const { domRef, core, context } = this.props
		this.coreops      = core.operations.filter(op => op.realm == 'node' && op.nid == context.nid)

		if (domRef) domRef.removeEventListener('keydown', this.keypress);
		if (domRef) domRef.addEventListener('keydown', this.keypress);
	}

	componentDidMount() {
		const { domRef, core, context } = this.props
		// todo: unless the event listener already is set
		this.coreops                    = core.operations.filter(op => op.realm == 'node' && op.nid == context.nid)

		if (domRef) domRef.addEventListener('keydown', this.keypress);
	}

	componentWillUnmount() {
		const { domRef } = this.props
		if (domRef) domRef.removeEventListener('keydown', this.keypress);
	}

	keypress(e) {
		if ((e.ctrlKey) && (!e.shiftKey) && (e.keyCode == 90)) {
			this.performUndo()
			e.preventDefault()
			}
		if ((e.ctrlKey) && (e.shiftKey) && (e.keyCode == 90)) {
			this.performRedo()
			e.preventDefault()
		}
	}

	undoPos() {
		const { core } = this.props

		var undoPos = this.coreops.length - 1

		while((undoPos>=0) && (this.coreops[undoPos].undoes)) {
			undoPos = this.coreops.findIndex(op => op.lid == this.coreops[undoPos].undoes)-1
		}

		return undoPos
	}

	redoPos() {
		const { core } = this.props
		const undoPos  = this.undoPos()
		const tmp      = {}

		var redoPos    = false

		for(var i=this.coreops.length - 1; i>undoPos; i--) {
			if (this.coreops[i].opType == 'undo') {
				if (!tmp[this.coreops[i].lid]) {
					redoPos = i
					break
				}
			}
			if (this.coreops[i].opType == 'redo') {
				tmp[this.coreops[i].undoes] = true
			}
		}

		return redoPos
	}

	performRedo() {
		const { core } = this.props

		const redoPos    = this.redoPos()

		if (redoPos) core.redoOperation(this.coreops[redoPos].lid)
	}

	performUndo() {
		const { core } = this.props
		var undoPos    = this.undoPos()
		console.log('Do UNDO operation at ' + undoPos, this.coreops[undoPos].lid)
		if (undoPos >= 0) core.undoOperation(this.coreops[undoPos].lid)
	}

	render() {
		const { core } = this.props;

		var undoButton = null
		var redoButton = null
		var s = (core.operations.map(op => op.lid).join())

		console.log('render undo')
		console.log(this.coreops.map((op) => op.lid + ': ' + op.op + ' -> ' + op.str))

		var undoPos    = this.undoPos()

		if (undoPos>=0) {
			undoButton = <div>
				<span className="fa fa-arrow-circle-left" onClick={this.performUndo} />{this.coreops[undoPos].op} {this.coreops[undoPos].str}
				</div>
		}

		var redoPos    = this.redoPos()

		if (redoPos) {
			console.log('set to redo ' + redoPos, this.coreops[redoPos].op, this.coreops[redoPos].str)
			redoButton = <div>
				<span className="fa fa-arrow-circle-right" onClick={() => {
						core.redoOperation(this.coreops[redoPos].lid)
				}} />{this.coreops[undoPos+1].op} {this.coreops[undoPos+1].str} by reverting {this.coreops[redoPos].op} {this.coreops[redoPos].str}
				</div>
		}

		return null
		/* return <div>
			{undoButton}
			{redoButton}
			<div>[{undoPos} | {redoPos} / {this.coreops.length}]</div>
		</div> */
	}


}

export default withStore(observer(GlobalUndo))
