import React from "react";

export default class DirectNodeModeSwitch extends React.Component {

	constructor(props) {
		super(props)
		
		this.domRef       = React.createRef();
		
		this.state = {
			expanded: false
		}
	}
	
	selectOption(v) {
		const {mode, onChange} = this.props
		const DOM              = this.domRef.current
		
		onChange(v)
		DOM.blur();
	}
	
	expand() {
		this.setState({expanded: true})		
	}

	collapse() {
		this.setState({expanded: false})		
	}

	render() {
		const {mode, onChange} = this.props
		const DOM              = this.domRef.current
		
		
		const options = [
		  { value: 'view', icon: <span className="fa fa-ellipsis-v"></span>, label: 'View', explanation: 'View this node' },
		  { value: 'edit', icon: <span className="fa fa-pencil" />, label: 'Edit', explanation: 'Edit this node' },
		  { value: 'history', icon: <span className="fa fa-clock-o" />, label: 'History', explanation: 'View the editing history of this node' },
		  { value: 'settings', icon: <span className="fa fa-gear" />, label: 'Settings', explanation: 'Edit the settings of this node' },
		]
		
		const selbox = <span className='direct-node-mode-switch-options-container'>
			{
				options.map( (o) => {
					return <span key={o.value} title={o.explanation} className='direct-node-mode-switch-option' onClick={ () => { this.selectOption.bind(this)(o.value) }}>{o.icon} {o.label}</span>
				} ).filter(o => (o !== null))				
			}
		</span>


		return <span className={"direct-node-topbar-button direct-node-mode-switch unselectable is-" + mode} tabIndex="0" onFocus={ this.expand.bind(this) } onBlur={ this.collapse.bind(this) } ref={this.domRef}>
			{ this.state.expanded ? selbox : null }
			{
				options.map( (o) => {
					if (mode != o.value) return null
					if (this.state.expanded) 
						return <span key={o.value} className={'direct-node-mode-switch-trigger is-' + mode} onMouseDown={ () => { setTimeout( () => { DOM.blur(); }, 100 )  } }>{o.icon}</span>
					else
						return <span key={o.value} className={'direct-node-mode-switch-trigger is-' + mode}>{o.icon}</span>
				} ).filter(o => (o !== null))
			}
		</span>
		
	}
	
}