const { observable, makeObservable } = require("mobx");


class StreamEvent {

	constructor(e, source = '') {

		this.id         = null;
		this.op         = undefined
		this.uid        = undefined
		this.ident      = {
		                  	type: false,
		                  	id: undefined,
		                  }

		this.type       = undefined

		this.supplementaryIds = {}
		this.operationTypes   = {}
		this.minimalOps       = []
		this.t_from     = undefined
		this.t_to       = undefined
		this.lid_from   = undefined
		this.lid_to     = undefined
		this.lids       = []
		this.terminated = false

		this.silent     = false
		this.lid_seen   = false
		this.t_seen     = false

		if (source === 'db') {
			var type = 'node'
			if (e.ident.substr(0,2) === 'e-') type = 'edge'

			this.id  = e.eventId
			this.uid = e.uid
			this.ident = {
				type: type,
				id: e.ident
			}
			this.op         = '-'
			this.t_from     = e.t_from
			this.t_to       = e.t_to
			this.lid_from   = e.lid_from
			this.lid_to     = e.lid_to
			this.silent     = e.silent
			this.t_seen     = e.t_seen
			this.lid_seen   = e.lid_seen
			this.minimalOps = JSON.parse(e.data)
		}
		else if (source === 'transfer') {
			Object.keys(e).forEach(k => {
				this[k] = e[k]
			})
		}
		else {
			this.op       = e.op
			this.uid      = e.uid
			this.t_from   = e.t_from
			this.t_to     = e.t_to
			this.lid_from = e.lid_from
			this.lid_to   = e.lid_to
			this.type     = e.type
			this.rel      = e.rel
			this.t_seen   = e.t_seen
			this.lid_seen = e.lid_seen
			this.ident = {
				type: 'node',
				id: e.nid
			}
			this.operationTypes[this.op] = true;

			const r = typeof e.row === 'string' ? JSON.parse(e.row) : {}

			switch(e.op) {

				case 'nodeCreate':

					this.minimalOps = [{
						op: 'nodeCreate',
						type: e.type,
						uid: e.uid,
						nid: e.nid
					}]

					switch(e.type) {
						case 'card':
							break

						case 'comment':
							this.ident.id = e.rel
							this.minimalOps[0].nid = e.rel
							this.minimalOps[0].comment = e.nid
							break

						case 'tag':
							break

						case 'predicate':
							this.ident = {
								type: 'edge',
								id: r.info.rel
							}
							break
					}
					break;


				case 'nodeEdits':

					this.minimalOps = [{
						op: 'nodeEdit',
						type: e.type,
						uid: e.uid,
						nid: e.nid
					}]

					switch(e.type) {

						case 'predicate':
							this.ident = {
								type: 'edge',
								id: e.rel
							}
							break;

						case 'comment':
							this.ident.id = e.rel
							this.minimalOps[0].nid = e.rel
							this.minimalOps[0].comment = e.nid
							break;

					}
					break;

				case 'nodeDelete':

					this.ident = {
						type: 'node',
						id: e.nid
					}

					this.minimalOps = [{
						op: 'nodeDelete',
						type: e.type,
						uid: e.uid,
						nid: e.nid
					}]

					break;

				case 'edgeCreate':

					this.ident = {
						type: 'edge',
						id: e.eid
					}

					this.minimalOps = [{
						op: 'edgeCreate',
						type: e.type,
						uid: e.uid,
						eid: e.eid
					}]

					switch(this.type) {

						case 'edge':
						case 'www':
							break

						case 'comment':

							this.ident = {
								type: 'node',
								id: r.src.nid
							}

							this.minimalOps = []

							break

						case 'tag':

							this.ident = {
								type: 'node',
								id: r.src.nid
							}

							this.minimalOps[0].anchor = r.src.nid
							this.minimalOps[0].tag = r.dst.nid

							break

						case 'like':
							this.ident = {
								type: 'node',
								id: r.src.nid
							}
							this.minimalOps[0].anchor = r.src.nid
							break

						case 'annotation':
							break

					}

					break

				case 'edgeUpdate':

					this.minimalOps = [{
						op: 'edgeUpdate',
						type: e.type,
						uid: e.uid,
						eid: e.eid
					}]

					this.ident = {
						type: 'edge',
						id: e.eid
					}

					break

				case 'edgeDelete':

					this.ident = {
						type: 'edge',
						id: r.eid
					}

					this.minimalOps = [{
						op: 'edgeDelete',
						type: e.type,
						uid: e.uid,
						eid: r.eid
					}]

					break;

				case 'groupAdd':
					this.minimalOps = [{
						op: 'groupAdd',
						type: e.eid ? 'edge' : 'node',
						uid: e.uid,
						anchor: e.eid ? e.eid : e.nid,
						gid: r.gid
					}]
					break;

				case 'groupDel':
					this.minimalOps = [{
						op: 'groupDel',
						type: e.eid ? 'edge' : 'node',
						uid: e.uid,
						anchor: e.eid ? e.eid : e.nid,
						gid: r.gid
					}]
					break;

			}

			this.lids = (this.lid_from == this.lid_to) ? [this.lid_from] : [this.lid_from + '-' + this.lid_to]
		}

		makeObservable(this, {
			silent: observable,
			lid_seen: observable,
			t_seen: observable,
		})
	}

	merge(e) {
		this.t_from = Math.min(this.t_from, e.t_from)
		this.t_to   = Math.max(this.t_to,   e.t_to)

		this.lid_from = Math.min(this.lid_from, e.lid_from)
		this.lid_to   = Math.max(this.lid_to,   e.lid_to)

		this.operationTypes[e.op] = true
		this.lids = [...this.lids, ...e.lids]

		//
		const hash_exists    = {}
		var   is_node_create = {}
		var   is_edge_create = {}
		this.minimalOps.forEach((row) => {
			if (row.op == 'nodeCreate') is_node_create[row.nid] = true
			if (row.op == 'edgeCreate') is_edge_create[row.eid] = true
			hash_exists[JSON.stringify(row)] = true
		})

		e.minimalOps.forEach(row => {
			if ((row.op == 'nodeEdit')   && (is_node_create[row.nid])) return
			if ((row.op == 'edgeUpdate') && (is_edge_create[row.eid])) return
			const key = JSON.stringify(row)
			if (!hash_exists[key]) {
				this.minimalOps.push(row)
				hash_exists[key] = true
			}
		})

		return this
	}

	print(prefix) {
		var newDate = new Date();
		newDate.setTime(this.t_from*1000);
		console.log(prefix + this.op + ' on ' + this.ident.type + ' ('+this.type+') ' + this.ident.id + ' : ' + newDate.toUTCString() + ' /', this.supplementaryIds, Object.keys(this.operationTypes))
	}

	pretty() {
		var newDate = new Date();
		newDate.setTime(this.t_from*1000);
		console.log(this.uid + ' performed ' + this.minimalOps.length + ' operation(s) on ' + this.ident.type + ' ' + this.ident.id + ' on ' + newDate.toUTCString() )
		this.minimalOps.forEach((op) => {
			const rest = Object.keys(op).filter(key => key != 'op' && key != 'type').map(key => key + ': ' + op[key]).join(', ')
			console.log('   ' + op.op + ' ' + op.type + ' [' + rest + ']' )
		})
		console.log(' ');
	}
}


class StreamEventQueue {

	constructor() {
		this.mergeWindow = 2 * 60 * 60 // 2-hour merge window
		this.queue       = []
	}

	findLatest(obj) {

		for(var i=this.queue.length-1; i>=0; i--) {
			const e = this.queue[i]
			if (
				(e.uid == obj.uid) &&
				(e.ident.id == obj.ident.id) &&
				((  e.t_to   - obj.t_from) < this.mergeWindow) &&
				((obj.t_from -   e.t_to) < this.mergeWindow) &&
				(!e.terminated)
			) return i
		}

		return -1
	}

	append(e) {
		//this.queue.unshift(e)
		this.queue.push(e)
	}

	store(e) {

		// e.print('+ ')

		const i = this.findLatest(e)
		if (i>=0) {
			//console.log(e, 'has match in queue:', i, this.queue[i])
			this.queue[i] = this.queue[i].merge(e)
		}
		else {
			this.append(e)
		}

	}

}

//module.exports = { StreamEvent, StreamEventQueue }
export { StreamEvent, StreamEventQueue }
