import React from "react";
import { observer } from "mobx-react";
import { withStore } from '../utility/Core'

window.DOM = {}
const debug = false

class DirectParagraph extends React.Component {

	preop       = []
	html        = []
	shadow_html = []

	prevBuild   = {
		last_mod_uuid: null,
		html: undefined,
	}

	constructor(props) {
		super(props)
		this.html         = []
		this.shadow_html  = []
		this.domRef       = React.createRef()
		this.resize       = this.updateDimensions.bind(this)
		this.winMouseup   = this.winMouseup.bind(this)
	}

	componentDidMount() {
		const { pid, nid, core, parStore } = this.props
		const paragraph = core.nodes[nid].paragraphs[pid]
		const nodeDOM   = this.domRef.current.closest('.node-body')

		parStore(pid, 'DOM', this.domRef)
		parStore(pid, 'setCaretPos', this.setCaretPos.bind(this))

		this.update()

		window.addEventListener("resize", this.resize)
		nodeDOM.addEventListener("mouseup", this.winMouseup)

		this.props.postRenderTask(pid, paragraph.last_mod_uuid)
		this.updateDimensions()
	}

	componentDidUpdate(prevProps, prevState) {
		const { pid, nid, core, nodeComponentId } = this.props
		const paragraph = core.nodes[nid].paragraphs[pid]
		// console.log('call postrender on', pid, paragraph.last_mod_uuid)
		this.props.postRenderTask(pid, paragraph.last_mod_uuid)

		if (pid !== prevProps.pid) {
			this.updateDimensions()
			this.update()
			core.setCaretX(nodeComponentId, false)
		}
		else {
			this.update()
		}

		if (core.nodes[nid].paragraphList[0] === pid) {
			core.nodes[nid].setAttr('title', core.nodes[nid].paragraphs[pid].txt)
		}
	}

	componentWillUnmount() {
		const nodeDOM   = this.domRef.current.closest('.node-body')

		if (this.refreshTimer) {
			clearTimeout(this.refreshTimer)
		}

		window.removeEventListener("resize", this.resize)
		nodeDOM.addEventListener("mouseup", this.winMouseup)
	}

	updateCaretX(param) {
		const { core, nid, pid, nodeComponentId } = this.props

		if (this.domRef.current === null) return false

		const DOM                                 = this.domRef.current.childNodes[0]
		const nodeDOM                             = this.domRef.current.closest('.node-body')

		const showingSuspended = (core.caretX[nodeComponentId]) && (core.caretX[nodeComponentId].pid === pid) && (core.caretX[nodeComponentId].pos === null) && (core.caretX[nodeComponentId].length === 0)

		this.update({skipOverlays: true})

		const posTupel     = this.getCaretPos()
		const p1           = posTupel[0]
		const p2           = posTupel[0] + posTupel[1]
		const beg          = Math.min(p1, p2)
		const end          = Math.max(p1, p2)
		const nodePosition = nodeDOM.getBoundingClientRect()
		const range        = this.setRange(beg, end)

		var   rangePos     = (range) ? range.getBoundingClientRect() : false
		var   pos          = posTupel[0]
		var   length       = posTupel[1]


		/*
		if (document.activeElement !== DOM) {
			console.warn('focus is on ', document.activeElement, 'not on', DOM)
			return
		}
		//*/

		if (param && param.setNull) {
			pos      = null
			length   = 0
			rangePos = DOM.getBoundingClientRect()
		}

		//console.log(screenpos.top, - nodeDOM.getBoundingClientRect().top)
		//console.log(screenpos.top, screenP.top, ' => ', screenpos.top - screenP.top)
		//console.log('Caret on Screen:', posTupel[0], posTupel[0] + posTupel[1], screenpos, range)

		core.setCaretX(nodeComponentId, {
			nid : nid,
			pid : pid,
			pos : pos,
			length : length,
			focus: true,
			nodeComponentId : nodeComponentId,
			left :  rangePos ? rangePos.left   - nodePosition.left : null,
			top :   rangePos ? rangePos.top    - nodePosition.top : null,
			bottom: rangePos ? rangePos.bottom - nodePosition.top : null,
			right : rangePos ? rangePos.right  - nodePosition.left : null,
		})

		// das update war ursprünglich früher... move back?
		if (showingSuspended) {
			this.update({skipOverlays: false, forceImmediate: true})
		}

	}

	onBlur() {
		const { core, nodeComponentId } = this.props
		if (typeof core.caretX[nodeComponentId] !== nodeComponentId) {
			const caret = core.caretX[nodeComponentId]
			caret.focus = false
			//core.setCaretX(nodeComponentId, false)
		}
	}

	setRange(beg, end) {
		const DOM                = this.domRef.current.childNodes[0]

		var   elm_beg, elm_end, dst_beg, dst_end

		var range = document.createRange();

		if (this.html.length === 0) {

			elm_beg = DOM.childNodes[0]
			dst_beg = 0
			elm_end = DOM.childNodes[0]
			dst_end = 0

		}
		else {

			for(var i=0; i<this.html.length; i++) {
				if ((this.html[i].beg <= beg) && (this.html[i].end >= beg)) {
					elm_beg   = this.html[i].obj;
					dst_beg   = beg - this.html[i].beg;
					break;
				}
			}

			for(i=0; i<this.html.length; i++) {
				if ((this.html[i].beg <= end) && (this.html[i].end >= end)) {
					elm_end   = this.html[i].obj;
					dst_end   = end - this.html[i].beg;
					break;
				}
			}
		}

		if (elm_beg && elm_end) {

			range.selectNodeContents(elm_beg)
			range.setStart(elm_beg, Math.min(elm_beg.length, dst_beg))
			range.setEnd(elm_end, Math.min(elm_end.length, dst_end))

			return range
		}

		return null
	}

	// draw Overlays
	drawOverlays() {
		const { core, nid, pid, mode, mark, nodeComponentId } = this.props
		const paragraph          = core.nodes[nid].paragraphs[pid]
		const DOM                = this.domRef.current.childNodes[1]

		const anchorRects        = DOM.getClientRects()
		const anchorRect         = anchorRects[0]

		while(DOM.childNodes.length) { DOM.removeChild(DOM.childNodes[0]); }

		const setMark = (em, type = false) => {

			const range = this.setRange.bind(this)(em.local.beg, em.local.end)

			if (range) {
				const rects         = range.getClientRects()
				const doubleTracker = {}

				for(var i=0; i<rects.length; i++) {
					const rect = rects[i]
					const name = rect.top+'-'+rect.left+'/'+rect.width+'-'+rect.height
					if (doubleTracker[name]) continue

					const mark = document.createElement('span')
					const css  = 'left:'+(rect.left - anchorRect.left)+'px;'
										 + 'top:'+(rect.top - anchorRect.top)+'px;'
										 + 'width:'+rect.width+'px;'
										 + 'height:'+rect.height+'px'

					mark.setAttribute('class', 'mark ' + (type ? type : em.edge.type))
					mark.setAttribute('style', css)
					DOM.appendChild(mark)
					doubleTracker[name] = true
				}

			}
		}

		const paragraphLevelEdges = []

		if (mode === 'marker') {
			paragraph.edgeMarks.forEach((em) => {
				if (em.edge.eid === mark.eid) setMark(em, 'highlight')
			})
		}
		else {
			paragraph.edgeMarks.forEach((em) => {
				// console.log(em.edge)
				if (em.edge.deleted) return
				if ((em.local.beg === null) && (em.local.end === null)) paragraphLevelEdges.push(em); else setMark(em)
			})

			if (paragraphLevelEdges.length) {
				const isActive = (core.caretX[nodeComponentId]) && (core.caretX[nodeComponentId].pid === pid) && (core.caretX[nodeComponentId].pos === null) && (core.caretX[nodeComponentId].length === 0)
				const position = ((this.screenP.left - this.anchorRect.left) - 32)
				const mark     = document.createElement('span')

				mark.setAttribute('class', "paragraph-has-suspended-edges fa fa-info-circle" + (isActive ? ' active' : ''))
				mark.setAttribute('title', 'This paragraph contains ' + paragraphLevelEdges.length + ' suspended overlays that have no anchor in the text (anymore).')
				mark.setAttribute('id', 'suspended-edges-of-' + pid)
				mark.setAttribute('style', 'left:' + position + 'px')

				DOM.appendChild(mark)
			}
		}

	}

	refreshOverlays(forceImmediate = false) {

		if (this.domRef.current === null) {
			// console.warn('ref', this)
			return false
		}

		const DOM                = this.domRef.current.childNodes[1]

		if (this.refreshTimer) {
			clearTimeout(this.refreshTimer)
		}

		while(DOM.childNodes.length) { DOM.removeChild(DOM.childNodes[0]); }

		if (forceImmediate) {
			this.drawOverlays()
		}
		else {
			this.refreshTimer = setTimeout(() => {
				this.drawOverlays.bind(this)()
			}, 200)
		}

	}

	updateDimensions() {
		const DOM         = this.domRef.current.childNodes[0]
		const anchorRects = DOM.getClientRects()
		this.anchorRect   = anchorRects[0]

		const nodeDOM     = this.domRef.current.closest('.node-body')
		this.screenP      = nodeDOM.getBoundingClientRect()


		this.updateCaretX()
		this.refreshOverlays()
	}

	// builds a full DOM representation of the paragraph
	build() {
		const { core, nid, pid } = this.props
		const paragraph          = core.nodes[nid].paragraphs[pid]

		var list = [{
			index:-1, point:'beg', type: '', pos:0
		}, {
			index:-1, point:'end', type: '', pos:paragraph.txt.length
		}];

		for(var i=0; i<paragraph.markup.length; i++) {
			list.push({ index: i, point: 'beg', type: paragraph.markup[i].type, pos: paragraph.markup[i].beg });
			list.push({ index: i, point: 'end', type: paragraph.markup[i].type, pos: paragraph.markup[i].end });
		}

		// make sure they are applied one after the other
		list.sort((a, b) => {
			if (a.pos > b.pos) {
				return 1
			} else if (a.pos<b.pos) {
				return -1
			} else {
				if ((a.point === 'beg') && (b.point === 'beg')) return a.type.localeCompare(b.type);  // if in the same position, make a reproducible order by using the tag
				if ((a.point === 'end') && (b.point === 'end')) return -a.type.localeCompare(b.type); // if in the same position, make a reproducible order by using the tag
				if ((a.point === 'beg') && (b.point === 'end')) return -1
				if ((a.point === 'end') && (b.point === 'beg')) return 1
			}
			return 0
		});

		const _parentFind = function(obj, name) {
			if (obj.parentNode) {
				if (obj.nodeName.toUpperCase() === name.toUpperCase()) {
					return [obj];
				}
				else {
					const x = _parentFind(obj.parentNode, name);
					if (x === false) return false;
					x.unshift(obj);
					return x;
				}
			}
			else {
				return false;
			}
		}

		const _build = function (obj, i) {
			if (i >= list.length) return;

			var curr = list[i];
			var next = list[i+1];
			if (debug) console.log(curr);
			if (debug) console.log(next);

			var txt  = paragraph.txt.substr(curr.pos, next.pos - curr.pos);
			if (txt.length) {
				//var txt_improved = txt.replace(/^ /, '\u00A0');																// todo [001]: is this the right way of making sure that we can split paragraphs before a space like "ein[BREAK] text" / does it require some kind of clean up?
				var txt_improved = txt.replace(/  /g, ' \u00A0');													  	// todo [001]: is this the right way of making sure that we can split paragraphs before a space like "ein[BREAK] text" / does it require some kind of clean up?
				var t_n  = document.createTextNode(txt_improved);
				obj.appendChild(t_n);
				this.shadow_html.push({beg: curr.pos, end: next.pos, obj: t_n});
			}

			if (next.point === 'beg') {
				if (next.type) {
					var nxt = document.createElement(next.type);
					obj.appendChild(nxt);
					_build.bind(this)(nxt, i+1)
				}
			}
			else if (next.point === 'end') {
				if (next.type) {
					if (debug) console.log("Close " + next.type + ' even though we are in ');
					if (debug) console.log(obj);
					var parentPath = _parentFind(obj, next.type);
					if (debug) console.log(parentPath);

					var hook = obj;
					var nxt  = obj;

					for (var n=0; n<parentPath.length; n++) { hook = hook.parentNode; }

					for (var n=0; n<parentPath.length-1; n++) {
						if (debug) console.log('append ' + parentPath[n].nodeName + ' to ' + hook );
						nxt  = document.createElement(parentPath[n].nodeName);
						hook.appendChild(nxt);
						hook = nxt;
					}

					_build.bind(this)(hook, i+1)
				}
			}
		}

		var [type]   = paragraph.type.split('-')
		if (type === 'ul') type = 'div'
		if (type === 'ol') type = 'div'

		const root = document.createElement(type);

		var   i    = 0
		this.shadow_html  = []

		_build.bind(this)(root, i)

		var fix_end = function(obj) {
			if ((obj.lastChild) && (obj.lastChild.nodeType === 1)) {
				fix_end(obj.lastChild);
			}
			else {
				if ((obj.lastChild) && (obj.lastChild.nodeType === 3) && (obj.lastChild.nodeValue.slice(-1) === ' ')) {
					var closing = document.createElement('br');
					obj.appendChild(closing);
				}
			}
		}

		fix_end(root)

		return(root)
	}

	renderString() {
		//return '<' + paragraph.type + '>' + this.build().innerHTML + '</' + paragraph.type + '>'
		return this.build().outerHTML
	}

	update(conf = {}) {
		const { core, nid, pid } = this.props
		const paragraph          = core.nodes[nid].paragraphs[pid]
		const DOM                = this.domRef.current.childNodes[0]
		var   rebuild_color      = '#faa'

		const nodeDOM  = this.domRef.current.closest('.node-body')
		this.screenP   = nodeDOM.getBoundingClientRect()

		if (DOM) {

			// only for debugging: make the DOM representation available
			if (debug) {
				window.DOM['DOM-' + pid] = DOM
				console.log('DOM-' + pid + ':', DOM)
			}


			// clean up currently displayed and model-based version of the paragraph for better comparison
			const orig    = DOM.innerHTML.replace(/&nbsp;/g, ' ').replace(/<br>(<[^>]+>)$/, '$1').trim()
			var   modDOM  = undefined
			var   modStr  = undefined

			// check if there exists a this.prevBuild with the same last_mod_uuid;
			// if so, do not regenerate the model but just redo the overlays
			if ((this.prevBuild.last_mod_uuid !== paragraph.last_mod_uuid) || (this.prevBuild.html !== 'orig')) {
				// the model has changed, or the contenteditable div does not match the model for this last_mod_uuid anymore -> rebuild

				modDOM = this.build()
				modStr  = modDOM.outerHTML.replace(/&nbsp;/g, ' ').replace(/<br>(<[^>]+>)$/, '$1').trim()

				// update prevBuild
				this.prevBuild.last_mod_uuid = paragraph.last_mod_uuid
				this.prevBuild.html          = modStr

				// Compare and enforce the model if necessary. Do a full update if either the rendered representation (innerHTML) differ, or if the model's makeup has changed since the last rendering
				if ((orig !== modStr) || (this.html.length !== this.shadow_html.length)) {
					if (orig !== '') console.warn("Model Enforcement:\n[" + orig + "]\ndoes not match\n[" + modStr + "]")
					const pos = (document.activeElement === DOM) ? this.getCaretPos()[0] : undefined

					if ((orig === modStr) && (this.html.length !== this.shadow_html.length)) {
						// the html representation of model and contenteditable are identical, but our this.html-model has become outdated
						// which also necessitates a redraw.
						rebuild_color = '#ffc';
					}

					while(DOM.childNodes.length) { DOM.removeChild(DOM.childNodes[0]); }

					DOM.appendChild(modDOM)
					this.html = this.shadow_html
					// console.log('SET html', this.html)

					DOM.style = 'background-color: ' + rebuild_color
					setTimeout(() => {DOM.style = 'background-color: none'}, 100)

					if (typeof pos !== 'undefined') this.setCaretPos(pos)
				}
				else {

					// update only the spreads of elements in our this.html-model
					for(var i=0; i<this.html.length; i++) {
						this.html[i].beg = this.shadow_html[i].beg
						this.html[i].end = this.shadow_html[i].end
					}

				}
			}
			else {
				console.log('no rerender necessary')
			}

			if (!conf.skipOverlays) {
				if (conf.forceImmediate)
					this.refreshOverlays(true)
				else
					this.refreshOverlays()
			}
		}

	}

	getCaretPos() {
		const DOM                = this.domRef.current.childNodes[0]
		const el                 = DOM.childNodes[0];
		var length = 0;

		var caretOffset = false;

    if (typeof window.getSelection != "undefined") {
				const sel   = window.getSelection();

				//todo: rauskriegen, warum das manchmal auf body angewendet wird.
				if (sel.anchorNode && (sel.anchorNode.nodeName === 'BODY')) {
					return [false, false];
				}

				if (typeof el === 'undefined') return [false, false];

				if ((sel) && (sel.focusNode != null) && (DOM.contains(sel.focusNode))) {
					var range = sel.getRangeAt(0);
					var preCaretRange = range.cloneRange();
					preCaretRange.selectNodeContents(el);
					preCaretRange.setEnd(range.endContainer, range.endOffset);
					caretOffset = preCaretRange.toString().length;

					if (!sel.isCollapsed) {
						preCaretRange.selectNodeContents(el);
						preCaretRange.setEnd(range.startContainer, range.startOffset);
						length = preCaretRange.toString().length - caretOffset;
					}

				}
    } else if (typeof document.selection !== "undefined" && document.selection.type !== "Control") {
        var textRange = document.selection.createRange();
				console.log('Range/Sel ', textRange, document.selection);
        var preCaretTextRange = document.body.createTextRange();
        preCaretTextRange.moveToElementText(el);
        preCaretTextRange.setEndPoint("EndToEnd", textRange);
        caretOffset = preCaretTextRange.text.length;

				if (!document.selection.isCollapsed) {
					preCaretTextRange.setEndPoint("EndToStart", textRange);
					length = preCaretTextRange.text.length - caretOffset;
				}

    }

		return [caretOffset, length]
	}

	setCaretPos(pos, length = 0) {

		if (pos === false) return;

		const range = this.setRange(pos, pos+length)
		if (range) {
			if (length === 0) range.collapse()

			var sel = window.getSelection();
			sel.removeAllRanges();
			sel.addRange(range)
		}
		else {
			console.warn('Could not setCaretPos(): no range at ', pos, length)
		}
	}

	caretScreenPos() {
		if (!window.getSelection().anchorNode) return null;
		const caret = window.getSelection().getRangeAt(0);
		const rect  = caret.getClientRects();
		//console.log(rect[0])
		if (typeof rect[0] === 'undefined') return null;

		const doc = document.documentElement;
		const scrollLeft = (window.pageXOffset || doc.scrollLeft) - (doc.clientLeft || 0);
		const scrollTop = (window.pageYOffset || doc.scrollTop)  - (doc.clientTop || 0);

		return {
			top: (rect[0].top + scrollTop),
			bottom: (rect[0].bottom + scrollTop),
			left: (rect[0].left + scrollLeft),
			right: (rect[0].rigth + scrollLeft),
			height: (rect[0].height),
			width: (rect[0].width),
			}
	}

	applyStyle(tag) {
		const { core, nid, pid, parStore, editable } = this.props
		const paragraph = core.nodes[nid].paragraphs[pid]
		const [pos, length] = this.getCaretPos()

		if (!editable) return

		const lid = core.uuid('l')

		core.op({
			realm: 'node',
			op: 'rangeInsert',
			nid: nid,
			pid: pid,
			uuid: core.unique_id(),
			pos: pos+length,
			length: -length,
			type: tag,
			uid: core.user.uid,
			lid: lid,
			prev_mod_uuid: paragraph.last_mod_uuid
		})

		this.props.postRenderTask(pid, lid, () => {
			parStore(pid, 'DOM').current.focus()
			parStore(pid, 'setCaretPos')(pos+length, -length)
		})

	}

	changeParStyle(style) {
		const { core, nid, pid, parStore } = this.props

		const paragraph = core.nodes[nid].paragraphs[pid]
		const [pos, length] = this.getCaretPos()
		const lid = core.uuid('l')

		core.op({
			op:            'parType',
			realm:         'node',
			nid:           nid,
			pid:           pid,
			val:           style,
			old:           paragraph.type,
			uid:           core.user.uid,
			prev_mod_uuid: paragraph.last_mod_uuid,
			lid:           lid,
		})

		this.props.postRenderTask(pid, lid, () => {
			parStore(pid, 'DOM').current.focus()
			parStore(pid, 'setCaretPos')(pos+length, -length)
		})

	}


	indent() {
		const { core, nid, pid, editable } = this.props
		if (!editable) return

		const node = core.nodes[nid]
		const paragraph = node.paragraphs[pid]

		var match
		const [type, plevel] = paragraph.type.split('-')

		if ((type === 'ul') || (type === 'ol')) {
			const level = parseInt(plevel || 1)
			if (level < 5) {
				const newType = type + '-' + (level+1)
				this.changeParStyle(newType)
			}
		}
		else if (match = /^h([0-9]+)$/i.exec(type)) {
			const level = parseInt(match[1])
			if ((level) && (level<3)) {
				const newType = 'h' + (level+1)
				this.changeParStyle(newType)
			}
		}
	}

	outdent() {
		const { core, nid, pid, editable } = this.props
		if (!editable) return

		const node = core.nodes[nid]
		const paragraph = node.paragraphs[pid]

		var match
		const [type, plevel] = paragraph.type.split('-')

		if ((type === 'ul') || (type === 'ol')) {
			const level = parseInt(plevel || 1)
			if (level >1) {
				const newType = type + '-' + (level-1)
				this.changeParStyle(newType)
			}
		}
		else if (match = /^h([0-9]+)$/i.exec(type)) {
			const level = parseInt(match[1])
			if ((level) && (level>1)) {
				const newType = 'h' + (level-1)
				this.changeParStyle(newType)
			}
		}

	}

	onKeyDown(e) {
		const { core, nid, pid, parStore, editable } = this.props
		const paragraph = core.nodes[nid].paragraphs[pid]

		const DOM                          = this.domRef.current.childNodes[0]

		if (!editable) return false

		const [pos, length] = this.getCaretPos()

		switch(e.keyCode) {

			case 8:	{	// backspace
				var   nof   = 1

				if (e.ctrlKey) {
					nof = Math.max(Math.min(pos, pos - paragraph.txt.lastIndexOf(' ', pos-2) - 1), 1);
				}
				else if (pos<=0) {

					const parIndex = core.nodes[nid].paragraphList.findIndex( (e) => e === pid )

					if (parIndex>=1) {

						e.stopPropagation(true)
						e.preventDefault(true)

						const pid2 = core.nodes[nid].paragraphList[parIndex-1]
						const par2 = core.nodes[nid].paragraphs[pid2]
						const old_par_length = par2.txt.length;

						core.op({
							realm: 'node',
							op: 'parMerge',
							pos: old_par_length,
							nid: nid,
							pid: pid2,
							pid2: pid,
							type2: paragraph.type,
							uid: core.user.uid,
							lid: core.uuid('l'),
							prev_mod_uuid: par2.last_mod_uuid,
							prev_mod_uuid2: paragraph.last_mod_uuid,
						})

						parStore(pid2, 'DOM').current.focus()
						parStore(pid2, 'setCaretPos')(old_par_length)

					}
					return false;

				}

				if (length<0) {
					nof = -length
				}

				this.preop = [{
					realm: 'node',
					op: 'charDelete',
					nid: nid,
					pid: pid,
					str: paragraph.txt.substr(pos-nof, nof),
					pos: pos-nof,
					uid: core.user.uid,
					lid: core.uuid('l'),
					prev_mod_uuid: paragraph.last_mod_uuid,
				}]

				return false
			}

			case 46:	{	// delete
				var dpos  = pos+1
				var nof = 1

				if (length<0) {
					nof = -length
					dpos += length
				}
				else {

					if (e.ctrlKey) {
						if (paragraph.txt.indexOf(' ', dpos) === -1) nof = paragraph.txt.length - dpos + 2
						else nof = Math.min(paragraph.txt.length - dpos, paragraph.txt.indexOf(' ', dpos) - dpos + 2);
					}
					else if (dpos === paragraph.txt.length + 1) {

						const parIndex = core.nodes[nid].paragraphList.findIndex( (e) => e === pid )

						if (parIndex < core.nodes[nid].paragraphList.length - 1) {

							e.stopPropagation(true)
							e.preventDefault(true)

							const pid2 = core.nodes[nid].paragraphList[parIndex + 1]
							const par2 = core.nodes[nid].paragraphs[pid2]
							const old_par_length = paragraph.txt.length;

							core.op({
								realm: 'node',
								op: 'parMerge',
								pos: old_par_length,
								nid: nid,
								pid: pid,
								pid2: pid2,
								type2: par2.type,
								uid: core.user.uid,
								lid: core.uuid('l'),
								prev_mod_uuid: paragraph.last_mod_uuid,
								prev_mod_uuid2: par2.last_mod_uuid,

							})

							parStore(pid, 'DOM').current.focus()
							parStore(pid, 'setCaretPos')(old_par_length)

						}

						return false;
					}

				}

				this.preop = [{
					realm: 'node',
					op: 'charDelete',
					nid: nid,
					pid: pid,
					str: paragraph.txt.substr(dpos -1, nof),
					pos: dpos - 1,
					uid: core.user.uid,
					lid: core.uuid('l'),
					prev_mod_uuid: paragraph.last_mod_uuid,
				}]

				return false
			}

			case 13: { // Enter
				const pid2  = core.uuid('p')
				const type2 = (((paragraph.type === 'h1') || (paragraph.type === 'h2') || (paragraph.type === 'h3')) && (pos === paragraph.txt.length)) ? 'p' : paragraph.type
				var uuid   	= paragraph.last_mod_uuid
				var lid     = core.uuid('l')

				e.stopPropagation(true)
				e.preventDefault(true)

				if (length < 0) {

					core.op({
						realm: 'node',
						op: 'charDelete',
						nid: nid,
						pid: pid,
						str: paragraph.txt.substr(pos+length, -length),
						pos: pos+length,
						uid: core.user.uid,
						lid: lid,
						prev_mod_uuid: paragraph.last_mod_uuid,
					})

					uuid = lid
					lid  = core.uuid('l')
				}

				core.op({
					realm: 'node',
					op: 'parSplit',
					nid: nid,
					pid: pid,
					pos: pos+length,
					pid2: pid2,
					type2: type2,
					prev_mod_uuid: uuid,
					uid: core.user.uid,
					lid: lid,
				})

				this.props.postRenderTask(pid2, lid, () => {
					parStore(pid2, 'DOM').current.focus()
					parStore(pid2, 'setCaretPos')(0)
				})

			} break

			// Arrow Keys / Cursor Keys
			case 39: {
				if (pos + length === paragraph.txt.length) {

					const parIndex = core.nodes[nid].paragraphList.findIndex( (e) => e === pid )

					if (parIndex+1 < core.nodes[nid].paragraphList.length) {
						const pid2 = core.nodes[nid].paragraphList[parIndex + 1]
						parStore(pid2, 'DOM').current.focus()
						parStore(pid2, 'setCaretPos')(0)
						e.stopPropagation(true)
						e.preventDefault(true)
					}

				}

			} break

			case 37: { // left

				if (pos + length === 0) {

					const parIndex = core.nodes[nid].paragraphList.findIndex( (e) => e === pid )

					if ((parIndex > 0) && (length === 0)) {
						const pid2 = core.nodes[nid].paragraphList[parIndex -1]
						parStore(pid2, 'DOM').current.focus()
						parStore(pid2, 'setCaretPos')(core.nodes[nid].paragraphs[pid2].txt.length)
						e.stopPropagation(true)
						e.preventDefault(true)
					}

				}
			} break

			case 38: {
				const nextPos         = paragraph.txt.length === 0 ? null : this.caretScreenPos().top - this.caretScreenPos().height * .7
				const scrollTop       = paragraph.txt.length === 0 ? null : (window.pageYOffset || document.documentElement.scrollTop)  - (document.documentElement.clientTop || 0);
				const paragraphTop    = paragraph.txt.length === 0 ? null : DOM.getClientRects()[0].top + scrollTop

				if ((nextPos === null) || (nextPos <= paragraphTop)) {

					const parIndex = core.nodes[nid].paragraphList.findIndex( (e) => e === pid )

					if (parIndex > 0) {
						const pid2 = core.nodes[nid].paragraphList[parIndex -1]
						parStore(pid2, 'DOM').current.focus()
						parStore(pid2, 'setCaretPos')(core.nodes[nid].paragraphs[pid2].txt.length)
						e.stopPropagation(true)
						e.preventDefault(true)
					}

				}
			} break

			case 40: {
				const nextPos         = paragraph.txt.length === 0 ? null : this.caretScreenPos().bottom + this.caretScreenPos().height * .7
				const scrollTop       = paragraph.txt.length === 0 ? null : (window.pageYOffset || document.documentElement.scrollTop)  - (document.documentElement.clientTop || 0);
				const paragraphBottom = paragraph.txt.length === 0 ? null : DOM.getClientRects()[0].bottom + scrollTop
				if ((nextPos === null) || (nextPos >= paragraphBottom)) {

					const parIndex = core.nodes[nid].paragraphList.findIndex( (e) => e === pid )

					if (parIndex+1 < core.nodes[nid].paragraphList.length) {
						const pid2 = core.nodes[nid].paragraphList[parIndex + 1]
						parStore(pid2, 'DOM').current.focus()
						parStore(pid2, 'setCaretPos')(0)
						e.stopPropagation(true)
						e.preventDefault(true)
					}

				}
			} break

			// Hotkeys
			case 73: // (Strg-i)
				if (e.ctrlKey) {
					console.log('set i')
					if (length<0) this.applyStyle.bind(this)('i')
					e.preventDefault();
				}
				break;

			case 66: // (Strg-b)
				if (e.ctrlKey) {
					if (length<0) this.applyStyle('strong')
					e.preventDefault();
				}
				break

			case 85: // (Strg-i))
				if (e.ctrlKey) {
					if (length<0) this.applyStyle('u')
					e.preventDefault();
				}
				break

			case 69: // (Strg-e))
				if (e.ctrlKey) {
					if (length<0) this.applyStyle('s')
					e.preventDefault();
				}
				break

			case 9:
				if (e.shiftKey) {
					this.outdent()
					e.preventDefault();
				}
				else {
					this.indent()
					e.preventDefault();
				}
				break

			case 76: // l für strg-l -> link
				break;

			case 67: // c für strg-c -> annotation; strg-shift-c -> comment
				break;

			default:
			  // console.log('Key: ', e.keyCode)
		}
	}

	onKeyPress(e) {
		const { core, nid, pid } = this.props
		const paragraph     = core.nodes[nid].paragraphs[pid]
		const [pos, length] = this.getCaretPos()

		if (e.which) {

			if (length>0) {
				console.error('length invalid: ', length)
				return false
			}

			this.preop = []
			const lid  = core.uuid('l')
			var uuid   = paragraph.last_mod_uuid

			if (length<0) {
				this.preop.push({
					realm: 'node',
					op: 'charDelete',
					nid: nid,
					pid: pid,
					str: paragraph.txt.substr(pos+length, -length),
					pos: pos+length,
					uid: core.user.uid,
					lid: lid,
					prev_mod_uuid: uuid,
				})

				uuid = lid
			}

			this.preop.push({
				realm: 'node',
				op: 'charInsert',
				nid: nid,
				pid: pid,
				str: String.fromCharCode(e.which),
				pos: pos+length,
				uid: core.user.uid,
				lid: core.uuid('l'),
				prev_mod_uuid: uuid,
			})
		}
	}

	onInput(e) {
		const { core } = this.props

		if (this.preop.length) {
			this.preop.map( op => core.op(op) )
			this.preop = [];
		}
	}

	onCut(e) {
		const { core, nid, pid, parStore, editable } = this.props
		const paragraph     = core.nodes[nid].paragraphs[pid]
		const [pos, length]   = this.getCaretPos()

		if (!editable) return

		e.preventDefault();

		const txt          = paragraph.txt.substr(pos+length, -length)

		e.clipboardData.setData('text/plain', txt);
		// e.clipboardData.setData('text/plain', txt); // set html in a way that can be read by POE?

		core.op({
			realm: 'node',
			op: 'charDelete',
			nid: nid,
			pid: pid,
			str: txt,
			pos: pos + length,
			uid: core.user.uid,
			lid: core.uuid('l'),
			prev_mod_uuid: paragraph.last_mod_uuid,
		})

		parStore(pid, 'DOM').current.focus()
		parStore(pid, 'setCaretPos')(pos+length)
	}

	onPaste(e) {
		const { core, nid, pid, parStore, editable } = this.props
		const paragraph     = core.nodes[nid].paragraphs[pid]
		var [pos, length]   = this.getCaretPos()
		var uuid = paragraph.last_mod_uuid
		var lid  = core.uuid('l')
		var pastePid = pid
		var lastLid = false

		e.stopPropagation(true)
		e.preventDefault(true)

		if (!editable) return false

		const pastedText = e.clipboardData.getData('text/plain') || e.clipboardData.getData('text/html') || prompt('Please paste your content here')
		const pars       = pastedText.split("\n")

		// remove marked text
		if (length<0) {
			core.op({
				realm: 'node',
				op: 'charDelete',
				nid: nid,
				pid: pid,
				str: paragraph.txt.substr(pos+length, -length),
				pos: pos+length,
				uid: core.user.uid,
				lid: lid,
				prev_mod_uuid: uuid,
			})
			uuid = lid
			lid  = core.uuid('l')
			pos += length
		}

		// paste content
		if (e.clipboardData.getData('text/html')) console.log(e.clipboardData.getData('text/html'))

		while(pars.length) {
			const par = pars.shift().trim();

			core.op({
				realm: 'node',
				op: 'charInsert',
				nid: nid,
				pid: pastePid,
				str: par,
				pos: pos,
				uid: core.user.uid,
				lid: lid,
				prev_mod_uuid: uuid,
			});

			pos     += par.length
			uuid     = lid
			lastLid  = lid
			lid      = core.uuid('l')

			if (pars.length) {
				const pid2 = core.uuid('p');
				core.op({
					realm: 'node',
					op: 'parSplit',
					nid: nid,
					pid: pastePid,
					pos: pos,
					pid2: pid2,
					type2: core.nodes[nid].paragraphs[pastePid].type,
					uid: core.user.uid,
					lid: lid,
					prev_mod_uuid: uuid,
				});

				pastePid = pid2
				uuid     = lid
				lid      = core.uuid('l')
				pos = 0
			}
		}

		this.props.postRenderTask(pastePid, lastLid, () => {
			parStore(pastePid, 'DOM').current.focus()
			parStore(pastePid, 'setCaretPos')(pos)
		})

		return false
	}

	winMouseup(e) {
		const { core, pid, nodeComponentId } = this.props

		if ((e.target.id) && (e.target.id === 'suspended-edges-of-' + pid)) {
			this.gotoSuspended()
			this.refreshOverlays(true)
		}
		else {
			if ((core.caretX[nodeComponentId]) && (core.caretX[nodeComponentId].pid === pid)) {
				this.updateCaretX()
			}
		}
	}

	onMouseUp(e) {
		const { core, nid, pid, mode, mark, nodeComponentId } = this.props

		const node = core.nodes[nid]
		const paragraph = node.paragraphs[pid]

		if (mode === 'marker') {
			const edge   = core.edgeById(mark.eid)

			if (edge.locked_uid === core.user.uid) {

				this.updateCaretX()
				const dir    = (edge.src.nid === nid) ? 'src' : 'dst'
				const pos    = core.caretX[nodeComponentId].pos
				const length = core.caretX[nodeComponentId].length
				const uid    = core.user.uid

				const prevNode = core.nodes[edge[dir].nid]
				const prevParagraph = prevNode.paragraphs[edge[dir].pid]


				const mods = {}

				mods[dir]  = {
					nid: nid,
					pid: pid,
					beg: pos+length,
					end: pos,
					prev_mod_uuid: paragraph.last_mod_uuid,
				}

				const prev = {}

				prev[dir] = {
					nid: edge[dir].nid,
					pid: edge[dir].pid,
					beg: edge[dir].beg,
					end: edge[dir].end,
					prev_mod_uuid: prevParagraph ? prevParagraph.last_mod_uuid : null,
				}

				core.op({
					op: 'edgeUpdate',
					uid: uid,
					realm: 'edge',
					eid: edge.eid,
					prev_mod_uuid: edge.last_mod_uuid,
					mods: mods,
					prev: prev,
					lid: core.uuid('l'),
				})

				this.setCaretPos(pos)

			}
		}
		else {
			// console.log(e)
		}
	}

	gotoSuspended() {
		const { core, pid, nodeComponentId } = this.props

		const isActive = (core.caretX[nodeComponentId]) && (core.caretX[nodeComponentId].pid === pid) && (core.caretX[nodeComponentId].pos === null) && (core.caretX[nodeComponentId].length === 0)

		if (isActive) {
			this.updateCaretX()
		}
		else {
			this.updateCaretX({setNull: true})
		}
	}

	render() {
		const { core, nid, pid, editable } = this.props
		const node = core.nodes[nid]
		const paragraph = node.paragraphs[pid]
		//const paragraphLevelEdges = []
		//var suspendedEdges = null

		// Subscribe to Updates
		var dummy = paragraph.txt + paragraph.last_mod_uuid
															+ paragraph.t_updated
		                          + paragraph.edgeMarks.map( (m) => {
																	// if ((m.local.beg === null) && (m.local.end === null)) paragraphLevelEdges.push(m)
																	return m.beg + '-' + m.end + m.edge.uuid + '/' + m.edge.deleted
																}).join('-')
		                        //+ paragraph.edgeMarks( (m) => { console.log(m) } ).join('-')

		// But make sure they are meaningless
		if (dummy) dummy = ''

		const className = ['dp-shell']

		/*
		if (paragraphLevelEdges.length) {
			const css    = this.screenP && this.anchorRect ? {left: ((this.screenP.left - this.anchorRect.left) - 32)+'px'} : null
			const isActive = (core.caretX[nodeComponentId]) && (core.caretX[nodeComponentId].pid == pid) && (core.caretX[nodeComponentId].pos === null) && (core.caretX[nodeComponentId].length == 0)
			suspendedEdges = <span
													title={'This paragraph contains ' + paragraphLevelEdges.length + ' suspended overlays.'}
													className={"paragraph-has-suspended-edges fa fa-info-circle" + (isActive ? ' active' : '')}
													id={'suspended-edges-of-' + pid}
													style={css}
												/>
		}
		*/

		return <div className={className.join(' ')} ref={this.domRef}>
			<div
				className="dp-container"
				dangerouslySetInnerHTML={{__html:dummy}}
				tabIndex={-1}
				onInput={    this.onInput.bind(this)      }
				onKeyPress={ this.onKeyPress.bind(this)   }
				onKeyDown={  this.onKeyDown.bind(this)    }
				onKeyUp={    this.updateCaretX.bind(this) }
				onFocus={    this.updateCaretX.bind(this) }
				onBlur={     this.onBlur.bind(this)       }
				onClick={    this.updateCaretX.bind(this) }
				onCut={      this.onCut.bind(this)        }
				onPaste={    this.onPaste.bind(this)      }
				onMouseUp={  this.onMouseUp.bind(this)    }
				contentEditable={ editable }
				spellCheck={ core.ui.param.spellchecker }
				datapid={    pid                          }
				></div>
				<div
					className="dp-overlay-canvas"
					dangerouslySetInnerHTML={{__html:dummy}}
				></div>
			</div>

	}

}

export default withStore(observer(DirectParagraph))
