import React from "react";
import { observer, inject } from "mobx-react";
import { withStore } from '../../utility/Core'
import { HashRouter, Switch, Route, Link, withRouter } from 'react-router-dom';

import PrettyError from "../PrettyError"

import Graph from "./Graph"

class ForceGraphInner extends React.Component {

	constructor(props) {
		super(props)
		this.domRef       = React.createRef();

		this.canvas_name  = props.core.uuid('c')
		this.resize       = this.resize.bind(this)

		this.state = {
			hasError: false,
			error: undefined,
			errorDetails: undefined,
		}
	}

	componentDidCatch(error, info) {
		console.warn('ForceGraph Error:', error, info)
		this.setState({ hasError: true, error: error, errorDetails: info })
	}

	componentDidMount() {
		const { data } = this.props

		const DOM = this.domRef.current
		if (!DOM) return null

		const rect   = DOM.getBoundingClientRect()
		const width  = rect.width
		const height = rect.height

		this.graph = new Graph(data, this.canvas_name, width, height)

		this.graph.click = this.props.connectorClick
		this.graph.doubleClick = this.props.connectorDoubleClick

		this.graph.restart()

		this.setBackgroundedClass()

		window.addEventListener("resize", this.resize)
	}

	componentDidUpdate() {
		const { core, data } = this.props

		// console.log('Update Force Graph', this.graph.nodes, this.props.data.nodes)
		var nofModified = 0

		nofModified += this.graph.updateNodes(data.nodes)
		nofModified += this.graph.updateLinks(data.links)

		this.setBackgroundedClass()

		if (nofModified) this.graph.restart()
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.resize)
	}

	setBackgroundedClass() {
		const { backgrounded } = this.props
		const DOM = this.domRef.current

		if (DOM) {
			var newClasses = DOM.getAttribute('class').replace(' backgrounded', '')
			if (backgrounded) {
				newClasses = newClasses + ' backgrounded'
			}
			DOM.setAttribute('class', newClasses)
		}

	}

	resize() {
		const DOM = this.domRef.current

		if (!DOM) return null

		const rect   = DOM.getBoundingClientRect()

		this.graph.setSize(rect.width, rect.height)
	}

	render() {
		const { core, data, backgrounded } = this.props
		const { hasError, error, errorDetails } = this.state

		if (hasError) {
			return 	<div className="d3-canvas">
								<PrettyError error={error} errorDetails={errorDetails} />
							</div>
		}

		var dummy = data.nodes.map(e => e.nid + e.loaded).join('-').backgrounded
		if (!dummy) dummy = null
		return <div
			id={this.canvas_name}
			className="d3-canvas"
			ref={this.domRef}
			dangerouslySetInnerHTML={{__html:dummy}}
			></div>
	}

}

class ForceGraph extends React.Component {

	constructor(props) {
		super(props)
		this.callConnectClick  = this.callConnectClick.bind(this)
		this.callConnectDoubleClick  = this.callConnectDoubleClick.bind(this)
	}

	callConnectClick(e) {
		// this.props.history.push('/node/'+e.id)
	}

	callConnectDoubleClick(e) {
		this.props.history.push('/node/'+e.id)
	}

	render() {
		const { core, data } = this.props

		return <ForceGraphInner connectorClick={this.callConnectClick} connectorDoubleClick={this.callConnectDoubleClick} {...this.props} />
	}

}

export default withRouter(withStore(observer(ForceGraph)))
