import React from "react";
import { observer, inject } from "mobx-react";
import { withStore } from '../utility/Core'
import { Link, withRouter } from 'react-router-dom';

class NodeViewingUsers extends React.Component {
	render() {
		const { core, nid } = this.props
		const t0            = parseInt(Date.now()/1000)
		const node          = core.nodes[nid]

		if (typeof node === 'undefined') return null

		const viewlist      = Object.entries(core.nodes[nid].viewing).filter(r => (r[0] != core.user.uid) && (r[1] > t0 - 20))

		if (viewlist.length == 0) return null

		const userlist = viewlist.map((r) => {
			const user  = core.userinfo(r[0])
			const style = { }
			style.borderColor = user.color
			return <span className="user" key={r[0]} style={style} title={user.name + ' is also viewing this'}>{user.short ? user.short : '-' }</span>
		})

		return <span className="viewing-users">{userlist}</span>
	}
}

export default withStore(observer(NodeViewingUsers))
