import React from "react";
import { observer, inject } from "mobx-react";
import { withStore } from '../utility/Core'
import Storage from "../utility/Storage";

import Username from './PoeParsedForUI/Username'
import TagSelector from "./TagSelector";

const debounce = require('sugar/function/debounce');
const cancel   = require('sugar/function/cancel');

class TaggerBox extends React.Component {

	constructor(props) {
		super(props)

		const { core } = this.props

		this.domRef    = React.createRef()

		this.storage   = new Storage()

		this.state = {
			currentTagName: '',
			groupSet: [],
			test: 'x',
			groupSelect: {},
			tagStatus: {},
		}

		this.baseGroupSet = [ core.user.defaultGid ]

		if (typeof props.groups !== 'undefined') {
			props.groups.map(g => { if (g.gid != core.user.defaultGid) this.baseGroupSet.push(g.gid) } )
		}

	}

	componentDidMount() {
		const { core } = this.props
		this.selectorReset()
	}

	componentDidUpdate(prevProps, prevState) {
		const { core } = this.props
		const { currentTagName, groupSet } = this.state

		if ((currentTagName) && (currentTagName != prevState.currentTagName)) {
			this.loadByName(currentTagName)
		}
	}

	componentWillUnmount() {
		if (typeof this.storage.ajax !== 'undefined') this.storage.ajax.cancelHandler.cancel('parent component unmounted');
	}

	refreshTagStatus(name = false) {
		const { currentTagName, groupSet } = this.state
		const { core } = this.props
		const tagStatus = {}
		if (!name) name = currentTagName

		groupSet.forEach(gid => {
			tagStatus[gid] = false
		})

		core.tagNodesByName(name).forEach(node => {
			tagStatus[node.groups[0].gid] = node.loaded
		})

		this.setState({ tagStatus })
	}

	loadByName(name) {
		const { groupSet } = this.state
		const { core } = this.props
		const groupSelect = {}
		const tagStatus = {}

		// reset
		groupSet.forEach(gid => {
			groupSelect[gid] = false
			tagStatus[gid] = 'loading'
		})

		this.refreshTagStatus()

		this.storage.query({ action: 'nodelist', filter: [{key: 'type', value: 'tag'}, { key: 'TITLE', value: name}], limit: '0, 25' })
			.then((response) => {

				const remote        = response.data.data

				const remoteMatches = core.bulkInsert(remote.result)

				this.refreshTagStatus()

				this.setState({
					groupSelect,
					loading:false,
				})

			})
			.catch((thrown) => {
				this.refreshTagStatus()
				this.setState({
					groupSelect,
					loading:false,
				})
				console.warn(thrown);
			})
	}

	selectorReset() {
		this.setState({
			currentTagName: '',
			groupSet: this.baseGroupSet,
			groupSelect: {},
		})
	}

	updateDestination(e) {
		this.setState({currentTagName: e.label})
	}

	changeCheckbox(gid, value) {
		const { groupSelect } = this.state
		groupSelect[gid] = value
		this.setState({ groupSelect })
	}

	tagClick(gid, currentTagName) {
		const { groupSelect, tagStatus } = this.state
		const { core } = this.props
		const tagNodes = core.tagNodesByName(currentTagName)
		const tag      = tagNodes.filter(node => node.groups.filter(g => g.gid == gid).length)
		if (tag.length) {
			this.changeCheckbox(gid, !groupSelect[gid])
		}
		else {
			// set node as loading
			tagStatus[gid] = 'loading'
			this.setState({tagStatus})

			// load/create the tag
			this.storage.query({ action: 'setTag', tagGid: gid, tagName: currentTagName })
			.then((response) => {

				const group = core.groupByGid(response.data.data.tagGid)
				const node = {
					nid: response.data.data.tagNid,
					type: 'tag',
					groups: [group],
					title: response.data.data.tagName,
					loaded: 'slim',
				}

				core.insertNode(node)

				this.refreshTagStatus()
				this.changeCheckbox(group.gid, true)
			})
			.catch((thrown) => {
				console.warn(thrown)
				this.refreshTagStatus()
			})
		}
	}

	render() {
		const { core, onSelect, onCancel } = this.props
		const { currentTagName, groupSet, groupSelect, tagStatus } = this.state
		const domRef = this.domRef.current
		const rect = domRef ? domRef.getBoundingClientRect() : { left:0, top:0 }

		// Prepare for empty
		var tagSelector     = <TagSelector
														localNid={ null }
														onSelect={ this.updateDestination.bind(this) }
														onCancel={ () => 	this.setState({ setDestination: false }) }
														offset_top={-rect.top}
														offset_left={-rect.left}
													/>
		var groupSelection  = null
		var buttons         = <div className="taggerBox-buttons">
														<button onClick={() => { if (onCancel) onCancel()  }}>Cancel</button>
													</div>

		// Change the dialog if a tag has been selected
		if (currentTagName) {

			tagSelector = <span onClick={this.selectorReset.bind(this)}><input className="clickable" type="text" readOnly={true} value={currentTagName} /></span>

			const tagNodes = core.tagNodesByName(currentTagName)
			const result   = []

			var groupBox = <div>{groupSet.map((gid) => {

				const group  = core.groupByGid(gid)

				const tag     = tagNodes.filter(node => node.groups.filter(g => g.gid == gid).length)
				const exists  = tag.length
				const allowed = (core.user.permission('create', 'tag', group))
				var   info    = ''
				var groupName = group.name

				var   match = false
				if (match = /^%([0-9]+) and %([0-9]+)$/.exec(groupName)) {
					var uid = match[1]
					if (uid == core.user.uid) uid = match[2]
					groupName = <span>You and <Username uid={uid} /></span>
				}

				if (!exists)  info = ' (create the tag)'
				if (!allowed) info = ' (not allowed)'

				//
				var icon = <span className="fa icon fa-circle-thin" />
				if ((tagStatus[gid] == 'full') || (tagStatus[gid] == 'slim')) {
					icon = <input
						className="icon"
						type="checkbox"
						disabled={!exists}
						checked={this.state.groupSelect[group.gid] ? true : false}
						onChange={(e) => {this.changeCheckbox(group.gid, e.target.checked) }}
					/>
				}
				if (tagStatus[gid] == 'loading') icon = <span className="fa fa-gear fa-spin icon" />
				if (!allowed) icon = <span className="fa fa-ban icon" />

				if (groupSelect[gid]) result.push(tag[0].nid)

				return <div key={gid} className={"tag-in-group non-mark" + (allowed ? ' clickable' : '') + (exists ? ' tag-exists' : ' tag-create') } onClick={() => { if (allowed) this.tagClick.bind(this)(gid, currentTagName) }}>
					{icon} {groupName}
					<span className="info">{info}</span>
				</div>

			})}</div>

			groupSelection = <div className='taggerBox-group-selection'>
				{groupBox}
			</div>

			buttons = <div className="taggerBox-buttons">
				<button onClick={() => { if (typeof onCancel !== 'undefined') onCancel()  }}>Cancel</button>
				<button onClick={() => { if (typeof onSelect !== 'undefined') onSelect(result)  }}>OK</button>
			</div>
		}

		// output
		return <div className="taggerBox" ref={this.domRef} style={{margin:'-20px', padding:'20px'}}>
			<div className="taggerBox-tag-name">
				<span className='fa fa-tag' />{tagSelector}
			</div>
			{groupSelection}
			{buttons}
		</div>
	}

}

export default withStore(observer(TaggerBox))
