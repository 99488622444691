import React from "react";
import ReactDOM from "react-dom";
import { action, observer, inject, Provider } from "mobx-react";
import { withStore } from '../utility/Core'

const dasherize = require('sugar/string/dasherize');

// cf. https://www.youtube.com/watch?v=WGjv-p9jYf0

class Modal extends React.Component {

	constructor() {
		super()
		this.state = {
			hasError: false,
		}
	}

	componentDidCatch(error, info) {
		console.error('ERROR', error, info)
		this.setState({ hasError: true });
	}

	render() {
		if (this.state.hasError) return '<Modal /> error'
		const { core } = this.props;

		if (this.props.isOpen) {
			return <ModalCore config={this.props.config}>{this.props.children}</ModalCore>
		} else {
			return null;
		}
	}
}

class ModalCore extends React.Component {

	config = {
		css: {
			screen: {
				'backgroundColor': 'rgba(100,100,100,.5)',
				'position': 'fixed',
				'left': '0',
				'top': '0',
				'width': '100%',
				'height': '100%',
				'zIndex': '2000',
				'outline': ' none !important'
			},
			content: {
				'display': 'inline-block',
				'backgroundColor': '#fff',
				'position': 'absolute',
				'left': '50%',
				'top': '50%',
				'transform': 'translate(-50%, -50%)',
				'border': ' 1px solid #000',
				'outline': 'none !important',
				'padding': '1em',
			}
		}
	}

	componentWillMount() {
		const css = this.config.css

		let screenStyle = '';
		for(let key in css.screen) {
			screenStyle += dasherize(key) + ':' + css.screen[key] + ';'
		}


		this.modalTarget = document.createElement('div');
		document.body.appendChild(this.modalTarget);

		this.modalTarget.className = 'modal-screen';
		this.modalTarget.setAttribute('style', screenStyle);
		this.modalTarget.tabIndex = '0';
		this.modalTarget.key = '0';

		this.modalTarget.addEventListener('click', (e) => { if (e.target.className == 'modal-screen') this.props.core.closeModal()});
		this.modalTarget.addEventListener('keydown', (e) => { if (e.keyCode == 27) this.props.core.closeModal()});
	}

	componentDidMount() {
/*
		this._render();
		//this.modalTarget.children[0].addEventListener('click', (e) => { e.preventDefault(); e.stopPropagation(); return false });
		this.modalTarget.children[0].focus();
		*/
	}

	componentWillUnmount() {
		document.body.removeChild(this.modalTarget);
	}

	render() {
		const { core } = this.props
		const { position, padding, width, height } = this.props.config;
		var css = this.config.css;

		if (position) {
			css.content['position'] = 'absolute';
			css.content['top']      = position.top + 'px'
			css.content['left']     = position.left + 'px'
			css.content['transform']= null
		}

		//console.log('PADDING', padding)

		if (typeof padding !== 'undefined') css.content.padding = padding;
		//if (typeof width   !== 'undefined') css.content.width   = css.content.width  || width;
		//if (typeof height  !== 'undefined') css.content.height  = css.content.height || height;

		//console.log(css, this.props.children)

		const elm = <Provider core={core}>
			<div class="modal-child" key="modal-child" tabIndex="0" style={css.content}>
				{this.props.children}
			</div>
		</Provider>

		return ReactDOM.createPortal(elm, this.modalTarget)
	}
}

export default withStore(observer(Modal))
