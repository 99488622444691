import React from "react";
import { Link } from 'react-router-dom';

import Storage from "../utility/Storage";

import PoeParsedForUI from './PoeParsedForUI';
import GroupAssociation from './GroupAssociation';
import OperationDetails from './OperationDetails';

import { observer, inject } from "mobx-react";
import { withStore } from '../utility/Core'

class NodeHistory extends React.Component {

	constructor(props) {
    super(props);
		this.storage = new Storage();
    this.state = {
			hasError: false,
			past: [],
			loading: false,
		};
  }

	componentDidCatch(error, info) {
		console.error('ERROR', error, info)
		this.setState({ hasError: true });
	}

	componentWillMount() {
		const { core, nid } = this.props;
	}

	componentDidMount() {
		const { core, nid } = this.props;

		if (!this.state.loading) {
			this.setState({ loading: true })
			this.storage.query({
					'action': 'history',
					'nid': nid,
				})
				.then((response) => {
					if ((response.data.status == 200) && (response.data.data.length>0)) {
						this.setState({past: response.data.data})
						this.setState({ loading: false })
					}
				})
			.catch((e) => {
				console.error(e)
				this.setState({past: false})
				this.setState({ loading: false })
			})
		}
	}

	componentWillUnmount() {
		const { core, nid } = this.props;

	}


	render() {
		const { core, nid } = this.props
		const node = core.nodes[nid]

		if (this.state.hasError) return <div className='error'>{'<DirectNodeHistory/> has an error'}</div>


		if (this.state.past === false) {
			return <div className="">
			<div className='error'>
				<h1><span className='fa fa-exclamation-triangle' /> 404 Not Found</h1>
				Could not load remote node history. Maybe the node has not yet synced?
			</div>
		</div>
		}

		const body = this.state.loading ? <div>... loading</div> : <table className="node-history op-history"><tbody>{
					this.state.past.map( (row, i) => {
							return <OperationDetails elm='table' key={row.lid} op={row} i={null} />
						})
				}</tbody></table>

		return <div className="">
				<h1>{node.title}</h1>
				<h2>Editing History</h2>
				{body}
			</div>
	}

}

export default withStore(observer(NodeHistory))
