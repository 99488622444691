import React from "react"
import { observer } from "mobx-react"
import { withStore } from '../../utility/Core'
import { withRouter } from 'react-router-dom'

import Storage from '../../utility/Storage'
import ModalAttachments from '../ModalAttachments'

class NodeAttachments extends React.Component {

	constructor() {
		super()

		this.state = {
			showDialog: false,
		}

		const storage  = new Storage()
		this.serverUrl = storage.serverUrl
		this.triggerDialog = this.triggerDialog.bind(this)
	}

	triggerDialog() {
		const { core, nid }     = this.props
		const node = core.nodes[nid]
		if (!node.viewing[core.user.uid])
			alert('The node does not seemt to be fully synced yet... Please wait.')
		else
			this.setState({ showDialog:true })
	}

	render() {
		const { core, nid }     = this.props
		const node = core.nodes[nid]

		if (!node) return null
		if (node.deleted) return null


		const attachments = node.attachments.map(f => {

			var status = <span style={{float:'right'}} className='fa fa-question-circle-o' title={'Status is not saved: ' + f.status} />
			if (f.status === 'saved') status = <span style={{float:'right'}} className='fa fa-check-circle-o' title={'Attachment is saved'} />

			if (f.status === 'saved') {
				return <li key={f.fid}><a target="_blank" href={this.serverUrl + '/getFile?fid=' + f.fid}>{f.filename}</a>{status}</li>
			}
			else {
				return <li key={f.fid}>[{f.filename}] {status}</li>
			}
		})

		return <div className="node-attachments">
			<h1 onClick={this.triggerDialog} className="clickable" style={{color:'#fff'}}>{parseInt(node.attachments.length)} { node.attachments.length === 1 ? 'Attachment' : 'Attachments' } <span className="fa fa-edit" title="Add a file attachment to this card" /></h1>
			{ attachments.length ? <ul>{attachments}</ul> : null }
			{ this.state.showDialog ? <ModalAttachments nid={nid} onClose={() => { this.setState({ showDialog:false }) }} /> : null }
		</div>
	}

}

export default withRouter(withStore(observer(NodeAttachments)))
