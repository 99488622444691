import React from "react";
import { observer, inject } from "mobx-react"
import { withStore } from '../utility/Core'
import { Link, withRouter } from 'react-router-dom'

import Username from './PoeParsedForUI/Username'

const truncate = require('sugar/string/truncate');

class PoeParsedForUI extends React.Component {

	render () {
		const { core } = this.props;
		const tag = (this.props.tag) ? (this.props.tag) : 'span';

		let contents   = [];
		let match;
		let lastHit = 0;
		const userfind = /%([0-9a-zA-Z\-]+)/g
		while(match = userfind.exec(this.props.txt)) {

			if (match[0].substr(0, 1) == '%') {
				if ((match.index)) {
					contents.push(this.props.txt.substr(lastHit, match.index - lastHit))
				}

				if (match[1].substr(0,2) == 'n-') {
					contents.push(<NodeLink nid={match[1]} key={match.index} />)
				} else if (match[1].substr(0,2) == 'e-') {
					contents.push(<span key={match.index}>Edge {match[1]}</span>)
				} else if (match[1].substr(0,2) == 'g-') {
					contents.push(<GroupLink key={match.index} gid={match[1].substr(2)} />)
				} else if ((!Number.isNaN(match[1])) && (match[1]>0)) {
					contents.push(<Username key={match.index} uid={match[1]} />)
				} else {
					contents.push(<span style={{color:'red'}} key={match.index} >{match[1]}</span>)
				}
				lastHit = match.index + match[0].length;
			}
		}
		if (lastHit < this.props.txt.length) {
			contents.push(this.props.txt.substr(lastHit, this.props.txt.length - lastHit))
		}

		return React.createElement(tag, this.props.props, contents);
	}

}

class GroupLink extends React.Component {
	render() {
		const { core, gid } = this.props
		if (core.userGroup(gid)) {
			return <Link to={'/filtered/[{"key":"groups","value":'+gid+'}]'}  className="link-to-group clickable"><span>{core.userGroup(gid).name}</span></Link>
		}
		else {
			return <span className="link-to-group invalid">a group you don't know</span>
		}//'
	}
}

class NodeLink extends React.Component {

	constructor(props) {
    super(props)
		const { core } = this.props
		this.componentId = core.componentId('PoeParsedForUI')
	}

	componentWillMount() {
		const { core, nid } = this.props
		if (typeof core.nodes[nid] === 'undefined') core.loadNode(nid, 'slim', this.componentId)
	}

	componentWillUnmount() {
		const { core, nid } = this.props
		core.unloadNode(nid, this.componentId)
	}

	render() {
		const { core, nid } = this.props
		const node = core.nodes[nid];

		let title = nid;
		if ((node) && (node.title)) {
			let t = truncate(node.title, 15)
			if (t == '') t = <em>untitled</em>

			let logo = <span className="fa fa-file-text-o" />
			if (node.type == 'comment') logo = <span className="fa fa-comment-o" />

			title = <span>{logo} {t}</span>
		}

		if ((node.type == 'comment') && (node.rel)) {
			return <Link title={node.title} key={nid} to={'node/' + node.rel + '/comment/' + node.nid} className="link-to-node clickable">{title}</Link>
		}

		return <Link title={node.title} key={nid} to={'node/' + nid} className="link-to-node clickable">{title}</Link>
	}
}

export default withRouter(withStore(observer(PoeParsedForUI)))
