import React from "react";
import { observer, inject } from "mobx-react";
import { withStore } from '../utility/Core'
import { Link, withRouter } from 'react-router-dom';

import Storage from "../utility/Storage";

class Invite extends React.Component {

	constructor(props) {
		super(props)

		this.state = {
			condition: ''
		}

		this.storage = new Storage()
	}

	checkRemote() {
		this.storage.query({
			'action': 'redeemInvite',
			'invite': this.props.invite,
			})
			.then((response) => {
				if (response.data.status == 200) {
					if (response.data.data == 'used') {
						this.setState({condition: 'used'})
					}
					else if (response.data.data == 'unused') {
						this.setState({condition: 'unused'})
					}
				}
			})

	}

	componentDidMount() {
		this.checkRemote.bind(this)()
	}

	componentDidUpdate(prevProps) {
		if (this.props.invite != prevProps.invite) {
			this.setState({condition: ''})
			this.checkRemote.bind(this)()
		}
	}

	render() {
		const { core, history } = this.props;

		function doLogin(e) {
			e.preventDefault();

			this.storage.query({
				'action': 'redeemInvite',
				'invite': this.props.invite,
				'username': document.getElementById("loginFormUsername").value,
				'password': document.getElementById("loginFormUserpass").value,
				'password_repeat': document.getElementById("loginFormUserpassRepeat").value,
				})
				.then(function(response) {
					if (response.data.status == 200) {
						console.log(response.data.data)
						core.login(response.data.data)
						history.push('/')
					}
					else {
						console.log(response)
						const msg = (typeof response.data.data == 'object') ? JSON.stringify(response.data.data) : response.data.data
						alert(msg)
					}
				})
				.catch(function(thrown) {
					alert("Could not log you in :/\nServer/Connection problem...");
					console.log(thrown);
				})
		}

		if (this.state.condition == 'used') {
			return <div id="login-form" className="tight">
			<h1>Invalid Invite</h1>
			<p>The invite you have tried to use seems to be invalid or used before. If you already have a user account, please simply <a href="/">log in</a>.</p>
			</div>
		}

		return <div id="login-form" className="tight">
			<h1>One-Time Login</h1>
			<form action="/">
				<div className="form-element"><label for="loginFormUsername">email</label>&nbsp;<input id="loginFormUsername" type="text" /></div>
				<div className="form-element"><label for="loginFormUserpass">password</label>&nbsp;<input id="loginFormUserpass" type="password" /></div>
				<div className="form-element"><label for="loginFormUserpassRepeat">(repeat)</label>&nbsp;<input id="loginFormUserpassRepeat" type="password" /></div>
				<div className="form-element"><label /><button type="submit" onClick={doLogin.bind(this)}>Submit</button></div>
			</form>
		</div>
	}
}


export default withRouter(withStore(observer(Invite)))
