const { makeObservable, observable, computed, action } = require("mobx")
const { StaticState } = require("./StaticState.js")

export class Node extends StaticState {

  //@computed get
  get nofDirty() {
    return this.paragraphList.reduce( (a, p) => {
      if (this.paragraphs[p].last_mod_uuid !== this.paragraphs[p].last_sync_uuid) return a+1 ;
      return a
    }, 0)
  }

  txt() {
    return this.paragraphList.map(pid => (typeof this.paragraphs[pid] === 'undefined') ? '' : this.paragraphs[pid].txt).join('')
  }

  getEdgeLocal(edge) {
    if (edge.src.nid === this.nid) return edge.src; else return edge.dst
  }

  getEdgeRemote(edge) {
    if (edge.src.nid === this.nid) return edge.dst; else return edge.src
  }

  edgeDirection(edge) {
    if (edge.src.nid === this.nid) return 'outgoing'; else return 'incoming'
  }

  edgeIsIncoming(edge) {
    if (this.edgeDirection(edge) === 'incoming') return true
    return false
  }

  edgeIsOutgoing(edge) {
    if (this.edgeDirection(edge) === 'outgoing') return true
    return false
  }

  hasGroup(gid) {
    const index = this.groups.findIndex( (g) => g.gid === gid )
    return index >= 0
  }

  //@action
  changeGroups(group, op) {
    const index = this.groups.findIndex( (g) => g.gid === group.gid );

    if (op.op === 'groupAdd') {
      if (this.groups.findIndex((g) => {return g.gid === group.gid}) === -1) this.groups.push(group)
      this.last_mod_uuid = op.lid
    }
    else if (op.op === 'groupDel') {
      if (index <0) return false

      this.groups.splice(index, 1)
      this.last_mod_uuid = op.lid
    }
  }

  //action
  setAttr(name, value) {
    this[name] = value
  }

  exportable() {
    const paragraphs = {}
    for(let key in this.paragraphs) {
      paragraphs[key] = this.paragraphs[key].exportable()
    }

    return {
      nid: this.nid,
      t_seen: this.t_seen,
      uid: this.uid,
      title: this.title,
      type: this.type,
      t_created: this.t_created,
      t_updated: this.t_updated,
      last_mod_uuid: this.last_mod_uuid,
      rel      : this.rel,
      paragraphList: this.paragraphList,
      paragraphs: paragraphs,
      attachments: this.attachments,
      groups: this.groups,
      edges: this.edges,
      locked_uid: this.locked_uid,
      locked_t: this.locked_t,
      loaded: this.loaded,
      deleted: this.deleted,
    }
  }


	constructor(obj) {

		super(obj)

    this.loaded         = 'not'
		this.nid            = obj.nid
		this.t_seen         = obj.t_seen
		this.uid 	          = obj.uid
		this.title 	        = obj.title
		this.type 	        = obj.type
		this.t_created 	    = obj.t_created
		this.t_updated      = obj.t_updated
		this.t_seen         = obj.t_seen
		this.last_mod_uuid  = obj.last_mod_uuid
		this.last_sync_uuid = obj.last_sync_uuid
		this.rel            = obj.rel
		this.locked_uid     = obj.locked_uid
		this.locked_t       = obj.locked_t
		this.deleted        = obj.deleted
    this.spellcheck     = false
    this.paragraphList  = []
    this.paragraphs     = {}

    this.attachments    = []

		if (typeof obj.viewing !== 'undefined')       this.viewing        = obj.viewing; else this.viewing = {}
		if (typeof obj.paragraphList !== 'undefined') this.paragraphList  = obj.paragraphList; else this.paragraphList = []
    if (typeof obj.attachments !== 'undefined') this.attachments      = obj.attachments

		this.groups         = obj.groups ? obj.groups : []

    makeObservable(this, {
      loaded: observable,
      groups: observable,
      spellcheck: observable,
      title: observable,

      t_seen: observable,
      t_created: observable,
      t_updated: observable,

      last_mod_uuid: observable,
      last_sync_uuid: observable,
      paragraphList: observable,
      attachments: observable,

      rel: observable,
      deleted: observable,

      locked_uid: observable,
      locked_t: observable,
      viewing: observable,

      nofDirty: computed,
      changeGroups: action,
      setAttr: action,

    })
	}

}
