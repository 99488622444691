const { observable, computed, action } = require("mobx")

export class Mark {

	constructor (uuid, beg, end, type, vanishable, delta) {
		//{this} = {uuid, beg, end, html, vanishable, delta};
		this.uuid = uuid;
		this.beg = beg;
		this.end = end;
		this.type = type;								      //    false for overlays, tagname for html
		this.vanishable = vanishable;    			//    own annotations/comments and tags are vanishable, other people's comments and edge-marks are not
		this.delta = delta;                   //    nid for edges/annotations ; additional info for htmls
	}
}
