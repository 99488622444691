import React from "react";
import { Link } from 'react-router-dom';
import { observer, inject } from "mobx-react";
import { withStore } from '../utility/Core'

import UserShort from "./UserShort"
import DateString from "./DateString"

class OperationDetails extends React.Component {

	render() {

		const { op, core } = this.props;

		const elm = (typeof this.props.elm !== 'undefined') ? this.props.elm : 'span'

		let str = './.';
		if (typeof op.str !== 'undefined') str = (op.str.length > 36) ? op.str.substr(0, 18) + ' ... ' + op.str.substr(-18)  : op.str

		let status = op.status
		if (status == 'done') status = <span className="fa sync-done fa-check-circle-o" />
		if (status == 'syncing') status = <span className="fa sync-syncing fa-cog fa-spin" />
		if (status == 'synced') status = <span className="fa sync-synced fa-check-circle" />
		if (status == 'deferred') status = <span className="fa sync-deferred fa-pause-circle clickable" onClick={() => { op.forced = true; op.status = 'done' }} />
		if (status == 'failed') status = <span className="fa sync-failed fa-exclamation-circle" />
		if ((status == 'synced') && (typeof op.syncDetails !== 'undefined') && (typeof op.syncDetails.details !== 'undefined') && (op.syncDetails.details.indexOf(' ')>-0)) status = <span className="fa sync-synced fa-info-circle" />

		const titleDefaultSynced = (op.status == 'synced') ? 'synced at ' + core.fulldate(op.t_sync) : op.status

		const cells = {
			handle:
				{ title: core.fulldate(op.t_local) + ' [' + op.lid + ']',  txt: (this.props.i === null) ? <span>{core.mindate(op.t_local)}</span> : this.props.i },
			user:
				{ title: 'fullname',            txt: <UserShort uid={op.uid} /> },
			op:
				{ title: 'Operation: ' + op.op, txt: op.op },
			details:
				{ title: 'Details', txt: op.str },
			sync:
				{ title: (typeof op.syncDetails !== 'undefined') ? op.syncDetails.status + "\n" + op.syncDetails.details : titleDefaultSynced, txt: status }
		}

		switch (op.op) {

			case 'charDelete':
				cells.details.txt = <span className="deletion">{op.str}</span>
				break;

			case 'parSplit':
				cells.details.txt = <span><span className="fa fa-paragraph" /> <span className="explain">Paragraph Break</span></span>
				break;

			case 'parSplit':
				cells.details.txt = <span><span className="fa fa-compress" /> <span className="explain">Paragraph Break</span></span>
				break;

			case 'parType':
				cells.details.txt = <span className="explain">Set paragraph style to {op.val} (was: {op.old})</span>
				break;

			case 'rangeInsert':
				cells.details.txt = <span className="explain">Applied Formatting <strong>{op.type}</strong></span>
				break;

			case 'rangeDelete':
				cells.details.txt = <span className="explain">Removed Formatting <strong>{op.type}</strong></span>
				break;

			case 'edgeCreate':
				if (op.type == 'like')             cells.details.txt = <span className="explain">Liked { op.src.pid ? 'a passage on this card' : 'this card' }</span>
				else if (op.type == 'annotation')  cells.details.txt = <span className="explain">Annotated this card</span>
				else if (op.type == 'edge')        cells.details.txt = <span className="explain">Added a link on this card</span>
				break;

			case 'groupAdd':
				cells.details.txt = <span className="explain">Shared this card to the group <em>{ (typeof core.userGroup(op.gid) !== 'undefined') ? core.userGroup(op.gid).name : '#' + core.userGroup(op.gid).gid }</em></span>
				break;

			case 'groupDel':
				const group = core.userGroup(op.gid)
				cells.details.txt = <span className="explain">Pulled this card from the group <em>{ (typeof core.userGroup(op.gid) !== 'undefined') ? core.userGroup(op.gid).name : '#' + core.userGroup(op.gid).gid }</em></span>
				break;

		}

		if (elm == 'span') {
			return <div className="tr" key={op.lid}>
			{ ['handle', 'user', 'op', 'details', 'sync'].map( (key) => {
				const cell = cells[key]
				return <span className={'cell-' + key} title={cell.title} key={'cell-' + key}>{cell.txt}</span>
			})}
			</div>
		}
		else if (elm == 'table') {
			return <tr className="tr" key={op.lid}>
			{ ['handle', 'user', 'op', 'details', 'sync'].map( (key) => {
				const cell = cells[key]
				return <td className={'cell-' + key} title={cell.title} key={'cell-' + key}>{cell.txt}</td>
			})}
			</tr>
		}
		else {
			return <div>--{elm}--</div>
		}
	}

}

export default withStore(observer(OperationDetails))
