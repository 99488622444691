import React from "react";
import { observer } from "mobx-react";
import { withStore } from '../utility/Core'
import { Link, withRouter } from 'react-router-dom';

import UserHomeButton from "./UserButton";
import Sync from "./Sync";
import FileSync from "./FileSync";

class Header extends React.Component {

	render() {
		const { core } = this.props

		function newNode() {
			const title = window.prompt('Please enter a title for the new card')
			const nid = core.uuid('n')
			const pid = core.uuid('p')
			if (title) {
				core.op({
					realm: 'node',
					op: 'nodeCreate',
					nid: nid,
					pid: pid,
					title: title,
					type: 'card',
					locked_uid: core.user.uid,
					locked_t: parseInt(Date.now()/1000),
				})

				this.props.history.push('/node/' + nid)
			}
		}
		//<div id="header-button-nodelist" class="header-button clickable"></div>

		// 			<div id="header-button-wall" title="Event Wall: See a list of recent edits..." class="header-button clickable" onClick={() => { this.props.history.push('/wall') }}><span class="fa fa-clock-o"></span></div>

		return (
		<div id="header">
			<NodeListButton core={core} />
			<div id="header-button-wall" title="Event Wall: See a list of recent actions..." className="header-button clickable" onClick={() => { this.props.history.push('/wall') }}><span className="fa fa-clock-o"></span></div>
			<div id="header-button-new-node" title="Create a new card..." className="header-button clickable" onClick={newNode.bind(this)}><span className="fa fa-plus"></span></div>
			<FileSync />
			<div id="header-button-sync" className="header-button clickable"><Sync /></div>
			<div id="header-button-user" title="User settings and logout..." className="header-button clickable"><UserHomeButton /></div>
		</div>
		);
	}

}


class NodeListButton extends React.Component {
	render() {
		const { core } = this.props;

		const recentNodes = (core.nodeHistory.recentNodes.length === 0) ? null : <div id="node-history-dropdown"><ul id="node-history-list">
			 { core.nodeHistory.recentNodes.map( (n) => {
				 return <li key={n.nid}><Link to={'/node/' + n.nid}>{n.title}</Link></li>
			 }).reverse() }
			</ul></div>

		return <div id="header-button-nodelist" title="List of all cards...">
			<Link className="header-button" to="/">
				<span className="fa fa-home"></span>
			</Link>
			{recentNodes}
		</div>
	}
}

export default withRouter(withStore(observer(Header)))
