import React from "react";
import { observer, inject, action, runInAction } from "mobx-react";
import { withStore } from '../../utility/Core'
import { Link, withRouter } from 'react-router-dom';

import UserShort from "../UserShort";
import DateString from "../DateString";
import ShowNode from "../ShowNode";
import DirectNode from "../DirectNode";
import DirectDestination from "../DirectDestination";


const truncate = require('sugar/string/truncate');

class NodeContextLink extends React.Component {

	constructor (props) {
		super(props)
		this.state = {
			predicateNid: undefined,
			editPredicate: false,
			setDestination: false,
		}
	}

	componentWillMount() {
		const { core, eid, localNid, nodeComponentId } = this.props

		const edge = core.edgeById(eid)

		if (!edge) return null

		const localNode  = core.nodes[localNid]
		const dstNid     = localNode.getEdgeRemote(edge).nid

		if (dstNid) {
			const dstNode    = core.nodes[dstNid]
			if (typeof dstNode === 'undefined') core.loadNode(dstNid)
		}

		if (edge.predicate_nid) {
			this.setState({ predicateNid: edge.predicate_nid })
			core.loadNode(edge.predicate_nid)
		}

	}

	componentDidUpdate() {
		const { core, eid, localNid, nodeComponentId } = this.props

		const edge = core.edgeById(eid)

		if (!edge) return null

		const localNode  = core.nodes[localNid]
		const dstNid     = localNode.getEdgeRemote(edge).nid

		if (dstNid) {
			const dstNode    = core.nodes[dstNid]
			if (typeof dstNode === 'undefined') core.loadNode(dstNid)
		}

		if ((edge.predicate_nid) && (typeof core.nodes[edge.predicate_nid] === 'undefined')) {
			this.setState({ predicateNid: edge.predicate_nid })
			core.loadNode(edge.predicate_nid)
		}
	}

	componentWillUnmount() {
		const { core }         = this.props;
		const { predicateNid } = this.state;
		if (!predicateNid) return
		if (!core.nodes[predicateNid]) return
		if (core.nodes[predicateNid].locked_uid == core.user.uid) this.releaseLock(predicateNid)
	}

	navigateToWWW(eid) {
		const { core } = this.props
		const edge  = core.edgeById(eid)
		var win     = window.open(edge.linked_url, '_blank')
		win.focus()
		return false
	}

	activatePredicate() {
		const { predicateNid } = this.state
		this.setState({editPredicate: true})
		this.acquireLock(predicateNid)
	}

	deactivatePredicate() {
		const { predicateNid } = this.state
		this.setState({editPredicate: false})
		this.releaseLock(predicateNid)
	}

	acquireLock(nid) {
		const { core } = this.props
		const node = core.nodes[nid]

		if (!core.user.permission('update', 'node', node)) {
			alert('You lack the necessary permissions to edit this card.')
			return false
		}

		core.requestLock(nid)
		    .then(() => {
					//onSuccess()
					})
		    .catch((e) => {
				  if (e.status == 401) {
						alert('Could not lock the edge predicate for editing. It is already locked by another user.')
					}
					else {
						alert('Could not lock the edge predicate. You may go offline to edit it locally.')
						console.error(e)
					}
				})
	}

	releaseLock(nid) {
		const { core } = this.props;

		core.releaseLock(nid)
		    .then(() => { })
		    .catch((e) => { /* console.error(e) */ })
	}

	setDestination(item) {
		const { core, eid, nodeComponentId }   = this.props
		const edge      = core.edgeById(eid)
		if (!edge) return null

		switch(item.type) {
			case 'url':
				core.op({
					realm: 'edge',
					op:    'edgeUpdate',
					eid:    edge.eid,
					mods: {
						dst: { nid: null, title: null },
						linked_url: item.label,
					},
					prev: {
						dst: edge.dst,
						linked_url: edge.linked_url
					},
					prev_mod_uuid: edge.last_mod_uuid,
					uid: core.user.uid,
					lid: core.uuid('l'),
				})
				break

			case 'new':
				const newNid = core.uuid('n')
				const newPid  = core.uuid('p')

				core.op({
					realm: 'node',
					op: 'nodeCreate',
					nid: newNid,
					pid: newPid,
					title: item.label,
					type: 'card',
					uid: core.user.uid,
					lid: core.uuid('l'),
				})

				core.op({
					realm: 'edge',
					op:    'edgeUpdate',
					prev_mod_uuid: edge.last_mod_uuid,
					eid:    edge.eid,
					mods: {
						dst: { nid: newNid, pid: null, beg: null, end: null, title: item.label }
					},
					prev: {
						dst: edge.dst,
						linked_url: edge.linked_url
					},
					uid: core.user.uid,
					lid: core.uuid('l'),
				})
				break

			case 'local':
			case 'remote':
				const dst = { nid: item.id, title: item.label }
				if (item.context) {
					dst.pid = item.context.pid
					dst.beg = item.context.beg
					dst.end = item.context.beg + item.context.str.length
				}
				core.op({
					realm: 'edge',
					op:    'edgeUpdate',
					eid:    edge.eid,
					mods: {
						dst: dst,
						linked_url: '',
					},
					prev: {
						dst: edge.dst,
						linked_url: edge.linked_url
					},
					prev_mod_uuid: edge.last_mod_uuid,
					uid: core.user.uid,
					lid: core.uuid('l'),
				})
				break

			default:
				console.error('Unknown item type ' + item.type)
		}

	}

	render() {
		const { core, eid, localNid, nodeComponentId }   = this.props
		const { predicateNid, setDestination } = this.state

		const edge      = core.edgeById(eid)

		if (!edge) return null
		if (edge.deleted) return null

		const localNode    = core.nodes[localNid]
		if (typeof localNode === 'undefined') return null


		const dummy      = Object.keys(core.caretX)
		const direction  = localNode.edgeIsIncoming(edge) ? <span title="incoming link from" className="fa fa-arrow-left" /> : <span title="links to" className="fa fa-arrow-right" />

		var predNode   = predicateNid ? <div className='contextual-edge-predicate'><span className="fa fa-ellipsis-v clickable" style={{float:'left', marginLeft:'-6px'}} onClick={this.activatePredicate.bind(this)}></span><ShowNode nid={predicateNid} style='text' /></div> : null

		if (this.state.editPredicate) predNode = <div className='contextual-edge-predicate'><span className="fa fa-ellipsis-v clickable" style={{float:'left', marginLeft:'-6px', color:'red', backgroundColor:'#fee'}} onClick={this.deactivatePredicate.bind(this)}></span><DirectNode nid={predicateNid} parStoreUp={(store) => {this.pars = store}} mode='edit' /></div>

		const editLink   = <Link className="micro-button" to={'/edge/' + edge.eid + '/edit'}>Modify</Link>

		//
		const dstNid     = localNode.getEdgeRemote(edge).nid
		const dstNode    = core.nodes[dstNid]
		var dstTitle     = <span className="fa fa-question" />
		var dstLink      = null

		if (dstNid) {
			if (localNode.getEdgeRemote(edge).title) dstTitle = localNode.getEdgeRemote(edge).title
			if (dstNode && dstNode.title)            dstTitle = dstNode.title

			dstLink        = <Link to={'/node/' + localNode.getEdgeRemote(edge).nid} title={dstTitle}>{direction} <span className="link">{dstTitle}</span></Link>
		}
		else if (edge.linked_url) {
			dstLink        = <a href={edge.linked_url} onClick={(e) => { e.preventDefault(); this.navigateToWWW.bind(this)(edge.eid)}}>{direction} <span className="link">{core.getHost(edge.linked_url)}</span></a>
		}
		else {

			var noDestination = null

			if (setDestination) {
				noDestination = <DirectDestination filter={{type: 'card'}} localNid={localNid} onSelect={ this.setDestination.bind(this) } onCancel={ () => 	this.setState({ setDestination: false }) } />
			}
			else {
				noDestination = <span className="warning-link-incomplete clickable" onClick={() => { this.setState({ setDestination: true }) }}><span className="fa fa-link" /> <span className="warning"><span className="fa fa-exclamation-triangle" /> No Destination. Click to fix.</span></span>
			}

			return <div className='contextual-edge edge-edge'>
				<span className="fa fa-link icon" />
				<div className="contextual-edge-details">
					<div className='contextual-edge edge-edge'>{noDestination}</div>
				</div>
			</div>
		}



		return <div className='contextual-edge edge-edge' data-eid={edge.eid}>
			<span className="fa fa-link icon" />
			<div className="contextual-edge-details">
				{predNode}
				<div className="contextual-edge-edge-target">{dstLink}</div>
				<div className='contextual-edge-operations'><DateString value={edge.t_created} />, <UserShort uid={edge.uid} /> | {editLink}</div>
			</div>
		</div>
	}

}

export default withStore(observer(NodeContextLink))
