import React from "react";
import { observer, inject } from "mobx-react";
import { HashRouter, Switch, Route, Link, withRouter } from 'react-router-dom';
import { withStore } from '../../utility/Core'

import PrettyError from "../PrettyError"
import ForceGraph from "./ForceGraph";

class Topography extends React.Component {

	constructor(props) {
		super(props)

		this.state = {
			hasError: false,
			error: undefined,
			errorDetails: undefined,
		}
	}

	componentDidMount() {
		const { core, gid, contextNid } = this.props

		setTimeout(() => {
			//core.loadNode('n-30x6ccwz-apsfbftw');
		}, 2000)

	}

	componentDidCatch(error, info) {
		console.warn('Topography Error:', error, info)
		this.setState({ hasError: true, error: error, errorDetails: info })
	}

	nodefilter(node) {
		const { core, filter, nodeMatchOverride } = this.props
		if (typeof filter === 'undefined') return true

		const remoteMatches = (typeof nodeMatchOverride !== 'undefined') ? nodeMatchOverride : {}

		return filter.reduce((acc, f) => {
			const { key, value } = f

			switch (key) {

					case 'type':
						if (node.type != value) return false
						break;

					case 'group':
						if (!node.hasGroup(value)) return false
						break;

					case 'body':
						if ((node) && (((node.txt) && (node.txt().toLowerCase().indexOf(value.toLowerCase()) >= 0)) || (remoteMatches[node.nid]))) return true
						return false
						break;

					case 'title':
						if ((node) && (node.title) && (node.title.toLowerCase().indexOf(value.toLowerCase()) >= 0)) return true
						return false
						break;

			}

			return acc
		}, 1)

	}

	edgefilter(edge) {
		const { core, filter, edgeMatchOverride } = this.props

		if (typeof filter === 'undefined') return true

		const remoteMatches = (typeof edgeMatchOverride !== 'undefined') ? edgeMatchOverride : {}

		return filter.reduce((acc, f) => {
			const { key, value } = f

			switch (key) {

				case 'type':
					//if (node.type != value) return false
					break;

				case 'group':
					if (!edge.hasGroup(value)) return false
					break;

				case 'title':
					const n1  = edge.src.nid ? core.nodes[edge.src.nid] : false
					const n2  = edge.dst.nid ? core.nodes[edge.dst.nid] : false
					const et1 = edge.src ? edge.src.title : ''
					const et2 = edge.dst ? edge.dst.title : ''
					const tp1 = n1 && n1.title ? n1.title : et1
					const tp2 = n2 && n2.title ? n2.title : et2
					const t1  = (tp1 || '').toLowerCase()
					const t2  = (tp2 || '').toLowerCase()
					if ((t1 && t2) && ((t1.indexOf(value.toLowerCase()) <0) || (t2.indexOf(value.toLowerCase()) <0))) return false
					break;

				case 'body':
					console.warn('body-search for edges not available')
					break;

			}

			return acc
		}, 1)

	}

	render() {
		const { core, gid, contextNid, backgrounded } = this.props
		const { hasError, error, errorDetails } = this.state

		if (hasError) {
			return 	<div className="d3-canvas">
								<PrettyError error={error} errorDetails={errorDetails} />
							</div>
		}

		const availableNodes = {}

		const nids = Object.keys(core.nodes)

		const nodes = nids.map(nid => {
				const node = core.nodes[nid];

				if (node.deleted) return null
				if (!this.nodefilter(node)) return null

				var type

				switch(node.type) {
					case 'card': type = 2; break
					case 'tag':  type = 3; break
					default:
						if (node.loaded == 'loading') {
							type = 9
						}
						else {
							return null
						}
				}
				availableNodes[node.nid] = true

				return {
					id: node.nid,
					title: node.title,
					group: (node.nid == contextNid) ? 1 : type
				}
			}).filter(e => e !== null)

		const links = core.edges
			.filter( (e) => {

				if (e.deleted) return false
				if (e.loaded == 'deleted') return false
				if ((e.type) != 'edge' && (e.type != 'tag')) return false
				if (!this.edgefilter(e)) return false

				if (e.src && e.dst && e.src.nid && e.dst.nid) {

					if (typeof availableNodes[e.src.nid] === 'undefined') nodes.push({
						id: e.src.nid,
						title: '? ' + e.src.title,
						group: 0,
						hint: e.type,
					})
					if (typeof availableNodes[e.dst.nid] === 'undefined') nodes.push({
						id: e.dst.nid,
						title: '? ' + e.dst.title,
						group: 0,
						hint: e.type,
					})
					return true
				}
				return false
			})
			.map(e => ({
				source: e.src.nid,
				target: e.dst.nid,
				id: e.eid,
			}))
		//console.log(nodes)

		const data = {
			nodes: nodes,
			links: links,
		}

		return <ForceGraph data={data} backgrounded={backgrounded} />
	}

}

export default withStore(observer(Topography))
