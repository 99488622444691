import React from "react";
import { observer, inject, action, runInAction } from "mobx-react";
import { withStore } from '../utility/Core'

class DirectParagraphIndentations extends React.Component {

	constructor(props) {
		super(props)

		this.domRef       = React.createRef();

		this.prev = {
			nid: undefined,
			pid: undefined,
		}
	}

	changeParStyle(style) {
		const { core, setCursorAfterOp, nodeComponentId } = this.props
		const nid = core.caretX[nodeComponentId].nid
		const pid = core.caretX[nodeComponentId].pid

		const paragraph = core.nodes[nid].paragraphs[pid]
		const pos = core.caretX[nodeComponentId].pos
		const length = core.caretX[nodeComponentId].length
		const lid = core.uuid('l')

		core.op({
			op:            'parType',
			realm:         'node',
			nid:           nid,
			pid:           pid,
			val:           style,
			old:           paragraph.type,
			uid:           core.user.uid,
			prev_mod_uuid: paragraph.last_mod_uuid,
			lid:           lid,
		})

		setCursorAfterOp(lid, pid, pos+length, -length, true)

	}


	indent() {
		const { core, nodeComponentId } = this.props

		if (Object.keys(core.caretX).indexOf(nodeComponentId) == -1) return null

		const nid = core.caretX[nodeComponentId].nid
		const pid = core.caretX[nodeComponentId].pid
		const node = core.nodes[nid]
		const paragraph = node.paragraphs[pid]

		var match
		const [type, plevel] = paragraph.type.split('-')

		if ((type == 'ul') || (type == 'ol')) {
			const level = parseInt(plevel || 1)
			if (level < 5) {
				const newType = type + '-' + (level+1)
				this.changeParStyle(newType)
			}
		}
		else if (match = /^h([0-9]+)$/i.exec(type)) {
			const level = parseInt(match[1])
			if ((level) && (level<3)) {
				const newType = 'h' + (level+1)
				this.changeParStyle(newType)
			}
		}
	}

	outdent() {
		const { core, nodeComponentId } = this.props

		if (Object.keys(core.caretX).indexOf(nodeComponentId) == -1) return null

		const nid = core.caretX[nodeComponentId].nid
		const pid = core.caretX[nodeComponentId].pid
		const node = core.nodes[nid]
		const paragraph = node.paragraphs[pid]

		var match
		const [type, plevel] = paragraph.type.split('-')

		if ((type == 'ul') || (type == 'ol')) {
			const level = parseInt(plevel || 1)
			if (level >1) {
				const newType = type + '-' + (level-1)
				this.changeParStyle(newType)
			}
		}
		else if (match = /^h([0-9]+)$/i.exec(type)) {
			const level = parseInt(match[1])
			if ((level) && (level>1)) {
				const newType = 'h' + (level-1)
				this.changeParStyle(newType)
			}
		}

	}

	render() {
		const { core, nodeComponentId, setCursorAfterOp } = this.props

		if (Object.keys(core.caretX).indexOf(nodeComponentId) == -1) return null

		const nid = core.caretX[nodeComponentId].nid
		const pid = core.caretX[nodeComponentId].pid

		if (typeof core.nodes[nid] === 'undefined') return null
		if (typeof core.nodes[nid].paragraphs[pid] === 'undefined') return null

		const node      = core.nodes[nid]
		const paragraph = node.paragraphs[pid]

		const buttons = [
			{
				k: 'indent',
				title: 'Indent by one (Tab)',
				classNames: ['direct-node-topbar-button', 'fa', 'fa-indent'],
				onClick: this.indent.bind(this),
			},{
				k: 'outdent',
				title: 'Outdent by one (Shift+Tab)',
				classNames: ['direct-node-topbar-button', 'fa', 'fa-outdent'],
				onClick: this.outdent.bind(this),
			}
		]

		// console.log(buttons)

		return <div className="block" ref={this.domRef}>
			{buttons.map((b) => {
				var [type, level] = (paragraph.type + '-1').split('-')
				var match
				var clickable = true

				if ((type != 'ul') && (type != 'ol') && (type != 'h1') && (type != 'h2') && (type != 'h3')) return null

				if ((type == 'ul') || (type == 'ol')) {
					if ((b.k == 'indent')  && (level >= 5)) clickable = false
					if ((b.k == 'outdent') && (level <= 1)) clickable = false
				}

				if (match = /^h([0-9]+)$/i.exec(type)) {
					level = match[1]
					if ((b.k == 'indent')  && (level >= 3)) clickable = false
					if ((b.k == 'outdent') && (level <= 1)) clickable = false
				}

				if (clickable) {
					b.classNames.push('clickable')
				}
				else {
					b.onClick = null
				}

				return <span key={b.k} title={b.title} className={b.classNames.join(' ')} onClick={b.onClick}></span>
			})

		}
		</div>
	}
}

export default withStore(observer(DirectParagraphIndentations))
