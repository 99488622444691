import React from "react";
import { observer, inject, Provider } from "mobx-react";
import { withStore } from '../../utility/Core'
import { Link, withRouter } from 'react-router-dom';
import Storage from "../../utility/Storage";

class InterfaceSettings extends React.Component {

	constructor(props) {
		super(props)

		this.set = this.set.bind(this)
		this.setNotification = this.setNotification.bind(this)
		this.state = {
			updating: {}
		}

		this.storage = new Storage()

	}

	componentWillUnmount() {
		if (typeof this.storage.ajax !== 'undefined') this.storage.ajax.cancelHandler.cancel('parent component unmounted');
	}


	set(key, value) {
		const { core } = this.props

		core.op({
			op: 'settingsChange',
			realm: 'settings',
			key: key,
			value: value,
			lid: core.uuid('l'),
		})
	}

	setNotification(key, e) {
		const { core } = this.props

		const updating = this.state.updating
		updating[key] = true
		this.setState({updating})

		// storage
		//

		this.storage.query({ action:'settingsMod', key , value: e.target.value })
			.then((response) => {
				if (response.status == 200) {
					console.log(response.data.data.result)
					const val = core.ui.param.notifications
					val[key]  = response.data.data.result
					core.ui.set('notifications', val)
				}
				updating[key] = false
				this.setState({updating})
			})
			.catch((e) => {
				updating[key] = false
				this.setState({updating})
				console.error(e)
			})


//
	}

	render() {
		const { core } = this.props

		/*
			<div class="row"><label className="col-1" for="notifications-cooloff" title="Minimum time of inactivity before you get notified">Cooloff:</label>
				<select id="notifications-cooloff" value={core.ui.param.notifications.cooloff} onChange={(e) => this.setNotification('cooloff', e)} disabled={this.state.updating['cooloff']}>
					<option value="0">immediately (don't wait)</option>
					<option value="1800">wait for 30 minutes of inactivity</option>
					<option value="3600">wait for 1 hour of inactivity</option>
					<option value="7200">wait for 2 hours of inactivity</option>
					<option value="14400">wait for 4 hours of inactivity</option>
				</select>
			</div>
		*/

		return <div id="settings-groups">
			<h2>Interface Settings</h2>
			<div class="settings-box">
				<div class="table">
					<div class="row"><label className="col-1" for="spellchecker">Spellchecker:</label><input id="spellchecker" type="checkbox" defaultChecked={core.ui.param.spellchecker} onChange={() => { this.set('spellchecker', document.getElementById('spellchecker').checked)}} /></div>
					<div class="row"><label className="col-1" for="user-is-expert">Expert Mode:</label><input id="user-is-expert" type="checkbox" defaultChecked={core.user.expert} onChange={() => { this.set('expert', document.getElementById('user-is-expert').checked)}} /></div>
					<div class="row"><label className="col-1" for="notifications-cycle" title="minimum time before another notification is sent">Notifications Cycle:</label>
						<select id="notifications-cycle" value={core.user.notify_interval} onChange={(e) => this.set('notify_interval', e.target.value)}>
							<option value="0">none</option>
							<option value="1">immediate</option>
							<option value="3600">hourly</option>
							<option value="14400">every 4 hours</option>
							<option value="86400">every 24 hours</option>
							<option value="604800">weekly</option>
						</select>
					</div>
				</div>
			</div>
		</div>
	}
}

export default withStore(observer(InterfaceSettings))
