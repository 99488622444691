import React from "react";
import { observer, inject } from "mobx-react";
import { withStore } from '../utility/Core'

import NodeSelector from "./NodeSelector";

class EdgeTargetSelector extends React.Component {

	constructor() {
		super()
		this.state = {
			pane: 1,
			newNodeTitle: '',
			targetUrl: '',
		}
	}

	setDestination(nid, title) {
		const { core, edge } = this.props

		core.op({
			realm: 'edge',
			op:    'edgeUpdate',
			prev_mod_uuid: edge.last_mod_uuid,
			eid:    edge.eid,
			mods: {
				dst: { nid: nid, title: title },
				linked_url: '',
			},
			uid: core.user.uid,
			lid: core.uuid('l'),
		})
	}

	setDestinationWWW(nid) {
		const { core, edge } = this.props

		core.op({
			realm: 'edge',
			op:    'edgeUpdate',
			prev_mod_uuid: edge.last_mod_uuid,
			eid:    edge.eid,
			mods: {
				dst: { nid: null, title: null },
				linked_url: this.state.targetUrl,
			},
			uid: core.user.uid,
			lid: core.uuid('l'),
		})
	}

	addNode() {
		const { core, edge } = this.props

		const newNid = core.uuid('n')
		const newPid  = core.uuid('p')

		core.op({
			realm: 'node',
			op: 'nodeCreate',
			nid: newNid,
			pid: newPid,
			title: this.state.newNodeTitle,
			type: 'card',
			uid: core.user.uid,
			lid: core.uuid('l'),
		})

		const node = core.nodes[newNid]

		edge.groups.filter( g => core.userGroup(g.gid) && (g.gid != core.user.defaultGid) ).map( (g) => {
			core.op({
				realm: 'node',
				op:    'groupAdd',
				prev_mod_uuid: node.last_mod_uuid,
				nid:   node.nid,
				gid:   g.gid,
				uid:   core.user.uid,
				lid:   core.uuid('l'),
			})
		})

		core.op({
			realm: 'edge',
			op:    'edgeUpdate',
			prev_mod_uuid: edge.last_mod_uuid,
			eid:    edge.eid,
			mods: {
				dst: { nid: newNid, pid: null, beg: null, end: null, title: this.state.newNodeTitle }
			},
			uid: core.user.uid,
			lid: core.uuid('l'),
		})
	}

	componentWillMount() {
		const { core, nid, edge } = this.props

		if (edge.linked_url) {
			this.setState({ pane: 2, targetUrl: edge.linked_url })
		}
	}

	render() {
		const { core, nid, edge } = this.props

		const pane = []
		const paneId = this.state.pane

		pane[0] = <div key="0">
			<div className='pane-heading clickable' onClick={ () => {this.setState({ pane: 0 })} }><span className={'fa ' + ((paneId == 0) ? 'fa-dot-circle-o' : 'fa-circle-o' )} />Create a new card and link to it</div>
			<div className='pane-body'>
				<input type="text"
					value={ ((paneId != 0) ? '' : this.state.newNodeTitle) }
					onChange={ (event) => { this.setState({newNodeTitle: event.target.value}) } }
					placeholder={ (paneId != 0) ? '' : 'Title of the new card' }
					disabled={paneId != 0} />
				{ ' ' }
				<button
					onClick={ this.addNode.bind(this) }
					disabled={(paneId != 0) || (this.state.newNodeTitle == '')}>Create Card</button>
			</div>
		</div>

		pane[1] = <div key="1">
			<div className='pane-heading clickable' onClick={ () => {this.setState({ pane: 1 })} }><span className={'fa ' + ((paneId == 1) ? 'fa-dot-circle-o' : 'fa-circle-o' )} />Link to an existing card</div>
			<div className='pane-body'>
				<NodeSelector disabled={paneId != 1} edge={edge} action={ (nid, title) => { this.setDestination.bind(this)(nid, title) } } />
			</div>
		</div>

		pane[2] = <div key="2">
			<div className='pane-heading clickable' onClick={ () => {this.setState({ pane: 2 })} }><span className={'fa ' + ((paneId == 2) ? 'fa-dot-circle-o' : 'fa-circle-o' )} />Link to a web page</div>
			<div className='pane-body'>
				<input type="text"
					value={ ((paneId != 2) ? '' : this.state.targetUrl) }
					onChange={ (event) => { this.setState({targetUrl: event.target.value}) } }
					placeholder={ (paneId != 2) ? '' : 'Please enter a URL (http://)' }
					disabled={paneId != 2} />
				{ ' ' }
				<button
					onClick={ this.setDestinationWWW.bind(this) }
					disabled={(paneId != 2) || (this.state.targetUrl == '') || (this.state.targetUrl.substr(0,4) != 'http') || (this.state.targetUrl == edge.linked_url)}>Link to WWW</button>

				{ (this.state.targetUrl == edge.linked_url) ? <span className="dst-url-ok fa fa-check-circle" /> : <span className="dst-url-warn fa fa-exclamation-circle" title="You have modified the URL but have not yet committed the change" /> }
			</div>
		</div>

		return <div className="edgeTargetSelector">
			{pane.map( (pane, i) => {
				return <div key={i} className={'pane' + ((paneId == i) ? ' active' : '') }>
				{pane}
				</div>
			})}
		</div>

	}

}

export default withStore(observer(EdgeTargetSelector))
