import React from "react";
import { observer, inject } from "mobx-react";
import { withStore } from '../utility/Core'

class SingleNode extends React.Component {

	render() {

		const { errorDetails, error, context } = this.props

		const extra = context ? <div style={{border:'1px solid #900', backgroundColor:'#fee', padding:'4px'}}>{context}</div> : null
		var   i = 0

		//return <div key={this.props.core.uuid('x')}>---</div>

		const details = Object.keys(errorDetails).map((key) => {

			const details = errorDetails[key]
			var   spec    = ''
			if (typeof details === 'string') {
				spec = <ul>{details.split('\n').map( l => l ? <li key={i++}>{l}</li> : null ).filter( l => l)}</ul>
			}
			else {
				spec = JSON.stringify(details)
			}

			return <div key={i++}>
				<h3>{key}:</h3>
				<div>{spec}</div>
			</div>

		})

		return <div>
			<h1>Sadly, an Error :/</h1>
			{extra}
			{error ? <h2 style={{color:'red'}}>{error.toString()}</h2> : null}
			<div>
				{details}
			</div>
		</div>

	}

}

export default withStore(observer(SingleNode))
