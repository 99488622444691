import React from "react";
import { observer, inject } from "mobx-react"
import { withStore } from '../../utility/Core'

import { Link, withRouter } from 'react-router-dom'

class Edgelink extends React.Component {

	constructor() {
		super();
		this.state = {
		}
	}

	componentWillMount() {
		const { core, eid } = this.props;

		//if (typeof core.cache.userDetails[uid] === 'undefined') core.getUserInfo(uid)

	}

	render() {
		const { core, eid, children } = this.props;
		var content
		const edge = core.edgeById(eid)

		if (children) {
			content = children
		}
		else {
			content = <span className="fa fa-question-circle" />
			if (edge) {
				/*
				var src_title = <span className="fa fa-question-circle" />
				var dst_title = <span className="fa fa-question-circle" />
				const src_node = core.nodes[edge.src.nid]
				const dst_node = edge.dst ? core.nodes[edge.dst.nid]
				if (src_node) src_title =
				*/
				var icon = null
				if (edge.type == 'edge') icon = <span className="fa fa-link" />
				if (edge.type == 'comment') icon = <span className="fa fa-comment-o" />
				if (edge.type == 'annotation') icon = <span className="fa fa-exclamationmark" />

				var src_title = edge.src.title
				var dst_title = edge.dst.title

				if (edge.type == 'tag') dst_title = <span><span className="fa fa-hashtag" />{dst_title}</span>

				content = <span>{icon} {src_title} <span className="fa fa-arrow-right"/> {dst_title}</span>
			}
		}

		return <Link to={'edge/' + eid}>{content}</Link>
	}

}

export default withRouter(withStore(observer(Edgelink)))
