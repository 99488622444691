import { makeObservable, observable, computed, action, autorun } from "mobx";

import Storage from "../utility/Storage";

// import { Mark } from "../poe/poe-mark";
// import { Group } from "../utility/Group";

import React from "react";

import NodeHistory from './NodeHistory'

const { User } = require("../utility/User.js")
const { Node } = require("../poe/poe-node.js")
const { Paragraph } = require("../poe/poe-paragraph.js")
const { Edge } = require("../poe/poe-edge.js")

const storage = new Storage()

const SugarDate = require('sugar/date');

const Core_ui = require('./Core_ui.js').Core_ui
const nodeHistory = new NodeHistory()

//useStrict(true);

autorun(() => {
	//console.log(JSON.stringify(window.state.nodes));
});


export default class Core {

	_componentIdCounter = 0

	groupPUBLIC = {
		gid: 1
	}

	storage = storage

	ui = Core_ui

	nodeHistory = nodeHistory

	// //@observable
	cache = {
		userDetails: {}
	}

	//@observable
	user            = new User()

	//@observable
	fileUploadQueue = []

	//@action
	fileUploadQueueSetStatus = (i, val) => {
		if (val === null) this.fileUploadQueue.splice(i, 1)
		else this.fileUploadQueue[i].status = val
	}

	constructor() {
		makeObservable(this, {
			user: observable,
			cache: observable,
			ui: observable,
			connectivity: observable,
			fatalError: observable,
			messages: observable,
			nodes: observable,
			nodelist: observable,
			edges: observable,
			caret: observable,
			caretX: observable,
			edgeEdit: observable,
			modal: observable,
			hoverTool: observable,
			operations: observable,
			fileUploadQueue: observable,

			nofUnsyncedOperations: computed,

			loadState: action,
			edgeEditSet: action,
			setGroupActive: action,
			showModal: action,
			closeModal: action,
			showHoverTool: action,
			closeHoverTool: action,
			unloadUnusedEdges: action,
			op: action,
			checkModChain: action,
			opNode: action,
			opEdge: action,
			opSettings: action,
			setCaret: action,
			setCaretX: action,
			setNode: action,
			unloadNode: action,
			unloadEdge: action,
			loadEdge: action,
			undoOperation: action,
			redoOperation: action,
			injectRemoteUpdates: action,
			applyRemoteUpdates: action,
			bulkInsert: action,
			loadNode: action,
			insertNode: action,
			deleteNode: action,
			undeleteNode: action,
			deleteEdge: action,
			undeleteEdge: action,
			nodeCreate: action,
			addEdge: action,
			setNodeList: action,
			setNodeListLoaded: action,
			nodelistChangeOrder: action,
			login: action,
			logout: action,
			setConnectivity: action,
			throwFatalError: action,
			setUserDetails: action,
			userinfo: action,
			requestLock: action,
			releaseLock: action,
			fileUploadQueueSetStatus: action,
		})
	}

	//@observable
	connectivity = {
		online:        (process.env.NODE_ENV != 'local'),
		forceOffline:  (process.env.NODE_ENV == 'local'),
		lastError:     '',
		t_last_sync:   false,
		maxLid:        false,
		stateIsLoaded: false,
	}

	//@observable
	fatalError = false;

	//@observable
	messages = {
		recent:         [],
		nofUnread:     0,
		nofActionable: 0,
	}

	//@observable
	nodes = {
		}

	//@observable
	nodelist = {
		filter: '',
		orderBy: { key: 't_updated', dir: -1 },
		list: [],
		loaded: false,
	}

	//@observable
	edges = [
	]

	//@observable
	caret           = {
		nid : false,
		pid : false,
		pos : false,
		length : false
	}

	//@observable
	caretX = {
	}

	//@observable
	edgeEdit = {
		nid: null,
		eid: null,
		dir: null,
	}

	//@action
	edgeEditSet = function(nid, eid, dir) { this.edgeEdit.nid=nid; this.edgeEdit.eid=eid; this.edgeEdit.dir=dir }

	//@observable
	modal = {
		isOpen: false,
		content: false,
		config: false,
	}

	//@observable
	hoverTool = {
		isOpen: false,
		content: false,
		config: false,
	}

	//@observable
	operations = [
	]

	//@computed get
	get nofUnsyncedOperations() {
		return parseInt(this.operations.filter( (o) => o.status == 'done' ).length)
	}

	unique_seed = this.unique_id();

	unique_id() {
		return (Math.random().toString(36)).substr(2,8);
	}

	uuid(prefix) {
		return prefix + '-' + this.unique_seed + '-' + this.unique_id();
	}

	componentId(prefix) {
		this._componentIdCounter++
		return prefix + '-' + this._componentIdCounter
	}

	//@action
	setGroupActive(gid, state) {
		const i = this.user.groups.findIndex((g) => { return g.gid == gid })
		if (i>=0) {
			this.user.groups[i].active = state
		}
	}

	//@action
	showModal(content, config) {
		this.modal.content = content;
		this.modal.config  = config;
		this.modal.isOpen  = true;
	}

	//@action
	closeModal() {
		this.modal.isOpen  = false;
		this.modal.content = null;
		this.modal.config  = {};
	}

	//@action
	showHoverTool(content, config) {
		this.hoverTool.content = content;
		this.hoverTool.config  = config;
		this.hoverTool.isOpen  = true;
	}

	//@action
	closeHoverTool() {
		this.hoverTool.isOpen = false;
	}

	//@action
	unloadUnusedEdges() {
		const nids = Object.keys(this.nodes);

		for(let i=this.edges.length-1; i>=0; i--) {
			const edge  = this.edges[i];
			// const found = nids.findIndex( (nid) => { return this.nodes[nid].loaded == 'full' && (edge.src.nid == nid || edge.dst.nid == nid) } )
			const found = nids.findIndex( (nid) => { return edge.src.nid == nid || edge.dst.nid == nid } )
			if (found == -1) {
				this.edges.splice(i, 1)
			}
		}
	}

	//@action
	op(op) {

		if (typeof op.lid === 'undefined') {
			console.warn('Setting lid for op ' + op.op, op)
			op.lid = this.uuid('l')
		}

		if ((typeof op.prev_mod_uuid === 'undefined') && (op.op != 'nodeCreate') && (op.op != 'noop') && (op.realm != 'settings')) {
			if ((op.realm != 'edge') || ((typeof op.src.prev_mod_uuid === 'undefined') && (typeof op.dst.prev_mod_uuid === 'undefined') && (op.src.pid !== null) && (op.dst.pid !== null))) {
				console.warn('Operation ' + op.op + ' had no prev_mod_uuid', op)
			}
		}

		if (typeof op.realm === 'undefined') {
			console.error('Operation ' + op.op + ' had no realm set', op)
			op.realm = 'node'
		}

		try {

			switch(op.realm) {
				case 'settings':
					this.opSettings(op)
					break;

				case 'node':
					this.opNode(op)
					break;

				case 'edge':
					this.opEdge(op)
					break;

				default:
					console.error('Unknown operation realm ' + op.realm, op)
			}

			if (op.doNotLog) {
				console.log('Silently perform action ' + op.op, op)
			}
			else {
				if (op.status != 'done') {
					op.uid     = this.user.uid
					op.status  = 'done'
					op.t_local = Date.now()
					this.operations.push(op)

					if (!op.undoes) this.undoPointer = this.operations.length - 1

				}
			}
		} catch(e) {
			console.error('Operation failed and was not logged', e)
		}
	}

	//@action
	checkModChain(op, rel, defaultSeverity = 'break', pmuLocation = false, rel2) {
		var severity = op.modChainMode
		if ((severity != 'warn') && (severity != 'fix') && (severity != 'break')) severity = defaultSeverity

		var pmu = op
		if (pmuLocation) pmuLocation.split('.').forEach( (k) => { pmu = pmu[k] } )
		//console.log('checkModChain', op, rel, rel.last_mod_uuid, pmu.prev_mod_uuid)
		switch(severity) {

			case 'warn':
				if (rel.last_mod_uuid != pmu.prev_mod_uuid) console.warn('Revision Conflict for #' + op.lid + ': ' + op.op + ' ['+pmuLocation+']. Rel\'s last_mod_uuid was ' + rel.last_mod_uuid + ' but op expected ' + pmu.prev_mod_uuid )
				if (typeof rel2 !== 'undefined') {
					if (rel2.last_mod_uuid != pmu.prev_mod_uuid2) console.warn('Revision Conflict for ' + op.op + ' ['+pmuLocation+']. Rel2 was ' + rel2.last_mod_uuid + ' but op expected ' + pmu.prev_mod_uuid2 )
				}
				break;

			case 'fix':
				pmu.prev_mod_uuid = rel.last_mod_uuid
				if (typeof rel2 !== 'undefined') {
					pmu.prev_mod_uuid2 = rel2.last_mod_uuid
				}
				break;

			case 'break':
			default:
				if (rel.last_mod_uuid != pmu.prev_mod_uuid) throw new Error('Revision Conflict for ' + op.op + '. Rel was ' + rel.last_mod_uuid + ' but op expected ' + pmu.prev_mod_uuid )
				if (typeof rel2 !== 'undefined') {
					if (rel2.last_mod_uuid != pmu.prev_mod_uuid2) throw new Error('Revision Conflict for ' + op.op + '. Rel was ' + rel2.last_mod_uuid + ' but op expected ' + pmu.prev_mod_uuid2 )
				}
		}

	}

	//@action
	opNode(op) {
		// const t0     = Date.now()
		let lastLine = false

		if (this.operations.length>0) lastLine = this.operations[this.operations.length-1];

		if (typeof op.uid === 'undefined') {
			console.warn('Adding user uid to op ' + op.op, op)
			op.uid = this.user.uid
		}

		if (op.realm != 'node') {
			console.error('opNode called for wrong realm. ABORTING.', op)
			return false
		}

		if (typeof op.nid === 'undefined') {
			console.error('opNode called without op.nid. ABORTING.', op)
			return false
		}

		if ((typeof this.nodes[op.nid] === 'undefined') && (op.op != 'nodeCreate')) {
			console.error('opNode called on a node that was not loaded. ABORTING.')
			return false
		}

		let par, par2

		try {

			// check prerequisites and modification chain
			switch(op.op) {
				case 'charInsert':
				case 'charDelete':
				case 'parType':
				case 'parSplit':
				case 'rangeInsert':
				case 'rangeDelete':
				case 'touch-par':
					par = this.nodes[op.nid].paragraphs[op.pid];
					if (typeof par === 'undefined') throw new Error('Paragraph ' + op.pid + ' is missing')
					this.checkModChain(op, par)
					this.nodes[op.nid].t_updated = Date.now()/1000
					break;

				case 'parMerge':
					par  = this.nodes[op.nid].paragraphs[op.pid];
					par2 = this.nodes[op.nid].paragraphs[op.pid2];
					if (typeof par === 'undefined') throw new Error('Paragraph ' + op.pid + ' is missing')
					if (typeof par2 === 'undefined') throw new Error('Paragraph 2 ' + op.pid + ' is missing')
					this.checkModChain(op, par, undefined, undefined, par2)
					this.nodes[op.nid].t_updated = Date.now()/1000
					break;

				case 'nodeDelete':
				case 'groupAdd':
				case 'groupDel':
					this.checkModChain(op, this.nodes[op.nid])
					break;

				case 'nodeUndelete':
				case 'nodeCreate':
				case 'noop':
					break;

				case 'fileAdd':
					if (typeof this.nodes[op.nid] !== 'undefined') {
						const node = this.nodes[op.nid]
						const i = node.attachments.findIndex(a => a.fid === op.file.fid)
						if (i>=0) {
							['filename', 'status', 't_modified', 'last_mod_uuid'].forEach((k) => {
								node.attachments[i][k] = op.file[k]
							})
						}
						else {
							const obj = { }
							Array('fid', 'uid', 'filename', 'size', 'status', 't_created', 't_modified', 'last_mod_uuid').forEach((k) => {
								obj[k] = typeof op.file[k] !== 'undefined' ? op.file[k] : null
							})
							node.attachments.push(obj)
						}
					}
					break;

				case 'fileDel':
					if (typeof this.nodes[op.nid] !== 'undefined') {
						const node = this.nodes[op.nid]
						const i = node.attachments.findIndex(a => a.fid === op.file.fid)
						if (i>=0) {
							node.attachments.splice(i, 1)
						}
					}
					break;

				default:
					throw new Error('Unknown Node Operation: ' + JSON.stringify(op));
			}

			// do the operation
			switch(op.op) {

				case 'noop':
					break;

				// paragraph-level operations

				case 'charInsert':

					par.charInsert(op);
					if ((lastLine) && (op.status != 'done') && (lastLine.status == 'done') && (lastLine.op == op.op) && (lastLine.nid == op.nid) && (lastLine.pid == op.pid) && (lastLine.pos + lastLine.str.length == op.pos)) {
						op.pos = lastLine.pos;
						op.str = lastLine.str + op.str;
						op.prev_mod_uuid = lastLine.prev_mod_uuid
						this.operations.pop();
					}
					break;

				case 'charDelete':
					par = this.nodes[op.nid].paragraphs[op.pid];
					if (typeof par === 'undefined') break;
					par.charDelete(op);
					if ((lastLine) && (op.status != 'done') && (lastLine.status == 'done') && (lastLine.op == op.op) && (lastLine.nid == op.nid) && (lastLine.pid == op.pid)) {
						if (lastLine.pos == op.pos) {
							op.pos = lastLine.pos;
							op.str = lastLine.str + op.str;
							op.prev_mod_uuid = lastLine.prev_mod_uuid
							this.operations.pop();
						}
						else {
							if (lastLine.pos - op.str.length == op.pos) {
								op.str = op.str + lastLine.str;
								op.prev_mod_uuid = lastLine.prev_mod_uuid
								this.operations.pop();
							}
						}
					}
					break;

				case 'parType':
					par = this.nodes[op.nid].paragraphs[op.pid]
					if (typeof par === 'undefined') break;
					par.parType(op)
					break;

				case 'parSplit':
					par = this.nodes[op.nid].paragraphs[op.pid]
					if (typeof par === 'undefined') break;
					par.parSplit(op, this.nodes[op.nid], this.edges)
					break;

				case 'parMerge':
					par = this.nodes[op.nid].paragraphs[op.pid]
					if (typeof par === 'undefined') break;
					par.parMerge(op, this.nodes[op.nid], this.edges)
					break;

				case 'rangeInsert':
					par = this.nodes[op.nid].paragraphs[op.pid]
					if (typeof par === 'undefined') break;
					par.rangeInsert(op)
					break;

				case 'rangeDelete':
					par = this.nodes[op.nid].paragraphs[op.pid]
					if (typeof par === 'undefined') break;
					par.rangeDelete(op)
					break;

				case 'touch-par':
					par = this.nodes[op.nid].paragraphs[op.pid]
					break

				// global node operations

				case 'nodeCreate':
					this.nodeCreate(op)
					break;

				case 'nodeDelete':
					this.deleteNode(op)
					break;

				case 'nodeUndelete':
					this.undeleteNode(op)
					break;

				case 'groupAdd':
				case 'groupDel':
					const group = this.userGroup(op.gid)
					if (typeof group !== undefined) this.nodes[op.nid].changeGroups(group, op)
					break

			} // switch

		} catch(e) {
			console.error('opNode aborted with error:', e)
			throw(e)
		}

	}

	//@action
	opEdge(op) {
		const t0     = Date.now()

		if (typeof op.uid === 'undefined') {
			console.warn('Adding user uid to op ' + op.op, op)
			op.uid = this.user.uid
		}

		if (op.realm != 'edge') {
			console.error('opEdge called for wrong realm. ABORTING.', op)
			return false
		}

		if (typeof op.eid === 'undefined') {
			console.error('opEdge called without op.eid. ABORTING.', op)
			return false
		}

		var edge = undefined

		if (op.op != 'edgeCreate') {
			edge = this.edgeById(op.eid)
			if (typeof edge === 'undefined') {
				console.error('opEdge '+op.op+' called on an edge ('+op.eid+') that was not loaded. ABORTING.')
				return false
			}
		}

		try {
			// check prerequisites and modification chain
			switch(op.op) {
				case 'edgeCreate':
					if ((typeof op.src !== 'undefined') && (typeof op.src.nid !== 'undefined') && (op.src.nid !== null) && (typeof op.src.pid !== 'undefined') && (op.src.pid !== null)) {
						const par = this.nodes[op.src.nid].paragraphs[op.src.pid];
						if (typeof par === 'undefined') console.warn('Paragraph for op.src ' + op.src.nid  + '/' + op.src.pid + ' is missing.', op)
						this.checkModChain(op, par, 'warn', 'src')
					}
					if ((typeof op.dst !== 'undefined') && (typeof op.dst.nid !== 'undefined') && (op.dst.nid !== null) && (typeof op.dst.pid !== 'undefined') && (op.dst.pid !== null)) {
						const par = this.nodes[op.dst.nid].paragraphs[op.dst.pid];
						if (typeof par === 'undefined') console.warn('Paragraph for op.dst ' + op.dst.nid  + '/' + op.dst.pid + ' is missing.', op)
						this.checkModChain(op, par, 'warn', 'dst')
					}
					if (typeof edge !== 'undefined') {
						this.checkModChain(op, edge, 'warn')
					}
					break

				case 'edgeUpdate':
					if ((typeof op.mods.src !== 'undefined') && (typeof op.mods.src.nid !== 'undefined') && (op.mods.src.nid !== null) && (typeof op.mods.src.pid !== 'undefined') && (op.mods.src.pid !== null)) {
						const par = this.nodes[op.mods.src.nid] ? this.nodes[op.mods.src.nid].paragraphs[op.mods.src.pid] : undefined;
						if (typeof par === 'undefined')
							console.warn('Paragraph for op.src ' + op.mods.src.nid  + '/' + op.mods.src.pid + ' is missing.', op)
						else
							this.checkModChain(op, par, 'warn', 'mods.src')
					}
					if ((typeof op.mods.dst !== 'undefined') && (typeof op.mods.dst.nid !== 'undefined') && (op.mods.dst.nid !== null) && (typeof op.mods.dst.pid !== 'undefined') && (op.mods.dst.pid !== null)) {
						const par = this.nodes[op.mods.dst.nid] ? this.nodes[op.mods.dst.nid].paragraphs[op.mods.dst.pid] : undefined;
						if (typeof par === 'undefined')
							console.warn('Paragraph for op.dst ' + op.mods.dst.nid  + '/' + op.mods.dst.pid + ' is missing.', op)
						else
							this.checkModChain(op, par, 'warn', 'mods.dst')
					}
					if (typeof edge !== 'undefined') {
						this.checkModChain(op, edge, 'warn')
					}
					break

				case 'edgeDelete':
					if (typeof edge !== 'undefined') {
						this.checkModChain(op, edge, 'warn')
					}
					break

				case 'edgeUndelete':
				case 'groupAdd':
				case 'groupDel':
					if (typeof edge !== 'undefined') {
						this.checkModChain(op, edge, 'warn')
					}
					break

				default:
					throw new Error('Unknown Edge Operation: ' + JSON.stringify(op));
			}

			// do the operation
			switch(op.op) {

				case 'edgeCreate':

					if ((typeof this.nodes[op.src.nid] !== 'undefined') && (typeof this.nodes[op.src.nid].title !== 'undefined')) op.src.title = this.nodes[op.src.nid].title
					if ((typeof this.nodes[op.dst.nid] !== 'undefined') && (typeof this.nodes[op.dst.nid].title !== 'undefined')) op.dst.title = this.nodes[op.dst.nid].title

					if (typeof op.t_created === 'undefined') op.t_created = t0 / 1000
					if (typeof op.t_updated === 'undefined') op.t_updated = t0 / 1000

					var groups = []
					if (typeof op.groups !== 'undefined') {
						// do i want to allow this even?
						groups = op.groups.map(gid => this.groupByGid(gid))
					}
					else {
					  if (op.uid == this.user.uid) groups = [this.userGroup(this.user.defaultGid)]
					}

					const newEdge = new Edge({
						eid: op.eid,
						uid: op.uid,
						type: op.type,
						src: op.src,
						dst: op.dst,
						groups: groups,
						//groups: (typeof op.groups === 'undefined') ? [this.userGroup(this.user.defaultGid)] : op.groups.map((g) => { if (Number.isInteger(g)) return this.userGroup(g) }),
						linked_url: op.linked_url ? op.linked_url : null,
						last_mod_uuid: op.set_mod_uuid || op.lid,
						locked_uid: op.locked_uid,
						locked_t: op.locked_t,
						t_created: op.t_created,
						t_updated: op.t_updated,
					})

					// todo-aidw^53d


					this.addEdge(newEdge)

					break;

				case 'edgeUpdate':
					edge.update(op)
					break

				case 'edgeDelete':
					this.deleteEdge(op)
					break

				case 'edgeUndelete':
					this.undeleteEdge(op)
					break

				case 'groupAdd':
					if (this.user.permission('GA_update', 'edge_' + edge.type, edge) && this.user.permission('create', 'edge_' + edge.type, {groups:[{gid: op.gid}]})) {
						edge.groupAdd(op)
					} else {
						console.log('groupAdd is not allowed:', this.user.permission('GA_update', 'edge_' + edge.type, edge), this.user.permission('create', 'edge_' + edge.type, {groups:[{gid: op.gid}]}))
						// console.error('not allowed ', this.userGroup(op.gid)['edge_' + edge.type + '_create'], '('+op.gid+') edge_' + edge.type + '_create')
					}
					break;

				case 'groupDel':
					if (this.user.permission('GA_update', 'edge_' + edge.type, edge) && (true)) {
						edge.groupDel(op)
					} else {
						console.log('groupDel is not allowed:', this.user.permission('GA_update', 'edge_' + edge.type, edge))
						// console.error('not allowed ', this.userGroup(op.gid)['edge_' + edge.type + '_create'], '('+op.gid+') edge_' + edge.type + '_create')
					}
					break;
			}


		} catch (e) {
			console.error('opNode aborted with error:', e)
			throw(e)
		}
	}

	//@action
	opSettings(op) {
		const {key, value} = op;
		//console.log('SETTINGS change', key, value);

		switch(key) {

			case 'username':
				this.user.name = value
				break;

			case 'usershort':
				this.user.short = value
				break;

			case 'usercolor':
				this.user.color = value;
				break;

			case 'expert':
				this.user.expert = value;
				break;

			case 'notify_interval':
				this.user.notify_interval = value;
				break;

			case 'spellchecker':
				this.ui.set('spellchecker', value)
				break;

			default:
				console.log('UNKNOWN SETTINGS OPERATION: ' + JSON.stringify(op));
		}
	}

	//@action
	setCaret(caret) {
		// console.log('Set Caret: ', caret)
		this.caret = caret
	}

	//@action
	setCaretX(nodeComponentId, caret = false) {
		//console.log('setCaretX:', nodeComponentId, caret)
		if ((caret === false) && (typeof this.caretX[nodeComponentId] !== 'undefined')) {
			delete this.caretX[nodeComponentId]
			return
		}
		//console.log('set: ', nodeComponentId)
		if (typeof this.caretX[nodeComponentId] === 'undefined') this.caretX[nodeComponentId] = {}

		Object.keys(caret).map((key) => {
			this.caretX[nodeComponentId][key] = caret[key]
		})

	}

	//@action
	setNode(nid, obj) {
		if (typeof this.nodes[nid] === 'undefined') {
			console.error('Node ' + nid + ' was not present when trying to set properties: ', obj)
			return
		}
		// console.log(nid, obj)
		if (typeof obj.paragraphList !== 'undefined') this.nodes[nid].paragraphList = obj.paragraphList;
		if (typeof obj.groups !== 'undefined')        Object.keys(obj.groups).map( (p) => { this.nodes[nid].groups[p] = obj.groups[p] });
		if (typeof obj.nid !== 'undefined')           this.nodes[nid].nid           = obj.nid;
		if (typeof obj.uid !== 'undefined')           this.nodes[nid].uid           = obj.uid;
		if (typeof obj.loaded !== 'undefined')        this.nodes[nid].loaded        = obj.loaded;
		if (typeof obj.type !== 'undefined')          this.nodes[nid].type          = obj.type;
		if (typeof obj.title !== 'undefined')         this.nodes[nid].title         = obj.title;
		if (typeof obj.t_created !== 'undefined')     this.nodes[nid].t_created     = obj.t_created;
		if (typeof obj.t_seen !== 'undefined')        this.nodes[nid].t_seen        = obj.t_seen;
		if (typeof obj.t_updated !== 'undefined')     this.nodes[nid].t_updated     = obj.t_updated;
		if (typeof obj.last_mod_uuid !== 'undefined') this.nodes[nid].last_mod_uuid = obj.last_mod_uuid;
		if (typeof obj.rel !== 'undefined')           this.nodes[nid].rel           = obj.rel;
	}

	//@action
	unloadNode(nid, componentId) {
		console.log('Unload node ' + nid + ' [' + componentId + ']');
		const node = this.nodes[nid]

		// the node is already unloaded
		if (typeof node === 'undefined') return

		if (node.loaded == 'not') {
			node.releaseAll()
			return
		}

		if (typeof componentId === 'undefined') {
			node.releaseAll()
		}
		else {
			node.release(componentId)
		}

		this.unloadUnusedEdges()
	}

	//@action
	unloadEdge(eid, componentId) {
		console.warn('Ts, ts, unloadEdge not implemented')
	}

	applyUnsyncedEdgeOps() {
			this.operations.map( (op) => {
				if (op.status == 'done') {
					if ((op.realm == 'edge') && (typeof op.prev_mod_uuid === 'undefined')) {
						if (op.op != 'edgeCreate') console.error('Op has no pre_mod_uuid but is not edgeCreate :/', op)
						else { if (!this.edgeById(op.eid)) this.op(op) }
					}
					else if ((op.realm == 'edge') && (op.prev_mod_uuid) && (this.edgeById(op.eid)) && (this.edgeById(op.eid).last_mod_uuid == op.prev_mod_uuid)) {
						console.log('Do connecting operation ', op)
						this.op(op)
					}
					else {
						console.log('do not apply non-edge op' + op.op, op)
					}
				}
			} );
	}

	//@action
	loadEdge(eid) {
		this.applyUnsyncedEdgeOps()
		const edge = this.edgeById(eid)

		return new Promise((resolve, reject) => {
			if (edge) {
				resolve()
			}
			else {
				storage.query({
					'action': 'getEdges',
					'eid': eid,
					})
					.then((response) => {
						if ((response.data.status == 200)) {
							if (response.data.data.length>0) this.addEdge(response.data.data[0])
							resolve('loaded')
						}
						else {
							console.error(response)
							reject('load failed')
						}
					})
					.catch((e) => {
						reject(e)
					})
			}
		})
	}

	//@action
	undoOperation(lid) {
		const makeUndo   = require('./Operations.js').makeUndo
		const op         = this.operations.find( op => op.lid == lid )

		const reverse    = makeUndo.bind(this)(op)
		reverse.undoes   = op.lid

		console.log('UNDOing ' + lid, op)
		console.log(reverse)

		this.op(reverse)
		//op.status        = 'undone'

	}

	//@action
	redoOperation(lid) {
		const makeUndo   = require('./Operations.js').makeUndo
		const op         = this.operations.find( op => op.lid == lid )

		const reverse    = makeUndo.bind(this)(op)
		reverse.undoes   = op.lid
		reverse.opType   = 'redo'

		this.op(reverse)
		//op.status        = 'undone'
	}


	//@action
	injectRemoteUpdates(ops) {
		const res = ops.filter(rOp => this.operations.findIndex(lOp => rOp.lid == lOp.lid) == -1)
		res.map((op) => {
			delete op.status
			op.doNotLog = true
		})
		// console.log('not yet local:', res)
		if (res.length) this.applyRemoteUpdates(res)
	}

	//@action
	applyRemoteUpdates(remoteOps) {
		const makeUndo    = require('./Operations.js').makeUndo
		const applyOpToOp = require('./Operations.js').applyOpToOp

		// roll back unsynced ops for nodes that are mentioned in the remote Ops
		const nidsToRollBack = {}
		remoteOps.map((op) => {
			if (typeof op.nid !== 'undefined') nidsToRollBack[op.nid] = true
			if ((typeof op.src !== 'undefined') && (typeof op.src.nid !== 'undefined')) nidsToRollBack[op.src.nid] = true
			if ((typeof op.dst !== 'undefined') && (typeof op.dst.nid !== 'undefined')) nidsToRollBack[op.dst.nid] = true
		})

		const rolledBack   = this.operations.filter((op) => {
			if (op.status == 'synced') return false
			if ((typeof op.nid !== 'undefined') && (nidsToRollBack[op.nid])) return true
			if ((typeof op.src !== 'undefined') && (typeof op.src.nid !== 'undefined') && (nidsToRollBack[op.src.nid])) return true
			if ((typeof op.dst !== 'undefined') && (typeof op.dst.nid !== 'undefined') && (nidsToRollBack[op.dst.nid])) return true
		}).reverse()

		//console.log('Roll Back', rolledBack.map(op => op.op))

		rolledBack.map((op) => {
			const reverse    = makeUndo.bind(this)(op)
			reverse.doNotLog = true
			this.op(reverse)
			op.status        = 'rolledBack'
			console.warn("ROLL BACK", op.op, op)
		})

		// apply new remote changes and adapt the rolled-back ones
		remoteOps.map((remoteOp) => {

			console.log('Remote op ' + remoteOp.op, remoteOp)
			this.op(remoteOp)

			for (var i=0; i<rolledBack.length; i++) {
				const op = rolledBack[i]
				applyOpToOp.bind(this)(remoteOp, op)
			}

		})

		// restore rolled-back ops
		rolledBack.reverse().map((op,i) => {

			delete op.status
			op.doNotLog     = true
			op.modChainMode = 'fix'
			console.log(op)

			this.op(op)

			delete op.doNotLog
			delete op.modChainMode
			op.status = 'done'

		})

		const preventDuplicates = {}

		const loadingEdges = remoteOps
			.filter(op => op.realm == 'edge')
			.filter((op) => {
				if ((typeof op.clientContext !== 'undefined') && (typeof op.clientContext.src !== 'undefined') && (typeof op.clientContext.src.nid !== 'undefined') && (typeof this.nodes[op.clientContext.src.nid] !== 'undefined' )) return true
				if ((typeof op.clientContext !== 'undefined') && (typeof op.clientContext.dst !== 'undefined') && (typeof op.clientContext.dst.nid !== 'undefined') && (typeof this.nodes[op.clientContext.dst.nid] !== 'undefined' )) return true
				})
			.filter((op) => { if (typeof preventDuplicates[op.eid] === 'undefined') { preventDuplicates[op.eid] = true; return true } else { return false } })
			.filter((op) => { return typeof this.edgeById(op.eid) === 'undefined' })
			.map((op) => {
				return this.loadEdge(op.eid)
				})

		Promise.all(loadingEdges)
			.then((res) => { /* console.log('loaded ' + res.length + ' missing edge(s)', res) */ })
			.catch((e) => { console.error('could not load missing edges', e) })
	}

	//@action
	bulkInsert(nodes, remoteMatches = {}) {

		nodes.map(node => {

			remoteMatches[node.nid] = true

			if (typeof this.nodes[node.nid] === 'undefined') {
				node.groups = node.groups.map(i => ({gid: i}))
				node.loaded = 'slim'
				this.insertNode(node)
			}
			else {
				// todo: update 'slim' information of local node (title, groups, ...)
			}
		})

	}

	/**
	 *  @brief Loads a node if it isn't present yet
	 *
	 *  @param [in] nid
	 *  @param [in] scope How much to load. Can be: slim (, txt, graph,) full, ...
	 *  @param [in] componentId The component doing the load (necessary for claiming/unclaiming it)
	 *  @return Return description
	 *
	 *  @details More details
	 */
	//@action
	loadNode(nid, scope = 'full', componentId) {
		console.log('Load Node ' + nid + ' (' + scope + ') for ' + componentId)

		if ((typeof this.nodes[nid] !== 'undefined') && (this.nodes[nid].loaded != 'fail') && ((this.nodes[nid].loaded != 'slim') || (scope == 'slim'))) {

			// add a claim to make sure that garbage collection can't remove it
			this.nodes[nid].claim(componentId)

			// do nothing else and wait for the regular sync to kick in, or trigger
			if (scope === this.nodes[nid].loaded) {
				//if (this.nodes[nid].type == 'card') this.nodeHistory.add(this.nodes[nid])
				return
			}
			else {
				// todo: how do I handle scope upgrades? Is there only one, from slim to full? In that case: simply do a regular load
			}
		}
		else {

			const oldNode = (typeof this.nodes[nid] === 'undefined') ? false : {...this.nodes[nid]}
			console.log('oldNode:', oldNode)

			this.nodes[nid]        = new Node({})
			this.nodes[nid].loaded = 'loading'
			this.nodes[nid].nid    = nid

			if (oldNode) {
				this.nodes[nid].type = oldNode.type
				this.nodes[nid].title = oldNode.title
				this.nodes[nid].t_created = oldNode.t_created
				this.nodes[nid].t_updated = oldNode.t_updated
				this.nodes[nid].t_seen    = oldNode.t_seen
				//this.nodes[nid]        = new Node(oldNode)
				//this.nodes[nid].loaded = 'loading'
			}

			storage
				.query({ action: 'nodeLoad', nid: nid, type: scope})
				.then((response) => {

					const node = response.data.data

					// Was the load successful?
					if (typeof node === 'undefined') {
						this.setNode(nid, {loaded: 'fail'})
						return false
					}

					if(this.insertNode(node)) {
						if (componentId) this.nodes[nid].claim(componentId)
						//if ((node.type == 'card') && (node.loaded=='full')) this.nodeHistory.add(this.nodes[nid])
						return true
					}

				})
				.catch((thrown) => {
					if (componentId) this.nodes[nid].release(componentId)
					this.setNode(nid, {loaded: 'fail'});
					console.error('loadNode failed:', thrown);
				});

		}

	}

	//@action
	insertNode(node, force = false) {
		// console.log('insert node', node.nid, Node)
		const nid          = node.nid
		const existingNode = this.nodes[nid]

		// Prepare the node
		// console.log('inserting ' + nid + ' to core')
		node.last_sync_uuid = node.last_mod_uuid
		var newNode

		newNode       = new Node(node)

		if (typeof node.paragraphs !== 'undefined') {
			for(let key in node.paragraphs) {
				newNode.paragraphs[key] = new Paragraph({
					user: this.user,
					edges: this.edges,
					paragraphList: newNode.paragraphList,
					last_sync_uuid: node.paragraphs[key].last_mod_uuid
					},
					node.paragraphs[key]
				);
			}
		}

		// move to a generalized: addPeripherals()
		if (node.edges) {
			node.edges.map((edge) => {
				if (this.edgeById(edge.eid)) return
				this.addEdge(edge)
			});
		}
		else {
			// console.log(node)
		}

		// Finalize the load process
		this.nodes[nid] = newNode
		newNode.loaded  = node.loaded

		return true;
	}

	loadState(t0, maxLid) {
		console.log('loadState', t0)
		this.connectivity.t0            = t0
		this.connectivity.maxLid        = maxLid
		this.connectivity.stateIsLoaded = true
	}


	userGroup(gid) {
		return this.groupByGid(gid)
	}

	groupByGid(gid) {
		const group = this.user.groups.filter( (g) => g.gid == gid	)
		if (group.length) return group[0]
		return false;
	}

	tagNodesByName(name) {
		return Object.keys(this.nodes).filter(nid => (this.nodes[nid].type == 'tag' && this.nodes[nid].title == name)).map(nid => this.nodes[nid])
	}

	//@action
	deleteNode(op) {
		const nid  = op.nid
		const node = this.nodes[nid]

		const obsoleteEdges = this.edges.map((e, i) => {
			if ((typeof e.src !== 'undefined') && (e.src.nid == nid)) return e.eid
			if ((typeof e.dst !== 'undefined') && (e.dst.nid == nid)) return e.eid
		}).filter(n => typeof n !== 'undefined')

		obsoleteEdges.map(eid => this.deleteEdge({eid: eid, lid: op.lid, set_mod_uuid: op.set_mod_uuid, prev_mod_uuid: op.prev_mod_uuid}))

		if (typeof node !== 'undefined') {
			// node.loaded = 'deleted'
			node.deleted       = true
			node.last_mod_uuid = op.set_mod_uuid || op.lid
			this.unloadNode(nid)
		}
	}

	//@action
	undeleteNode(op) {
		this.nodes[op.nid].deleted       = 0
		this.nodes[op.nid].t_updated     = Date.now()/1000
		this.nodes[op.nid].last_mod_uuid = op.set_mod_uuid
		// this.nodes[op.nid].loaded        = 'full' // deletion should not unset load-status, only onloading it through garbage collection should

		// restore deleted edges
		this.edges.filter(e => (e.deleted) && (e.last_mod_uuid == op.prev_mod_uuid) && (e.src.nid == op.nid || e.dst.nid == op.nid) ).map((edge) => {
			//console.log('Restore edge ' + edge.eid + ' [' + edge.last_mod_uuid + ' == ' + op.prev_mod_uuid + ']')
			this.undeleteEdge({eid: edge.eid, lid: op.lid, set_mod_uuid: op.set_mod_uuid, prev_mod_uuid: op.prev_mod_uuid})
		})
	}

	//@action
	deleteEdge(op) {
		const eid  = op.eid
		const edge = this.edgeById(eid)

		if (typeof edge !== 'undefined') {
			edge.deleted       = true
			// edge.loaded        = 'deleted'
			edge.last_mod_uuid = op.set_mod_uuid || op.lid

			// delete predicates
			const predicates = []
			if (edge.predicate_nid) predicates.push(edge.predicate_nid)
			if (edge.rpredicate_nid) predicates.push(edge.rpredicate_nid)
			predicates.map(nid => this.deleteNode({nid:nid, lid: op.lid}))

			this.unloadEdge(eid)
		}

		// in garbage collection: purge edge
		  //this.edges.splice(this.edgeIndex(op.eid), 1) // todo: do a legitimate op instead and properly retire the edge
			//edge.src           = {nid: edge.src.nid, pid: null, beg: null, end: null, title: null}
			//edge.dst           = {nid: edge.dst.nid, pid: null, beg: null, end: null, title: null}
	}

	//@action
	undeleteEdge(op) {
		const eid  = op.eid
		const edge = this.edgeById(eid)

		edge.deleted       = false
		edge.t_updated     = Date.now()/1000
		edge.last_mod_uuid = op.set_mod_uuid
		// edge.loaded  = 'full'  // deletion should not unset load-status, only onloading it through garbage collection should

		// undelete edge predicates ({eid: edge.eid, lid: op.lid, set_mod_uuid: op.set_mod_uuid, prev_mod_uuid: op.prev_mod_uuid})
		if (edge.predicate_nid)  this.undeleteNode({nid: edge.predicate_nid , lid: op.lid, set_mod_uuid: op.set_mod_uuid, prev_mod_uuid: op.prev_mod_uuid})
		if (edge.rpredicate_nid) this.undeleteNode({nid: edge.rpredicate_nid, lid: op.lid, set_mod_uuid: op.set_mod_uuid, prev_mod_uuid: op.prev_mod_uuid})
	}

	//@action
	nodeCreate(op) {
		const t0 = parseInt(Date.now()/1000)

		const node = new Node({
			uid: op.uid,
			nid: op.nid,
			title: op.title,
			type: op.type,
			t_created: t0,
			t_updated: t0,
			last_mod_uuid: op.lid,
			locked_uid: op.locked_uid,
			locked_t: op.locked_t,
		})

		if (op.uid == this.user.uid) node.groups = [this.groupByGid(this.user.defaultGid)]
		if (node.type == 'tag') {
			node.groups = [this.groupByGid(op.gid)]
		}

		const newPar = new Paragraph({user: this.user, edges: this.edges, paragraphList: node.paragraphList}, {
			pid: op.pid,
			uid: op.uid,
			nid: node.nid,
			markup: [],
			type: ((op.type == 'card') || (op.type == 'tag')) ? 'h1' : 'p',
			txt: op.title,
			t_created: Date.now(),
			t_updated: Date.now(),
			last_mod_uuid: op.lid,
		})

		node.paragraphList.push(newPar.pid)
		node.paragraphs[newPar.pid] = newPar

		this.nodes[op.nid] = node
		this.nodes[op.nid].createdOnTheFly = true
		this.nodes[op.nid].loaded = 'full'
	}


	edgeIndex(eid) {
		return this.edges.findIndex( (e) => (e.eid == eid) )
	}

	edgeById(eid) {
		return this.edges.filter(e => e.eid == eid)[0]
	}

	//@action
	addEdge(edge) {

		const alreadyAt = this.edgeIndex(edge.eid);

		if (alreadyAt == -1) {
			const e = new Edge(edge)
			this.edges.push(e)
		}
		else {
			// todo: only modify if new edge is different
			this.edges[alreadyAt] = new Edge(edge);
		}
	}

	//@action
	setNodeList(list) {
		this.nodelist.list = list;
	}

	//@action
	setNodeListLoaded(state) {
		this.nodelist.loaded = state;
	}

	//@action
	nodelistChangeOrder(key) {
		if (key == this.nodelist.orderBy.key) {
			this.nodelist.orderBy.dir = -this.nodelist.orderBy.dir;
		}
		else {
			this.nodelist.orderBy.key = key
			this.nodelist.orderBy.dir = -1
		}
		console.log(this.nodelist.orderBy)
	}

	//@action
	login(session) {
		this.user = new User(session.user)
		this.groups = []
		if (typeof session.user.ui_data !== 'undefined') Object.keys(session.user.ui_data).map(key => {
			this.ui.param[key] = session.user.ui_data[key]
		})
		delete session.user.ui_data
	}

	//@action
	logout = function () {
		this.user = false;
		this.connectivity.stateIsLoaded = false;
	}

	//@action
	setConnectivity = function(key, value) {
		this.connectivity[key] = value
		return
	}

	//@action
	throwFatalError = function(s) {
		this.fatalError = { msg: s }
	}

	//@action
	setUserDetails(data) {
		let obj = {}
		//obj[data.uid] = new User(data)
		this.cache.userDetails[data.uid] = new User(data)
		//extendObservable(this.cache.userDetails, obj)
		//extendObservable(this, obj)
	}

	getUserInfo = function(uid) {
		this.setUserDetails({uid: uid})
		storage.query({action: 'getUserInfo', uid: uid})
			.then((res) => {
				this.setUserDetails({...res.data.data, uid: uid})
			})
			.catch((thrown) => { console.log(thrown) })
	}

	//@action
	userinfo(uid) {
		if (typeof this.cache.userDetails[uid] === 'undefined') {
			this.cache.userDetails[uid] = new User({})
			this.getUserInfo(uid)
		}

		return this.cache.userDetails[uid]
	}

	getInvites = function () {
		return new Promise( (resolve, reject) => {
			storage.query({action: 'inviteGetList'})
				.then((res) => { resolve(res.data.data) })
				.catch((thrown) => { reject(thrown) })
		})
	}

	getGroups = function () {
		return new Promise( (resolve, reject) => {
			storage.query({action: 'groupGetList'})
				.then((res) => { resolve(res.data.data) })
				.catch((thrown) => { reject(thrown) })
		})
	}

	groupCreate = function (name) {
		return new Promise( (resolve, reject) => {
			storage.query({action: 'groupCreate', name: name})
				.then((res) => { resolve(res.data.data) })
				.catch((thrown) => { reject(thrown) })
		})
	}

	sendInvite = function (email) {
		return new Promise( (resolve, reject) => {
			storage.query({action: 'inviteSend', email: email})
				.then((res) => { resolve(res.data.data) })
				.catch((thrown) => { reject(thrown) })
		})
	}

	getWallData = function () {
		return new Promise( (resolve, reject) => {
			storage.query({action: 'wall'})
				.then((res) => { resolve(res.data.data) })
				.catch((thrown) => { reject(thrown) })
		})
	}

	/*
	lockable(id, remote = false) {
		const t       = Math.round((new Date()).getTime() / 1000)

		if (id.substr(0,2) == 'n-') {
			if (typeof this.nodes[id] !== 'undefined') {
				if ((this.nodes[id].locked_uid === null) || (this.nodes[id].locked_uid == this.user.uid) || (t - this.nodes[id].locked_t > 10 * 60)) return true
				return false
			}
		}

		if (id.substr(0,2) == 'e-') {
			const edge = this.edgeById(id)
			if (typeof edge !== 'undefined') {
				if ((edge.locked_uid === null) || (edge.locked_uid == this.user.uid) || (t - edge.locked_t > 10 * 60)) return true
				return false
			}
		}

		return undefined
	}*/

	//@action
	requestLock(id) {
		const t = Math.round((new Date()).getTime() / 1000)
		var node = undefined
		var edge = undefined

		return new Promise((resolve, reject) => {

			var query = new Promise((resolve, reject) => { resolve({ data: { data: { status: 200, details: 'performed in offline mode' } } }) })

			if (!this.connectivity.forceOffline) {
				query = storage.query({
					action: 'lock',
					id: id,
					acquire: true,
				})
			}

			query
				.then((res) => {

					if (res.data.data.status == 200) {
						if ((id.substr(0,2) == 'n-') && (typeof (node = this.nodes[id]) !== 'undefined')) {
							node.locked_uid = this.user.uid
							node.locked_t   = t
							resolve(res.data.data)
						}
						else if ((id.substr(0,2) == 'e-') && (typeof (edge = this.edgeById(id)) !== 'undefined')) {
							edge.locked_uid = this.user.uid
							edge.locked_t   = t
							if ((edge.predicate_nid) && (typeof (node = this.nodes[edge.predicate_nid]) !== 'undefined')) { node.locked_uid = this.user.uid; node.locked_t = t }
							if ((edge.rpredicate_nid) && (typeof (node = this.nodes[edge.rpredicate_nid]) !== 'undefined')) { node.locked_uid = this.user.uid; node.locked_t = t }
							resolve(res.data.data)
						}
						else {
							reject({status: 404, details: id + ' has gone away'})
						}
					}
					else if (res.data.data.status == 401) {
						if ((id.substr(0,2) == 'n-') && (typeof (node = this.nodes[id]) !== 'undefined')) {
							node.locked_uid = res.data.data.locked_uid
							node.locked_t   = t
							reject(res.data.data)
						}
						else if ((id.substr(0,2) == 'e-') && (typeof (edge = this.edgeById(id)) !== 'undefined')) {
							edge.locked_uid = res.data.data.locked_uid
							edge.locked_t   = t
							reject(res.data.data)
						}
						else {
							reject({status: 404, details: id + ' has gone away'})
						}
					}
					else {
						reject(res.data.data)
					}
				})
				.catch((thrown) => {
					reject(thrown)
				})
		})
	}

	//@action
	releaseLock(id) {
		const t = Math.round((new Date()).getTime() / 1000)
		var node = undefined
		var edge = undefined

		return new Promise((resolve, reject) => {
			var query = new Promise((resolve, reject) => { resolve({ data: { data: { status: 200, details: 'performed in offline mode' } } }) })

			if (!this.connectivity.forceOffline) {
				query = storage.query({
					action: 'lock',
					id: id,
					acquire: false,
				})
			}

			query
				.then((res) => {
					if(res.data.data.status == 200) {
						if ((id.substr(0,2) == 'n-') && (typeof (node = this.nodes[id]) !== 'undefined')) {
							node.locked_uid = null
							node.locked_t   = t
							resolve(res.data.data)
						}
						else if ((id.substr(0,2) == 'e-') && (typeof (edge = this.edgeById(id)) !== 'undefined')) {
							edge.locked_uid = null
							edge.locked_t   = t
							if ((edge.predicate_nid) && (typeof (node = this.nodes[edge.predicate_nid]) !== 'undefined')) { node.locked_uid = null; node.locked_t = t }
							if ((edge.rpredicate_nid) && (typeof (node = this.nodes[edge.rpredicate_nid]) !== 'undefined')) { node.locked_uid = null; node.locked_t = t }
							resolve(res.data.data)
						}
						else {
							reject({status: 404, details: id + ' has gone away'})
						}
					}
					else if (res.data.data.status == 401) {
						if ((id.substr(0,2) == 'n-') && (typeof (node = this.nodes[id]) !== 'undefined')) {
							node.locked_uid = res.data.data.locked_uid
							node.locked_t   = t
							reject(res.data.data)
						}
            else if ((id.substr(0,2) == 'e-') && (typeof (edge = this.edgeById(id)) !== 'undefined')) {
							edge.locked_uid = res.data.data.locked_uid
							edge.locked_t   = t
							reject(res.data.data)
						}
						else {
							reject({status: 404, details: id + ' has gone away'})
						}
					}
					else if (res.data.data.status == 404) {
						reject(res.data.data)
					}
					else {
						reject(res.data.data)
					}

				})
				.catch((thrown) => {
					reject(thrown)
				})
		})
	}

	preferredGroups(node, perm = false) {
		const availableGroups = []
		const preferredGroups = []

		node.groups.filter( (g) => {
			const group = this.userGroup(g.gid)
			if (group) {
				const allowed = ((perm === false) || (group[perm]))
				if (allowed) availableGroups.push(group)
				if ((group.active) && (allowed)) preferredGroups.push(group)
			}
		} )

		if (preferredGroups.length) return preferredGroups

		return availableGroups
	}

	nodeScope(nid, perm = false) {
		const isActive = {},
					scope = [],
					permId = false;
		let   nofFavoredGroups = 0

		//if (typeof this.node === 'undefined') return false
		//if (typeof this.node[nid] === 'undefined') return false

		this.user.groups.map( (g) => { if (g.active) { isActive[g.gid] = true; nofFavoredGroups++ } } )

		this.nodes[nid].groups.map( (g) => {
			const group = this.userGroup(g.gid)
			if (group) {
				if ((permId == false) || (this.user.permission(perm.perm, perm.realm, perm.obj))) {
					if ((nofFavoredGroups == 0) || (isActive[g.gid])) {
						//console.log(userGroupStatus)
						scope.push(group)
					}
				}
			}
		})

		return scope
	}

	getHost(url) {
		let l = document.createElement("a")
    l.href = url
    return l.hostname
	}


	markedText(mark) {
		if (typeof this.nodes[mark.nid] === 'undefined') return null
		if (typeof this.nodes[mark.nid].paragraphs[mark.pid] === 'undefined') return null
		return this.nodes[mark.nid].paragraphs[mark.pid].txt.substr(mark.beg, mark.end-mark.beg)
	}

	fulldate(t) {
		try {
			if (t > 10443998871) t /= 1000
			return SugarDate.Date.format(new SugarDate.Date.create(1000 * t), '%Y-%m-%d %H:%M:%S')
		} catch(e) {
			return 'invalid date'
			// console.log(t, typeof t, e)
		}
	}

	mindate(t) {
		try {
			const t0     = Date.now() / 1000
			if (t > 10443998871) t /= 1000

			if ((t0 - t) < (24 * 60 * 60)) {
				return SugarDate.Date.format(new SugarDate.Date.create(1000 * t), '%H:%M')
			}
			else if ((t0 - t) < (180 * 24 * 60 * 60)) {
				return SugarDate.Date.format(new SugarDate.Date.create(1000 * t), '%d. %b')
			}
			else {
				return SugarDate.Date.format(new SugarDate.Date.create(1000 * t), '%b %Y')
			}
		} catch(e) {
			return '-'
			// console.log(t, typeof t, e)
		}
	}

	queueForUpload(file, fid, nid, quotaGroup) {

		this.fileUploadQueue.push({
			status: 'pending',
			file,
			nid,
			fid,
			quotaGroup,
		})

		console.log('Queued', file)
	}

}

/* Store helpers */

const StoreContext = React.createContext();

export const StoreProvider = ({ children, core }) => {
  return (
    <StoreContext.Provider value={core}>{children}</StoreContext.Provider>
  );
};

/* Hook to use store in any functional component */
export const useStore = () => React.useContext(StoreContext);

/* HOC to inject store to any functional or class component */
export const withStore = (Component) => (props) => {
  return <Component {...props} core={useStore()} />;
};
