import React from "react";
import { observer, inject, action, runInAction } from "mobx-react";
import { withStore } from '../utility/Core'

//
import NodeContextLike from "./nodeContext/NodeContextLike";
import NodeContextComment from "./nodeContext/NodeContextComment";
import NodeContextEdge from "./nodeContext/NodeContextEdge";
import NodeContextAnnotation from "./nodeContext/NodeContextAnnotation";
import NodeContextTag from "./nodeContext/NodeContextTag";

import ModalTagger from "./ModalTagger";
import ModalEdgeDialog from "./ModalEdgeDialog";

import Topography from "./Visualization/Topography";

class DirectParagraphTextStyles extends React.Component {

	constructor(props) {
		super(props)

		this.domRef       = React.createRef();

		this.state       = {
			hotY: 0,
			boxY: 0,
			toolsExpanded: false,
			contextBackground: 0,
			openTagger: false,
			openEdger:  false,
		}

		this.prev = {
			nid: undefined,
			pid: undefined,
		}

		this.backgrounds = [
			'Blank',
			'Topography',
			'Outline',
		]

		this.keypress = this.keypress.bind(this)
	}

	keypress(e) {
		if ((e.ctrlKey) && (e.shiftKey) && (e.keyCode == 75)) { this.setState({openTagger: true}); e.preventDefault() }
		if ((e.ctrlKey) && (e.shiftKey) && (e.keyCode == 76)) { this.setState({openEdger: true}); e.preventDefault() }
		//console.log(e.keyCode)
	}

	componentDidMount() {
		// console.log(document.activeElement)
		this.setPosition()
		document.addEventListener('keydown', this.keypress);
	}

	componentDidUpdate() {
		this.setPosition()
	}

	componentWillUnmount() {
		document.removeEventListener('keydown', this.keypress);
	}

	setPosition() {
		const { core, nid, nodeComponentId } = this.props
		const DOM_canvas = this.domRef.current
		const DOM_box    = this.domRef.current ? this.domRef.current.childNodes[0] : undefined

		if (!core.caretX[nodeComponentId]) return
		if (!DOM_canvas) return

		const canvas = DOM_canvas.getBoundingClientRect()

		var offset = {
			top:0,
			left:0,
			height: 24,
			widht: 24,
		}

		if (DOM_box) {
			offset = DOM_box.getBoundingClientRect()
		}

		const caretTop     = (core.caretX[nodeComponentId].top  - (core.caretX[nodeComponentId].scrollTop || 0) + 32)
		const caretBottom  = (core.caretX[nodeComponentId].bottom  - (core.caretX[nodeComponentId].scrollTop || 0) + 32)
		const hotY         = ((caretTop + caretBottom) / 2)
		const boxY         = Math.min(Math.max(hotY - (offset.height / 2), 32), canvas.height - offset.height)
		const canvasBottom = canvas.height

		if (isNaN(hotY)) return
		if (isNaN(boxY)) return

		if ((hotY != this.state.hotY) || (boxY != this.state.boxY)) this.setState({
			hotY: hotY,
			boxY: boxY,
			canvasBottom: canvasBottom,
		})
	}

	addAnnotation() {
		const { core, nid, nodeComponentId, setCursorAfterOp } = this.props
		const pos = core.caretX[nodeComponentId].pos
		const pid = core.caretX[nodeComponentId].pid
		const length = core.caretX[nodeComponentId].length
		const paragraph = core.nodes[core.caretX[nodeComponentId].nid].paragraphs[core.caretX[nodeComponentId].pid]

		core.op({
			op: 'edgeCreate',
			realm: 'edge',
			eid: core.uuid('e'),
			type: 'annotation',
			src: {
				nid: nid, pid: pid, beg: Math.min(pos+length, pos), end: Math.max(pos+length, pos), prev_mod_uuid: paragraph.last_mod_uuid,
			},
			dst: {
				nid: null, pid: null, beg: null, end: null
			},
			uid: core.user.uid,
			lid: core.uuid('l'),
		})

		setCursorAfterOp(null, pid, pos+length, -length)
	}

	addLike() {
		const { core, nodeComponentId, setCursorAfterOp } = this.props
		const nid = core.caretX[nodeComponentId].nid
		const pid = core.caretX[nodeComponentId].pid
		const pos = core.caretX[nodeComponentId].pos
		const length = core.caretX[nodeComponentId].length
		const paragraph = core.nodes[nid].paragraphs[pid]

		core.op({
			op: 'edgeCreate',
			realm: 'edge',
			eid: core.uuid('e'),
			type: 'like',
			src: {
				nid: nid, pid: pid, beg: Math.min(pos+length, pos), end: Math.max(pos+length, pos), prev_mod_uuid: paragraph.last_mod_uuid,
			},
			dst: {
				nid: null, pid: null, beg: null, end: null
			},
			uid: core.user.uid,
			lid: core.uuid('l'),
		})

		setCursorAfterOp(null, pid, pos+length, -length)
	}

	addLink() {
		const { core, nid, nodeComponentId, setCursorAfterOp } = this.props

		const pos = core.caretX[nodeComponentId].pos
		const pid = core.caretX[nodeComponentId].pid
		const length = core.caretX[nodeComponentId].length
		const paragraph = core.nodes[core.caretX[nodeComponentId].nid].paragraphs[core.caretX[nodeComponentId].pid]

		core.op({
			op: 'edgeCreate',
			realm: 'edge',
			eid: core.uuid('e'),
			type: 'edge',
			src: {
				nid: nid, pid: pid, beg: Math.min(pos+length, pos), end: Math.max(pos+length, pos), prev_mod_uuid: paragraph.last_mod_uuid,
			},
			dst: {
				nid: null, pid: null, beg: null, end: null
			},
			uid: core.user.uid,
			lid: core.uuid('l'),
		})

		setCursorAfterOp(null, pid, pos+length, -length)

	}

	addComment() {
		const { core, nid, nodeComponentId, setCursorAfterOp } = this.props
		const pos = core.caretX[nodeComponentId].pos
		const pid = core.caretX[nodeComponentId].pid
		const length = core.caretX[nodeComponentId].length
		const paragraph = core.nodes[core.caretX[nodeComponentId].nid].paragraphs[core.caretX[nodeComponentId].pid]

		const nNid = core.uuid('n')
		const eid  = core.uuid('e')
		const uid  = core.user.uid
		const t0   = Math.round((new Date()).getTime() / 1000)


		core.op({
			realm: 'node',
			op: 'nodeCreate',
			nid: nNid,
			pid: core.uuid('p'),
			title: '',
			type: 'comment',
			info: {
				rel: nid
			},
			uid: core.user.uid,
			lid: core.uuid('l'),
			locked_uid: uid,
			locked_t: t0,
		})

		core.op({
			op: 'edgeCreate',
			realm: 'edge',
			eid: eid,
			type: 'comment',
			src: {
				nid: nid, pid: pid, beg: Math.min(pos+length, pos), end: Math.max(pos+length, pos), prev_mod_uuid: paragraph.last_mod_uuid,
			},
			dst: {
				nid: nNid, pid: null, beg: null, end: null
			},
			info: {
				rel: nid
			},
			uid: core.user.uid,
			lid: core.uuid('l'),
		})

		setCursorAfterOp(null, pid, pos+length, -length)

	}

	activeContext() {
		const { core, nodeComponentId, setCursorAfterOp } = this.props
		const { hotY, boxY, canvasBottom, toolsExpanded } = this.state

		if (Object.keys(core.caretX).indexOf(nodeComponentId) == -1) return null
		if (typeof core.nodes[core.caretX[nodeComponentId].nid] === 'undefined') return null
		if (typeof core.nodes[core.caretX[nodeComponentId].nid].paragraphs[core.caretX[nodeComponentId].pid] === 'undefined') return null

		const nid    = core.caretX[nodeComponentId].nid // nb: wir kriegen auch ein nid in den this.props... die sollten identisch sein!
		if (nid != this.props.nid) {
			return null
			throw('Panic: invalid caret nid ' + nid + '(vs. '+this.props.nid+')')
		}

		const node      = core.nodes[nid]
		const pos       = core.caretX[nodeComponentId].pos
		const length    = core.caretX[nodeComponentId].length
		const pid       = core.caretX[nodeComponentId].pid
		const paragraph = core.nodes[nid].paragraphs[pid]
		const markerY = Math.min(Math.max(32, (hotY-6)), canvasBottom - 12)

		if (!node) return null

		// we need this to trigger a redraw on changes
		const dummy   = core.caretX[nodeComponentId].scrollTop

		//
		var tools = [<span key="trigger" className="context-button fa fa-caret-left trigger" />]
		if ((core.user.perms.annotation) && (true))                                               tools.push(<span key="anno" className="context-button fa fa-exclamation" onClick={this.addAnnotation.bind(this)} />)
		if ((core.user.perms.like)     && (core.user.permission('create', 'edge_like', node)))    tools.push(<span key="like" className="context-button fa fa-thumbs-o-up" onClick={this.addLike.bind(this)} />)
		if ((core.user.perms.comment)  && (core.user.permission('create', 'edge_comment', node))) tools.push(<span key="comm" className="context-button fa fa-comment-o" onClick={this.addComment.bind(this)} />)
		if ((core.user.perms.tag)      && (core.user.permission('create', 'edge_tag', node)))     tools.push(<span key="tags" className="context-button fa fa-hashtag" onClick={() => {this.setState({openTagger: true})}} />)
		//if ((core.user.perms.edge)     && (core.user.permission('create', 'edge_edge', node)))    tools.push(<span key="edge" className="context-button fa fa-link" onClick={this.addLink.bind(this)} />)
		if ((core.user.perms.edge)     && (core.user.permission('create', 'edge_edge', node)))    tools.push(<span key="edge" className="context-button fa fa-link" onClick={() => {this.setState({openEdger: true})}} />)

		//
		const edgeToolSet = toolsExpanded ? <div className="context-buttons" onClick={() => { this.setState({ toolsExpanded: false }) }}>
			{tools}
		</div> : <div className="context-buttons" onClick={() => { this.setState({ toolsExpanded: true }) }}>
			<span className="context-button fa fa-caret-right trigger" />
		</div>

		const edgeTools = length ? edgeToolSet : null

		// Collect all edges that are activated by the Cursor / Caret
		const activeEdges = paragraph.edgeMarks.map((m) => {

			var edge = null

			if (
				(
					(
						(m.local.beg !== null) || (m.local.end !== null)
					)
					&&
					( !m.edge.deleted )
					&&
					(
						((pos>=m.local.beg) && (pos <= m.local.end)) ||
						((pos+length>=m.local.beg) && (pos+length <= m.local.end)) ||
						((pos>=m.local.beg) && (pos+length <= m.local.end))
					)
				) || ((m.local.beg === pos) && (m.local.end === pos))
				) {

					switch(m.edge.type) {

						case 'like':
							edge = <NodeContextLike key={m.edge.eid} eid={m.edge.eid} nodeComponentId={nodeComponentId} localNid={nid} setCursorAfterOp={ setCursorAfterOp.bind(this) } />
							break;

						case 'comment':
							edge = <NodeContextComment key={m.edge.eid} eid={m.edge.eid} nodeComponentId={nodeComponentId} localNid={nid} setCursorAfterOp={ () => { setCursorAfterOp(null, paragraph.pid, pos+length, -length) } } />
							break;

						case 'edge':
							edge = <NodeContextEdge key={m.edge.eid} eid={m.edge.eid} nodeComponentId={nodeComponentId} localNid={nid} />
							break;

						case 'tag':
							edge = <NodeContextTag key={m.edge.eid} eid={m.edge.eid} nodeComponentId={nodeComponentId} localNid={nid} />
							break;

						case 'annotation':
							edge = <NodeContextAnnotation key={m.edge.eid} eid={m.edge.eid} nodeComponentId={nodeComponentId} localNid={nid} />
							break;

						default:
							edge = <span key={m.edge.eid} className={'contextual-edge edge-' + m.edge.type}>{m.edge.type}: {m.edge.eid} [ {core.caretX[nodeComponentId].top} ] [{m.edge.type}]</span>
					}

			}
			return edge
		}).filter( e => e !== null)

		var modal = null

		if (this.state.openTagger) modal = <ModalTagger key="modal-tagger"
			marker={{nid: nid, pid: pid, beg: pos+length, end: pos }}
			onClose={() => { this.setState({ openTagger: false })	}}
			onSelect={(result) => { this.setState({ openTagger: false })	}}
			/>

		if (this.state.openEdger) modal = <ModalEdgeDialog key="modal-edger"
			marker={{nid: nid, pid: pid, beg: pos+length, end: pos }}
			onClose={() => { this.setState({ openEdger: false })	}}
			onSelect={(result) => { this.setState({ openEdger: false })	}}
		/>

		// Display active content if we have edges to show, or at least the tools...
		const activeContext = (edgeTools || activeEdges.length) ? [
			<div key="box" className="active-context" style={{top: (boxY+ 'px')}}>
				{activeEdges}
				{edgeTools}
			</div>,
			<div key="pointer" className="active-context-hot-pointer" style={{top: (markerY + 'px')}}>
				<div className="pointer"></div>
			</div>,
			modal
		]	: modal

		return activeContext
	}

	render() {
		const { core, nid, nodeComponentId, setCursorAfterOp, scrollNodeTo } = this.props
		const contextBackground = core.ui.param.singleNodeContextType

		const node = core.nodes[nid]
		const activeContext = this.activeContext()

		var topography   = null
		var buttons      = null
		var outline      = null

		var classes = "node-context"
		if (activeContext) classes = classes + ' backgrounded'

		const nextBackground = (contextBackground+1) % 3
		buttons = <div className="context-switcher">
			<div className="button non-mark" onClick={() => { core.ui.set('singleNodeContextType', nextBackground) }}>Show {this.backgrounds[nextBackground]}</div>
		</div>

		if (contextBackground == 1) {
			const activeGroups = core.user.groups.filter(g => g.active)
			const filter = []

			activeGroups.map((g) => {
				filter.push({
					key: 'group',
					value: g.gid
				})
			})

			topography  = <Topography className="context-topography" contextNid={nid} filter={filter} backgrounded={activeContext ? true:false} />
		}



		if ((node) && (contextBackground == 2)) {
			const backgrounded = activeContext ? true : false

			outline = <div className={"context-outline non-mark" + (backgrounded ? ' backgrounded': '' )}>
			{node.paragraphList.map(pid => {
				const paragraph = node.paragraphs[pid]
				if ((paragraph.type != 'h1') && (paragraph.type != 'h2') && (paragraph.type != 'h3')) return null
				return <div key={"outline-"+pid} onClick={() => { if (!backgrounded) scrollNodeTo(pid)}} className={'outline-' + paragraph.type + (backgrounded ? '' : ' clickable' )}>
					{paragraph.txt}
				</div>
			}).filter( p => p !== null)}
			</div>
		}

		return <div className={classes} ref={this.domRef}>
			{activeContext}
			{topography}
			{outline}
			{buttons}
			<div id="context-mouse-catcher" onClick={ (e) => { core.setCaretX(nodeComponentId) } }></div>
		</div>

	}

}

export default withStore(observer(DirectParagraphTextStyles))
